import { AxiosPromise } from 'axios';
import { plainToClass } from 'class-transformer';
import { useSnackbar } from 'notistack';
import React, { createContext, useContext, useState } from 'react';
import useRouter from 'use-react-router';

import { client } from '../../apis';
import { ASSESSMENT_URI } from '../../apis/constants';
import { ASSESSMENT_REQUEST_ACTIONS, ControlTypes, Question } from '../../models';
import { AssessmentForm } from '../../models/assessment-dto';
import { JwtPayload } from '../../models/auth';
import { apiMessages } from '../../siteSettings';
import { errorCbTemplate, infoOptions, successOptions } from '../../utils/snackbar';
import { useUserState } from '../context/UserStateContext';
import { useAssessmentManager, useAssessorManager } from './AssessmentContext';
import * as AuthService from '../../apis/auth';

export interface AssessmentRequestContextProps {
  setAnswers: (answers: QuestionAnswerDto[]) => void;
  answers: QuestionAnswerDto[];
  setComments: (comments: ComponentComment[]) => void;
  comments: ComponentComment[];
  requestStatus: string;
  setRequestStatus: (value: string) => void;
  assessmentRequest: AssessmentForm;
  setAssessmentRequest: (value: AssessmentForm) => void;
}

export interface AssessmentRequestProviderProps {
  children: React.ReactNode;
  assessment: AssessmentForm;
  user: JwtPayload;
}

export const AssessmentRequestContext = createContext<AssessmentRequestContextProps>({
  answers: [],
  setAnswers: () => {},
  comments: [],
  setComments: () => {},
  requestStatus: '',
  setRequestStatus: () => {},
  assessmentRequest: null,
  setAssessmentRequest: () => {},
});

export const AssessmentRequestConsumer = AssessmentRequestContext.Consumer;

export const AssessmentRequestProvider = ({
  children,
  comments: _comments,
  requestStatus: _requestStatus,
  assessmentRequest: _assessmentRequest,
}: {
  children: React.ReactNode;
  comments: ComponentComment[];
  requestStatus: string;
  assessmentRequest?: AssessmentForm;
}) => {
  const { questions } = useAssessmentManager();
  const [assessmentRequest, setAssessmentRequest] = useState<AssessmentForm>(
    plainToClass(AssessmentForm, _assessmentRequest),
  );
  const [requestStatus, setRequestStatus] = useState(_requestStatus);
  const [comments, setComments] = useState<ComponentComment[]>(_comments);
  const [answers, setAnswers] = useState<QuestionAnswerDto[]>(initialAnswers());
  const value = {
    answers,
    setAnswers,
    comments,
    setComments,
    requestStatus,
    setRequestStatus,
    assessmentRequest,
    setAssessmentRequest,
  };
  return <AssessmentRequestContext.Provider value={value}>{children}</AssessmentRequestContext.Provider>;

  function initialAnswers() {
    return questions.map(question => {
      if (question.answer) {
        const answer = question.answer;
        return new QuestionAnswerDto({
          questionId: question.id,
          controlType: question.control,
          comment: (answer && answer.comment) || '',
          answer: {
            choiceId: answer.choiceId,
            choiceIds: answer.choiceIds,
            yesNo: answer.yesNo,
            rating: answer.rating,
            detail: answer.detail,
            date: answer.date,
            controlType: question.control,
          },
        });
      }

      return new QuestionAnswerDto({
        questionId: question.id,
        controlType: question.control,
        comment: '',
        answer: {
          controlType: question.control,
          choiceIds: [],
          detail: '',
        },
      });
    });
  }
};

export const useAssessmentRequest = () => {
  const { answers, setAnswers, comments = [], requestStatus, assessmentRequest } = useContext(AssessmentRequestContext);
  const {
    assessment,
    isProcessing,
    setIsProcessing,
    setErrors,
    setPermissions,
    setQuestions,
    setEvents,
    remark,
  } = useAssessmentManager();
  const { _addAssessor, _deleteAssessor, getAllAssessors, getAllUniqueAssessors } = useAssessorManager();
  const { enqueueSnackbar } = useSnackbar();
  const { history } = useRouter();
  const { user } = useUserState();
  const rootId =
    assessmentRequest && assessmentRequest.assessors.find(assessor => assessor.assessorRole === 'ASSESSOR').employeeId;
  const SAVE_ASSESSMENT_URI = `assessment-requests/${assessment.no}/${rootId}/save-assessment`;
  const SUBMIT_ASSESSMENT_URI = `assessment-requests/${assessment.no}/${rootId}/submit-assessment`;
  const SUBMIT_FOR_ASSIGNMENT_URI = `assessment-requests/${assessment.no}/${rootId}/submit-assign-for-assignment`;
  const UPDATE_CLOSING_DATE_URI = `assessment-requests/${assessment.no}/${rootId}/update-closing-date`;
  const MANAGE_PREPARER_URI = `assessment-requests/${assessment.no}/${rootId}/preparers`;
  const ASSIGN_ALL_URI = `assessment-requests/${assessment.no}/${rootId}/assign-preparers`;

  const getRootId = () => {
    return getAllAssessors().find(assessor => assessor.assessorRole === 'ASSESSOR').employeeId;
  };

  const getAllPreparer1st = () => {
    return getAllUniqueAssessors().filter(a => a.assessorRole === 'PREPARER_1ST');
  };

  const getAssessor = (employeeId: string) => {
    return getAllAssessors().find(a => a.employeeId === employeeId);
  };

  const getCurrentUserAsAssessor = () => {
    return getAssessor(user.id);
  };

  const updateAssessmentCb = ({ data: payload }) => {
    const data = payload.data as AssessmentForm;
    setQuestions(data.questions);
    setEvents(data.events);
    setPermissions(data.permissions);
    return Promise.resolve();
  };

  const errorCb = (err: any) => {
    errorCbTemplate(enqueueSnackbar)(err);
    if (err.response && err.response.status === 400) {
      setErrors(err.response.data.message);
    } else {
      console.error(err);
    }
  };

  const getAssessmentRequest = () => {
    return client.get(`/assessment-requests/${assessment.no}/${rootId}`);
  };

  const updateClosingDate = (data: any): AxiosPromise => {
    if (isProcessing('closing-date')) return;
    setIsProcessing('closing-date', true);
    return client
      .post(UPDATE_CLOSING_DATE_URI, { changedClosingDate: data.changedClosingDate })
      .then(getAssessmentRequest)
      .then(response => {
        enqueueSnackbar('Updated Successfully', infoOptions);
        updateAssessmentCb(response);
        return response;
      })
      .finally(() => setIsProcessing('closing-date', false));
  };

  const getQuestionAnswer = (questionId: string): QuestionAnswerDto => {
    return answers.find(a => Number(a.questionId) === Number(questionId));
  };

  const updateQuestionAnswer = (question: Question, newAnswer: QuestionAnswerDto) => {
    // console.group();
    // console.log(question, newAnswer);
    // console.groupEnd();
    const alreadyHas = answers.some(answer => answer.questionId === question.id);
    const newAnswers = answers.map(answer => {
      if (answer.questionId === question.id) return newAnswer;
      return answer;
    });

    if (alreadyHas) {
      setAnswers(newAnswers);
    } else {
      setAnswers(newAnswers.concat(newAnswer));
    }
  };

  const save = () => {
    if (isProcessing('save-draft-answer')) return;
    setIsProcessing('save-draft-answer', true);
    setErrors([]);
    return client
      .post(SAVE_ASSESSMENT_URI, { questions: answers, comments })
      .then(() => enqueueSnackbar('Save Draft Successfully', infoOptions))
      .catch(errorCb)
      .finally(() => {
        setIsProcessing('save-draft-answer', false);
        AuthService.refreshToken();
      });
  };

  const submit = () => {
    if (isProcessing('submit-answer')) return;
    setIsProcessing('submit-answer', true);
    setErrors([]);
    return client
      .post(SAVE_ASSESSMENT_URI, { questions: answers, comments })
      .then(() => client.post(SUBMIT_ASSESSMENT_URI, { remark }))
      .then(() => {
        enqueueSnackbar('Submit Successfully', infoOptions);
        history.push('/worklist');
      })
      .catch(errorCb)
      .finally(() => {
        setIsProcessing('submit-answer', false);
        AuthService.refreshToken();
      });
  };

  const assign = (componentId: string, questionId: string, employeeId: string) => {
    if (isProcessing('assign-preparer')) return;
    setIsProcessing('assign-preparer', true);

    return client
      .post(MANAGE_PREPARER_URI, {
        employeeId,
        questionId,
        assessorType: false,
      })
      .then(response => {
        const { data: payload } = response.data;
        _addAssessor(questionId, payload);
        enqueueSnackbar(apiMessages.assessor.ADD_TEXT, successOptions);
        return response;
      })
      .catch(err => {
        if (err.response && err.response.status === 400) {
          console.error(err);
        } else {
          errorCb(err);
        }
        return Promise.reject(err);
      })
      .finally(() => {
        setIsProcessing('assign-preparer', false);
      });
  };

  const deletePreparer = (componentId: string, questionId: string, employeeId: string) => {
    if (isProcessing('assign-preparer')) return;
    setIsProcessing('assign-preparer', true);

    return client
      .delete(MANAGE_PREPARER_URI, {
        data: {
          employeeId,
          questionId,
        },
      })
      .then(response => {
        _deleteAssessor(questionId, { employeeId, questionId } as any);
        enqueueSnackbar(apiMessages.assessor.DELETE_TEXT, successOptions);
        return response;
      })
      .catch(errorCb)
      .finally(() => setIsProcessing('assign-preparer', false));
  };

  const submitToAssign = () => {
    if (isProcessing('submit-to-assign')) return;
    setIsProcessing('submit-to-assign', true);
    return client
      .post(SAVE_ASSESSMENT_URI, { questions: answers, comments })
      .then(() => client.post(SUBMIT_FOR_ASSIGNMENT_URI, { remark }))
      .then(response => {
        enqueueSnackbar(apiMessages.assessor.ADD_TEXT, successOptions);
        history.push('/worklist');
        return response;
      })
      .catch(errorCb)
      .finally(() => {
        setIsProcessing('submit-to-assign', false);
        AuthService.refreshToken();
      });
  };

  const addCommentator = (componentId: string, questionId: string, employeeId: string) => {
    if (isProcessing('add-commentator')) return;
    setIsProcessing('add-commentator', true);
    return client
      .post(`assessors/${questionId}/${employeeId}/commentator`)
      .then(getAssessmentRequest)
      .then(updateAssessmentCb)
      .catch(errorCb)
      .finally(() => setIsProcessing('add-commentator', false));
  };

  const removeCommentator = (componentId: string, questionId: string, employeeId: string) => {
    if (isProcessing('remove-commentator')) return;
    setIsProcessing('remove-commentator', true);
    return client
      .delete(`assessors/${questionId}/${employeeId}/commentator`)
      .then(() => client.get(`/assessment-requests/${assessment.no}/${rootId}`))
      .then(updateAssessmentCb)
      .catch(errorCb)
      .finally(() => setIsProcessing('remove-commentator', false));
  };

  const assignAll = (assessmentNo: string, employeeId: string, isCommentator: boolean) => {
    if (isProcessing('assign-all')) return;
    setIsProcessing('assign-all', true);
    return client
      .post(ASSIGN_ALL_URI, {
        employeeId,
        isCommentator,
        assessorType: false,
      })
      .then(() => client.get(`/assessment-requests/${assessment.no}/${rootId}`))
      .then(response => {
        enqueueSnackbar(apiMessages.assignAll.ADD_TEXT, successOptions);
        return response;
      })
      .then(updateAssessmentCb)
      .catch(errorCb)
      .finally(() => setIsProcessing('assign-all', false));
  };

  const hasAnyActions = (action: ASSESSMENT_REQUEST_ACTIONS) => {
    return assessmentRequest && assessmentRequest.allowedActions.includes(action);
  };

  return {
    assignAll,
    save,
    answers,
    submit,
    updateClosingDate,
    assign,
    getQuestionAnswer,
    updateQuestionAnswer,
    submitToAssign,
    deletePreparer,
    addCommentator,
    removeCommentator,
    requestStatus,
    assessmentRequest,
    hasAnyActions,
    getRootId,
    getCurrentUserAsAssessor,
    getAllAssessors,
    getAllUniqueAssessors,
    getAllPreparer1st,
    getAssessor,
  };
};

export interface QuestionAnswerNest {
  choiceId?: number;
  choiceIds?: any[];
  date?: Date;
  detail?: string;
  rating?: number;
  yesNo?: boolean;
  controlType: ControlTypes;
}

export class ComponentComment {
  componentId: string;
  comment: string;
}

export class QuestionAnswerDto {
  questionId?: string;
  controlType: ControlTypes;
  comment?: string;
  hasPermission?: boolean;
  answer: QuestionAnswerNest;

  constructor(partial: Partial<QuestionAnswerDto> = {}) {
    Object.assign(this, { ...partial });
  }

  static build(partial: Partial<QuestionAnswerDto>) {
    return new QuestionAnswerDto(partial);
  }

  updateAnswer?(answer: Partial<QuestionAnswerNest>) {
    this.answer = { ...this.answer, ...answer };
    return this;
  }
}
