import JSZip from 'jszip';
import { baseURL } from '../apis/constants';

export const onBulkDownload = async (question: any) => {
  const files: typeof question.attachments = question.attachments || [];
  const assessmentNo = question.assessmentNo || 'files.zip';
  if (!files || files.length === 0) {
    console.error('No files to download');
    return;
  }

  const zip = new JSZip();
  const downloadPromises = files.map(file => {
    return new Promise<void>((resolve, reject) => {
      if (!file || !file.title) {
        console.error('Invalid file or file title');
        return reject();
      }

      const hrefURLFile = `${baseURL}/static/${file.uri ? file.uri : `${file.bucket}/${file.fileName}`}`;
      const xhr = new XMLHttpRequest();

      xhr.open('GET', hrefURLFile, true);
      xhr.responseType = 'blob';
      xhr.onload = function () {
        if (xhr.status === 200) {
          zip.file(file.title, xhr.response);
          resolve();
        } else {
          // console.error('Failed to download file');
          resolve()
          // reject();
        }
      };
      xhr.send();
    });
  });

  try {
    await Promise.all(downloadPromises);
    const content = await zip.generateAsync({ type: 'blob' });
  
    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(content);

    a.download = `${assessmentNo}.zip`; // Set the desired name for your zip file
    document.body.appendChild(a); // Append to body to ensure it works in all browsers
    a.click();
    document.body.removeChild(a); // Clean up after click
  } catch (error) {
    console.error('An error occurred during zip creation:', error);
  }

};
