import { Box, Grid, Typography, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { memo } from 'react';

import ComponentText from './ComponentText';
import { Section } from '../models';

const SectionTitle = memo(
  ({ section }: { section: any }) => {
    return (
      <Box borderBottom={1} borderColor="grey.300" paddingLeft={1} paddingRight={1} paddingBottom={1} paddingTop={1}>
        <Box borderLeft={4} borderColor="#00B2A9">
          <Box marginLeft={1} bgcolor="rgba(0, 178, 169, 0.07)">
            <ComponentText style={{ padding: 4 }}>
              <Box fontWeight={500} fontSize="0.9rem">
                {section.no !== null ? `${section.no}.` : ``} {section.title}
              </Box>
            </ComponentText>
          </Box>
        </Box>
      </Box>
    );
  },
  (prev, next) => prev.section.no === next.section.no && prev.section.title === next.section.title,
);

type SectionViewProps = {
  section: Section;
  collapse?: boolean;
  totalTodoQuestion?: number;
  totalQuestion?: number;
};

const SectionView: React.FC<SectionViewProps> = ({ section, collapse, children, totalTodoQuestion, totalQuestion }) => {
  if (collapse) {
    return (
      <SectionPanel
        section={section}
        totalTodoQuestion={totalTodoQuestion}
        totalQuestion={totalQuestion}
        children={children}
      />
    );
  }
  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <Box border={0} borderColor="grey.300">
          <SectionTitle section={section} />
          <Box paddingRight={2} paddingBottom={2} paddingTop={1}>
            {children}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  heading: {
    color: 'rgb(0,0,0)',
    fontSize: '0.9rem',
    flexBasis: '70%',
    flexShrink: 0,
  },
  headingSecondary: {
    fontSize: '0.815rem',
    color: 'rgb(0,0,0)',
    flexBasis: '15%',
    paddingTop: 4,
    flexShrink: 0,
  },
  panelSummaryRoot: {
    padding: 0,
  },
}));

const SectionPanel: React.FC<SectionViewProps> = ({ section, children, totalTodoQuestion, totalQuestion }) => {
  const classes = useStyles({});

  return (
    <ExpansionPanel square elevation={0}>
      <ExpansionPanelSummary
        classes={{ root: classes.panelSummaryRoot }}
        expandIcon={<ExpandMoreIcon />}
        aria-label="Expand"
        id={`section-${section.id}`}>
        <Box
          borderBottom={1}
          borderColor="grey.300"
          paddingLeft={1}
          paddingRight={1}
          paddingBottom={1}
          paddingTop={1}
          flexBasis="100%">
          <Box borderLeft={4} borderColor="#00B2A9" flexBasis="100%">
            <Box
              padding={1}
              marginLeft={1}
              bgcolor="rgba(0, 178, 169, 0.07)"
              display="flex"
              flex={1}
              alignItems="center">
              <Typography className={classes.heading}>
                {section.no !== null ? `${section.no}.` : ``} {section.title}
              </Typography>
              <Typography className={classes.headingSecondary}>จำนวนข้อที่ต้องประเมิน: {totalTodoQuestion}</Typography>
              <Typography className={classes.headingSecondary}>จำนวนข้อทั้งหมด: {totalQuestion}</Typography>
            </Box>
          </Box>
        </Box>
      </ExpansionPanelSummary>

      <ExpansionPanelDetails classes={{}}>{children}</ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default SectionView;
