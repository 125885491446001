import { Box, Checkbox, Grid, Paper, Typography } from '@material-ui/core';
import uniqBy from 'lodash/uniqBy';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import LazyLoad from 'react-lazyload';

import { client } from '../../apis';
import { ASSESSMENT_URI } from '../../apis/constants';
import { Assessment, Component, ControlTypes, Question, Section } from '../../models';
import { ComponentManager } from '../../models/aggregator';
import { defaultOptions, ERROR_TEXT, errorOptions, successOptions } from '../../utils/snackbar';
import CommentatorTable from '../CommentatorTable';
import FormControlLabel from '../form/FormControlLabel';
import Loading from '../Loading';
import QuestionComponent from '../question/QuestionComponent';
import SectionComponent from '../SectionComponent';
import AddQuestionButton from './AddQuestionButton';
import { useAssessmentManager, useAssessorManager, useComponentManager } from './AssessmentContext';
import { flatMap } from '../../utils/array';

const useStyles = makeStyles((theme: Theme) => ({
  componentTitle: {},
  componentItem: {
    '&:hover': {
      boxShadow: theme.shadows[1],
    },
  },
  commentatorSection: {
    padding: '8px !important',
    paddingRight: '0 !important',
    paddingLeft: '0 !important',
    margin: '0',
  },
}));

export interface AssessmentComponentProps {
  assessment: Assessment;
  component: Component;
  sections?: Section[];
  questions?: Question[];
}

function AssessmentComponent({
  assessment,
  component,
  questions = [],
  sections = [],
  ...props
}: AssessmentComponentProps) {
  const classes = useStyles({});
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isAdding, setIsAdding] = useState(false);
  const [checked, setChecked] = useState<boolean>(component.status === 'A');
  const { permissions } = useAssessmentManager();
  const { addCommentator, removeCommentator } = useAssessorManager();
  const {
    addSection,
    addQuestion,
    deleteQuestion,
    deleteSection,
    updateQuestion,
    updateSection,
    isLoading,
  } = useComponentManager();
  const componentManager = new ComponentManager(questions, sections);

  const errorCb = (ex: Error) => {
    console.error(ex);
    enqueueSnackbar(ERROR_TEXT + ': ' + ex.message, { ...errorOptions });
  };

  const onCommentatorChange = (assessor, checked) => {
    if (checked) {
      addCommentator(component.id, assessor);
    } else {
      removeCommentator(component.id, assessor);
    }
  };

  const handleCheckbox = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    const snackbar = enqueueSnackbar('Updating...', { ...defaultOptions });
    client
      .put(
        `${ASSESSMENT_URI}/${assessment.no}/components/${component.id}/${event.target.checked ? 'active' : 'inactive'}`,
      )
      .then(() => enqueueSnackbar('Saved successfully.', successOptions))
      .catch(errorCb)
      .finally(() => closeSnackbar(snackbar));
  };

  const handleAddItem = (componentId: string, sectionId?: string) => (type: ControlTypes) => {
    const snackbar = enqueueSnackbar('Adding...', { ...defaultOptions });
    const question: Partial<Question> = {
      componentId: componentId,
      sectionId: sectionId,
      control: type,
      required: false,
      title: '',
      comment: 'โปรดระบุ',
    };

    if (question.control === 'rating') {
      question.maxRate = 5;
    }

    if (question.sectionId) {
      question.sequence = componentManager.getNextSequenceBySectionId(sectionId);
    } else {
      question.sequence = componentManager.getNextSequenceByComponentId(componentId);
    }

    let promise = type === 'section' ? addSection(componentId) : addQuestion(componentId, question);

    return promise
      .then(() => enqueueSnackbar('Saved successfully.', successOptions))
      .catch(errorCb)
      .finally(() => closeSnackbar(snackbar));
  };

  const handleUpdate = (componentId: string, sectionId?: string) => (entity: Question | Section) => {
    const snackbar = enqueueSnackbar('Updating...', { ...defaultOptions });
    let promise = sectionId
      ? updateSection(componentId, entity as Section)
      : updateQuestion(componentId, entity as Question);
    promise
      .then(() => enqueueSnackbar('Updated successfully.', successOptions))
      .catch(errorCb)
      .finally(() => closeSnackbar(snackbar));
  };

  const handleDelete = (componentId: string, sectionId?: string) => (questionId: string) => {
    const snackbar = enqueueSnackbar('Deleting...', { ...defaultOptions });
    let promise = sectionId ? deleteSection(componentId, sectionId) : deleteQuestion(componentId, questionId);
    promise
      .then(() => enqueueSnackbar('Deleted successfully.', successOptions))
      .catch(errorCb)
      .finally(() => closeSnackbar(snackbar));
  };

  const disabled = !permissions.editable;
  const commentatorList = uniqBy(
    flatMap(questions, q => q.assessors || []),
    a => a.employeeId,
  );

  return (
    <Box {...props}>
      <Box borderBottom={1} borderColor="grey.300" paddingLeft={1} paddingRight={1} className={classes.componentTitle}>
        <FormControlLabel
          disabled={disabled}
          control={
            <Checkbox
              checked={checked}
              onChange={handleCheckbox('active')}
              value="active"
              id="active"
              name="active"
              color="primary"
            />
          }
          label={component.fullname}
        />
      </Box>
      <Box p={2}>
        <Grid container spacing={2}>
          <Grid item md={12}>
            {componentManager.getItems().map(item => {
              if (item instanceof Question)
                return (
                  <Box
                    key={item.id}
                    className={classes.componentItem}
                    border={1}
                    borderColor="grey.300"
                    paddingTop={1}
                    paddingBottom={2}
                    paddingLeft={2}
                    paddingRight={2}
                    marginBottom={3}>
                    <QuestionComponent
                      data={item}
                      handleUpdate={handleUpdate(component.id)}
                      handleDelete={handleDelete(component.id)}
                    />
                  </Box>
                );

              return (
                <Box
                  border={1}
                  borderColor="grey.300"
                  paddingTop={1}
                  paddingBottom={2}
                  paddingLeft={2}
                  paddingRight={2}
                  marginBottom={3}
                  key={item.id}
                  className={classes.componentItem}>
                  <SectionComponent
                    disabled={disabled}
                    isLoading={isLoading}
                    data={item}
                    handleAddItem={handleAddItem(component.id, item.id)}
                    handleUpdate={handleUpdate(component.id, item.id)}
                    handleDelete={handleDelete(component.id, item.id)}>
                    <Grid container direction="row">
                      {componentManager.getQuestionBySectionId(item.id).map(question => (
                        <LazyLoad height={200} offset={100} key={`lazy-${question.id}`}>
                          <Grid item xs={12} key={question.id}>
                            <Box
                              className={classes.componentItem}
                              border={1}
                              borderColor="grey.300"
                              marginBottom={2}
                              paddingBottom={2}
                              paddingTop={2.2}
                              paddingRight={3}
                              paddingLeft={3}>
                              <QuestionComponent
                                data={question}
                                handleUpdate={handleUpdate(component.id)}
                                handleDelete={handleDelete(component.id)}
                              />
                            </Box>
                          </Grid>
                        </LazyLoad>
                      ))}
                    </Grid>
                  </SectionComponent>
                </Box>
              );
            })}
          </Grid>
          {isAdding && (
            <Box paddingLeft={2}>
              <Loading />
            </Box>
          )}
          <LazyLoad height={200} offset={100}>
            <Grid item md={12}>
              {!disabled && (
                <AddQuestionButton
                  handleAddItem={type => {
                    setIsAdding(true);
                    handleAddItem(component.id)(type).finally(() => setIsAdding(false));
                  }}
                />
              )}
            </Grid>
          </LazyLoad>
        </Grid>
        <LazyLoad height={200} offset={100}>
          <Grid container item xs={12} spacing={1} classes={{ item: classes.commentatorSection }}>
            <Grid item xs={12}>
              <Typography variant="caption" component="h6">
                ผู้สรุปความคิดเห็น
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Paper square>
                <CommentatorTable
                  list={commentatorList}
                  onCheck={onCommentatorChange}
                  disabled={assessment.currentStatus !== 'Draft'}
                />
              </Paper>
            </Grid>
          </Grid>
        </LazyLoad>
      </Box>
    </Box>
  );
}

export default AssessmentComponent;
