import { useAssessmentRequest } from './AssessmentRequestContext';

export interface StatusVisible {
  children: any;
  requiredRequestStatus?: ('Submitted' | 'Reviewed' | 'Assessment')[];
  requiredAssessmentStatus?: ('Draft' | 'Submitted' | 'Assessment' | 'Summarize' | 'Completed' | 'Cancelled')[];
}

export function StatusVisible({ children, requiredRequestStatus, requiredAssessmentStatus }: StatusVisible) {
  const { assessmentRequest } = useAssessmentRequest();

  if (!assessmentRequest) return null;

  const {
    requestStatus,
    information: { currentStatus: assessmentStatus },
  } = assessmentRequest;

  if (
    requiredRequestStatus &&
    requiredAssessmentStatus &&
    requiredRequestStatus.includes(requestStatus as any) &&
    requiredAssessmentStatus.includes(assessmentStatus)
  )
    return children;

  if (requiredRequestStatus && requiredRequestStatus.includes(requestStatus as any) && !requiredAssessmentStatus)
    return children;
  if (requiredAssessmentStatus && requiredAssessmentStatus.includes(assessmentStatus) && !requiredRequestStatus)
    return children;

  return null;
}

export default StatusVisible;
