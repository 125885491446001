import { client } from './client';
import { HOME_IMAGE_URI } from './constants';

export async function getOne(id: number) {
  return await client.get(`${HOME_IMAGE_URI}/${id}`, {});
}

export async function getAll() {
  return await client.get(`${HOME_IMAGE_URI}`, {});
}

export async function create(request: any) {
  return await client.post(`${HOME_IMAGE_URI}`, request, {});
}

export async function update(id: number, request: any) {
  return await client.put(`${HOME_IMAGE_URI}/${id}`, request, {});
}

export async function deleteOne(id: number) {
  return await client.delete(`${HOME_IMAGE_URI}/${id}`, {});
}
