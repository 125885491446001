import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { Router, Switch } from 'react-router-dom';

import { AppStateProvider } from './components/context/AppStateContext';
import { UserStateProvider } from './components/context/UserStateContext';
import { LoadingScreenProvider } from './components/LoadingScreenProvider';
import AboutPage from './pages/AboutPage';
import AdminSummarizePage from './pages/assessment/AdminSummarizePage';
import AssessmentRequestPage from './pages/assessment/AssessmentRequestPage';
import CreatePage from './pages/assessment/CreatePage';
import EditPage from './pages/assessment/EditPage';
import { PreviewPage } from './pages/assessment/PreviewPage';
import FaqPage from './pages/FaqPage';
import HomePage from './pages/HomePage';
import LdapLoginPage from './pages/LdapLoginPage';
import LoginPage from './pages/LoginPage';
import MasterAnnouncement from './pages/masterdata/MasterAnnouncement';
import MasterFaq from './pages/masterdata/MasterFaq';
import MasterHome from './pages/masterdata/MasterHome';
import MasterPortal from './pages/masterdata/MasterLink';
import MasterLookup from './pages/masterdata/MasterLookup';
import NotFoundPage from './pages/NotFoundPage';
import PortalPage from './pages/PortalPage';
import SiteMapPage from './pages/SiteMapPage';
import Report1Page from './pages/report/Report1Page';
import { Report2Page } from './pages/report/Report2Page';
import Report3Page from './pages/report/Report3Page';
import Report4IndividualPage from './pages/report/Report4_1Page';
import Report5Page from './pages/report/Report5Page';
import Report6Page from './pages/report/Report6Page';
import Report7Page from './pages/report/Report7Page';
import Report8Page from './pages/report/Report8Page';
import ReportPage from './pages/report/ReportPage';
import { StatisticPage } from './pages/StatisticPage';
import WorklistPage from './pages/WorklistPage';
import {
  AdminRoute,
  AuthenticatedRoute,
  Debug as DebugRouter,
  history,
  OnlyAnonymousRoute,
} from './utils/react-router-util';
import { theme } from './utils/theme';
import Analytics from './components/Analytics';
import MasterEmployee from './pages/masterdata/MasterEmployee';
import MasterEmployeeRole from './pages/masterdata/MasterEmployeeRole';
import MasterConfigNotification from './pages/masterdata/MasterConfigNotification';
import MasterReportConfiguration from './pages/masterdata/MasterReportConfiguration';
import MasterComponent from './pages/masterdata/MasterComponent';
import MasterProcessName from './pages/masterdata/MasterProcessName';

const StateProvider = (props: any) => {
  return (
    <SnackbarProvider maxSnack={3} autoHideDuration={5000} preventDuplicate>
      <LoadingScreenProvider>
        <AppStateProvider>
          <UserStateProvider>
            <Analytics>
              <MuiPickersUtilsProvider utils={MomentUtils}>{props.children}</MuiPickersUtilsProvider>
            </Analytics>
          </UserStateProvider>
        </AppStateProvider>
      </LoadingScreenProvider>
    </SnackbarProvider>
  );
};

const App: React.FC = () => {
  return (
    <Router history={history}>
      <ThemeProvider theme={theme}>
        <StateProvider>
          <DebugRouter />
          <Switch>
            <AuthenticatedRoute path="/" exact component={HomePage} />
            <AuthenticatedRoute path="/home" exact component={HomePage} />
            <AuthenticatedRoute path="/worklist" exact component={WorklistPage} />
            <OnlyAnonymousRoute path="/login" exact component={LdapLoginPage} />
            <OnlyAnonymousRoute path="/login/local" exact component={LoginPage} />
            <AdminRoute path="/worklist/:id/preview" exact component={PreviewPage} roles={['ADMIN']} />
            <AdminRoute path="/worklist/:id/edit" exact component={EditPage} roles={['ADMIN']} />
            <AdminRoute path="/worklist/:id/summarize" exact component={AdminSummarizePage} roles={['ADMIN']} />
            <AuthenticatedRoute path="/worklist/:assessmentNo/:assessorId" exact component={AssessmentRequestPage} />
            <AdminRoute path="/new-assessment" exact component={CreatePage} roles={['ADMIN']} />
            <AuthenticatedRoute path="/faq" exact component={FaqPage} />
            <AuthenticatedRoute path="/portal" exact component={PortalPage} />
            <AuthenticatedRoute path="/sitemap" exact component={SiteMapPage} />
            <AuthenticatedRoute path="/statistic" exact component={StatisticPage} />
            <AuthenticatedRoute path="/reports" exact component={ReportPage} />
            <AuthenticatedRoute path="/reports/rpt-01" exact component={Report1Page} roles={['ADMIN']} />
            <AuthenticatedRoute path="/reports/rpt-02" exact component={Report2Page} roles={['ADMIN']} />
            <AdminRoute path="/reports/rpt-03" exact component={Report3Page} roles={['ADMIN']} />
            <AdminRoute path="/reports/rpt-04" exact component={Report4IndividualPage} roles={['ADMIN']} />
            <AdminRoute path="/reports/rpt-05" exact component={Report5Page} roles={['ADMIN']} />
            <AdminRoute path="/reports/rpt-06" exact component={Report6Page} roles={['ADMIN']} />
            <AdminRoute path="/reports/rpt-07" exact component={Report7Page} roles={['ADMIN']} />
            <AdminRoute path="/reports/rpt-08" exact component={Report8Page} roles={['ADMIN']} />
            <AuthenticatedRoute path="/about" exact component={AboutPage} />
            <AdminRoute path="/master/announcement" exact component={MasterAnnouncement} roles={['ADMIN']} />
            <AdminRoute path="/master/faq" exact component={MasterFaq} roles={['ADMIN']} />
            <AdminRoute path="/master/portal" exact component={MasterPortal} roles={['ADMIN']} />
            <AdminRoute path="/master/lookup" exact component={MasterLookup} roles={['ADMIN']} />
            <AdminRoute path="/master/employee" exact component={MasterEmployee} roles={['ADMIN']} />
            <AdminRoute path="/master/employee-role" exact component={MasterEmployeeRole} roles={['ADMIN']} />
            <AdminRoute path="/master/homepage" exact component={MasterHome} roles={['ADMIN']} />
            <AdminRoute path="/master/notification-date" exact component={MasterConfigNotification} roles={['ADMIN']} />
            <AdminRoute path="/master/component" exact component={MasterComponent} roles={['ADMIN']} />
            <AdminRoute path="/master/process-name" exact component={MasterProcessName} roles={['ADMIN']} />
            <AdminRoute
              path="/master/report-configuration"
              exact
              component={MasterReportConfiguration}
              roles={['ADMIN']}
            />
            <AuthenticatedRoute component={NotFoundPage} />
          </Switch>
        </StateProvider>
      </ThemeProvider>
    </Router>
  );
};

export default App;
