import React from 'react';
import { LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ActivityName } from '../../models';

const useStyles = makeStyles(() => ({
  colorNormal: {
    backgroundColor: 'rgba(0, 194, 184, 0.3)',
  },
  barColorNormal: {
    backgroundColor: 'rgba(0, 154, 146, 1)',
  },
  colorDeadline: {
    backgroundColor: 'rgba(253, 189, 193, 1)',
  },
  barColorDeadline: {
    backgroundColor: 'rgba(179, 0, 4, 1)',
  },
  colorDone: {
    backgroundColor: 'rgb(204, 204, 204);',
  },
  barColorDone: {
    backgroundColor: 'rgb(204, 204, 204);',
  },
}));

export interface WorklistProgressBar {
  totalDays: number;
  pastDays: number;
  activityName: ActivityName;
}

export const WorklistProgressBar = ({ totalDays, pastDays, activityName }: WorklistProgressBar) => {
  const classes = useStyles({});
  const progressBar = (pastDays / totalDays) * 100;
  const remainDays = totalDays - pastDays;

  const postProgressBar = progressBar < 0 || progressBar > 100 ? 100 : progressBar;
  const progressStyle = ['Completed', 'Cancelled'].includes(activityName)
    ? 'Done'
    : remainDays <= 7
    ? 'Deadline'
    : 'Normal';

  return (
    <LinearProgress
      variant="determinate"
      classes={{
        colorPrimary: classes[`color${progressStyle}`],
        barColorPrimary: classes[`barColor${progressStyle}`],
      }}
      color="primary"
      value={postProgressBar}
      style={{ height: 10, borderRadius: 10 }}
    />
  );
};

export default WorklistProgressBar;
