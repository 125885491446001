// https://material-ui.com/components/drawers/#temporary-drawer

import React, { useState } from 'react';

import { SwipeableDrawer } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { useAppState } from './context/AppStateContext';
import { AdapterLink } from './RouterLink';
import { useUserState } from './context/UserStateContext';
import RoleVisible from './assessment/RoleVisible';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const routingMapping = {
  Home: {
    link: '/home',
    type: 'internal',
  },
  Worklist: {
    link: '/worklist',
    type: 'internal',
  },
  Report: {
    link: '/reports',
    type: 'internal',
  },
  'FAQ Page': {
    link: '/master/faq',
    type: 'internal',
  },
  'FAQ': {
    link: '/faq',
    type: 'internal',
  },
  'About Internal Control': {
    link: '/COSO 2013_Internal Control - Integrated.pdf',
    type: 'external',
  },
  Portal: {
    link: '/portal',
    type: 'internal',
  },
  Lookup: {
    link: '/master/lookup',
    type: 'internal',
  },
  'About Internal Control Page': {
    link: '/master/announcement',
    type: 'internal',
  },
  Homepage: {
    link: '/master/homepage',
    type: 'internal',
  },
  Employee: {
    link: '/master/employee',
    type: 'internal',
  },
  'Employee Role': {
    link: '/master/employee-role',
    type: 'internal',
  },
  'Notification Date': {
    link: '/master/notification-date',
    type: 'internal',
  },
  'Report Configuration': {
    link: '/master/report-configuration',
    type: 'internal',
  },
  'Process Name': {
    link: '/master/process-name',
    type: 'internal',
  },
  'Link Portal Page': {
    link: '/master/portal',
    type: 'internal',
  },
  'Component': {
    link: '/master/component',
    type: 'internal',
  },
  Manual: {
    link: '/User Manual.pdf',
    type: 'external',
  },
  'Site Map': {
    link: '/sitemap',
    type: 'internal',
  },
  Statistics: {
    link: '/statistic',
    type: 'internal',
  },
};

function Sidebar() {
  const classes = useStyles({});
  const { sidebarOpen, sidebarToggle } = useAppState();
  const { hasAnyRoles } = useUserState();
  const [collapse, setCollapse] = useState<string>();

  const closeSidebar = () => sidebarToggle(false);
  const openSidebar = () => sidebarToggle(true);
  const isCollapseOpen = (name: string) => collapse === name;
  const collapseToggle = (name: string) => () => {
    setCollapse(collapse ? undefined : name);
  };

  const toListItem = (text: string, nested: boolean = false) => {
    const routing = routingMapping[text];
    const className = clsx({ [classes.nested]: nested });
    if (routing.type === 'external') {
      return (
        <ListItem
          className={className}
          key={text}
          button
          component="a"
          href={routing.link}
          target="_blank"
          onClick={closeSidebar}>
          <ListItemText primary={text} />
        </ListItem>
      );
    }
    return (
      <ListItem
        className={className}
        key={text}
        button
        component={AdapterLink}
        to={routing.link}
        onClick={closeSidebar}>
        <ListItemText primary={text} />
      </ListItem>
    );
  };

  return (
    <div>
      <SwipeableDrawer
        disableBackdropTransition={true}
        open={sidebarOpen}
        onOpen={openSidebar}
        onClose={closeSidebar}
        anchor="right">
        <div className={classes.list} role="presentation">
          <List>
            {['Home', 'Worklist'].map(text => toListItem(text))}

            {hasAnyRoles('ADMIN') && (
              <>
                <ListItem button onClick={collapseToggle('create-assessment')}>
                  <ListItemText primary="Create Assessment" />
                  {isCollapseOpen('create-assessment') != null ? (
                    isCollapseOpen('create-assessment') ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )
                  ) : null}
                </ListItem>
                <Collapse in={isCollapseOpen('create-assessment')} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem
                      button
                      component={AdapterLink}
                      to="/new-assessment"
                      className={classes.nested}
                      onClick={closeSidebar}>
                      <ListItemText primary="New" />
                    </ListItem>
                    <ListItem
                      button
                      component={AdapterLink}
                      to="/reports/rpt-01"
                      className={classes.nested}
                      onClick={closeSidebar}>
                      <ListItemText primary="Copy" />
                    </ListItem>
                  </List>
                </Collapse>
              </>
            )}

            {hasAnyRoles('ADMIN') && toListItem('Report')}
            {toListItem('About Internal Control')}
            {toListItem('FAQ')}
            {toListItem('Statistics')}
            {toListItem('Manual')}

            {hasAnyRoles('ADMIN') && (
              <>
                <ListItem button onClick={collapseToggle('master-data')}>
                  <ListItemText primary="Master Data" />
                  {isCollapseOpen('master-data') != null ? (
                    isCollapseOpen('master-data') ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )
                  ) : null}
                </ListItem>
                <Collapse in={isCollapseOpen('master-data')} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {[
                      'Lookup',
                      'Employee',
                      'Employee Role',
                      'Report Configuration',
                      'Notification Date',
                      'Homepage',
                      'About Internal Control Page',
                      'FAQ Page',
                      'Link Portal Page',
                      'Component',
                      'Process Name',
                    ].map(text => toListItem(text, true))}
                  </List>
                </Collapse>
              </>
            )}
          </List>
        </div>
      </SwipeableDrawer>
    </div>
  );
}

export default Sidebar;
