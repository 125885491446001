import { Divider, ExpansionPanelDetails, Grid, Hidden, MenuItem, Select, Typography } from '@material-ui/core';
import { ExpansionPanelProps } from '@material-ui/core/ExpansionPanel';
import { makeStyles, Theme } from '@material-ui/core/styles';
import DescriptionIcon from '@material-ui/icons/Description';
import { KeyboardDatePicker as DatePicker } from '@material-ui/pickers';
import debounce from 'lodash/debounce';
import minBy from 'lodash/minBy';
import moment from 'moment';
import React, { useState } from 'react';
import { ActionMeta, ValueType } from 'react-select/src/types';

import { CORPORATE_LEVEL, PROCESS_LEVEL } from '../../apis/constants';
import { Assessment } from '../../models';
import { toDepartmentOption, toLevelOption } from '../../utils/options';
import AutocompleteSelect, { OptionType } from '../AutocompleteSelect';
import { FormInput } from '../form';
import InputBase from '../InputBase';
import InputText from '../InputText';
import Loading from '../Loading';
import { useFormValidation } from '../useFormValidation';
import { useAssessmentManager } from './AssessmentContext';
import { AssessmentPanel, AssessmentPanelSummary } from './AssessmentPanel';
import InformationCreatedBy from './information/InformationCreatedBy';
import InformationRequestNo from './information/InformationRequestNo';
import InformationStatus from './information/InformationStatus';
import InformationTitle from './information/InformationTitle';
import { RoundCount } from './RoundCount';

const useStyles = makeStyles((theme: Theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

export interface InformationSectionProps {
  years: number[];
  expansionProps?: ExpansionPanelProps;
}

const InformationSection = ({ expansionProps, years }: InformationSectionProps) => {
  const classes = useStyles({});
  const { isProcessing, setAssessment, assessment, settings, permissions } = useAssessmentManager();
  const [type, setType] = useState<string>('');

  const { values, setValue, setValues } = useFormValidation({
    initialValues: assessment,
    type: Assessment,
    onSuccess: () => {},
  });

  function _handleChange(event: React.ChangeEvent<{ name?: string; value: unknown }>) {
    const name = event.target.name as any;
    const value = event.target.value;
    setValue(name, value);
    setAssessment({ ...assessment, [name]: value });
  }

  const _handleChangeDebounce = debounce((name: any, value: any) => {
    setValue(name, value);
    setAssessment({ ...assessment, [name]: value });
  }, 200);

  const _handleDateChange = (name: string) => (date: moment.Moment | null) => {
    if (name === 'startDate') {
      const nextRound =
        date &&
        date
          .clone()
          .add(1, 'year')
          .format('YYYY-MM-DD');
      setValues({
        ...values,
        publishDateNextRound: nextRound as any,
        [name as any]: date && date.format('YYYY-MM-DD'),
      });
      setAssessment({
        ...assessment,
        publishDateNextRound: nextRound as any,
        [name as any]: date && date.format('YYYY-MM-DD'),
      });
    } else {
      setValue(name as any, date && date.format('YYYY-MM-DD'));
      setAssessment({ ...assessment, [name as any]: date && date.format('YYYY-MM-DD') });
    }
  };

  const _handleSelect = (name: string) => (option: ValueType<OptionType>, actionMeta: ActionMeta) => {
    const _option = option as any;

    if (name === 'levelId') {
      const levelNo = _option.value;
      setValues({
        processOwnerDepartmentId: '',
        processOwnerLevelId: levelNo,
        processOwnerId: '',
      });
      setType('level');
    }

    if (name === 'unitId') {
      const departmentId = _option.value;
      const department = settings.departments.find(d => d.id === departmentId);
      if (!department) return;

      const highestEmployee = minBy(settings.managers.filter(emp => emp.departmentId === department.id), 'levelNo');

      setValues({
        processOwnerDepartmentId: department.id,
        processOwnerLevelId: department.levelNo,
        processOwnerId: (highestEmployee && highestEmployee.id) || '',
      });
      setAssessment({
        ...assessment,
        processOwnerDepartmentId: departmentId,
        processOwnerId: (highestEmployee && highestEmployee.id) || '',
      });
      setType('unitId');
    }

    if (name === 'processOwnerId') {
      const owner = settings.managers.find(emp => emp.id === _option.value);
      setValues({
        processOwnerDepartmentId: owner.departmentId,
        processOwnerLevelId: owner.levelNo,
        processOwnerId: _option.value as string,
      });
      setAssessment({
        ...assessment,
        processOwnerDepartmentId: owner.departmentId,
        processOwnerLevelId: owner.levelNo,
        processOwnerId: _option.value as string,
      });
      setType('ownerId');
    }
    if (name === 'processId') {
      setValue('processId', _option.value as any);
      setAssessment({ ...assessment, processId: _option.value as any });
    }
  };

  const levelOptions = settings.departmentLevels.map(toLevelOption);
  const departmentOptions = settings.departments
    .filter(d => (values.processOwnerLevelId ? d.levelNo === Number(values.processOwnerLevelId) : true))
    .map(toDepartmentOption);
  const employeeOptions = settings.managers
    .filter(e => (type === 'unitId' ? e.departmentId === values.processOwnerDepartmentId : true))
    .map(d => ({
      label: `${d.initialName} ${d.firstName} ${d.lastName}`,
      value: d.id,
      description: `${d.departmentShortName} ${d.departmentNameEn}`,
    }));

  const disabled = !permissions.editable;

  return (
    <AssessmentPanel {...expansionProps}>
      <AssessmentPanelSummary aria-controls="information-content" id="information-header">
        <Typography component="div" className={classes.heading}>
          <DescriptionIcon color="primary" />
          ข้อมูล / Information {isProcessing('save-assessment') && <Loading size={18} />}
        </Typography>
      </AssessmentPanelSummary>
      <Divider variant="middle" />
      <ExpansionPanelDetails>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={3}>
            <FormInput fullWidth id="document-code" label="รหัสแบบประเมิน / Document Code" disabled={disabled}>
              <InputText
                id="document-code"
                name="code"
                defaultValue={values.code}
                onChange={e => _handleChangeDebounce('code', e.target.value)}
                autoFocus
              />
            </FormInput>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <RoundCount disabled={disabled} documentCode={values.code} level={values.level} year={values.year} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <InformationRequestNo value={values.no} disabled={disabled} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <InformationCreatedBy
              value={`${assessment.createdByInitialName || ''} ${assessment.createdByName || ''}`}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <FormInput fullWidth id="year" label="ปี / Year" required disabled={disabled}>
              {disabled ? (
                <InputText id="year" disabled defaultValue={values.year} />
              ) : (
                <Select onChange={_handleChange} input={<InputBase name="year" id="year" value={values.year} />}>
                  {years.map(year => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </FormInput>
          </Grid>

          <Hidden only="xs">
            <Grid item md={2}>
              <FormInput fullWidth id="round" label="ครั้งที่ / Round" disabled={disabled}>
                <InputText
                  id="round"
                  name="round"
                  type="number"
                  onChange={e => _handleChangeDebounce('round', e.target.value)}
                  defaultValue={values.round}
                />
              </FormInput>
            </Grid>
          </Hidden>

          <Grid item xs={12} sm={6} md={3}>
            <FormInput fullWidth required label="ระดับ / Level" id="year" disabled={disabled}>
              {assessment.no ? (
                <InputText id="level" disabled value={values.level === 'CO' ? 'องค์กร' : 'กระบวนการ'} />
              ) : (
                <Select value={values.level} onChange={_handleChange} input={<InputBase name="level" id="level" />}>
                  <MenuItem value={CORPORATE_LEVEL}>องค์กร</MenuItem>
                  <MenuItem value={PROCESS_LEVEL}>กระบวนการ</MenuItem>
                </Select>
              )}
            </FormInput>
          </Grid>
          <Grid item xs={12} sm={6} md={5}>
            <InformationTitle value={settings.assessmentTitle[values.level]} disabled={disabled} />
          </Grid>

          <Grid item xs={12}>
            <FormInput fullWidth id="description" label="คำอธิบาย / Description" disabled={disabled}>
              <InputText
                id="description"
                name="description"
                onChange={e => _handleChangeDebounce('description', e.target.value)}
                defaultValue={values.description}
                rowsMax={10}
                multiline
                rows={2}
              />
            </FormInput>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <FormInput
              fullWidth
              id="startDate"
              required
              label="วันที่เปิดรับประเมิน / Opening Date"
              disabled={disabled}>
              <DatePicker
                disabled={disabled}
                disablePast={!disabled}
                autoOk
                margin="normal"
                value={values.startDate}
                onChange={_handleDateChange('startDate')}
                format="DD/MM/YYYY"
              />
            </FormInput>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormInput fullWidth id="endDate" label="วันที่ปิดรับประเมิน / Closing Date" required disabled={disabled}>
              <DatePicker
                disabled={disabled}
                autoOk
                minDate={!disabled && values.startDate}
                minDateMessage="วันที่ปิดรับฟัง ต้องเกิดหลังจากวันเปิดรับฟังเท่านั้น"
                disablePast={!disabled}
                margin="normal"
                value={values.endDate}
                onChange={_handleDateChange('endDate')}
                format="DD/MM/YYYY"
              />
            </FormInput>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <FormInput
              fullWidth
              id="publish-next-round"
              required
              label="วันที่เปิดรับประเมินรอบถัดไป / Opening date Next Round"
              disabled={disabled}>
              <DatePicker
                disabled={disabled}
                disablePast={!disabled}
                clearable
                autoOk
                minDate={values.endDate}
                minDateMessage="วันที่เปิดรับประเมินรอบถัดไป ควรเกิดขึ้นหลังจากวันที่ปิดรับฟังเท่านั้น"
                margin="normal"
                value={values.publishDateNextRound}
                onChange={_handleDateChange('publishDateNextRound')}
                format="DD/MM/YYYY"
              />
            </FormInput>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormInput fullWidth label="ชื่อกระบวนการ / Process Name" id="process-name" required disabled={disabled}>
              {assessment.level === PROCESS_LEVEL ? (
                <AutocompleteSelect
                  name={'processId'}
                  value={values.processId || ''}
                  options={settings.processNames.map(d => ({ label: d.nameTh, value: d.id })) || []}
                  onChange={_handleSelect('processId')}
                />
              ) : (
                <InputText disabled />
              )}
            </FormInput>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <FormInput fullWidth label="ระดับหน่วยงาน / Unit Level" id="level-unit" required disabled={disabled}>
              {assessment.level === PROCESS_LEVEL ? (
                <AutocompleteSelect
                  name="levelId"
                  value={(values.processOwnerLevelId && values.processOwnerLevelId.toString()) || ''}
                  options={levelOptions}
                  onChange={_handleSelect('levelId')}
                />
              ) : (
                <InputText disabled />
              )}
            </FormInput>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormInput fullWidth id="unit-name" label="ชื่อหน่วยงาน / Unit Name" required disabled={disabled}>
              {assessment.level === PROCESS_LEVEL ? (
                <AutocompleteSelect
                  name="unitId"
                  value={values.processOwnerDepartmentId}
                  options={departmentOptions}
                  onChange={_handleSelect('unitId')}
                />
              ) : (
                <InputText disabled />
              )}
            </FormInput>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <FormInput
              fullWidth
              label="เจ้าของกระบวนการ / Process Owner"
              id="processOwnerId"
              required
              disabled={disabled}>
              {assessment.level === PROCESS_LEVEL ? (
                <AutocompleteSelect
                  name="processOwnerId"
                  value={values.processOwnerId}
                  options={employeeOptions}
                  onChange={_handleSelect('processOwnerId')}
                />
              ) : (
                <InputText disabled />
              )}
            </FormInput>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <InformationStatus value={values.currentStatus} />
          </Grid>
        </Grid>
      </ExpansionPanelDetails>
    </AssessmentPanel>
  );
};

export default InformationSection;
