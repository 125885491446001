import { client } from "./client"

type UploadData = {
  type: 'file' | 'url';
  title?: string;
  description?: string;
  url?: string;
  confidential?: boolean;
  rootId?: string;
  itemId?: string;
  [key: string]: any;
}

export const upload = (name: string, file: File, data: UploadData) => {
  const formData = new FormData();
  formData.append('file', file);
  Object.keys(data).forEach(key => {
    const value = data[key]
    formData.append(key, value)
  })
  return client.post(`attachments/${name}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  })
}