import React from 'react';

import { Box, Typography } from '@material-ui/core';

function ContentContainer({
  title,
  error,
  children,
}: {
  title: React.ReactNode;
  error?: boolean;
  children: React.ReactNode;
}) {
  const borderColor = error ? 'red' : 'grey.300';
  const border = error ? 3 : 1;

  const bgcolor = error ? 'red' : undefined;
  const color = error ? '#fff' : undefined;
  const fontWeight = error ? 600 : 400;
  const fontSize = error ? '1.5rem' : '1.3rem';
  return (
    <Box border={border} borderColor={borderColor} marginBottom={4} bgcolor="#fff" boxShadow={2}>
      <Box
        borderBottom={1}
        borderColor={borderColor}
        paddingLeft={1}
        paddingRight={1}
        paddingBottom={1}
        paddingTop={1}
        bgcolor={bgcolor}
        color={color}>
        <Typography component="div">
          <Box fontSize={fontSize} fontWeight={fontWeight}>
            {title}
          </Box>
        </Typography>
      </Box>
      <Box p={2}>{children}</Box>
    </Box>
  );
}

export default ContentContainer;
