export abstract class BaseModel {
  createdBy?: string;
  modifiedBy?: string;

  createdAt?: Date;
  modifiedAt?: Date;

  status?: 'A' | 'I' | 'D';

  constructor(partial: Partial<BaseModel> = {}) {
    Object.assign(this, { ...partial });
  }
}
