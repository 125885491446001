import MaterialTable, { Column } from 'material-table';
import React from 'react';

import { Faq } from '../../models';
import { defaultOptions, tableIcons } from './constants';
import { TableProps } from './types';
import RichText from '../Richtext/RichText';
import RichTextView from '../Richtext/RichTextView';

const FaqTable = ({ data, onAdd, onUpdate, onDelete, isLoading = false }: TableProps<Faq>) => {
  const tableColumns: Column<any>[] = [
    { title: 'Sequence', field: 'sequence', type: 'numeric', headerStyle: { width: '14%' } },
    {
      title: 'Question',
      field: 'question',
      headerStyle: { width: '35%' },
      editComponent: props => (
        <RichText onChange={value => props.onChange(JSON.stringify(value))} value={props.value} />
      ),
      render: rowData => <RichTextView value={rowData.question} />,
    },
    {
      title: 'Answer',
      field: 'answer',
      headerStyle: { width: '35%' },
      editComponent: props => (
        <RichText onChange={value => props.onChange(JSON.stringify(value))} value={props.value} />
      ),
      render: rowData => <RichTextView value={rowData.answer} />,
    },
    { title: 'Status', field: 'status', editable: 'onUpdate' , lookup: { 'A': 'Active', 'I': 'Inactive', 'D': 'Delete' } },
  ];

  return (
    <MaterialTable
      columns={tableColumns}
      data={data}
      title={'Master FAQ'}
      icons={tableIcons}
      options={defaultOptions}
      editable={{
        onRowAdd: newData => onAdd(newData),
        onRowUpdate: (newData, oldData) => onUpdate(newData, oldData),
        onRowDelete: oldData => onDelete(oldData),
      }}
    />
  );
};

export default FaqTable;
