import React from 'react';

import MainLayout from '../components/MainLayout';
import ErrorComponent from '../components/ErrorComponent';

const AccessDenied = () => {
  return (
    <MainLayout>
      <ErrorComponent title="You have no permission to access the assessment." description="ไม่สามารถเข้าถึงใบประเมินนี้ได้" status="Access Denied" />
    </MainLayout>
  );
};

export default AccessDenied;
