import { Box, FormControl, Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React, { lazy, Suspense } from 'react';
import KeyboardEventHandler from 'react-keyboard-event-handler';

import ComponentWrapper from '../../components/assessment/ComponentWrapper';
import ComponentBox from '../../components/ComponentBox';
import TextInput from '../../components/InputBase';
import LoadingButton from '../../components/LoadingButton';
import { useAdminReviewAssessment } from '../../context/AdminReviewContext';
import { Component, Question } from '../../models';
import { ComponentManager } from '../../models/aggregator';

import AllPreparerTable from '../AllPreparerTable';
import ContentContainer from '../ContentContainer';
import { ComponentProvider } from '../context/ComponentContext';
import { useUserState } from '../context/UserStateContext';
import EventTable from '../EventTable';
import SubmitButton from '../SubmitButton';
import { useAssessmentManager, useAssessorManager } from './AssessmentContext';
import { useAssessmentRequest } from './AssessmentRequestContext';
import InformationSectionView from './InformationSectionView';
import StatusVisible from './StatusVisible';
import RoleVisible from './RoleVisible';
import { AdminReviewAssessmentInputBox } from '../AdminReviewInputBox';
import { AdminReviewedRecallButton } from './AdminReviewedRecallButton';

const ChangeClosingDate = lazy(() => import('./ChangeClosingDate'));
const AddAssignAllPreparer = lazy(() => import('../AddAssignAllPreparer'));
const AssessmentErrorMessages = lazy(() => import('../AssessmentErrorMessages'));
const AdminReviewReturnPopup = lazy(() => import('../AdminReviewReturnPopup'));

const useStyles = makeStyles((theme: Theme) => ({
  remarkBox: {
    paddingTop: '0px',
  },
  button: {
    padding: '0px 20px 0px 20px',
    maxWidth: '220px',
    margin: '10px auto 10px auto',
  },
}));

const AssessmentRequestContainer = () => {
  const classes = useStyles({});
  const {
    assessment,
    components,
    questions,
    sections,
    events,
    isProcessing,
    errors,
    handleRemarkChange,
    remark,
  } = useAssessmentManager();
  const {
    save,
    submit,
    submitToAssign,
    assessmentRequest,
    requestStatus,
    hasAnyActions,
    assignAll,
  } = useAssessmentRequest();
  const { hasAnyRoles } = useUserState();
  const remarkPermission = getRemarkPermission();
  const componentManager = new ComponentManager(questions, sections);
  const assessorManager = useAssessorManager();
  const adminReviewManager = useAdminReviewAssessment();
  const currentAssessor = assessorManager.getCurrentUserAsAssessor();

  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <ComponentWrapper
          headerBarProps={{ bgcolor: '#00b2a9c7' }}
          titleProps={{ color: '#ffffff' }}
          title={'ข้อมูล / Information'}>
          <InformationSectionView information={assessment} />
          {hasAnyRoles('ADMIN') && (
            <Suspense fallback={null}>
              <ChangeClosingDate />
            </Suspense>
          )}
        </ComponentWrapper>
      </Grid>
      <KeyboardEventHandler
        handleKeys={['ctrl+s', 'cmd+s']}
        onKeyEvent={(key, e) => {
          e.preventDefault();
          save();
        }}>
        <Grid item xs={12}>
          {components
            .filter(comp => comp.status === 'A')
            .map((comp: Component, index: number) => (
              <ComponentProvider
                key={comp.id}
                component={comp}
                questions={questions && questions.filter(q => comp.id === q.componentId)}
                sections={sections && sections.filter(q => comp.id === q.componentId)}>
                <ComponentBox
                  key={comp.id}
                  component={comp}
                  questions={questions && questions.filter(q => comp.id === q.componentId)}
                  sections={sections && sections.filter(q => comp.id === q.componentId)}
                />
              </ComponentProvider>
            ))}
        </Grid>

        {['Assessment'].includes(assessment.currentStatus) &&
          currentAssessor &&
          ['ASSESSOR'].includes(currentAssessor.assessorRole) && (
            <Grid item xs={12}>
              <Box className={classes.remarkBox}>
                <ComponentWrapper title={'ผู้จัดทำ'}>
                  {hasAnyActions('ASSESSMENT_SUBMIT_FOR_ASSIGNMENT') && (
                    <Suspense fallback={null}>
                      <Box>
                        <AddAssignAllPreparer
                          assessmentNo={assessment.no}
                          isLoading={isProcessing('assign-all')}
                          disabled={isProcessing('assign-all')}
                          handleSubmit={assignAll}
                        />
                      </Box>
                    </Suspense>
                  )}
                  <AllPreparerTable noBorder assessors={assessorManager.getAllPreparer1st()} />
                </ComponentWrapper>
              </Box>
            </Grid>
          )}
        <StatusVisible requiredRequestStatus={['Assessment', 'Submitted', 'Reviewed']}>
          <Suspense fallback={null}>
            <Grid item xs={12}>
              <Box className={classes.remarkBox}>
                <AdminReviewAssessmentInputBox
                  ableToComment={hasAnyActions('ADMIN_REVIEW_COMMENT')}
                  ableToFollowUp={hasAnyActions('ADMIN_REVIEW_FOLLOW_UP')}
                  loading={isProcessing('admin-review-loading')}
                  disabled={!hasAnyRoles('ADMIN') || !['Submitted', 'Reviewed'].includes(requestStatus)}
                />
              </Box>
            </Grid>
          </Suspense>
        </StatusVisible>
        {((hasAnyRoles('ADMIN') && requestStatus === 'Submitted') ||
          (!hasAnyRoles('ADMIN') && requestStatus === 'Assessment')) && (
          <Grid item xs={12}>
            <Box className={classes.remarkBox}>
              <ComponentWrapper title="หมายเหตุ / Remark">
                <FormControl fullWidth disabled={remarkPermission}>
                  <TextInput
                    id="remark"
                    name="remark"
                    placeholder="กรอกหมายเหตุที่นี่"
                    onChange={e => handleRemarkChange(e.target.value)}
                    multiline
                    rows={3}
                    rowsMax={10}
                  />
                </FormControl>
              </ComponentWrapper>
            </Box>
          </Grid>
        )}
      </KeyboardEventHandler>
      {errors && errors.length !== 0 && (
        <Suspense fallback={null}>
          <Box marginTop={2} width="100%">
            <ContentContainer error title="โปรดระบุข้อมูลให้ครบถ้วนดังนี้">
              <AssessmentErrorMessages
                errors={errors}
                components={components}
                questions={componentManager.getItems().filter(d => d instanceof Question) as Question[]}
                sections={sections}
              />
            </ContentContainer>
          </Box>
        </Suspense>
      )}
      <StatusVisible requiredRequestStatus={['Assessment']}>
        <AssessmentAction />
      </StatusVisible>
      <StatusVisible requiredRequestStatus={['Submitted', 'Reviewed']}>
        <RoleVisible adminOnly>
          <Suspense fallback={null}>
            <AdminReviewAction />
          </Suspense>
        </RoleVisible>
      </StatusVisible>
      <Grid item xs={12}>
        <ComponentWrapper title="Activity Log">
          <EventTable events={events} />
        </ComponentWrapper>
      </Grid>
      <KeyboardEventHandler
        handleKeys={['ctrl+s', 'cmd+s']}
        onKeyEvent={(key, e) => {
          e.preventDefault();
          save();
        }}
      />
    </Grid>
  );

  function AssessmentAction() {
    return (
      <Grid item xs={12}>
        <Grid container spacing={0} direction="row" justify="center" alignItems="center">
          <Grid item xs={12} sm={12} md={3} hidden={!hasAnyActions('ASSESSMENT_SAVE')}>
            <Box className={classes.button}>
              <LoadingButton
                variant="contained"
                color="primary"
                onClick={save}
                disabled={isProcessing('submit-answer')}
                isLoading={isProcessing('save-draft-answer')}
                fullWidth>
                Save Draft
              </LoadingButton>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={3} hidden={!hasAnyActions('ASSESSMENT_SUBMIT')}>
            <Box className={classes.button}>
              <SubmitButton
                variant="contained"
                color="default"
                onClick={submit}
                disabled={isProcessing('save-draft-answer')}
                isLoading={isProcessing('submit-answer')}
                fullWidth>
                Submit
              </SubmitButton>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={3} hidden={!hasAnyActions('ASSESSMENT_SUBMIT_FOR_ASSIGNMENT')}>
            <Box className={classes.button}>
              <LoadingButton
                variant="contained"
                color="secondary"
                onClick={submitToAssign}
                disabled={
                  !assessorManager.hasPermissionToSubmitForAssignment() ||
                  isProcessing('submit-to-assign', 'save-draft-answer', 'submit-answer')
                }
                isLoading={isProcessing('submit-to-assign')}
                fullWidth>
                Submit for Assignment
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  function AdminReviewAction() {
    return (
      <Grid item xs={12}>
        <Grid container spacing={0} direction="row" justify="center" alignItems="center">
          <Grid item xs={12} sm={12} md={3} hidden={!hasAnyActions('ADMIN_REVIEW_SAVE')}>
            <Box className={classes.button}>
              <LoadingButton
                variant="contained"
                color="primary"
                onClick={adminReviewManager.saveAdminReview}
                disabled={isProcessing('submit-review')}
                isLoading={isProcessing('save-draft-review')}
                fullWidth>
                Save Draft
              </LoadingButton>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={3} hidden={!hasAnyActions('ADMIN_REVIEW_SUBMIT')}>
            <Box className={classes.button}>
              <SubmitButton
                variant="contained"
                color="default"
                onClick={adminReviewManager.submitAdminReview}
                disabled={isProcessing('save-draft-review')}
                isLoading={isProcessing('submit-review')}
                fullWidth>
                Submit
              </SubmitButton>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={3} hidden={!hasAnyActions('ADMIN_REVIEWED_RECALL')}>
            <Box className={classes.button}>
              <AdminReviewedRecallButton assessmentNo={assessment.no} assessorId={assessmentRequest.rootId} remark={remark} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={3} hidden={!hasAnyActions('ADMIN_REVIEW_RETURN')}>
            <Box className={classes.button}>
              <Suspense fallback={null}>
                <AdminReviewReturnPopup />
              </Suspense>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  function getRemarkPermission() {
    const userStatus = ['Assessment'];
    const adminStatus = ['Assessment', 'Submitted'];
    if (hasAnyRoles('ADMIN')) return !adminStatus.includes(assessmentRequest.requestStatus);
    return !userStatus.includes(assessment.currentStatus);
  }
};

export { AssessmentRequestContainer };

export default AssessmentRequestContainer;
