import { useState, createContext, useEffect, useLayoutEffect } from 'react';
import moment from 'moment';
import * as qs from 'query-string';
import { client } from '../../apis';
import { extractObjectByKeys } from '../../utils/object';
import useRouter from 'use-react-router';

export const ReportSearchContext = createContext({});

const defaultFilter = {
  year: moment().year(),
  level: 'CO',
  round: '',
  code: '',
  no: '',
  processId: 'all',
};

const keys = ['year', 'level', 'no', 'processId'];

function getParams(search) {
  if (!search) {
    return defaultFilter;
  }
  const params = extractObjectByKeys(qs.parse(search), keys);
  params.processId = params.processId || 'all';
  return params;
}

export const useReportSearch = () => {
  const { location } = useRouter();
  const [searching, setSearching] = useState(false);
  const [result, setResult] = useState<any>([]);
  const [filter, setFilter] = useState(getParams(location.search));

  useEffect(() => {
    search();
  }, [filter.year, filter.level, filter.processId]);

  const search = async () => {
    setSearching(true);
    setResult([]);
    try {
      const { data: payload } = await client.get(`reports/search`, {
        params: {
          year: filter.year,
          level: filter.level,
          processId: filter.processId,
        },
      });
      setResult(payload.data);

      const item = payload.data.find(item => item.no === filter.no);
      if (payload.data.length > 0 && !item) {
        const head = payload.data[0];
        setFilter({
          ...filter,
          code: head.code,
          round: head.round,
          no: head.no,
        });
      } else if (item) {
        setFilter({
          ...filter,
          code: item.code,
          round: item.round,
          no: item.no,
        });
      } else {
        setFilter({
          ...filter,
          code: undefined,
          round: undefined,
          no: undefined,
        });
      }

      return Promise.resolve(payload);
    } catch (ex) {
      console.error(ex);
      return Promise.reject(ex);
    } finally {
      setSearching(false);
    }
    // client
    //   .get(`reports/search`, {
    //     params: {
    //       year: filter.year,
    //       level: filter.level,
    //       processId: filter.processId,
    //     },
    //   })
    //   .then(({ data: payload }) => {
    //   setResult(payload.data);

    //   if (payload.data.length > 0 && payload.data.some(item => item.no !== filter.no)) {
    //     const head = payload.data[0];
    //     setFilter({
    //       ...filter,
    //       code: head.code,
    //       round: head.round,
    //       no: head.no,
    //     });
    //   }

    //   return Promise.resolve(payload)
    // })
    // .catch(ex => {
    //   console.log(ex.response);
    //   console.error(ex);
    //   setResult(null);
    // })
    // .finally(() => setSearching(false));
  };

  const value = {
    filter,
    setFilter,
    result,
    setResult,
    searching,
    search,
    paramString: filter.no,
  };
  return value;
};
