import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import MuiInputLabel from '@material-ui/core/InputLabel';

const InputLabel = withStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'block',
      transformOrigin: 'top left',
      fontSize: theme.typography.pxToRem(18),
      fontWeight: theme.typography.fontWeightMedium,
      color: theme.palette.common.black,
      minWidth: 'max-content',
    },
    asterisk: {
      color: theme.palette.error.light,
    },
  }),
)(MuiInputLabel);

export default InputLabel;

export const GreyInputLabel = withStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'block',
      transformOrigin: 'top left',
      fontSize: theme.typography.pxToRem(18),
      fontWeight: theme.typography.fontWeightBold,
      color: '#616161',
    },
    asterisk: {
      color: theme.palette.error.light,
    },
  }),
)(MuiInputLabel);
