/**
 * ถ้าไม่มี title
 */
import {
  AppBar,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  LinearProgress,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import withMobileDialog, { WithMobileDialog } from '@material-ui/core/withMobileDialog';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import React, { useEffect, useState } from 'react';

import { AttachmentFile, Question, Section } from '../models';
import { AttachmentState } from './AddAttachmentDialog';
import { FormInput } from './form';
import InputBase from './InputBase';
import LoadingButton from './LoadingButton';
import { useFormValidation } from './useFormValidation';

const useStyles = makeStyles((theme: Theme) => ({
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

export interface AttachmentSummarizeDialogProps extends WithMobileDialog {
  itemId?: string;
  onSubmit?: (attachmentData: AttachmentState) => Promise<any>;
  isLoading?: boolean;
  disabled?: boolean;
  uploadProgress?: number;
  mode?: 'add' | 'edit';
  attachment?: AttachmentFile;
  question?: Question;
  section?: Section;
}

const AddAttachmentSummarizeDialog = ({
  itemId,
  fullScreen,
  onSubmit,
  isLoading,
  disabled,
  uploadProgress,
  mode = 'add',
  attachment,
  section,
  question,
}: AttachmentSummarizeDialogProps) => {
  const classes = useStyles({});
  const defaultValue: AttachmentState = {
    confidential: true,
    description: '',
    title: '',
    itemId: itemId,
    type: null,
    url: '',
  };
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    resetData();
  }, [open]);

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    if (isLoading) return;

    setOpen(false);

    clearErrors();
  };

  const { values, handleSubmit, handleChange, hasError, setValue, setValues, clearErrors } = useFormValidation({
    initialValues: attachment || defaultValue,
    type: AttachmentState,
    groups: [mode],
    onSuccess: async () => {
      const newValue = {
        ...values,
        title: `${values.title}`,
      };
      try {
        setError(null);
        await onSubmit(newValue as AttachmentState);
        handleClose();
      } catch (ex) {
        console.log('error', ex);
        setError(ex);
      } finally {
      }
    },
  });

  function resetData() {
    console.log("-----reseting-------")
    console.log("reseting")
    console.log("------------")
    setValues({
      file: null,
      confidential: (attachment && attachment.confidential) || true,
      description: (attachment && attachment.description) || '',
      title: (attachment && attachment.title) || '',
      itemId: itemId,
      url: (attachment && attachment.url) || '',
      type: (attachment && attachment.type) || 'file',
    });
  }

  const handleFileChange = (event: any) => {
    const _file = event.target.files[0] as any;
    setError(null);
    setValues({
      file: _file,
      title: _file.name,
    });
  };

  const handleCheckbox = (event: any) => {
    setError(null);
    setValue([event.target.name] as any, !event.target.checked);
  };

  const handleUrlChange = (event: any) => {
    setError(null);
    setValues({ url: event.target.value });
  };

  return (
    <React.Fragment>

      {mode === 'add' ? (
        <Tooltip title="Add File" aria-label="Add file">
          <Button variant="contained" color="primary" size="small" onClick={handleOpen} disabled={disabled}>
            <AddIcon className={classes.leftIcon} fontSize="inherit" />
            Add File
          </Button>
        </Tooltip>
      ) : (
        <Tooltip title="Edit File" aria-label="Edit file">
          <IconButton color="primary" size="medium" onClick={handleOpen} disabled={disabled}>
            <EditIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
      )}

      <Dialog open={open} fullScreen={fullScreen} onClose={handleClose} aria-labelledby="add-attachment-dialog">
        {fullScreen && (
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="Close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                เอกสารแนบเพิ่มเติม
              </Typography>
            </Toolbar>
          </AppBar>
        )}

        <DialogTitle id="add-attachment-dialog">
          {!fullScreen && (
            <>{mode === 'add' ? 'เอกสารแนบเพิ่มเติม / Add Attachment' : 'แก้ไขเอกสารแนบ / Edit Attachment'}</>
          )}
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={1}>
            {isLoading && uploadProgress && (
              <Grid item xs={12} hidden={!isLoading}>
                <LinearProgress variant="determinate" value={uploadProgress} />
              </Grid>
            )}
            <Grid item xs={12}>
              {(attachment && attachment.type === 'file') ||
                (mode === 'add' && (
                  <FormInput fullWidth error={hasError('file')} label="ไฟล์แนบ / Attach File" id="file">
                    {mode === 'add' ? (
                      <>
                        <InputBase
                          id="file"
                          name="file"
                          type="file"
                          onChange={handleFileChange}
                          aria-describedby="file-text"
                          // disabled={isLoading}
                        />
                        <FormHelperText id="file-text">
                          รองรับไฟล์ txt, png, jpg, pdf, doc, pptx, docx, xls, xlsx, mp4
                        </FormHelperText>
                      </>
                    ) : (
                      <InputBase value={attachment.fileName} disabled fullWidth />
                    )}
                  </FormInput>
                ))}
            </Grid>
            <Grid item xs={12}>
              <FormInput
                fullWidth
                error={hasError('title')}
                required
                label="ชื่อไฟล์ / Title"
                id="title"
                disabled={isLoading}>
                <InputBase id="title" name="title" value={values.title} onChange={handleChange} fullWidth />
              </FormInput>
            </Grid>
            <Grid item xs={12}>
              <FormInput fullWidth error={hasError('url')} label="ลิงค์ไฟล์ / URL" id="url" 
              // disabled={isLoading}
              >
                <InputBase id="url" name="url" value={values.url} onChange={handleUrlChange} fullWidth />
              </FormInput>
            </Grid>
            <Grid item xs={12}>
              <FormInput fullWidth label="รายละเอียดเอกสารแนบ / Description" id="description" 
              // disabled={isLoading}
              >
                <InputBase
                  id="description"
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                  multiline
                  rows={2}
                  rowsMax={10}
                  fullWidth
                />
              </FormInput>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!values.confidential}
                      onChange={handleCheckbox}
                      name="confidential"
                      value="checked"
                    />
                  }
                  label="ที่เปิดเผยในรายงานประจำปี"
                />
              </FormControl>
            </Grid>
          </Grid>
          {error && error.response && (
            <Box marginTop={2} marginBottom={2}>
              <Typography style={{ color: 'red', fontWeight: 500 }}>
                {error.response.status === 400 && !error.response.message
                  ? 'กรุณาแนบไฟล์ที่ระบบรองรับดังนี้  txt, png, jpg, pdf, doc, pptx, docx, xls, xlsx, mp4'
                  : 'Bad Request'}
              </Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <LoadingButton variant="contained" color="primary"
            disabled={
              values &&
              mode === 'add' &&
              (values.title === '' || (values.url === '' && (values.file == null || values.file === undefined)))
            }
          onClick={handleSubmit} isLoading={isLoading}>
            {mode === 'add' ? (
              <React.Fragment>
                <CloudUploadIcon /> Upload
              </React.Fragment>
            ) : (
              <React.Fragment>
                <SaveIcon /> Save
              </React.Fragment>
            )}
          </LoadingButton>
          <LoadingButton variant="outlined" color="default" onClick={handleClose} disabled={isLoading}>
            Cancel
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default withMobileDialog()(AddAttachmentSummarizeDialog);
