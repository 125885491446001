import React from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ButtonProps as MuiButtonProps } from '@material-ui/core/Button';

const useStyles = makeStyles((theme: Theme) => ({
  loading: {
    marginRight: theme.spacing(1),
  },
}));

export interface ButtonProps extends MuiButtonProps {
  isLoading?: boolean;
  hidden?: boolean;
}

export default ({ isLoading, children, disabled, hidden, ...buttonProps }: ButtonProps) => {
  const classes = useStyles({});

  if (hidden) return null;

  return (
    <Button disabled={isLoading || disabled} {...buttonProps}>
      {isLoading && <CircularProgress className={classes.loading} color="inherit" size={16} />}
      {children}
    </Button>
  );
};
