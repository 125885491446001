import { Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import ContentWrapper from '../../components/ContentWrapper';
import MainLayout from '../../components/MainLayout';
import { Report3Provider } from '../../components/report/Report3Context';
import Report3Filter from '../../components/report/Report3Filter';
import { Report3Title } from '../../components/report/Report3Title';

const useStyles = makeStyles(() => ({
  paper: {
    padding: 0,
  },
}));

export const Report3Page = () => {
  const classes = useStyles({});

  return (
    <MainLayout>
      <ContentWrapper>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Report3Title />
          </Grid>
        </Grid>
        <Report3Provider>
          <Paper className={classes.paper}>
            <Report3Filter />
          </Paper>
        </Report3Provider>
      </ContentWrapper>
    </MainLayout>
  );
};

export default Report3Page;
