import { client } from './client';
import { FAQ_URI } from './constants';
import { FaqCreateRequest, FaqUpdateRequest } from '../models/master-dto';

export async function getOne(id: number) {
  return await client.get(`${FAQ_URI}/${id}`, {});
}

export async function getAll() {
  return await client.get(`${FAQ_URI}`, {});
}

export async function create(request: FaqCreateRequest) {
  return await client.post(`${FAQ_URI}`, request, {});
}

export async function update(id: number, request: FaqUpdateRequest) {
  return await client.put(`${FAQ_URI}/${id}`, request, {});
}

export async function deleteOne(id: number) {
  return await client.delete(`${FAQ_URI}/${id}`, {});
}
