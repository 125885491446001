import React, { useState } from 'react';
import MainLayout from '../components/MainLayout';
import { Typography, Grid, Paper, Box, Card, CardContent, Hidden } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ContentWrapper from '../components/ContentWrapper';
import PageBanner from '../components/PageBanner';
import bannerImg from '../static/img/Banner-SiteMap.png';

const useStyles = makeStyles((theme: Theme) => ({}));

const SiteMapPage = () => {
  const classes = useStyles({});

  return (
    <MainLayout>
      <ContentWrapper>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <PageBanner image={bannerImg} />
          </Grid>
        </Grid>
      </ContentWrapper>
    </MainLayout>
  );
};

export default SiteMapPage;
