import React, { memo } from 'react';

import { Radio, RadioGroup, FormControlLabel, Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { QuestionComponentViewProps } from './props';

const useStyles = makeStyles((theme: Theme) => ({
  radio: {
    height: '30px',
    paddingLeft: '10px',
  },
}));

type YesNoChoiceProps = { disabled?: boolean; checked: boolean };

const YesChoice: React.FC<YesNoChoiceProps> = ({ disabled, checked }) => {
  const classes = useStyles({});
  return (
    <Box className={classes.radio}>
      <FormControlLabel
        disabled={disabled}
        value="true"
        checked={checked}
        control={<Radio color="primary" />}
        label="ใช่ / Yes"
      />
    </Box>
  );
};

const NoChoice: React.FC<YesNoChoiceProps> = ({ disabled, checked }) => {
  const classes = useStyles({});
  return (
    <Box className={classes.radio}>
      <FormControlLabel
        disabled={disabled}
        value="false"
        checked={checked}
        control={<Radio color="primary" />}
        label="ไม่ใช่ / No"
      />
    </Box>
  );
};

const QuestionYesNoView = ({ disabled, onChange, question, questionAnswer }: QuestionComponentViewProps) => {
  if (!onChange || !question || !question.permission) {
    return (
      <RadioGroup onChange={onChange}>
        <YesChoice disabled checked={false} />
        <NoChoice disabled checked={false} />
      </RadioGroup>
    );
  }
  return (
    <RadioGroup onChange={onChange}>
      <YesChoice
        disabled={!question.permission.writeable}
        checked={question.permission.readable && questionAnswer.answer.yesNo === true}
      />
      <NoChoice
        disabled={!question.permission.writeable}
        checked={question.permission.readable && questionAnswer.answer.yesNo === false}
      />
    </RadioGroup>
  );
};

export { QuestionYesNoView };

export default QuestionYesNoView;
