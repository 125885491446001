import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import CreateIcon from '@material-ui/icons/Create';
import BlockIcon from '@material-ui/icons/Block';
import CommentIcon from '@material-ui/icons/Comment';

interface StyleProps {
  bgcolor?: string;
  color?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: '100%',
    background: (props: StyleProps) => props.bgcolor || theme.palette.primary.main,
    color: (props: StyleProps) => props.color || '#fff',
    float: 'right',
    display: 'table',
  },
  statusBox: {
    display: 'table-cell',
    verticalAlign: 'middle',
  },
  statusIcon: {
    display: 'table',
    margin: '0 auto',
  },
  statusText: {
    display: 'table',
    margin: '0 auto',
    textAlign: 'center',
  },
}));

interface TaskActivityCardProps {
  icon?: React.ReactNode;
  text?: string;
  bgcolor?: string;
  color?: string;
}

const TaskActivityCard = ({ icon, text, bgcolor, color }: TaskActivityCardProps) => {
  const classes = useStyles({ bgcolor, color });
  return (
    <div className={classes.root}>
      <div className={classes.statusBox}>
        <div className={classes.statusIcon}>{icon}</div>
        <Typography className={classes.statusText}>{text}</Typography>
      </div>
    </div>
  );
};

export const CreateAssessmentActivity = () => {
  return <TaskActivityCard bgcolor="#cccccc" icon={<CreateIcon fontSize="large" />} text="Create Assessment" />;
};

export const AssessmentActivity = () => {
  return <TaskActivityCard icon={<AssignmentIcon fontSize="large" />} text="Assessment" />;
};

export const AdminReviewActivity = () => {
  return <TaskActivityCard icon={<CommentIcon fontSize="large" />} text="Admin Review" />;
};

export const AdminReviewedActivity = () => {
  return <TaskActivityCard icon={<CommentIcon fontSize="large" />} text="Admin Reviewed" />;
};

export const AdminSummarizeActivity = () => {
  return <TaskActivityCard icon={<AssignmentIcon fontSize="large" />} text="Admin Summarize" />;
};

export const CompletedActivity = () => {
  return <TaskActivityCard icon={<DoneAllIcon fontSize="large" />} text="Completed" />;
};

export const CancelledActivity = () => {
  return <TaskActivityCard bgcolor="#cccccc" icon={<BlockIcon fontSize="large" />} text="Cancelled" />;
};

export const TaskActivity = ({ status }) => {
  switch (status) {
    case 'Create Assessment':
      return <CreateAssessmentActivity />;
    case 'Admin Reviewed':
      return <AdminReviewedActivity />;
    case 'Admin Review':
      return <AdminReviewActivity />;
    case 'Admin Summarize':
      return <AdminSummarizeActivity />;
    case 'Cancelled':
      return <CancelledActivity />;
    case 'Completed':
      return <CompletedActivity />;
    case 'Assessment':
    default:
      return <AssessmentActivity />;
  }
};
