import { makeStyles, Theme } from '@material-ui/core/styles';
import UncheckIcon from '@material-ui/icons/CancelOutlined';
import CheckIcon from '@material-ui/icons/CheckCircleOutlined';
import React from 'react';

export interface CheckOrUncheckIconProps {
  value: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  check: {
    color: theme.palette.primary.main,
  },
  uncheck: {
    color: theme.palette.grey[500],
  },
}));

export const CheckOrUncheckIcon = ({ value }: CheckOrUncheckIconProps) => {
  const classes = useStyles({});
  return value ? <CheckIcon className={classes.check} /> : <UncheckIcon className={classes.uncheck} />;
};

export default CheckOrUncheckIcon;
