import { BaseModel } from './base';
import { Employee } from './user';

export class Announcement extends BaseModel {
  id: number;
  title: string;
  detail?: any;
  imagePath?: string;
  cover?: string;
  sequence: number;
}

export class HomeImage extends BaseModel {
  id: number;
  imagePath: string;
  titleTh: string;
  titleEn: string;
  sequence: number;
}

export class Link extends BaseModel {
  id: number;
  title: string;
  imagePath: string;
  url: string;
  sequence: number;
}

export class Faq extends BaseModel {
  id: number;
  question: string;
  answer: string;
  sequence: number;
}

export class Lookup extends BaseModel {
  id: number;
  fieldName: string;
  nameTh: string;
  nameEn: string;
  shortNameTh: string;
  shortNameEn: string;
  description: string;
}

export class ProcessName extends BaseModel {
  id: string;
  nameTh: string;
  nameEn: string;
  status: StatusTypes;
}

export class AttachmentFile extends BaseModel {
  id: number;
  title: string;
  fileName: string;
  description: string;
  bucket: string;
  folder: string;
  itemId: string;
  confidential: boolean;
  uri: string;
  type: 'file' | 'url';
  url?: string;
  initialName?: string;
  firstName?: string;
  lastName?: string;
  fullnameWithInitialName: string;
  departmentId?: string;
  departmentNameEn?: string;
  departmentNameTh?: string;
  departmentShortName?: string;
  employee: Employee;

  constructor(partial: Partial<AttachmentFile> = {}) {
    super(partial);
    Object.assign(this, { ...partial });
  }
}

export type StatusTypes = 'A' | 'I' | 'D';
