import { Table, TableBody, TableCell, TableHead, TableRow, Menu, Box, MenuItem, Divider, Button } from '@material-ui/core';
import React, {useState} from 'react';
import moment from 'moment';
import Loading from '../Loading';
import SectionView from '../SectionView';
import { useReport8 } from './Report8Context';
import { ModifiedHistory } from '../../models/assessment';
import { Link } from './../../models/master';
import { AdapterLink } from '../RouterLink';
import { apiMessages } from '../../siteSettings';

export const Report8ResultTableView = () => {
  const { isProcessing, data } = useReport8();

  if (isProcessing('submitting')) {
    return <Loading />;
  }

  if (!data) return null;

  return (
    <Box marginTop={3}>
    <Box border={1} borderColor="grey.300">
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell align="center" rowSpan={3}>
              รหัสแบบประเมิน
            </TableCell>
            <TableCell align="center" rowSpan={2}>
              ผู้ประเมิน
            </TableCell>
            <TableCell align="center" rowSpan={2}>
              Activity
            </TableCell>
            <TableCell align="center" rowSpan={2}>
              First Submit Date
            </TableCell>
            <TableCell align="center" rowSpan={2}>
              Admin Return Date
            </TableCell>
            <TableCell align="center" rowSpan={2}>
              Completed
            </TableCell>
          <TableCell align="center" rowSpan={2}>
              วันที่ปิดการประเมิน<br />(ใบหลัก)
            </TableCell>
            <TableCell align="center" rowSpan={2}>
              วันที่ปิดการประเมิน <br />(ใบผู้ประเมิน)
            </TableCell>
            <TableCell align="center" rowSpan={2}>
              สถานะ
            </TableCell>
 
            <TableCell align="center" rowSpan={2}>
              ดูรายละเอียด
            </TableCell>

          
          </TableRow>
      
        </TableHead>
        <TableBody>
          {data.length === 0 && (
            <TableRow>
              <TableCell colSpan={10}>ไม่มีข้อมูล</TableCell>
            </TableRow>
          )}
          {data.map(record => (
            <TableRow key={`${record.code},${record.round},${record.year}`}>
              <TableCell align="left">{record.assessmentNo}</TableCell>
              <TableCell align="left">{record.employeeInitialName} {record.employeeName}</TableCell>
              <TableCell align="right">{record.assessorCurrentStatus}</TableCell>
              <TableCell align="center">{moment(record.firstSubmitDate).format('DD/MM/YYYY')}</TableCell>
              <TableCell align="center">
        
                {record.adminReturnDate === null || record.adminReturnDate === undefined ? "-" : moment(record.adminReturnDate).format('DD/MM/YYYY')}
         
            </TableCell>
            <TableCell align="center">
            {record.adminSubmitReviewedDate === null || record.adminSubmitReviewedDate === undefined ? "-" : moment(record.adminSubmitReviewedDate).format('DD/MM/YYYY')}
           </TableCell>
         
              <TableCell align="center">{moment(record.assessmentStartDate).format('DD/MM/YYYY')}</TableCell>
              <TableCell align="center">{moment(record.assessmentEndDate).format('DD/MM/YYYY')}</TableCell>
        
              <TableCell align="center">{record.status ? "ล่าช้า" : "-"}</TableCell>
           
              <TableCell align="center">
                <PortalMenu record={record} />
              </TableCell> 
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  </Box>
  );
};

type PortalMenuProps = {
  record: any;
};


const PortalMenu: React.FC<PortalMenuProps> = ({ record }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const id = `menu-report01-${record.no}`;
  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <React.Fragment>
      <Button
        aria-controls={id}
        aria-haspopup="true"
        onClick={handleOpen}
        variant="outlined"
        color="primary"
        size="small">
        ดูรายละเอียด
      </Button>
      <Menu id={id} anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem dense component={AdapterLink} to={`/worklist/${record.assessmentNo}/edit`}>
          ใบประเมิน
        </MenuItem>
        <Divider variant="fullWidth" />
        <MenuItem
          dense
          component={AdapterLink}
          to={`/reports/rpt-02?year=${record.year}&level=${record.level}&no=${record.assessmentNo}`}>
          {apiMessages.report.rpt02}
        </MenuItem>
        <MenuItem
          dense
          component={AdapterLink}
          to={`/reports/rpt-03?year=${record.year}&level=${record.level}&no=${record.assessmentNo}`}>
          {apiMessages.report.rpt03}
        </MenuItem>
        <MenuItem
          dense
          component={AdapterLink}
          to={`/reports/rpt-04?year=${record.year}&level=${record.level}&no=${record.assessmentNo}`}>
          {apiMessages.report.rpt04}
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

// function parseAnswer(question, answer) {
//   switch (question.control) {
//     case 'yesno':
//       return answer.yesNo ? 'ใช่' : 'ไม่ใช่';
//     case 'checkbox': {
//       return answer.choiceIds
//         .map(choiceId => {
//           const choice = question.choices.find(({ id }) => id === choiceId);
//           return choice && choice.title;
//         })
//         .join('\r\n');
//     }
//     case 'choice': {
//       const answerChoice = question.choices.find(({ id }) => id === answer.choiceId)
//       return answerChoice && answerChoice.title
//     }
//     case 'text':
//       return answer.detail === null ? '-' : answer.detail;
//     case 'rating':
//       return answer.rating === null ? '-' : answer.rating;
//     case 'ranking': {
//       if (!answer.choiceIds) return ''
//       return answer.choiceIds.map(i => i.split(':'))
//         .sort(([, rank1], [, rank2]) => Number(rank1) - Number(rank2))
//         .map(([choiceId], index) => {
//           const choice = question.choices.find(({ id }) => id === choiceId);
//           return `${index + 1}. ${choice.title}`;
//         })
//         .join(', ');
//     }
//     case 'date':
//       return answer.date === null ? '-' : answer.date;
//   }
// }