import { Button, CircularProgress, FormControl, Grid, Paper, TextField, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import { IsNotEmpty, MinLength } from 'class-validator';
import { useSnackbar } from 'notistack';
import queryString from 'query-string';
import React, { useState } from 'react';
import useRouter from 'use-react-router';

import { signInLdap } from '../apis/auth';
import { useUserState } from '../components/context/UserStateContext';
import { useFormValidation } from '../components/useFormValidation';
import Logo from '../static/img/logo.png';
import { errorOptions, successOptions } from '../utils/snackbar';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    marginTop: theme.spacing(1.2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),

    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  heading: {
    backgroundColor: theme.palette.primary.main,
    height: '60px',
    width: '100%',
    color: '#FFFFFF',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '12px',
    margin: '2rem 0rem 0px 0rem',
  },
  logo: {
    height: '180px',
    width: '180px',
    paddingBottom: '1rem',
  },
  username: {
    paddingTop: 12,
    paddingBottom: 8,
    paddingLeft: 10,
    paddingRight: 10,
  },
  textInput: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  root: {
    height: '100vh',
  },
  loginForm: {
    maxWidth: 480,
    paddingTop: 60,
    margin: '0 auto',
    [theme.breakpoints.down('xs')]: {
      maxWidth: 480,
      paddingTop: 20,
    },
  },
  loginButton: {
    height: '45px',
    width: '100px',
  },
  wrapLoginButton: {
    paddingLeft: '19px',
    paddingRight: '19px',
    paddingBottom: '1rem',
    paddingTop: '2rem',
  },
  topSpace: {
    paddingTop: '2rem',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  caption: {
    fontSize: '15px',
    paddingBottom: '10px',
  },
}));

class LoginForm {
  @IsNotEmpty()
  email: string;

  @MinLength(4)
  password: string;
}

const LdapLoginPage = () => {
  const classes = useStyles({});
  const { location, history } = useRouter();
  const { setAccessToken, setRefreshToken } = useUserState();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const { values, handleSubmit, setValue, hasError, isSubmitting } = useFormValidation({
    initialValues: {
      email: '',
      password: '',
    },
    type: LoginForm,
    onSuccess: async (request: LoginForm) => {
      setLoading(true);
      return await signInLdap(request.email, request.password)
        .then(({ data }) => {
          setRefreshToken(data.refreshToken);
          setAccessToken(data.accessToken);

          const redirectTo = queryString.parse(location.search)['redirectTo'] as string;
          const to = redirectTo ? redirectTo : '/worklist';
          history.push(to);
          enqueueSnackbar('Login successfully.', successOptions);
        })
        .catch(error => {
          enqueueSnackbar('Login Failed.', errorOptions);
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  const handleChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value.trim();
    setValue(name, value);
  };

  return (
    <div className={classes.root}>
      <form className={classes.loginForm} onSubmit={handleSubmit}>
        <Paper className={classes.paper}>
          <Grid container spacing={0} direction="row" justify="center" alignItems="center">
            <div className={classes.heading}>
              <Grid item xs={12}>
                <Typography variant="h2">e-CSA</Typography>
              </Grid>
            </div>
            <div className={classes.topSpace}>
              <Grid item xs={12}>
                <div className={classes.logo}>
                  <img src={Logo} alt="Logo" />
                </div>
              </Grid>
            </div>
            <Grid item xs={12}>
              <div className={classes.username}>
                <FormControl fullWidth required>
                  <TextField
                    label="abcd@bangchak.co.th or Domain\Initial"
                    variant="outlined"
                    id="email"
                    name="email"
                    onChange={handleChange}
                    value={values.email}
                    error={hasError('email')}
                    disabled={isSubmitting}
                    fullWidth
                  />
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.textInput}>
                <FormControl fullWidth required>
                  <TextField
                    label="Password"
                    type="password"
                    variant="outlined"
                    id="password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    error={hasError('password')}
                    disabled={isSubmitting}
                    fullWidth
                  />
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.wrapLoginButton}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  type="submit"
                  className={classes.loginButton}
                  onClick={handleSubmit}
                  disabled={isSubmitting}>
                  {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                  เข้าสู่ระบบ
                </Button>
              </div>
              <div>
                <Typography variant="caption" component="p" className={classes.caption}>
                  เพื่อความสะดวกในการใช้งานกรุณาเข้าระบบด้วย Google Chrome
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </form>
    </div>
  );
};

export default LdapLoginPage;
