import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';

import React from 'react';
import Loading from '../Loading';
import SectionView from '../SectionView';
import { useReport7 } from './Report7Context';

export const Report7ResultTableView = () => {
  const { isProcessing, data } = useReport7();

  if (isProcessing('submitting')) {
    return <Loading />;
  }

  if (!data) return null;

  return (
    <div>
      {data.components
        .filter(_ => _.status === 'A')
        .map(component => (
          <div key={component.id}>
            <Typography variant="h6">องค์ประกอบ: {component.fullname}</Typography>
            {data.sections
              .filter(section => section.componentId === component.id)
              .map(section => {
                return (
                  <SectionView section={section} key={section.id}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>ลำดับข้อ</TableCell>
                          <TableCell>คำถาม</TableCell>
                          {/* <TableCell>คำตอบ</TableCell> */}
                          {/* <TableCell>คำอธิบาย</TableCell> */}

                          <TableCell
                            style={{
                              width: '150px',
                              textAlign: 'center',
                              verticalAlign: 'middle',
                              whiteSpace: 'pre-wrap',
                              wordWrap: 'break-word',
                            }}>
                            จำนวนผู้ประเมินที่ต้องตอบ
                          </TableCell>
                          <TableCell
                            style={{
                              width: '100px',
                              textAlign: 'center',
                              verticalAlign: 'middle',
                              whiteSpace: 'pre-wrap',
                              wordWrap: 'break-word',
                            }}>
                            โดย
                          </TableCell>
                          {/* <TableCell>เอกสารประกอบ</TableCell> */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {/* {data.questions
                          .filter(({ question }) => question.sectionId === section.id)
                          .map(({ question, answerByAssessors }) =>
                            answerByAssessors
                              .filter(({ answer }) => answer)
                              .map(({ answer, assessor, attachments }, no) => {
                                return (
                                  answer && (
                                    <TableRow
                                      // style={{}}
                                      key={`${question.id},${assessor.employeeInitialName},${assessor.departmentInitialName}`}>
                                    <TableCell  style={{
                                      fontSize: 12,
                                      textAlign: 'center',
                                      verticalAlign: 'top', // aligns content to the top
                                      whiteSpace: 'pre-wrap',
                                      wordWrap: 'break-word',
                                    }}
                                   >{no === 0 && question.no}</TableCell>

                                <TableCell style={{
                                fontSize: 14,
                                verticalAlign: 'middle',
                                whiteSpace: 'pre-wrap',
                                wordWrap: 'break-word',
                              }}>{no === 0 && question.title.split('\n').map((line, index) => (
                                <React.Fragment key={index}>
                                  {line}
                                  <br />
                                </React.Fragment>
                              ))}</TableCell>

                                      <TableCell>
                                        {(assessor &&
                                          `${assessor.departmentInitialName}-${assessor.employeeInitialName}`) ||
                                          '-'}
                                      </TableCell>
                              
                                      <TableCell>
                                      {(answerByAssessors && answerByAssessors.length) ? answerByAssessors.length: 0  }     
                                      </TableCell>
                                    </TableRow>
                                  )
                                );
                              }),
                          )} */}

                        {data.questions
                          .filter(({ question }) => question.sectionId === section.id)
                          .map(({ question, answerByAssessors }) => (
                            <TableRow
                              // key={`${question.id},${assessor.employeeInitialName},${assessor.departmentInitialName}`}>
                              key={`${question.id}`}>
                              <TableCell
                                style={{
                                  fontSize: 12,
                                  textAlign: 'center',
                                  verticalAlign: 'top', // aligns content to the top
                                  whiteSpace: 'pre-wrap',
                                  wordWrap: 'break-word',
                                }}>
                                {question && question.no}
                              </TableCell>

                              <TableCell
                                style={{
                                  fontSize: 14,
                                  verticalAlign: 'middle',
                                  whiteSpace: 'pre-wrap',
                                  wordWrap: 'break-word',
                                }}>
                                {question &&
                                  question.title.split('\n').map((line, index) => (
                                    <React.Fragment key={index}>
                                      {line}
                                      <br />
                                    </React.Fragment>
                                  ))}
                              </TableCell>
                              <TableCell
                                style={{
                                  textAlign: 'center',
                                  verticalAlign: 'middle',
                                  whiteSpace: 'pre-wrap',
                                  wordWrap: 'break-word',
                                }}>
                                {answerByAssessors && answerByAssessors.length ? answerByAssessors.length : 0}
                              </TableCell>
                              <TableCell>
                                {answerByAssessors
                                  .filter(({ answer }) => answer)
                                  .map(({ answer, assessor }, no, array) => (
                                    <React.Fragment key={no}>
                                      {answer &&
                                        `${
                                          assessor
                                            ? `${assessor.departmentInitialName}-${assessor.employeeInitialName}`
                                            : '-'
                                        }`}
                                      {no < array.length - 1 && ', '}
                                    </React.Fragment>
                                  ))}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </SectionView>
                );
              })}
          </div>
        ))}
    </div>
  );
};

function parseAnswer(question, answer) {
  switch (question.control) {
    case 'yesno':
      return answer.yesNo ? 'ใช่' : 'ไม่ใช่';
    case 'checkbox': {
      return answer.choiceIds
        .map(choiceId => {
          const choice = question.choices.find(({ id }) => id === choiceId);
          return choice && choice.title;
        })
        .join('\r\n');
    }
    case 'choice': {
      const answerChoice = question.choices.find(({ id }) => id === answer.choiceId);
      return answerChoice && answerChoice.title;
    }
    case 'text':
      return answer.detail === null ? '-' : answer.detail;
    case 'rating':
      return answer.rating === null ? '-' : answer.rating;
    case 'ranking': {
      if (!answer.choiceIds) return '';
      return answer.choiceIds
        .map(i => i.split(':'))
        .sort(([, rank1], [, rank2]) => Number(rank1) - Number(rank2))
        .map(([choiceId], index) => {
          const choice = question.choices.find(({ id }) => id === choiceId);
          return `${index + 1}. ${choice.title}`;
        })
        .join(', ');
    }
    case 'date':
      return answer.date === null ? '-' : answer.date;
  }
}
