import { client } from './client';
import { MASTER_PROCESS_NAME_URI } from './constants';

import { ProcessNameCreateRequest, ProcessNameUpdateRequest } from '../models/master-dto';

export async function create(request: ProcessNameCreateRequest) {
  return await client.post(`${MASTER_PROCESS_NAME_URI}`, request, {});
}

export async function update(id: string, request: ProcessNameUpdateRequest) {
  return await client.put(`${MASTER_PROCESS_NAME_URI}/${id}`, request, {});
}

export async function deleteOne(id: string) {
  return await client.delete(`${MASTER_PROCESS_NAME_URI}/${id}`, {});
}
