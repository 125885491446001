import useAxios from 'axios-hooks';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';

import { FAQ_URI } from '../../apis/constants';
import * as FaqService from '../../apis/faq';
import ContentWrapper from '../../components/ContentWrapper';
import MainLayout from '../../components/MainLayout';
import FaqTable from '../../components/masterdata/FaqTable';
import { Faq } from '../../models';
import { FaqCreateRequest, FaqUpdateRequest } from '../../models/master-dto';
import { ERROR_TEXT, errorOptions } from '../../utils/snackbar';

const MasterFaq = () => {
  const [{ data: payload = {} as any, loading, error }, refetch] = useAxios<Faq[]>(FAQ_URI, { manual: true });
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    refetch()
  }, [])

  if (error) enqueueSnackbar(ERROR_TEXT, errorOptions);

  const data = payload.data || [];

  return (
    <MainLayout>
      <ContentWrapper>
        <FaqTable isLoading={loading} data={data} onAdd={handleAdd} onUpdate={handleUpdate} onDelete={handleDelete} />
      </ContentWrapper>
    </MainLayout>
  );

  function handleAdd(data: FaqCreateRequest) {
    data.sequence = Number(data.sequence);
    return FaqService.create(data).then(() => refetch());
  }

  function handleUpdate(newData: Faq, oldData: FaqUpdateRequest) {
    newData.sequence = Number(newData.sequence);
    return FaqService.update(newData.id, newData).then(() => refetch());
  }

  function handleDelete(lookup: Faq) {
    return FaqService.deleteOne(lookup.id).then(() => refetch());
  }
};

export default MasterFaq;
