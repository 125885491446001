import FileSaver from 'file-saver';
import React, { useContext, useState } from 'react';
import * as qs from 'query-string';

import { client } from '../../apis';
import { useIsProcessing } from '../useIsProcessing';
import { mimetype } from '../../utils/mimetype';
import { extractObjectByKeys } from '../../utils/object';
import useRouter from 'use-react-router';

const Report1Context = React.createContext(null);

const defaultFilter = {
  level: 'CO',
  processId: '',
};

const keys = ['level', 'processId'];

function getParams(search) {
  return search ? extractObjectByKeys(qs.parse(search), keys) : defaultFilter;
}

export const Report1Provider = ({ children }) => {
  const { location } = useRouter();
  const { isProcessing, setIsProcessing } = useIsProcessing();
  const [data, setData] = useState<any>();
  const [assessmentFilter, setAssessmentFilter] = useState(getParams(location.search));

  const hofHandleChange = (getter: any, setter: any) => (name: string) => (e: any) => {
    setter({
      ...getter,
      [name]: e.target.value,
    });
  };

  const handleAssessmentFilterChange = hofHandleChange(assessmentFilter, setAssessmentFilter);

  const searchReport1 = () => {
    setIsProcessing('searching', true);
    client
      .get(`reports/rpt-01`, { params: assessmentFilter })
      .then(({ data: payload }) => {
        setData(payload.data.assessments);
      })
      .catch(ex => {
        console.error(ex);
        setData(null);
      })
      .finally(() => setIsProcessing('searching', false));
  };

  const submitFilterForm = () => {
    searchReport1();
  };

  const exportExcel = () => {
    setIsProcessing('exporting', true);
    client
      .get(`reports/rpt-01/excel`, { params: assessmentFilter, responseType: 'blob' })
      .then(response => {
        const { filename } = response.headers;

        (window as any).$ = response;

        const blob = new Blob([response.data], {
          type: mimetype.excel,
        });
        FileSaver.saveAs(blob, decodeURIComponent(filename));
      })
      .finally(() => setIsProcessing('exporting', false));
  };

  const value = {
    assessmentFilter,
    setAssessmentFilter,
    handleAssessmentFilterChange,
    submitFilterForm,
    exportExcel,
    isProcessing,
    setIsProcessing,
    data,
  };

  return <Report1Context.Provider value={value}>{children}</Report1Context.Provider>;
};

export interface Report1Values {
  assessmentFilter: any;
  setAssessmentFilter: any;
  assessmentDataFilter: any;
  setAssessmentDataFilter: any;
  handleAssessmentFilterChange: any;
  handleAssessmentDataFilterChange: any;
  submitFilterForm: () => void;
  exportExcel: () => void;
  isProcessing: (...keys: string[]) => boolean;
  setIsProcessing: (key: string, flag: boolean) => void;
  data: any;
}

export const useReport1 = () => {
  return useContext<Report1Values>(Report1Context);
};
