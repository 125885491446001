import MaterialTable, { Column } from 'material-table';
import React from 'react';
import { head } from 'lodash';

import { Announcement } from '../../models';
import { defaultOptions, tableIcons } from './constants';
import { TableProps } from './types';
import RichText from '../Richtext/RichText';
import RichTextView from '../Richtext/RichTextView';
import { ColumnImage } from './ColumnImage';

const AnnouncementTable = ({ data, onAdd, onUpdate, onDelete, isLoading = false }: TableProps<Announcement>) => {
  const tableColumns: Column<any>[] = [
    { title: 'Sequence', field: 'sequence', editable: 'always', type: 'numeric' },
    { title: 'Title', field: 'title', editable: 'always' },
    {
      title: 'Detail',
      field: 'detail',
      editable: 'always',
      headerStyle: { width: '35%' },
      editComponent: props => (
        <RichText onChange={value => props.onChange(JSON.stringify(value))} value={props.value} />
      ),
      render: rowData => <RichTextView value={rowData.detail} />,
    },
    {
      title: 'Image',
      field: 'file',
      editComponent: props => (
        <input id="file" name="file" type="file" onChange={e => props.onChange(head(e.target.files))} />
      ),
      render: rowData => (
        <ColumnImage title={rowData.title} imageUrl={`${process.env.REACT_APP_BASE_URL}/static/${rowData.uri}`} />
      ),
    },
    { title: 'Status', field: 'status', editable: 'onUpdate', lookup: { A: 'Active', I: 'Inactive', D: 'Delete' } },
  ];

  return (
    <MaterialTable
      columns={tableColumns}
      data={data}
      title={'Master About Internal Control'}
      icons={tableIcons}
      options={defaultOptions}
      editable={{
        onRowAdd: newData => onAdd(newData),
        onRowUpdate: (newData, oldData) => onUpdate(newData, oldData),
        onRowDelete: oldData => onDelete(oldData),
      }}
    />
  );
};

export default AnnouncementTable;
