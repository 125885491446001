import React from 'react';
import maxBy from 'lodash/maxBy';
import { FormControlLabel, Grid, IconButton, Checkbox as MuiCheckbox, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import CancelIcon from '@material-ui/icons/Cancel';
import { QuestionComponentWithChoicesProps } from './props';
import { FormGroup, FormInput } from '../form';
import InputText from '../InputText';
import LoadingButton from '../LoadingButton';
import { apiMessages } from '../../siteSettings';

const useStyles = makeStyles((theme: Theme) => ({
  cancelIcon: {
    marginTop: theme.spacing(0.9),
  },
  inputTitle: {
    paddingTop: 4,
  },
  label: {
    paddingTop: 10,
  },
}));

const QuestionRanking = ({
  question,
  choices,
  handleAddChoice,
  handleUpdateChoice,
  handleDeleteChoice,
  handleBlurChoice,
  handleCheck,
  isLoading,
  disabled,
}: QuestionComponentWithChoicesProps) => {
  const classes = useStyles({});

  return (
    <>
      <Grid container>
        <Grid item xs={1} md={1} />
        <Grid item xs={4} md={11}>
          <FormInput label="คำตอบ" disabled={disabled}>
            <FormGroup>
              {choices.map((choice, i: number) => (
                <Grid container key={choice.id}>
                  <Grid container item md={6}>
                    <Grid item xs={10} md={10}>
                      <InputText
                        disabled={disabled}
                        className={classes.inputTitle}
                        placeholder="ระบุคำตอบ"
                        fullWidth
                        value={choice.title}
                        onChange={(e: React.ChangeEvent<{ name?: string; value: unknown }>) =>
                          handleUpdateChoice({
                            ...choice,
                            title: e.target.value as string,
                          })
                        }
                        onBlur={() => handleBlurChoice(choice)}
                      />
                    </Grid>
                    <Grid item xs={1} md={1}>
                      <IconButton
                        aria-label="Cancel"
                        size="small"
                        className={classes.cancelIcon}
                        disabled={isLoading || disabled}
                        onClick={() => handleDeleteChoice(choice.id)}>
                        <CancelIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </FormGroup>
          </FormInput>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={1} />
        <Grid item xs={4}>
          {!disabled && (
            <LoadingButton isLoading={isLoading} variant="outlined" size="small" onClick={() => addChoice()}>
              + Add Options
            </LoadingButton>
          )}
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={1} md={1} />
        <Grid container item md={8}>
          <Grid item xs={6}>
            <Typography variant="body1" align="center" className={classes.label}>
              โปรดระบุ
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <FormControlLabel
              disabled={disabled}
              onChange={handleCheck}
              control={
                <MuiCheckbox
                  checked={!!question.requiredComment}
                  id="requiredComment"
                  name="requiredComment"
                  value="requiredComment"
                />
              }
              label={apiMessages.requireComment}
            />
          </Grid>
        </Grid>
        <Grid container item md={3}>
          <Grid item xs={6}>
            <Typography variant="body1" align="center" className={classes.label}>
              เอกสารแนบ
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <FormControlLabel
              disabled={disabled}
              onChange={handleCheck}
              control={
                <MuiCheckbox
                  checked={!!question.requiredAttachment}
                  id="requiredAttachment"
                  name="requiredAttachment"
                  value="requiredAttachment"
                />
              }
              label={apiMessages.requireAttachment}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );

  function addChoice() {
    if (handleAddChoice) {
      const _choice = maxBy(choices, s => s.sequence);
      const sequence = _choice ? _choice.sequence + 1 : 1;
      const choice = {
        title: '',
        sequence,
        isOther: false,
        required: false,
        requiredAttachment: false,
        requiredCompletedDate: false,
      };
      handleAddChoice(choice);
    }
  }
};

export { QuestionRanking };

export default QuestionRanking;
