import React from 'react';
import { useReport8 } from './Report8Context';
import { ExportToolbar } from './ExportToolbar';
import { Report8ResultTableView } from './Report8ResultTableView';

export const Report8Result = () => {
  const { exportExcel, isProcessing, data } = useReport8();
  return (
    <div>
      <ExportToolbar
        disabled={!data}
        excelFn={exportExcel}
        enabled={['excel']}
        excelLoading={isProcessing('excel-exporting')}
      />
      <Report8ResultTableView />
    </div>
  );
};

export default Report8Result;
