import React from 'react';

import { Grid } from '@material-ui/core';
import ViewListItem from '@material-ui/icons/ViewList';

import MainLayout from '../components/MainLayout';
import ContentWrapper from '../components/ContentWrapper';
import PageTitle from '../components/PageTitle';
import { WorklistContainer } from '../containers/WorklistContainer';
import { WorklistProvider } from '../context';

const WorklistPage = () => {
  return (
    <MainLayout>
      <ContentWrapper>
        <Grid container>
          <Grid item>
            <PageTitle title="Worklist" icon={ViewListItem} />
          </Grid>
        </Grid>

        <WorklistProvider>
          <WorklistContainer />
        </WorklistProvider>
      </ContentWrapper>
    </MainLayout>
  );
};

export default WorklistPage;
