import { AssessorRole } from '../../models';
import { useUserState } from '../context/UserStateContext';
import { useAssessmentRequest } from './AssessmentRequestContext';

export interface RoleVisible {
  adminOnly?: boolean;
  notAdmin?: boolean;
  adminByPass?: boolean;
  roles?: AssessorRole[];
  children: any;
}

export function RoleVisible({ notAdmin, adminOnly, adminByPass, roles, children }: RoleVisible) {
  const { hasAnyRoles } = useUserState();
  const { getCurrentUserAsAssessor } = useAssessmentRequest();
  const currentAssessor = getCurrentUserAsAssessor();

  if (adminOnly && !hasAnyRoles('ADMIN')) return null;
  if (((adminByPass || adminOnly) && hasAnyRoles('ADMIN')) || (!hasAnyRoles('ADMIN') && notAdmin)) return children;

  if (roles && currentAssessor && roles.includes(currentAssessor.assessorRole)) return children;
  return null;
}

export default RoleVisible;
