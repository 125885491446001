import React from 'react';

import TextInput from '../InputBase';
import { FormInput } from '../form';
import { QuestionComponentViewProps } from './props';

const QuestionTextView = ({ disabled, onChange, questionAnswer, question }: QuestionComponentViewProps) => {
  if (!onChange || !question || !question.permission) {
    return (
      <FormInput fullWidth id="answer" label="คำตอบ / Answer" disabled={disabled}>
        <TextInput multiline rows={3} disabled value="" />
      </FormInput>
    );
  }
  return (
    <FormInput fullWidth id="answer" label="คำตอบ / Answer" disabled={disabled}>
      <TextInput
        multiline
        rows={3}
        disabled={!question.permission.writeable}
        onChange={onChange}
        value={(question.permission.readable && questionAnswer.answer.detail) || ''}
      />
    </FormInput>
  );
};

export { QuestionTextView };

export default QuestionTextView;
