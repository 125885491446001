import { Box, Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker as DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import React, { memo, useState } from 'react';

import { useWorklist } from '../../context';
import { FormInput } from '../form';
import InputText from '../InputText';
import LoadingButton from '../LoadingButton';
import WorklistStatusOption from './WorklistStatusOption';

const useStyles = makeStyles(() => ({
  root: {
    padding: 16,
  },
}));

type ButtonProps = {
  onClick: () => void;
};

const SearchButton = memo(({ onClick }: ButtonProps) => (
  <LoadingButton fullWidth variant="contained" color="primary" size="small" type="submit">
    Search
  </LoadingButton>
));

const ResetButton = memo(({ onClick }: ButtonProps) => (
  <LoadingButton fullWidth variant="contained" color="secondary" size="small" onClick={onClick}>
    Reset
  </LoadingButton>
));

export const WorklistSearch = () => {
  const classes = useStyles({});
  const { filter, setFilterByKey, submit, resetFilter } = useWorklist();
  const [startDate, setStartDate] = useState<any>(
    (filter.startDate && moment(filter.startDate).format('YYYY-MM-DD')) || null,
  );
  const [endDate, setEndDate] = useState<any>((filter.endDate && moment(filter.endDate).format('YYYY-MM-DD')) || null);

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        submit({
          ...filter,
          page: 1,
        });
      }}>
      <Paper className={classes.root}>
        <Grid container spacing={2}>
          <Grid item md={4}>
            <FormInput fullWidth id="form-search" label="Search">
              <InputText
                id="document-code"
                placeholder="รหัสแบบประเมิน / Document Code"
                name="code"
                value={filter.code}
                onChange={e => setFilterByKey('code', e.target.value)}
                autoFocus
              />
            </FormInput>
          </Grid>
          <Grid item md={2}>
            <FormInput fullWidth id="form-startDate" label="Start Date">
              <DatePicker
                autoOk
                clearable
                margin="normal"
                value={startDate || null}
                onChange={date => {
                  setStartDate(date && date.format('YYYY-MM-DD'));
                  setFilterByKey('startDate', date && date.format('YYYY-MM-DD'));
                }}
                invalidDateMessage=""
                error={false}
                format="DD/MM/YYYY"
              />
            </FormInput>
          </Grid>
          <Grid item md={2}>
            <FormInput fullWidth id="form-endDate" label="End Date">
              <DatePicker
                autoOk
                clearable
                margin="normal"
                value={endDate || null}
                onChange={date => {
                  setEndDate(date && date.format('YYYY-MM-DD'));
                  setFilterByKey('endDate', date && date.format('YYYY-MM-DD'));
                }}
                invalidDateMessage=""
                error={false}
                format="DD/MM/YYYY"
              />
            </FormInput>
          </Grid>
          <Grid item md={2}>
            <WorklistStatusOption />
          </Grid>
          <Grid item md={1}>
            <Box marginTop={2.5}>
              <SearchButton onClick={() => submit()} />
            </Box>
          </Grid>
          <Grid item md={1}>
            <Box marginTop={2.5}>
              <ResetButton
                onClick={() => {
                  resetFilter();
                  setStartDate(null);
                  setEndDate(null);
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </form>
  );
};

export default WorklistSearch;
