import { Box, Card, Grid, Typography, Link } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React, { useRef, useState, useEffect, useCallback } from 'react';

import RichTextView from './Richtext/RichTextView';
import { AdapterLink } from './RouterLink';

const useStyles = makeStyles((theme: Theme) => ({
  aboutCard: {
    padding: '10px 0px 10px 0px',
  },
  aboutCardImg: {
    width: '100%',
    height: '100%',
    display: 'flex',
  },
  contentBox: {
    padding: '5px',
  },
  aboutCardContent: {
    display: '-webkit-box',
    WebkitLineClamp: 4,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    whiteSpace: 'normal',
  },
}));

export interface AnnouncementCardProps {
  data: any;
}

export const AnnouncementCard: React.FC<AnnouncementCardProps> = ({ data }) => {
  const [readMore, setReadMore] = useState(false)
  const classes = useStyles({});

  const measuredRef = useCallback(node => {
    if (node !== null) {
      if (node.scrollHeight > 84) {
        setReadMore(true)
      }
    }
  }, []);

  return (
    <Card>
      <Grid container spacing={0}>
        <Grid item xs={4}>
          <Box className={classes.aboutCardImg}>
            <img src={`${process.env.REACT_APP_BASE_URL}/static/${data.uri}`} width={110} height={110} alt="Announcement" />
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Box paddingTop={1} paddingBottom={1}>
            <Link component={AdapterLink} to="/about">
              <Typography variant="h6" component="div">
                {data.title}
              </Typography>
              <div className={classes.contentBox} >
                <div className={classes.aboutCardContent} ref={measuredRef}>
                  <RichTextView value={data.detail} />
                </div>
                {readMore && "Read more"}
              </div>
              
            </Link>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};

export default AnnouncementCard;
