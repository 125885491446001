import React, { memo } from 'react';
import { FormInput } from './form';
import { Box, Paper } from '@material-ui/core';
import CommentatorTable from './CommentatorTable';
import { Assessor } from '../models';
import { listenerCount } from 'cluster';

export type ComponentSummaryProps = {
  disabled?: boolean;
  list?: any[];
  onChange?: (assessor: Assessor, checked: boolean) => void;
};

export const ComponentSummary = memo(
  ({ disabled, list, onChange }: ComponentSummaryProps) => {
    return (
      <FormInput grey id="commentCommentator" label="ผู้สรุปความคิดเห็น">
        <Box marginTop={3}>
          <Paper square>
            <CommentatorTable list={list} onCheck={onChange} disabled={disabled} />
          </Paper>
        </Box>
      </FormInput>
    );
  },
  (prev, next) => {
    if (next.disabled) return true;
    return prev.list.length === next.list.length;
  },
);
