import { Grid, Box, Select, MenuItem } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';

import { FormInput } from '../form';
import InputBase from '../InputBase';
import InputText from '../InputText';
import LoadingButton from '../LoadingButton';
import { useReport4_1 } from './Report4IndividualContext';
import { getYearListFrom } from '../../utils/date';
import { ReportSearchButton } from './ReportSearchButton';

const useStyles = makeStyles((theme: Theme) => ({
  //style here
  root: {
    padding: '5px 20px 5px 20px',
  },
  input: {
    padding: '10px',
  },
  paper: {
    padding: '0px',
  },
  resetButton: { paddingLeft: '10px', marginTop: 30 },
  table: {
    padding: '10px',
  },
}));

const Report4IndividualFilter = () => {
  const classes = useStyles({});
  const {
    filter,
    setFilter,
    assessmentDataFilter,
    handleAssessmentFilterChange,
    handleAssessmentDataFilterChange,
    submitFilterForm,
    isProcessing,
    result,
    data,
    meta,
    searching,
    resetDataFilter,
  } = useReport4_1();

  const handleCodeChange = e => {
    const no = e.target.value;
    setFilter({
      ...filter,
      no,
    });
  };

  return (
    <React.Fragment>
      <Grid container direction="row" alignItems="center">
        <Grid item xs={12} sm={12} md={2}>
          <Box className={classes.input}>
            <FormInput fullWidth id="year" label="ปี / Year" required>
              <Select
                onChange={handleAssessmentFilterChange('year')}
                input={<InputBase name="year" id="year" value={filter.year} />}>
                {getYearListFrom().map(year => (
                  <MenuItem key={year} value={year}>
                    {year + 543}
                  </MenuItem>
                ))}
              </Select>
            </FormInput>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={2}>
          <Box className={classes.input}>
            <FormInput fullWidth id="level" label="ระดับ / Level" required>
              <Select
                onChange={handleAssessmentFilterChange('level')}
                input={<InputBase name="level" id="level" value={filter.level} />}>
                <MenuItem value="CO">องค์กร</MenuItem>
                <MenuItem value="PC">กระบวนการ</MenuItem>
              </Select>
            </FormInput>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <Box className={classes.input}>
            <FormInput fullWidth id="code" label="รหัสแบบประเมิน / Document Code" required>
              {!result || result.length === 0 ? (
                <InputText disabled value={searching ? 'กำลังโหลด' : 'ไม่มีข้อมูล'} />
              ) : (
                <Select onChange={handleCodeChange} input={<InputBase name="code" id="code" value={filter.no} />}>
                  {result.map(result => (
                    <MenuItem key={result.no} value={result.no}>
                      {result.code} ครั้งที่ {result.round}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </FormInput>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={2}>
          <ReportSearchButton
            onClick={submitFilterForm}
            disabled={!filter.no || isProcessing()}
            loading={isProcessing('submitting') || searching}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={12} md={3}>
          <Box className={classes.input}>
            <FormInput fullWidth id="componentId" label="องค์ประกอบ / Component" disabled={!data}>
              <Select
                onChange={handleAssessmentDataFilterChange('componentId')}
                input={<InputBase name="componentId" id="componentId" value={assessmentDataFilter.componentId} />}>
                {meta &&
                  meta.components.map(item => (
                    <MenuItem value={item.id} key={item.id}>
                      {item.text}
                    </MenuItem>
                  ))}
              </Select>
            </FormInput>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <Box className={classes.input}>
            <FormInput fullWidth id="departmentInitialName" label="ฝ่าย / DepartmentInitialName" disabled={!data}>
              <Select
                onChange={handleAssessmentDataFilterChange('departmentInitialName')}
                input={<InputBase name="departmentInitialName" id="departmentInitialName" value={assessmentDataFilter.departmentInitialName} />}>
                {meta &&
                  meta.departmentInitialNames && meta.departmentInitialNames.map(item => (
                    <MenuItem value={item.id} key={item.id}>
                      {item.text}
                    </MenuItem>
                  ))}
              </Select>
            </FormInput>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={2}>
          <Box className={classes.resetButton}>
            <LoadingButton
              fullWidth
              variant="contained"
              size="small"
              color="secondary"
              onClick={resetDataFilter}
              disabled={!data}>
              Reset Filter
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Report4IndividualFilter;
