import { client } from './client';
import { MASTER_EMPLOYEE_ROLE } from './constants';

export async function getOne(id: string) {
  return await client.get(`${MASTER_EMPLOYEE_ROLE}/${id}`, {});
}

export async function getAll() {
  return await client.get(`${MASTER_EMPLOYEE_ROLE}`, {});
}

export async function create(request: any) {
  return await client.post(`${MASTER_EMPLOYEE_ROLE}`, request, {});
}

export async function update(id: string, request: any) {
  return await client.put(`${MASTER_EMPLOYEE_ROLE}/${id}`, request, {});
}

export async function deleteOne(id: string) {
  return await client.delete(`${MASTER_EMPLOYEE_ROLE}/${id}`, {});
}
