import React, { useContext, useState, useEffect } from 'react';
import FileSaver from 'file-saver';

import { client } from '../../apis';
import { useIsProcessing } from '../useIsProcessing';
import { mimetype } from '../../utils/mimetype';
import { useReportSearch } from './ReportSearchContext';

const Report2Context = React.createContext(null);

export const Report2Provider = ({ children }) => {
  const { isProcessing, setIsProcessing } = useIsProcessing();
  const { filter, result, searching, setFilter, paramString } = useReportSearch();
  const [data, setData] = useState<any>(null);
  const [meta, setMeta] = useState<any>(null);

  const [assessmentDataFilter, setAssessmentDataFilter] = useState({
    componentId: '',
    departmentInitialName: '',
    sectionId: '',
    questionId: '',
  });

  useEffect(() => {
    setData(null);
  }, [filter.year, filter.level]);

  useEffect(() => {
    submitFilterForm();
  }, [assessmentDataFilter]);

  const hofHandleChange = (getter: any, setter: any) => (name: string) => (e: any) => {
    setter({
      ...getter,
      [name]: e.target.value,
    });
  };

  const handleAssessmentFilterChange = hofHandleChange(filter, setFilter);
  const handleAssessmentDataFilterChange = hofHandleChange(assessmentDataFilter, setAssessmentDataFilter);

  const resetDataFilter = () => {
    setAssessmentDataFilter({
      componentId: '',
      departmentInitialName: '',
      questionId: '',
      sectionId: '',
    });
  };

  const submitFilterForm = () => {
    setIsProcessing('submitting', true);
    setData(null);

    client
      .get(`reports/rpt-02/${paramString}`, { params: assessmentDataFilter })
      .then(({ data: payload }) => {
        setData(payload.data);
        setMeta(payload.meta);
      })
      .catch(ex => {
        console.log(ex.response);
        console.error(ex);
        setData(null);
      })
      .finally(() => setIsProcessing('submitting', false));
  };

  const exportExcel = () => {
    setIsProcessing('excel-exporting', true);
    client
      .get(`reports/rpt-02/${paramString}/excel`, { responseType: 'blob', params: assessmentDataFilter })
      .then(response => {
        const { filename } = response.headers;

        (window as any).$ = response;

        const blob = new Blob([response.data], {
          type: mimetype.excel,
        });
        FileSaver.saveAs(blob, decodeURIComponent(filename));
      })
      .finally(() => setIsProcessing('excel-exporting', false));
  };

  const exportWord = () => {
    setIsProcessing('word-exporting', true);
    client
      .get(`reports/rpt-02/${paramString}/word`, { responseType: 'blob', params: assessmentDataFilter })
      .then(response => {
        const { filename } = response.headers;

        (window as any).$ = response;

        const blob = new Blob([response.data], {
          type: mimetype.word,
        });
        FileSaver.saveAs(blob, decodeURIComponent(filename));
      })
      .finally(() => setIsProcessing('word-exporting', false));
  };

  const exportPdf = () => {
    setIsProcessing('pdf-exporting', true);
    client
      .get(`reports/rpt-02/${paramString}/pdf`, { responseType: 'blob', params: assessmentDataFilter })
      .then(response => {
        const { filename } = response.headers;

        (window as any).$ = response;

        const blob = new Blob([response.data], {
          type: mimetype.pdf,
        });
        FileSaver.saveAs(blob, decodeURIComponent(filename));
      })
      .finally(() => setIsProcessing('pdf-exporting', false));
  };

  const value = {
    filter,
    setFilter,
    meta,
    setMeta,
    assessmentDataFilter,
    setAssessmentDataFilter,
    handleAssessmentFilterChange,
    handleAssessmentDataFilterChange,
    submitFilterForm,
    exportExcel,
    exportWord,
    exportPdf,
    isProcessing,
    setIsProcessing,
    searching,
    result,
    data,
    resetDataFilter,
  };

  return <Report2Context.Provider value={value}>{children}</Report2Context.Provider>;
};

export interface Report2Values {
  filter: any;
  setFilter: any;
  meta: any;
  setMeta: any;
  assessmentDataFilter: any;
  setAssessmentDataFilter: any;
  handleAssessmentFilterChange: any;
  handleAssessmentDataFilterChange: any;
  submitFilterForm: () => void;
  exportExcel: () => void;
  exportWord: () => void;
  exportPdf: () => void;
  isProcessing: (...keys: string[]) => boolean;
  setIsProcessing: (key: string, flag: boolean) => void;
  searching: boolean;
  result: any;
  data: any;
  hasResult: boolean;
  hasNoResult: boolean;
  resetDataFilter: () => void;
}

export const useReport2 = () => {
  const context = useContext<Report2Values>(Report2Context);
  const hasNoResult = !context.data || context.data.length === 0;
  return {
    ...context,
    hasResult: !hasNoResult,
    hasNoResult: hasNoResult,
  };
};
