import { CircularProgress, Dialog } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  paper: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    boxShadow: 'none',
    overflow: 'hidden',
  },
}));

export const LoadingDialog = ({ open }) => {
  const classes = useStyles({});

  return (
    <Dialog
      open={open}
      aria-labelledby="loading-dialog-title"
      BackdropProps={{
        classes: {
          root: classes.root,
        },
      }}
      PaperProps={{
        classes: {
          root: classes.paper,
        },
      }}>
      <div>
        <CircularProgress style={{ display: 'inline-block' }} size={50} color="primary" />
      </div>
    </Dialog>
  );
};
