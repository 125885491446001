import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import React from 'react';

import Loading from '../Loading';
import SectionView from '../SectionView';
import { useReport2 } from './Report2Context';

export const Report2ResultTableView = () => {
  const { isProcessing, data } = useReport2();

  if (isProcessing('submitting')) {
    return <Loading />;
  }

  if (!data) return null;

  return (
    <div>
      {data.components
        .filter(_ => _.status === 'A')
        .map(component => (
          <div key={component.id}>
            <Typography variant="h6">
              องค์ประกอบ: {component.fullname}
            </Typography>
            {data.sections
              .filter(section => section.componentId === component.id)
              .map(section => {
                return (
                  <SectionView section={section} key={section.id}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>ลำดับข้อ</TableCell>
                          <TableCell>คำถาม</TableCell>
                          <TableCell>คำตอบ</TableCell>
                          <TableCell>คำอธิบาย</TableCell>
                          <TableCell>โดย</TableCell>
                          <TableCell>เอกสารประกอบ</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data.questions
                          .filter(({ question }) => question.sectionId === section.id)
                          .map(({ question, answerByAssessors }) =>
                            answerByAssessors
                              .filter(({ answer }) => answer)
                              .map(({ answer, assessor, attachments }, no) => {
                                return (
                                  answer && (
                                    <TableRow
                                      key={`${question.id},${assessor.employeeInitialName},${assessor.departmentInitialName}`}>
                                      <TableCell>{no === 0 && question.no}</TableCell>
                                      <TableCell>{no === 0 && question.title}</TableCell>
                                      <TableCell>
                                        {parseAnswer(question, answer)}
                                      </TableCell>
                                      <TableCell>{answer.comment}</TableCell>
                                      <TableCell>
                                        {(assessor &&
                                          `${assessor.departmentInitialName}-${assessor.employeeInitialName}`) ||
                                          '-'}
                                      </TableCell>
                                      <TableCell>
                                        {attachments.map(file => (
                                          <div key={file.id}>{file.title}</div>
                                        ))}
                                      </TableCell>
                                    </TableRow>
                                  )
                                );
                              }),
                          )}
                      </TableBody>
                    </Table>
                  </SectionView>
                );
              })}
          </div>
        ))}
    </div>
  );
};

function parseAnswer(question, answer) {
  switch (question.control) {
    case 'yesno':
      return answer.yesNo ? 'ใช่' : 'ไม่ใช่';
    case 'checkbox': {
      return answer.choiceIds
        .map(choiceId => {
          const choice = question.choices.find(({ id }) => id === choiceId);
          return choice && choice.title;
        })
        .join('\r\n');
    }
    case 'choice': {
      const answerChoice = question.choices.find(({ id }) => id === answer.choiceId)
      return answerChoice && answerChoice.title
    }
    case 'text':
      return answer.detail === null ? '-' : answer.detail;
    case 'rating':
      return answer.rating === null ? '-' : answer.rating;
    case 'ranking': {
      if (!answer.choiceIds) return ''
      return answer.choiceIds.map(i => i.split(':'))
        .sort(([, rank1], [, rank2]) => Number(rank1) - Number(rank2))
        .map(([choiceId], index) => {
          const choice = question.choices.find(({ id }) => id === choiceId);
          return `${index + 1}. ${choice.title}`;
        })
        .join(', ');
    }
    case 'date':
      return answer.date === null ? '-' : answer.date;
  }
}