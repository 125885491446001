import React from 'react';
import { Stepper, Step, StepButton } from '@material-ui/core';
import { Assessment } from '../models';
import useRouter from 'use-react-router';

export type AssessmentStepperProps = {
  assessment: Assessment;
  activeStep?: number;
};

export const AssessmentStepper = ({ assessment, activeStep = 0 }: AssessmentStepperProps) => {
  const { history } = useRouter();
  const link = [`/worklist/${assessment.no}/edit`, `/worklist/${assessment.no}/summarize`];

  return (
    <Stepper nonLinear activeStep={activeStep}>
      <Step>
        <StepButton onClick={() => history.push(link[0])}>Assessment - Edit Form</StepButton>
      </Step>
      <Step>
        <StepButton
          onClick={() => history.push(link[1])}
          disabled={!['Summarize', 'Completed'].includes(assessment.currentStatus)}>
          Assessment - Admin Summarize
        </StepButton>
      </Step>
    </Stepper>
  );
};
