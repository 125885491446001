import { Box, Grid, Paper } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';

import ContentWrapper from '../../components/ContentWrapper';
import MainLayout from '../../components/MainLayout';
import { Report5Provider } from '../../components/report/Report5Context';
import Report5Filter from '../../components/report/Report5Filter';
import { Report5Result } from '../../components/report/Report5Result';
import { Report5Title } from '../../components/report/Report5Title';

const useStyles = makeStyles((theme: Theme) => ({
  //style here
  root: {
    padding: '5px 20px 5px 20px',
  },
  input: {
    padding: 10,
  },
  paper: {
    padding: 0,
  },
  resetButton: { paddingLeft: 10 },
  table: {
    marginTop: 10,
    padding: 10,
  },
}));

export const Report5Page = () => {
  const classes = useStyles({});

  return (
    <MainLayout>
      <ContentWrapper>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Report5Title />
          </Grid>
        </Grid>
        <Report5Provider>
          <Paper className={classes.paper}>
            <Report5Filter />
            <Box marginTop={4} padding={3}>
              <Report5Result />
            </Box>
          </Paper>
        </Report5Provider>
      </ContentWrapper>
    </MainLayout>
  );
};

export default Report5Page;
