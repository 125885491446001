import React, { createContext, useState, useContext, useEffect } from 'react';
import { client } from '../apis';
import { useAssessmentManager } from '../components/assessment/AssessmentContext';
import { useSnackbar } from 'notistack';
import { errorCbTemplate, infoOptions, successOptions } from '../utils/snackbar';
import useRouter from 'use-react-router';
import { ATTACHMENT_URI } from '../apis/constants';
import * as AuthService from '../apis/auth';
import { apiMessages } from '../siteSettings';
import { AttachmentFile } from '../models';

export const AdminSummarizeContext = createContext(null);

export const AdminSummarizeProvider = ({ children }) => {
  const [questions, setQuestions] = useState([]);
  const [components, setComponents] = useState([]);
  const [sections, setSections] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const { assessment } = useAssessmentManager();

  const getSummarize = async () => {
    try {
      const { data: payload } = await client.get(`assessment-requests/${assessment.no}/admin-summarize/report`);
      const { components = [], sections = [], questions = [], attachments = [] } = payload.data;
      setQuestions(questions);
      setComponents(components);
      setSections(sections);
      setAttachments(attachments);

   
    } catch (ex) {
      console.error(ex)
    }
  };

  useEffect(() => {
    getSummarize();
  }, [assessment.no]);

  const value = {
    questions,
    sections,
    attachments,
    components,
    getSummarize,
  };
  return <AdminSummarizeContext.Provider value={value}>{children}</AdminSummarizeContext.Provider>;
};

export const useAdminSummarize = () => {
  const { questions, sections, components, attachments, getSummarize } = useContext(AdminSummarizeContext);
  const { assessment, isProcessing, setIsProcessing, setErrors, remark } = useAssessmentManager();
  const { enqueueSnackbar } = useSnackbar();
  const { history } = useRouter();

  const errorCb = (err: any) => {
    errorCbTemplate(enqueueSnackbar)(err);
    if (err.response && err.response.status === 400) {
      setErrors(err.response.data.message);
      return Promise.reject(err);
    } else {
      console.error(err);
    }
  };

  const submit = async () => {
    try {
      if (isProcessing('submit-admin-summarize')) return;
      setIsProcessing('submit-admin-summarize', true);
      setErrors([]);

      const { data: payload } = await client.post(`assessment-requests/${assessment.no}/admin-summarize/submit`, {
        remark,
      });

      enqueueSnackbar('Submit Successfully', infoOptions);
      history.push('/worklist');
    } catch (ex) {
      console.error(ex);
      errorCb(ex);
    } finally {
      setIsProcessing('submit-admin-summarize', false);
      AuthService.refreshToken();
    }
  };

  const uploadAttachment = (
    formData: FormData,
    bucketName: string,
    options: { onUploadProgress: (progressEvent: any) => void },
  ) => {
    setIsProcessing('add-attachment', true);
    return client
      .post(`${ATTACHMENT_URI}/${bucketName}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: options.onUploadProgress,
      })
      .then(getSummarize)
      .then(response => {
        if (bucketName === 'question') {
        }
        enqueueSnackbar(apiMessages.attachment.ADD_TEXT, successOptions);
      })
      .catch(errorCb)
      .finally(() => setIsProcessing('add-attachment', false));
  };

  const deleteAttachment = (attachmentId: number) => {
    setIsProcessing('delete-attachment', true);
    return client
      .delete(`${ATTACHMENT_URI}/${attachmentId}`)
      .then(getSummarize)
      .then(response => {
        enqueueSnackbar(apiMessages.attachment.DELETE_TEXT, successOptions);
        return response;
      })
      .catch(errorCb)
      .finally(() => setIsProcessing('delete-attachment', false));
  };

  const updateAttachment = (attachmentId: number, attachment: AttachmentFile) => {
    setIsProcessing('edit-attachment', true);
    return client
      .put(`${ATTACHMENT_URI}/${attachmentId}`, {
        title: attachment.title,
        description: attachment.description,
        confidential: attachment.confidential,
        url: attachment.url,
        type: attachment.type,
      })
      .then(getSummarize)
      .then(response => {
        enqueueSnackbar(apiMessages.attachment.UPDATE_TEXT, successOptions);
        return response;
      })
      .catch(errorCb)
      .finally(() => setIsProcessing('edit-attachment', false));
  };

  return {
    questions,
    sections,
    components,
    attachments,
    submit,
    uploadAttachment,
    updateAttachment,
    deleteAttachment,
  };
};
