import React from 'react';
import clsx from 'clsx'
import { serialize } from './serializer';
import { Typography } from '@material-ui/core';

export interface RichTextViewProps {
  value?: any;
  inline?: boolean;
  
}

export const RichTextView: React.FC<RichTextViewProps> = ({ value, inline }) => {
  if (inline)
    return (
      <Typography component="div" variant="body1" style={{ wordBreak: 'break-word', display: 'inline-block' }} dangerouslySetInnerHTML={{ __html: serialize(value) }} />
    );

  return (
    <Typography component="div" variant="body1" style={{ wordBreak: 'break-word' }} dangerouslySetInnerHTML={{ __html: serialize(value) }} />
  );
};

export default RichTextView;
