import React, { memo } from 'react';

import { FormInput } from '../../form';
import InputText from '../../InputText';

export interface Props {
  value: string;
  disabled?: boolean;
}

export const InformationCreatedBy: React.FC<Props> = memo(
  ({ value, disabled }) => {
    return (
      <FormInput fullWidth id="created-by" label="ผู้สร้าง / Created By" disabled={disabled}>
        <InputText id="created-by" value={value} disabled />
      </FormInput>
    );
  },
  (prev, next) => prev.value === next.value,
);

export default InformationCreatedBy;
