// https://material-ui.com/components/app-bar/
import {
  AppBar,
  Button,
  Divider,
  Hidden,
  IconButton,
  Link,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuIcon from '@material-ui/icons/Menu';
import classnames from 'classnames';
import React, { useState } from 'react';

import settings from '../siteSettings';
import Logo from '../static/img/Logo-Bangchak.png';
import { useAppState } from './context/AppStateContext';
import { useUserState } from './context/UserStateContext';
import { AdapterLink } from './RouterLink';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  loginButton: {
    color: theme.palette.common.white,
    marginRight: theme.spacing(2),
  },
  logo: {
    maxHeight: 55,
    marginRight: theme.spacing(2),
  },
}));

function Header() {
  const classes = useStyles({});
  const { sidebarToggle } = useAppState();
  const { isAuthenticated, logout, user } = useUserState();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  function handleMenu(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <Hidden only="xs">
            <Link component={AdapterLink} to="/" data-cy="logo">
              <img src={Logo} alt="Bangchak" className={classes.logo} />
            </Link>
            <Typography variant="h6" className={classnames(classes.title)}>
              {settings.title}
            </Typography>
          </Hidden>

          {isAuthenticated ? (
            <React.Fragment>
              <Typography component="div" variant="body1" className={classes.menuButton}>{`${user.initialName ||
                '...'} ${user.firstName || '...'} ${user.lastName || '...'}`}</Typography>
              <div className={classes.menuButton}>
                <IconButton
                  aria-owns={open ? 'menu-appbar' : undefined}
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                  data-cy="account-button">
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={open}
                  onClose={handleClose}>
                  <ListItem>
                    <ListItemText
                      primary={`${user.initialName} ${user.firstName} ${user.lastName}`}
                      secondary={
                        <>
                          <Typography component="span">{user.email}</Typography> <br />
                          <Typography component="span">ID: {user.id}</Typography> <br />
                          {user.roles && user.roles.length > 0 && (
                            <Typography component="span">ROLES: {user.roles}</Typography>
                          )}
                        </>
                      }
                    />
                  </ListItem>
                  <Divider />
                  <MenuItem onClick={logout} data-cy="logout-button">
                    Logout
                  </MenuItem>
                </Menu>
              </div>
            </React.Fragment>
          ) : (
            <Button
              component={AdapterLink}
              to="/login"
              variant="text"
              size="small"
              className={classes.loginButton}
              data-cy="login-button">
              Login
            </Button>
          )}

          <IconButton
            edge="start"
            className={classnames(classes.menuButton)}
            color="inherit"
            aria-label="Menu"
            onClick={() => sidebarToggle(true)}
            data-cy="menu-button">
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default Header;
