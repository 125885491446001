import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import moment from 'moment';
import React, { memo } from 'react';

import { AssessmentEvent } from '../models';
import ComponentText from './ComponentText';

export interface EventTableProps {
  events: AssessmentEvent[];
}

function areEquals(prev, next) {
  const { prevEvents = [] } = prev
  const { nextEvents = [] } = next
  return prevEvents.length === nextEvents.length
}

const EventTable = memo(({ events }: EventTableProps) => {
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>No</TableCell>
          <TableCell>Activity</TableCell>
          <TableCell>Action</TableCell>
          <TableCell>Action By</TableCell>
          <TableCell>Action Role</TableCell>
          <TableCell>Action Date</TableCell>
          <TableCell>Remark</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {events.length === 0 ? (
          <TableRow>
            <TableCell colSpan={6}>ไม่มีข้อมูล</TableCell>
          </TableRow>
        ) : (
          events.map((event, no) => (
            <TableRow key={event.id}>
              <TableCell>{no + 1}</TableCell>
              <TableCell>{event.activityName}</TableCell>
              <TableCell>{event.actionName}</TableCell>
              <TableCell>
                {(event.actionBy &&
                  `${event.actionBy.initialName} ${event.actionBy.firstName} ${event.actionBy.lastName}`) ||
                  event.actionById}
              </TableCell>
              <TableCell>{event.actionRole}</TableCell>
              <TableCell>{moment(event.actionDate).format('DD/MM/YYYY HH:mm:ss')}</TableCell>
              <TableCell>
                <ComponentText>{event.remark}</ComponentText>
              </TableCell>
            </TableRow>
          ))
        )}
      </TableBody>
    </Table>
  );
}, areEquals);

export { EventTable };

export default EventTable;
