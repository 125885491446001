import React, { useEffect } from 'react';
import useAxios from 'axios-hooks';

import ContentWrapper from '../../components/ContentWrapper';
import MainLayout from '../../components/MainLayout';
import AnnouncementTable from '../../components/masterdata/AnnouncementTable';
import { useSnackbar } from 'notistack';
import { ERROR_TEXT, errorOptions } from '../../utils/snackbar';
import { ANNOUNCEMENT_URI } from '../../apis/constants';
import * as AnnouncementService from '../../apis/announcement';
import { AnnouncementCreateRequest, AnnouncementUpdateRequest } from '../../models/master-dto';
import { Announcement } from '../../models';
import { upload } from '../../apis/upload';
import BGdefaultFile from '../../image/BG2.png';

const MasterAnnouncement = () => {
  const [{ data: payload = {} as any, loading, error }, refetch] = useAxios(ANNOUNCEMENT_URI, { manual: true });
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    refetch();
  }, []);

  // if (error) enqueueSnackbar(ERROR_TEXT, errorOptions);

  const data = payload.data || [];

  return (
    <MainLayout>
      <ContentWrapper>
        <AnnouncementTable
          isLoading={loading}
          data={data}
          onAdd={handleAdd}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
        />
      </ContentWrapper>
    </MainLayout>
  );

  async function handleAdd({ file, ...newData }: AnnouncementCreateRequest) {
    try {
      if (!file) {
        const response = await fetch(BGdefaultFile);
        const blob = await response.blob();
        const defaultFile = new File([blob], 'BG2.png', { type: 'image/png' });
        const { data: fileResponse } = await upload('master', defaultFile, {
          type: 'file',
        });
        newData.sequence = Number(newData.sequence);
        await AnnouncementService.create({
          ...newData,
          sequence: Number(newData.sequence),
          fileName: fileResponse.fileName,
        });
        refetch();
      } else {
        const { data: fileResponse } = await upload('master', file, {
          type: 'file',
        });
        newData.sequence = Number(newData.sequence);
        await AnnouncementService.create({
          ...newData,
          sequence: Number(newData.sequence),
          fileName: fileResponse.fileName,
        });
        refetch();
      }
    } catch (ex) {
      console.error(ex);
    }
  }

  async function handleUpdate({ file, ...newData }: any, oldData: AnnouncementUpdateRequest) {
    try {
      if (file) {
        const { data: fileResponse } = await upload('master', file, {
          type: 'file',
        });

        await AnnouncementService.update(newData.id, {
          ...newData,
          sequence: Number(newData.sequence),
          fileName: fileResponse.fileName,
        });
      } else {
        await AnnouncementService.update(newData.id, {
          ...newData,
          sequence: Number(newData.sequence),
        });
      }

      refetch();
    } catch (ex) {
      console.error(ex);
    }
  }

  function handleDelete(lookup: Announcement) {
    return AnnouncementService.deleteOne(lookup.id).then(() => refetch());
  }
};

export default MasterAnnouncement;
