/**
 * เช็คสิทธิ์การแก้ไข
 * ถ้า assessment.currentStatus === 'DRAFT' และ เป็น Admin -> return true
 * ถ้า ['Completed', 'Reviewed'].includes(assessment.currentStatus) return false
 * ถ้า assessmentRequest.requestStatus !== 'Assessment' (รวมกรณี Submitted, Reviewed) return false
 *
 * ถ้า currentAssessor.assessorRole === 'ASSESSOR' || file.createdBy === user.idreturn true
 */

import {
  Box,
  Divider,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DeleteIcon from '@material-ui/icons/Delete';
import moment from 'moment';
import React from 'react';

import { baseURL } from '../apis/constants';
import { AttachmentFile } from '../models';
// import AddAttachmentDialog from './AddAttachmentDialog';
// import { useAssessmentManager, useAssessorManager } from './assessment/AssessmentContext';
// import { useAssessmentRequest } from './assessment/AssessmentRequestContext';
import CheckOrUncheckIcon from './CheckOrUncheckIcon';
// import { useUserState } from './context/UserStateContext';
import { apiMessages } from '../siteSettings';
import AddAttachmentSummarizeDialog from './AddAttachmentSummarizeDialog';
import { Button } from './Richtext/components';
import AddIcon from '@material-ui/icons/Add';

interface AttachmentTableSummarizeProps {
  files?: AttachmentFile[];
  loading?: boolean;
  handleDelete?: (attachmentId: number) => Promise<any>;
  handleEdit?: (attachmentId: number, attachment: AttachmentFile) => Promise<any>;
  disabled?: boolean;
}

const AttachmentSummarizeTable = ({
  files = [],
  loading,
  handleEdit,
  handleDelete: _handleDelete,
  disabled,
}: AttachmentTableSummarizeProps) => {
  // const { assessment } = useAssessmentManager();
  // const { assessmentRequest } = useAssessmentRequest();
  // const { getCurrentUserAsAssessor } = useAssessorManager();
  // const { user } = useUserState();
  const theme = useTheme();

  function handleDelete(attachmentId: number) {
    if (window.confirm(apiMessages.confirmPopup)) {
      _handleDelete(attachmentId);
    }
  }

  return (
    <>
      <Hidden only="xs">
        <Box border={1} borderBottom={0} borderColor={theme.palette.divider}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="right">No</TableCell>
                <TableCell align="left">
                  ชื่อเอกสาร/
                  <br />
                  Document Name
                </TableCell>
                <TableCell align="left">รายละเอียดเอกสาร/Description</TableCell>
                <TableCell align="center">
                  รายงาน/
                  <br />
                  Report
                </TableCell>
                <TableCell align="left">
                  ผู้แนบเอกสาร/
                  <br />
                  Attached By
                </TableCell>
                <TableCell align="left">
                  วันที่แนบเอกสาร/
                  <br />
                  Attached Date
                </TableCell>
                <TableCell align="right" style={{ width: '17%' }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {files.map((file, index) => (
                <TableRow key={file.id}>
                  <TableCell align="right">{index + 1}</TableCell>
                  <TableCell align="left" scope="row">
                    <AttachmentTitle attachment={file} />
                  </TableCell>
                  <TableCell align="left">{file.description || '-'}</TableCell>
                  <TableCell align="center">
                    <CheckOrUncheckIcon value={!file.confidential} />
                  </TableCell>
                  <TableCell align="left">{file.fullnameWithInitialName}</TableCell>
                  <TableCell align="left">
                    {(file.createdAt && moment(new Date(file.createdAt)).format('DD/MM/YYYY')) || '-'}
                  </TableCell>
                  <TableCell align="right">
                    <Tooltip title="Download File" aria-label="download file">
                      <IconButton
                        color="primary"
                        size="medium"
                        aria-label="Download"
                        component="a"
                        href={`${baseURL}/static/${file.uri ? file.uri : `${file.bucket}/${file.fileName}`}`}
                        // download={file.title ? file.title : file.fileName}
                        download={"test.xlsx"}
                        disabled={file.type === 'url'}
                        target="_blank">
                        <CloudDownloadIcon fontSize="inherit" />
                      </IconButton>
                    </Tooltip>
                    <AddAttachmentSummarizeDialog
                      isLoading={loading}
                      mode="edit"
                      attachment={file}
                      onSubmit={data => handleEdit(file.id, data as any)}
                      disabled={disabled}
                    />
                    <Tooltip title="Delete File" aria-label="Delete file">
                      <IconButton
                        color="primary"
                        aria-label="Delete"
                        size="medium"
                        disabled={disabled || loading}
                        onClick={() => handleDelete(file.id)}>
                        <DeleteIcon fontSize="inherit" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
              {files.length === 0 && (
                <TableRow>
                  <TableCell colSpan={7}>ไม่มีข้อมูล</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
      </Hidden>
      <Hidden only={['sm', 'md', 'lg', 'xl']}>
        <List dense>
          {files.map((file, index) => (
            <React.Fragment key={file.id}>
              <ListItem>
                <ListItemText primary={file.fileName} secondary={file.description} />
              </ListItem>
              <Divider variant="middle" component="li" />
            </React.Fragment>
          ))}
        </List>
      </Hidden>
    </>
  );

  function AttachmentTitle({ attachment }) {
    if (attachment.type === 'file') return attachment.title;
    if (!attachment.url || attachment.url.length === 0) return attachment.title;

    const url = attachment.url.includes('http') ? attachment.url : `//${attachment.url}`;

    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        {attachment.title}
      </a>
    );
  }
};

export default AttachmentSummarizeTable;
