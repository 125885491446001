import useAxios from 'axios-hooks';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import MaterialTable, { Column } from 'material-table';

import { defaultOptions, tableIcons } from '../../components/masterdata/constants';
import { TableProps } from '../../components/masterdata/types';

import { MASTER_EMPLOYEE_ROLE } from '../../apis/constants';
import * as RoleService from '../../apis/employee-role';
import ContentWrapper from '../../components/ContentWrapper';
import MainLayout from '../../components/MainLayout';
import { ERROR_TEXT, errorOptions } from '../../utils/snackbar';

const MasterEmployeeRole = () => {
  const [{ data: payload = {} as any, loading, error }, refetch] = useAxios(MASTER_EMPLOYEE_ROLE, { manual: true });
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    refetch()
  }, [])

  if (error) enqueueSnackbar(ERROR_TEXT, errorOptions);

  return (
    <MainLayout>
      <ContentWrapper>
        <DataTable
          data={payload.data || []}
          isLoading={loading}
          onAdd={handleAdd}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
        />
      </ContentWrapper>
    </MainLayout>
  );

  function handleAdd(data: any) {
    data.roleId = Number(data.roleId)
    return RoleService.create(data).then(() => refetch());
  }

  function handleUpdate(newData: any, oldData: any) {
    newData.roleId = Number(newData.roleId)
    return RoleService.update(newData.id, newData).then(() => refetch());
  }

  function handleDelete(data: any) {
    return RoleService.deleteOne(data.id).then(() => refetch());
  }
};

const DataTable = ({ data, onAdd, onUpdate, onDelete, isLoading = false }: TableProps<any>) => {
  const tableColumns: Column<any>[] = [
    { title: 'Id', field: 'id', editable: 'never', hidden: true },
    { title: 'Employee', field: 'employeeId', editable: 'always',  render: rowData => <span>{rowData.employee ? rowData.employee.fullnameWithInitialName : '-'} ({rowData.employeeId})</span> },
    { title: 'Role', field: 'roleId', editable: 'always',  type: 'numeric', render: rowData => <span>{rowData.role.nameEn}</span> },
    { title: 'Description', field: 'description', editable: 'always',  },
  ];

  return (
    <MaterialTable
      columns={tableColumns}
      data={data}
      title={'Master Employee Role'}
      icons={tableIcons}
      isLoading={isLoading}
      options={defaultOptions}
      editable={{
        onRowAdd: newData => onAdd(newData),
        onRowUpdate: (newData, oldData) => onUpdate(newData, oldData),
        onRowDelete: oldData => onDelete(oldData),
      }}
    />
  );
};


export default MasterEmployeeRole;
