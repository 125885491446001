import useAxios from 'axios-hooks';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';

import { HOME_IMAGE_URI } from '../../apis/constants';
import * as HomeImageService from '../../apis/home-images';
import ContentWrapper from '../../components/ContentWrapper';
import MainLayout from '../../components/MainLayout';
import HomeTable from '../../components/masterdata/HomeTable';
import { HomeImage } from '../../models';
import { HomeImageCreateRequest } from '../../models/master-dto';
import { ERROR_TEXT, errorOptions } from '../../utils/snackbar';
import { upload } from '../../apis/upload';

const MasterHome = () => {
  const [{ data: payload = {} as any, loading, error }, refetch] = useAxios(HOME_IMAGE_URI, { manual: true });
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    refetch()
  }, [])

  if (error) enqueueSnackbar(ERROR_TEXT, errorOptions);

  const data = payload.data || [];

  return (
    <MainLayout>
      <ContentWrapper>
        <HomeTable isLoading={loading} data={data} onUpdate={handleUpdate} onDelete={handleDelete} onAdd={handleAdd} />
      </ContentWrapper>
    </MainLayout>
  );

  async function handleAdd({ file, ...data }: HomeImageCreateRequest) {
    try {
      const { data: fileResponse } = await upload('master', file, {
        type: 'file',
      });

      await HomeImageService.create({
        ...data,
        sequence: Number(data.sequence),
        fileName: fileResponse.fileName,
      });
      refetch()
    } catch (ex) {
      console.error(ex)
    }
  }

  async function handleUpdate({ file, ...newData }: HomeImageCreateRequest, oldData: HomeImageCreateRequest) {
    try {
      if (file) {
        const { data: fileResponse } = await upload('master', file, {
          type: 'file',
        });

        await HomeImageService.update(newData.id, {
          ...newData,
          sequence: Number(newData.sequence),
          fileName: fileResponse.fileName,
        });
      }

      else {
        await HomeImageService.update(newData.id, {
          ...newData,
          sequence: Number(newData.sequence),
        });
      }

      
      refetch()
    } catch (ex) {
      console.error(ex)
    }
  }

  async function handleDelete(data: HomeImage) {
    return HomeImageService.deleteOne(data.id).then(() => refetch());
  }
};

export default MasterHome;
