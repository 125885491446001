import React from 'react';

import { Container } from '@material-ui/core';
import { ContainerProps } from '@material-ui/core/Container';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: 30,
    marginBottom: 50,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: 16,
      paddingRight: 16,
    },
    height: '100%',
  },
}));

export type ContentWrapperProps = {
  readonly children: JSX.Element | JSX.Element[];
} & ContainerProps;

const ContentWrapper = ({ children, ...rest }: ContentWrapperProps) => {
  const classes = useStyles({});

  return (
    <Container maxWidth="lg" {...rest} className={classes.root}>
      {children}
    </Container>
  );
};

export default ContentWrapper;
