import * as qs from 'query-string';

export function extractObjectByKeys<T = any, O = any>(object: T, keys: string[]): O {
  return Object.keys(object).reduce((prev, key) => {
    if (!keys.includes(key)) {
      return prev;
    }

    const value = object[key];
    return {
      ...prev,
      [key]: value,
    };
  }, {}) as O;
}

export function updateQueryString(objects: any) {
  window.history.replaceState('', '', '?' + qs.stringify(objects));
}
