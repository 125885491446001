import debounce from 'lodash/debounce';
import uniqBy from 'lodash/uniqBy';
import maxBy from 'lodash/maxBy';
import { ValidationError } from 'class-validator';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { createContext, useContext, useEffect, useState } from 'react';
import useRouter from 'use-react-router';
import useAxios from 'axios-hooks';

import { client } from '../../apis';
import * as AssessmentService from '../../apis/assessment';
import { ASSESSMENT_FORM_URI, ASSESSMENT_URI, ATTACHMENT_URI } from '../../apis/constants';
import { Assessment, AssessmentEvent, Assessor, AttachmentFile, Component, Question, Section } from '../../models';
import { AssessmentForm, AssessmentSettings, Sequence, Permissions } from '../../models/assessment-dto';
import { apiMessages } from '../../siteSettings';
import { errorCbTemplate, infoOptions, successOptions } from '../../utils/snackbar';
import { useUserState } from '../context/UserStateContext';
import { useError } from '../../context/AssessmentErrorContext';
import { useLoadingScreen } from '../LoadingScreenProvider';
import { flatMap } from '../../utils/array';

export interface AssessmentProps {
  saveDraft: () => any;
  setAssessmentForm: (assessmentForm: AssessmentForm) => any;
  setAssessment: (assessment: Assessment) => any;
  setAttachments: (attachments: AttachmentFile[]) => any;
  setComponents: (components: Component[]) => any;
  setSections: (attachments: Section[]) => any;
  setQuestions: (components: Question[]) => any;
  assessment: Assessment;
  attachments: AttachmentFile[];
  components: Component[];
  isLoading: boolean;
  isCreated: boolean;
  questions: Question[];
  sections: Section[];
  events: AssessmentEvent[];
  settings: AssessmentSettings;
  submitAssessment: () => any;
  errors: ValidationError[];
  setErrors: (errors: ValidationError[]) => any;
  setIsProcessing: (key: string, flag: boolean) => void;
  isProcessing: (...key: string[]) => boolean;
  permissions: Permissions;
  setPermissions: (permissions: Permissions) => void;
  setEvents: (e: AssessmentEvent[]) => void;
  remark: string;
  setRemark: (remark: string) => void;
  requestStatus: string;
}

const defaultProps: AssessmentProps = {
  saveDraft: () => {},
  setAssessment: (assessment: Assessment) => {},
  setAssessmentForm: (assessment: AssessmentForm) => {},
  setAttachments: (attachments: AttachmentFile[]) => {},
  setComponents: (components: Component[]) => {},
  setSections: (attachments: Section[]) => {},
  setQuestions: (components: Question[]) => {},
  assessment: null,
  sections: [],
  questions: [],
  events: [],
  attachments: [],
  components: [],
  isLoading: false,
  isCreated: false,
  settings: null,
  errors: [],
  setErrors: () => {},
  submitAssessment: () => {},
  setIsProcessing: (key: string, flag: boolean) => {},
  isProcessing: (key: string) => false,
  permissions: null,
  setPermissions: () => {},
  setEvents: () => {},
  remark: '',
  setRemark: (remark: string) => {},
  requestStatus: '',
};

export const AssessmentContext = createContext<AssessmentProps>(defaultProps);

export interface AssessmentProviderProps {
  children: JSX.Element | JSX.Element[];
  initialValues: AssessmentForm;
  mode: 'create' | 'edit';
}

export function AssessmentProvider(props: AssessmentProviderProps) {
  const { error: errors, setError: setErrors } = useError();
  const { enqueueSnackbar } = useSnackbar();
  const errorCb = errorCbTemplate(enqueueSnackbar);
  const { history } = useRouter();
  const [remark, setRemark] = useState('');
  const [settings, setSettings] = useState<AssessmentSettings>({
    years: [moment().year(), moment().year() + 1],
    components: [],
    departmentLevels: [],
    departments: [],
    processNames: [],
    levels: [],
    managers: [],
    assessmentTitle: {
      CO: '',
      PC: '',
    },
  });
  const [assessment, setAssessment] = useState<Assessment>({
    ...props.initialValues.information,
    startDate: moment(props.initialValues.information.startDate),
    endDate: moment(props.initialValues.information.endDate),
    publishDate: moment(props.initialValues.information.publishDate),
  });
  const [requestStatus, setRequestStatus] = useState(props.initialValues.requestStatus);
  const [permissions, setPermissions] = useState<Permissions>(props.initialValues.permissions);
  const [components, setComponents] = useState<Component[]>(props.initialValues.components);

  const [attachments, setAttachments] = useState<AttachmentFile[]>(props.initialValues.attachments);
  const [sections, setSections] = useState<Section[]>(props.initialValues.sections);
  const [questions, setQuestions] = useState<Question[]>(props.initialValues.questions);
  const [events, setEvents] = useState<AssessmentEvent[]>(props.initialValues.events);
  const [isLoading, setIsLoading] = useState(false);
  // const [errors, setErrors] = useState<ValidationError[]>([]);
  const [_isProcessing, _setIsProcessing] = useState<{ [key: string]: boolean }>({});

  // useEffect(() => {
  //   console.log("--------EFFFECT-----------")
  //   console.log(props.initialValues.attachments)
  //   console.log("-------------------")
  // },[])



  const setIsProcessing = (key: string, flag: boolean): void => {
    _setIsProcessing({
      ..._isProcessing,
      [key]: flag,
    });
  };

  /**
   * Check if the name of processing is true
   */
  const isProcessing = (...keys: string[]): boolean => {
    if (keys.length === 0) Object.values(_isProcessing).includes(true);
    return Object.keys(_isProcessing)
      .filter(k => keys.includes(k))
      .map(k => _isProcessing[k])
      .includes(true);
  };

  useEffect(() => {
    setIsLoading(true);
    client
      .get(`${ASSESSMENT_FORM_URI}/settings`)
      .then(({ data: payload }) => {
        setSettings(payload.data);
      })
      .catch(errorCb)
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const create = () => {
    setIsProcessing('save-assessment', true);
    AssessmentService.save({ ...assessment, title: settings.assessmentTitle[assessment.level] }, remark)
      .then(({ data: payload }) => {
        history.push(`/worklist/${payload.data.no}/edit`);
        enqueueSnackbar('Save Draft Successfully', infoOptions);
      })
      .catch(errorCb)
      .finally(() => setIsProcessing('save-assessment', false));
  };

  const update = () => {
    setIsProcessing('save-assessment', true);
    AssessmentService.update(assessment.no, assessment)
      .then(() => {
        enqueueSnackbar('Save Draft Successfully', infoOptions);
      })
      .catch(errorCb)
      .finally(() => setIsProcessing('save-assessment', false));
  };

  const saveDraft = () => {
    if (isProcessing('save-assessment')) return;

    // If the object has `no` property, it means the assessment is already created.
    if (!assessment.no) {
      create();
    } else {
      update();
    }
  };

  const submitAssessment = () => {
    const baseURI = `${ASSESSMENT_URI}/${assessment.no}`;
    setIsProcessing('submit-assessment', true);
    setErrors([]);
    return AssessmentService.update(assessment.no, assessment)
      .then(() => client.post(`/${baseURI}/submit`, { remark }))
      .then(() => {
        setPermissions({ editable: false })
        enqueueSnackbar('Submitted Successfully.', infoOptions);
        history.push(`/worklist`);
      })
      .catch(error => {
        console.error(error.response.data.message);
        setErrors(error.response.data.message);
      })
      .finally(() => {
        setIsProcessing('submit-assessment', false);
      });
  };

  const context: AssessmentProps = {
    saveDraft,
    errors: errors,
    setAssessmentForm: (assessmentForm: AssessmentForm) => {
      setAssessment(assessmentForm.information);
      setAttachments(assessmentForm.attachments);
      setComponents(assessmentForm.components);
    },
    setAssessment: (assessment: Assessment) => setAssessment(assessment),
    setAttachments: (attachments: AttachmentFile[]) => setAttachments(attachments),
    setComponents: (components: Component[]) => setComponents(components),
    isCreated: !!assessment.no,
    assessment,
    attachments,
    components,
    questions,
    sections,
    events,
    setQuestions,
    setSections,
    setEvents,
    settings,
    isLoading,
    submitAssessment,
    setIsProcessing,
    isProcessing,
    setErrors: (errors: ValidationError[]) => setErrors(errors),
    permissions,
    setPermissions,
    remark,
    setRemark,
    requestStatus,
  };

  return <AssessmentContext.Provider value={context}>{props.children}</AssessmentContext.Provider>;
}

export function useAssessmentManager(assessmentNo?: string) {
  const context = useContext(AssessmentContext);

  const getQuestion = (questionId: string) => {
    return context.questions.find(question => question.id === questionId);
  };

  const updateQuestion = (questionId: string, question: Question) => {
    const _questions = context.questions.filter(question => question.id !== questionId);
    context.setQuestions(_questions.concat(question));
  };

  const handleRemarkChange = debounce(value => context.setRemark(value), 200);

  return {
    ...context,
    getQuestion,
    updateQuestion,
    handleRemarkChange,
  };
}

interface ComponentManagerProps {
  addQuestion: (componentId: string, question: Partial<Question>) => Promise<any>;
  addSection: (componentId: string) => Promise<any>;
  updateQuestion: (componentId: string, question: Question) => Promise<any>;
  updateSection: (componentId: string, section: Section) => Promise<any>;
  deleteQuestion: (componentId: string, questionId: string) => Promise<any>;
  deleteSection: (componentId: string, sectionId: string) => Promise<any>;
  isLoading: boolean;
  questions: Question[];
  sections: Section[];
  setQuestions: (q: Question[]) => any;
  setSections: (q: Section[]) => any;
  getRootLevels: (componentId: string) => (Question | Section)[];
  getNestedLevels: (componentId: string, sectionId: string) => Question[];
}

export function useComponentManager(): ComponentManagerProps {
  const [isLoading, setIsLoading] = useState(false);
  const { assessment, questions = [], sections = [], setSections, setQuestions } = useContext(AssessmentContext);

  const finallyCb = () => {
    setIsLoading(false);
  };

  const getRootLevels = (componentId: string) => {
    return [...questions, ...sections].filter(i => i.componentId === componentId);
  };

  const getNestedLevels = (componentId: string, sectionId: string) => {
    return questions.filter(i => i.componentId === componentId && i.sectionId === sectionId);
  };

  const addQuestion = (componentId: string, question: Partial<Question>) => {
    setIsLoading(true);

    const items = question.sectionId ? getNestedLevels(componentId, question.sectionId) : getRootLevels(componentId);
    question.sequence = items.length;

    return AssessmentService.createQuestion(assessment.no, componentId, question)
      .then(({ data: payload }) => setQuestions([...questions, { ...payload.data, componentId }]))
      .finally(finallyCb);
  };

  const addSection = (componentId: string) => {
    setIsLoading(true);

    const items = getRootLevels(componentId);
    return AssessmentService.createSection(assessment.no, componentId, { sequence: items.length })
      .then(({ data: payload }) => setSections([...sections, payload.data]))
      .finally(finallyCb);
  };

  const updateQuestion = (componentId: string, question: Question) => {
    setIsLoading(true);
    return AssessmentService.updateQuestion(assessment.no, componentId, question).finally(finallyCb);
  };

  const updateSection = (componentId: string, section: Section) => {
    setIsLoading(true);
    return AssessmentService.updateSection(assessment.no, componentId, section).finally(finallyCb);
  };

  const deleteQuestion = (componentId: string, questionId: string) => {
    setIsLoading(true);
    const _questions = questions.filter(q => q.id !== questionId);
    setQuestions(_questions);
    return AssessmentService.deleteQuestion(assessment.no, componentId, questionId).finally(finallyCb);
  };

  const deleteSection = (componentId: string, sectionId: string) => {
    setIsLoading(true);
    const _sections = sections.filter(q => q.id !== sectionId);
    setSections(_sections);
    return AssessmentService.deleteSection(assessment.no, componentId, sectionId).finally(finallyCb);
  };

  const sequenceUp = (componentId: string, sequences: Sequence[]) => {
    setIsLoading(true);
    const _questions = questions.map(q => {
      const target = sequences.find(s => s.type === 'question' && s.id === q.id);
      q.sequence = target.sequence;
      return q;
    });
    const _sections = sections.map(q => {
      const target = sequences.find(s => s.type === 'section' && s.id === q.id);
      q.sequence = target.sequence;
      return q;
    });

    setQuestions(_questions);
    setSections(_sections);
    return AssessmentService.sequenceUp(assessment.no, componentId, sequences).finally(finallyCb);
  };

  return {
    isLoading,
    addQuestion,
    addSection,
    updateQuestion,
    updateSection,
    deleteQuestion,
    deleteSection,
    sections,
    questions,
    setSections,
    setQuestions,
    getRootLevels,
    getNestedLevels,
  };
}

export interface AttachmentManager {
  attachments: AttachmentFile[];
  setAttachments: (attachments: AttachmentFile[]) => any;
  addAttachment: (attachment: AttachmentFile) => void;
  deleteAttachment: (attachmentId: number) => Promise<any>;
  uploadAttachment: (
    formData: FormData,
    bucketName: string,
    options: { onUploadProgress: (progressEvent: any) => void },
  ) => Promise<any>;
  updateAttachment: (attachmentId: number, attachment: AttachmentFile) => Promise<any>;
}


export function useAttachmentManager(): AttachmentManager {
  const { attachments, setAttachments, setIsProcessing } = useAssessmentManager();

  const {
    match: { params },
  } = useRouter();

  const fetchAssessmentData = async (id) => {
    try {
      const response = await client.get(`${ASSESSMENT_URI}/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  
  const { enqueueSnackbar } = useSnackbar();
  const errorCb = errorCbTemplate(enqueueSnackbar);

  const addAttachment = (attachment: AttachmentFile) => {
    fetchAssessmentData(params['id'])
    .then((data) => {
      if (data && data.data) {
        const {attachments} = data.data
        if (attachments) {
          setAttachments(attachments)
        }else{
          setAttachments([])
        }
      }
    return data
    })
    .catch((err) => {
      console.error(err)
    });
  

  };
 
  const _deleteAttachment = (attachmentId: number) => {
    fetchAssessmentData(params['id'])
    .then((data) => {
      if (data && data.data) {
        const {attachments} = data.data
        if (attachments) {
          const indexedAttachments = attachments.map((attachment, index) => ({
              ...attachment,
              key: index + 1// Creating keys like item_1, item_2, ...
            }));
          setAttachments(indexedAttachments)
        }else{
          setAttachments([])
        }
      }
     return data
    })
    .catch((err) => {
      console.error(err)
    });
    
  };

  const _updateAttachment = (attachmentId: number, data: AttachmentFile) => {
    // const _attachments = attachments.filter(a => a.id !== attachmentId);
    // const attachment = attachments.find(a => a.id === attachmentId);
    // // console.log("==========attachment contcat update================")
    // // console.log(_attachments)
    // // console.log(attachment)
    // // console.log("==========================")
    // const updatedAttachment = {
    //   ...attachment,
    //   title: data.title,
    //   description: data.description,
    //   confidential: data.confidential,
    //   url: data.url,
    // };
    
    fetchAssessmentData(params['id'])
    .then((data) => {
      if (data && data.data) {
        const {attachments} = data.data
        if (attachments) {
          const indexedAttachments = attachments.map((attachment, index) => ({
            ...attachment,
            key: index + 1// Creating keys like item_1, item_2, ...
          }));
      
          setAttachments(indexedAttachments);
        }else{
          setAttachments([])
        }
      }
    return data
    })
    .catch((err) => {
      console.error(err)
    });

    // setAttachments(_attachments.concat(updatedAttachment));
    // setAttachments(updatedAttachment);
  };

  const uploadAttachment = (
    formData: FormData,
    bucketName: string,
    options: { onUploadProgress: (progressEvent: any) => void },
  ) => {
    setIsProcessing('add-attachment', true);
    return client
      .post(`${ATTACHMENT_URI}/${bucketName}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: options.onUploadProgress,
      })
      .then(response => {
        if (bucketName === 'question') {
        }
  
        addAttachment(response.data as AttachmentFile);
        enqueueSnackbar(apiMessages.attachment.ADD_TEXT, successOptions);
        return response;
      })
      .catch(errorCb)
      .finally(() => setIsProcessing('add-attachment', false));
  };

  const deleteAttachment = (attachmentId: number) => {
    setIsProcessing('delete-attachment', true);
    return client
      .delete(`${ATTACHMENT_URI}/${attachmentId}`)
      .then(response => {
        _deleteAttachment(attachmentId);
        console.group('Attachment Group');
        console.log(attachments);
        console.groupEnd();    
        enqueueSnackbar(apiMessages.attachment.DELETE_TEXT, successOptions);
        return response;
      })
      .catch(errorCb)
      .finally(() => setIsProcessing('delete-attachment', false));
  };

  const updateAttachment = (attachmentId: number, attachment: AttachmentFile) => {
    console.log(attachmentId, attachment);
    setIsProcessing('edit-attachment', true);
    return client
      .put(`${ATTACHMENT_URI}/${attachmentId}`, {
        title: attachment.title,
        description: attachment.description,
        confidential: attachment.confidential,
        url: attachment.url,
        type: attachment.type,
      })
      .then(response => {
        _updateAttachment(attachmentId, attachment);
        enqueueSnackbar(apiMessages.attachment.UPDATE_TEXT, successOptions);
        return response;
      })
      .catch(errorCb)
      .finally(() => setIsProcessing('edit-attachment', false));
  };

  return {
    attachments: attachments,
    setAttachments: setAttachments,
    addAttachment: addAttachment,
    deleteAttachment: deleteAttachment,
    uploadAttachment: uploadAttachment,
    updateAttachment: updateAttachment,
  };
}

export function useQuestionManager(questionId: string) {
  const { questions, setQuestions, setIsProcessing } = useAssessmentManager();
  const { enqueueSnackbar } = useSnackbar();
  const errorCb = errorCbTemplate(enqueueSnackbar);
  const currentQuestion = () => questions.find(question => question.id === questionId);
  const otherQuestion = () => questions.filter(question => question.id !== questionId);

  const addAttachment = (attachment: AttachmentFile) => {
    const _question = currentQuestion();
    const _questions = otherQuestion();

    _question.attachments = _question.attachments ? _question.attachments.concat(attachment) : [attachment];
    setQuestions(_questions.concat(_question));
  };

  const _deleteAttachment = (attachmentId: number) => {
    const _question = currentQuestion();
    const _questions = otherQuestion();

    _question.attachments = _question.attachments.filter(a => a.id !== attachmentId);
    setQuestions(_questions.concat(_question));
  };

  const _updateAttachment = (attachmentId: number, data: AttachmentFile) => {
    const _question = currentQuestion();
    const _questions = otherQuestion();
    const otherAttachments = _question.attachments.filter(a => a.id !== attachmentId);
    const attachment = _question.attachments.find(a => a.id === attachmentId);
    const updatedAttachment = {
      ...attachment,
      title: data.title,
      description: data.description,
      confidential: data.confidential,
      url: data.url,
    };

    _question.attachments = otherAttachments.concat(updatedAttachment).sort((a1, a2) => a1.id - a2.id);
    setQuestions(_questions.concat(_question));
  };

  const uploadAttachment = (
    formData: FormData,
    bucketName: string,
    options: { onUploadProgress: (progressEvent: any) => void },
  ) => {
    setIsProcessing('add-attachment', true);
    return client
      .post(`${ATTACHMENT_URI}/${bucketName}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: options.onUploadProgress,
      })
      .then(response => {
        addAttachment(response.data as AttachmentFile);
        enqueueSnackbar(apiMessages.attachment.ADD_TEXT, successOptions);
        return response;
      })
      .catch(errorCb)
      .finally(() => setIsProcessing('add-attachment', false));
  };

  const deleteAttachment = (attachmentId: number) => {
    setIsProcessing('delete-attachment', true);
    return client
      .delete(`${ATTACHMENT_URI}/${attachmentId}`)
      .then(response => {
        _deleteAttachment(attachmentId);
        enqueueSnackbar(apiMessages.attachment.DELETE_TEXT, successOptions);
        return response;
      })
      .catch(errorCb)
      .finally(() => setIsProcessing('delete-attachment', false));
  };

  const updateAttachment = (attachmentId: number, attachment: AttachmentFile) => {
    setIsProcessing('edit-attachment', true);
    return client
      .put(`${ATTACHMENT_URI}/${attachmentId}`, {
        title: attachment.title,
        description: attachment.description,
        confidential: attachment.confidential,
        url: attachment.url,
      })
      .then(response => {
        _updateAttachment(attachmentId, attachment);
        enqueueSnackbar(apiMessages.attachment.UPDATE_TEXT, successOptions);
        return response;
      })
      .catch(errorCb)
      .finally(() => setIsProcessing('edit-attachment', false));
  };

  return {
    addAttachment: addAttachment,
    deleteAttachment: deleteAttachment,
    uploadAttachment: uploadAttachment,
    updateAttachment: updateAttachment,
  };
}

export function useAssessorManager() {
  const { user } = useUserState();
  const { assessment, questions, setQuestions, isProcessing, setIsProcessing } = useAssessmentManager();
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const errorCb = errorCbTemplate(enqueueSnackbar);
  const baseURI = `${ASSESSMENT_URI}/${assessment.no}`;

  const getAllAssessors = () => {
    return flatMap(questions, q => q.assessors);
  };

  const getAllUniqueAssessors = () => {
    return uniqBy(flatMap(questions, q => q.assessors), a => a.employeeId);
  };

  const getAllPreparer1st = () => {
    return getAllUniqueAssessors().filter(a => a.assessorRole === 'PREPARER_1ST');
  };

  const getCurrentUserAsAssesosor = () => {
    return getAllAssessors().find(a => a.employeeId === user.id);
  };

  const getAssessor = (employeeId: string) => {
    return getAllAssessors().find(a => a.employeeId === employeeId);
  };

  const getCurrentUserAsAssessor = () => {
    return getAssessor(user.id);
  };

  const _addAssessor = (questionId: string, assessor: Assessor) => {
    const question = questions.find(q => q.id === questionId);
    const _questions = questions.filter(q => q.id !== questionId);
    const _question: Question = {
      ...question,
      assessors: question.assessors ? question.assessors.concat(assessor) : [assessor],
    };
    setQuestions(_questions.concat(_question));
  };

  const addAssessor = (componentId: string, questionId: string, assessor: Assessor) => {
    setIsLoading(true);

    return client
      .post(`${baseURI}/components/${componentId}/questions/${questionId}/assessors`, {
        ...assessor,
        userId: assessor.employeeId, // TODO: move to employeeId
      })
      .then(response => {
        const { data: payload } = response.data;
        _addAssessor(questionId, payload);
        enqueueSnackbar(apiMessages.assessor.ADD_TEXT, successOptions);
        return response;
      })
      .catch(errorCb)
      .finally(() => setIsLoading(false));
  };

  const _updateAssessor = (questionId: string, assessor: Assessor) => {
    const question = questions.find(q => q.id === questionId);
    const _assessors = question.assessors
      ? question.assessors.map(a =>
          a.questionId === questionId && a.employeeId === assessor.employeeId ? assessor : a,
        )
      : [assessor];

    question.assessors = _assessors;
    const _questions = questions.map(q => {
      if (q.id === questionId) return question;
      return q;
    });
    setQuestions(_questions);
  };

  const updateAssessor = (componentId: string, questionId: string, assessor: Assessor) => {
    setIsLoading(true);

    return client
      .put(`${baseURI}/components/${componentId}/questions/${questionId}/assessors/${assessor.employeeId}`, {
        ...assessor,
        userId: assessor.employeeId, // TODO: move to employeeId
      })
      .then(response => {
        const { data: payload } = response.data;
        _updateAssessor(questionId, payload);
        enqueueSnackbar(apiMessages.assessor.UPDATE_TEXT, successOptions);
        return response;
      })
      .catch(errorCb)
      .finally(() => setIsLoading(false));
  };

  const _deleteAssessor = (questionId: string, assessor: Assessor) => {
    const question = questions.find(q => q.id === questionId);
    const _questions = questions.filter(q => q.id !== questionId);
    const _question: Question = {
      ...question,
      assessors: question.assessors.filter(a => a.employeeId !== assessor.employeeId),
    };
    setQuestions(_questions.concat(_question));
  };

  const deleteAssessor = (componentId: string, questionId: string, assessor: Assessor) => {
    const URI = `${baseURI}/components/${componentId}/questions/${questionId}/assessors/${assessor.employeeId}`;
    setIsLoading(true);

    return client
      .delete(URI)
      .then(response => {
        _deleteAssessor(questionId, assessor);
        enqueueSnackbar(apiMessages.assessor.DELETE_TEXT, successOptions);
        return response;
      })
      .catch(errorCb)
      .finally(() => setIsLoading(false));
  };

  const addCommentator = (componentId: string, assessor: Assessor) => {
    if (isProcessing('add-commentator')) return;
    setIsProcessing('add-commentator', true);
    return client
      .post(`assessors/${assessor.questionId}/${assessor.employeeId}/commentator`)
      .then(response => {
        return response;
      })
      .catch(errorCb)
      .finally(() => setIsProcessing('add-commentator', false));
  };

  const removeCommentator = (componentId: string, assessor: Assessor) => {
    if (isProcessing('remove-commentator')) return;
    setIsProcessing('remove-commentator', true);
    return client
      .delete(`assessors/${assessor.questionId}/${assessor.employeeId}/commentator`)
      .catch(errorCb)
      .finally(() => setIsProcessing('remove-commentator', false));
  };

  const hasPermissionToSubmitForAssignment = () => {
    const assessors = getAllAssessors();
    const currentUserAsAssessor = getCurrentUserAsAssesosor();
    if (!currentUserAsAssessor) {
      return;
    }

    if ('ASSESSOR' === currentUserAsAssessor.assessorRole) {
      return assessors.some(a => a.assessorRole === 'PREPARER_1ST' && !a.isAssigned && a.createdBy === user.id);
    }
    if ('PREPARER_1ST' === currentUserAsAssessor.assessorRole) {
      return assessors.some(a => a.assessorRole === 'PREPARER_2ND' && !a.isAssigned && a.createdBy === user.id);
    }
  };

  return {
    getAllAssessors,
    getAssessor,
    hasPermissionToSubmitForAssignment,
    getAllPreparer1st,
    getCurrentUserAsAssessor,
    addAssessor: addAssessor,
    deleteAssessor: deleteAssessor,
    _addAssessor: _addAssessor,
    _deleteAssessor: _deleteAssessor,
    updateAssessor,
    isLoading: isLoading,
    addCommentator,
    removeCommentator,
    getAllUniqueAssessors,
  };
}

export const useMoveable = (type: 'section' | 'question', itemId: string) => {
  const { openLoadingScreen, closeLoadingScreen } = useLoadingScreen();
  const { assessment, questions, setQuestions, sections, setSections } = useAssessmentManager();

  const moveUp = async () => {
    try {
      openLoadingScreen();
      const { data: payload } = await client.post(`/assessments/${assessment.no}/${type}s/${itemId}/up`);
      const responseQuestions = payload.data.questions;
      const responseSections = payload.data.sections;

      const _questions = questions.map(question => {
        const updatedQuestion = responseQuestions.find(q => q.id === question.id);
        return updatedQuestion ? updatedQuestion : question;
      });

      const _sections = sections.map(section => {
        const updatedSection = responseSections.find(s => s.id === section.id);
        return updatedSection ? updatedSection : section;
      });

      setQuestions(_questions);
      setSections(_sections);
    } catch (ex) {
      console.error(ex);
    } finally {
      closeLoadingScreen();
    }
  };

  const moveDown = async () => {
    openLoadingScreen();
    try {
      const { data: payload } = await client.post(`/assessments/${assessment.no}/${type}s/${itemId}/down`);
      const responseQuestions = payload.data.questions;
      const responseSections = payload.data.sections;

      const _questions = questions.map(question => {
        const updatedQuestion = responseQuestions.find(q => q.id === question.id);
        return updatedQuestion ? updatedQuestion : question;
      });

      const _sections = sections.map(section => {
        const updatedSection = responseSections.find(s => s.id === section.id);
        return updatedSection ? updatedSection : section;
      });

      setQuestions(_questions);
      setSections(_sections);
    } catch (ex) {
      console.error(ex);
    } finally {
      closeLoadingScreen();
    }
  };

  return {
    moveUp,
    moveDown,
  };
};

export const useQuestion = (questionId: string) => {
  const { openLoadingScreen, closeLoadingScreen } = useLoadingScreen();
  const { assessment, questions, setQuestions, sections, setSections } = useAssessmentManager();

  const copy = async () => {
    try {
      openLoadingScreen();
      const { data: payload } = await client.post(`/assessments/${assessment.no}/questions/${questionId}/copy`);
      const responseQuestions = payload.data.questions;
      const responseSections = payload.data.sections;

      const newQuestion = maxBy(responseQuestions, (item: any) => Number(item.id));
      const _questions = questions
        .map(question => {
          const updatedQuestion = responseQuestions.find(q => q.id === question.id);
          return updatedQuestion ? updatedQuestion : question;
        })
        .concat(newQuestion);

      const _sections = sections.map(section => {
        const updatedSection = responseSections.find(s => s.id === section.id);
        return updatedSection ? updatedSection : section;
      });

      setQuestions(_questions);
      setSections(_sections);
    } catch (ex) {
      console.error(ex);
    } finally {
      closeLoadingScreen();
    }
  };

  return {
    copy,
  };
};
