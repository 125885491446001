const siteSettings = {
  title: 'e - Internal Control Self-Assessment (e-CSA)',
  staticPath: '/static',
  totalSessionTimeout: 15,
  cautionSessionTimeout: 3, // แจ้งเตือน ก่อนหมดเวลา กี่นาที
};

const apiMessages = {
  assessmentCopy: {
    success: 'Copy assessment successfully',
    failure: 'Copy assessment unsuccessfully',
  },
  requireComment: 'Required! รายละเอียด/คำอธิบาย',
  requireAttachment: 'Required! เอกสารแนบ',
  confirmPopup: 'กรุณายืนยันหากต้องการลบรายการ',
  assessor: {
    ADD_TEXT: 'Added assessor successfully',
    UPDATE_TEXT: 'Updated assessor successfully',
    DELETE_TEXT: 'Deleted assessor successfully',
    ERROR_TEXT: '',
  },
  attachment: {
    ADD_TEXT: 'Uploaded attachment successfully',
    UPDATE_TEXT: 'Updated attachment successfully',
    DELETE_TEXT: 'Deleted attachment successfully',
  },
  assignAll: {
    ADD_TEXT: 'Assign all successfully',
  },
  sessionAlert: {
    caution: {
      title: 'การดำเนินการต่อ Session',
      text: 'อีก 3 นาที ท่านจะออกจากระบบโดยอัตโนมัติ หากต้องการดำเนินการต่อกรุณากดปุ่มตกลง',
    },
    timeout: {
      title: 'Session Timeout',
      text: 'ออกจากระบบโดยอัตโนมัติ กรุณา Login อีกครั้ง',
    },
  },
  report: {
    rpt01: 'รายงานสรุปรายการประเมิน',
    rpt02: 'รายงานแบบประเมินความเพียงพอของระบบการควบคุมภายใน',
    rpt03: 'รายงานสรุปผลการประเมินระบบการควบคุมภายใน',
    rpt04: 'รายงานสรุปความเห็นเกี่ยวกับระบบการควบคุมภายในประจำปีโดยผู้ประเมิน',
    rpt05: 'รายการติดตาม',
    rpt06: 'รายงานการควบคุมภายในที่เปิดเผยในรายงานประจำปี',
    rpt07: 'รายงานสถานะการตอบแบบประเมินความเพียงพอของระบบการควบคุมภายใน',
    rpt08: 'รายงานติดตามสถานะการประเมินของผู้ประเมิน',
  },
};

export const DEFAULT_DATE_PICKER_FORMAT = 'DD/MM/YYYY';

export default siteSettings;

export { apiMessages };
