import useAxios from 'axios-hooks';
import moment from 'moment';
import React from 'react';

import { Grid, Paper } from '@material-ui/core';
import AssessmentIcon from '@material-ui/icons/Assessment';

import { ASSESSMENT_FORM_URI } from '../../apis/constants';
import { AssessmentProvider } from '../../components/assessment/AssessmentContext';
import AssessmentEditForm from '../../components/assessment/AssessmentEditForm';
import ContentWrapper from '../../components/ContentWrapper';
import LoadingContent from '../../components/LoadingContent';
import MainLayout from '../../components/MainLayout';
import PageTitle from '../../components/PageTitle';
import { AssessmentForm, AssessmentSettings } from '../../models/assessment-dto';
import NotFoundPage from '../NotFoundPage';
import { Assessment } from '../../models';
import { useUserState } from '../../components/context/UserStateContext';
import { ScrollTopDown } from '../../components/ScrollTopDown';
import { AssessmentErrorProvider } from '../../context/AssessmentErrorContext';

const Page = () => {
  const { user } = useUserState();
  const [{ data: payload = {}, loading, error }] = useAxios({
    url: `${ASSESSMENT_FORM_URI}/settings`,
    method: 'GET',
  });

  if (error) return <NotFoundPage />;
  if (loading) return <LoadingContent />;

  const form: AssessmentSettings = (payload && payload.data) || undefined;

  if (!form) return <NotFoundPage />;

  const defaultValue: Assessment = {
    title: '',
    startDate: moment(Date()),
    endDate: moment(Date()).add(30, 'days'),
    year: moment().year(),
    level: 'CO',
    description: '',
    no: '',
    status: 'A',
    currentStatus: 'Draft',
    publishDateNextRound: moment(Date()).add(1, 'year'),
    code: '',
    createdByInitialName: user.initialName,
    createdByName: user.firstName + ' ' + user.lastName,
  };

  const initialValues: Partial<AssessmentForm> = {
    information: defaultValue,
    attachments: [],
    components: form.components,
    permissions: {
      editable: true,
    },
  };

  return (
    <AssessmentErrorProvider>
      <AssessmentProvider initialValues={initialValues as AssessmentForm} mode="create">
        <ScrollTopDown>
          <MainLayout>
            <ContentWrapper>
              <Grid container>
                <Grid item>
                  <PageTitle title="Assessment Form" icon={AssessmentIcon} />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item sm={12}>
                  <Paper>
                    <AssessmentEditForm />
                  </Paper>
                </Grid>
              </Grid>
            </ContentWrapper>
          </MainLayout>
        </ScrollTopDown>
      </AssessmentProvider>
    </AssessmentErrorProvider>
  );
};

export default Page;
