import { createBrowserHistory } from 'history';
import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import useRouter from 'use-react-router';

import { useUserState } from '../components/context/UserStateContext';
import { AlertSessionTimeoutProvider } from '../context/AlertSessionTimeout';
import { AlertRefreshToken } from '../components/AlertRefreshToken';

export const history = createBrowserHistory();

export const Debug = () => {
  const { location, ...rest } = useRouter();

  if (process.env.NODE_ENV !== 'production') {
    console.log('Route:', location.pathname, rest);
  }

  return null;
};

export interface AuthenticatedRouteProps extends RouteProps {
  loginPath?: string;
  roles?: string[];
}

export const AuthenticatedRoute = ({
  children,
  loginPath = '/login',
  roles = [],
  ...props
}: AuthenticatedRouteProps) => {
  const { isAuthenticated, hasAnyRoles } = useUserState();
  const { location } = useRouter();

  if (!isAuthenticated) {
    const blacklist = ['/', '/login', '/login/ldap'];
    const redirectTo = !blacklist.includes(location.pathname) ? location.pathname : '/';
    const loginPathWithRedirect = `${loginPath}?redirectTo=${redirectTo}`;
    return <Redirect to={loginPathWithRedirect} />;
  }

  if (roles.length > 0 && !hasAnyRoles(...roles)) {
    return <Redirect to="/" />;
  }

  return (
    <AlertSessionTimeoutProvider>
      <AlertRefreshToken />
      <Route {...props} />
    </AlertSessionTimeoutProvider>
  );
};

export const AdminRoute: React.FC<AuthenticatedRouteProps> = ({ roles = [], ...props }) => {
  const _roles = ['ADMIN', ...roles];
  return <AuthenticatedRoute roles={_roles} {...props} />;
};

export const OnlyAnonymousRoute = ({ children, ...props }: AuthenticatedRouteProps) => {
  const { isAuthenticated } = useUserState();

  if (isAuthenticated) return <Redirect to="/" />;

  return <Route {...props} />;
};
