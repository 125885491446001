import { Box, Grid } from '@material-ui/core';
import React, { lazy, memo, Suspense } from 'react';

import { Question, Section } from '../../models';
import ComponentText from '../ComponentText';

function areEquals(prev, next) {
  return prev.question.id === next.question.id;
}

const QuestionCheckboxView = lazy(() => import('./QuestionCheckboxView'));
const QuestionChoiceView = lazy(() => import('./QuestionChoiceView'));
const QuestionRankingView = lazy(() => import('./QuestionRankingView'));
const QuestionRatingView = lazy(() => import('./QuestionRatingView'));
const QuestionTextView = lazy(() => import('./QuestionTextView'));
const QuestionYesNoView = lazy(() => import('./QuestionYesNoView'));
const QuestionDateView = lazy(() => import('./QuestionDateView'));

export interface QuestionViewNoWriteableProps {
  question: Question;
  section?: Section;
}

const QuestionViewNoWriteable = memo(({ question, section }: QuestionViewNoWriteableProps) => {
  return (
    <Box border={0} borderColor="grey.300" paddingLeft={7} paddingRight={1} paddingBottom={1} paddingTop={1}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <ComponentText>
            <Box fontWeight={500} fontSize="1.0rem" color={question.permission.readable ? '#02b1a8' : '#bfbdbd'}>
              {(question.no && question.no) || ``} {question.title}
            </Box>
          </ComponentText>
        </Grid>
      </Grid>
      <Box paddingLeft={5}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Suspense fallback={null}>{getQuestion(question)}</Suspense>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
  
  function getQuestion(question) {
    switch (question.control) {
      case 'checkbox':
        return <QuestionCheckboxView disabled question={question} choices={question.choices} questionAnswer={{ controlType: 'checkbox', answer: { controlType: 'checkbox', choiceIds: [] } }} />;
      case 'choice':
        return <QuestionChoiceView disabled question={question} choices={question.choices} questionAnswer={{ controlType: 'choice', answer: { controlType: 'choice', choiceId: undefined } }}/>;
      case 'yesno':
        return <QuestionYesNoView disabled question={question} questionAnswer={{ controlType: 'yesno', answer: { controlType: 'yesno', yesNo: undefined } }}/>;
      case 'rating':
        return <QuestionRatingView disabled question={question} questionAnswer={{ controlType: 'rating', answer: { controlType: 'rating', rating: undefined } }} />;
      case 'date':
        return <QuestionDateView disabled question={question} questionAnswer={{ controlType: 'date', answer: { controlType: 'date', date: new Date() } }}/>;
      case 'ranking':
        return <QuestionRankingView disabled question={question} choices={question.choices} questionAnswer={{ controlType: 'text', answer: { controlType: 'text', detail: '' } }} />;
      case 'text':
        return <QuestionTextView disabled question={question} questionAnswer={{ controlType: 'text', answer: { controlType: 'text', detail: '' } }} />;
      default:
    }
  }
}, areEquals);

export { QuestionViewNoWriteable };

export default QuestionViewNoWriteable;
