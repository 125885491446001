import { Checkbox, FormControlLabel, Grid, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import React, { useState } from 'react';

import { apiMessages } from '../../siteSettings';
import { QuestionComponentProps } from './props';

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    paddingTop: 10,
  },
}));

const QuestionDate = ({ question, disabled, handleCheck, isLoading }: QuestionComponentProps) => {
  const classes = useStyles({});
  const [selectedDate, handleDateChange] = useState(moment());

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={1} />
        <Grid item xs={3}>
          <DatePicker value={selectedDate} onChange={handleDateChange} disabled />
        </Grid>
        <Grid item xs={3}>
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <Typography variant="body1" align="center" className={classes.label}>
                โปรดระบุ
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                disabled={disabled}
                onChange={handleCheck}
                control={
                  <Checkbox
                    checked={!!question.requiredComment}
                    id="requiredComment"
                    name="requiredComment"
                    value="requiredComment"
                  />
                }
                label={apiMessages.requireComment}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <Typography variant="body1" align="center" className={classes.label}>
                เอกสารแนบ
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                disabled={disabled}
                onChange={handleCheck}
                control={
                  <Checkbox
                    checked={!!question.requiredAttachment}
                    id="requiredAttachment"
                    name="requiredAttachment"
                    value="requiredAttachment"
                  />
                }
                label={apiMessages.requireAttachment}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export { QuestionDate };

export default QuestionDate;
