import useAxios from 'axios-hooks';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';

import { LOOKUP_URI } from '../../apis/constants';
import * as LookupService from '../../apis/lookup';
import ContentWrapper from '../../components/ContentWrapper';
import MainLayout from '../../components/MainLayout';
import LookupTable from '../../components/masterdata/LookupTable';
import { Lookup } from '../../models';
import { LookupCreateRequest, LookupUpdateRequest } from '../../models/master-dto';
import { ERROR_TEXT, errorOptions } from '../../utils/snackbar';

const MasterLookup = () => {
  const [{ data: payload = {} as any, loading, error }, refetch] = useAxios(LOOKUP_URI, { manual: true });
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    refetch()
  }, [])

  if (error) enqueueSnackbar(ERROR_TEXT, errorOptions);

  return (
    <MainLayout>
      <ContentWrapper>
        <LookupTable
          data={payload.data || []}
          isLoading={loading}
          onAdd={handleAdd}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
        />
      </ContentWrapper>
    </MainLayout>
  );
  function handleAdd(lookup: LookupCreateRequest) {
    return LookupService.create(lookup).then(() => refetch());
  }

  function handleUpdate(newData: Lookup, oldData: LookupUpdateRequest) {
    return LookupService.update(newData.id, newData).then(() => refetch());
  }
  function handleDelete(lookup: Lookup) {
    return LookupService.deleteOne(lookup.id).then(() => refetch());
  }
};

export default MasterLookup;
