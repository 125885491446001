import React, { memo } from 'react';
import { FormControl } from '@material-ui/core';
import { FormControlProps } from '@material-ui/core/FormControl';
import InputLabel, { GreyInputLabel } from '../InputLabel';

export interface FormInputProps extends FormControlProps {
  label: string;
  grey?: boolean;
}

type LabelProps = {
  grey?: boolean;
  id: string;
  required?: boolean;
  label: string;
};

const Label: React.FC<LabelProps> = memo(
  ({ label, required, id, grey }) => {
    return grey ? (
      <GreyInputLabel shrink htmlFor={id} required={required}>
        {label}
      </GreyInputLabel>
    ) : (
      <InputLabel shrink htmlFor={id} required={required}>
        {label}
      </InputLabel>
    );
  },
  (prev, next) => prev.grey === next.grey && prev.required === next.required,
);

function FormInput({ label, children, required, id, grey, ...props }: FormInputProps) {
  return (
    <FormControl fullWidth {...props} required={required}>
      <Label label={label} required={required} id={id} grey={grey} />
      {children}
    </FormControl>
  );
}

export { FormInput };

export default FormInput;
