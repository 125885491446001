import React from 'react';

import { ExportToolbar } from './ExportToolbar';
import { useReport5 } from './Report5Context';
import { Report5ResultTableView } from './Report5ResultTableView';
import { Report5Title } from './Report5Title';

export const Report5Result = () => {
  const { exportExcel, isProcessing, data } = useReport5();
  return (
    <div>
      <Report5Title />
      <ExportToolbar
        disabled={!data}
        excelFn={exportExcel}
        enabled={['excel']}
        excelLoading={isProcessing('excel-exporting')}
      />
      <Report5ResultTableView />
    </div>
  );
};
