import React, { memo } from 'react';

import InputLabel from '../../InputLabel';
import InputText from '../../InputText';
import { InformationProps } from './InformationProps';
import { FormControl, Hidden } from '@material-ui/core';

export const InformationTitle: React.FC<InformationProps> = memo(
  ({ value }) => {
    return (
      <FormControl fullWidth disabled>
        <Hidden only="xs">
          <InputLabel shrink htmlFor="name" />
        </Hidden>
        <InputText id="name" disabled value={value} />
      </FormControl>
    );
  },
  (prev, next) => prev.value === next.value,
);

export default InformationTitle;
