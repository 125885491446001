import { useSnackbar } from 'notistack';
import { useState } from 'react';
import useRouter from 'use-react-router';

import { client } from '../apis';
import { apiMessages } from '../siteSettings';
import { errorOptions, successOptions } from '../utils/snackbar';
import { useLoadingScreen } from '../components/LoadingScreenProvider';

export const useAssessmentCopy = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const { openLoadingScreen, closeLoadingScreen } = useLoadingScreen();
  const { history } = useRouter();

  const copy = async assessmentNo => {
    setLoading(true);
    openLoadingScreen();
    try {
      const { data: payload } = await client.post(`assessment-copy/${assessmentNo}`, {}, { timeout: 60000 });
      const data = payload.data;
      enqueueSnackbar(apiMessages.assessmentCopy.success, successOptions);
      history.push(`/worklist/${data.assessmentNo}/edit`);
      return true;
    } catch (ex) {
      enqueueSnackbar(apiMessages.assessmentCopy.failure, errorOptions);
      console.error(ex);
    } finally {
      setLoading(false);
      closeLoadingScreen();
    }
  };

  return { copy, loading };
};
