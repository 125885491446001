// import { configure } from 'axios-hooks';
import { configure } from 'axios-hooks';
import axios from 'axios';

import * as LocalStorageService from './LocalStorageService';
import { baseURL } from './constants';

const client = axios.create({
  baseURL: baseURL,
  timeout: 40000,
  adapter: require('axios/lib/adapters/http'),
});

client.interceptors.request.use(config => {
  // Refresh token if token is expired
  let token = LocalStorageService.getAccessToken();

  if (token != null) {
    config.headers = { Authorization: `Bearer ${token}` };
  }
  return config;
});

configure({ axios: client });

export { client };
