import React from 'react';
import Html from 'slate-html-serializer';

export const rules = [
  {
    serialize(obj, children) {
      if (obj.object === 'block' && obj.type === 'paragraph') {
        return React.createElement('span', {}, children, React.createElement('br'));
      }

      if (obj.object === 'block' && obj.type === 'heading-one') {
        return React.createElement('h1', {}, children);
      }

      if (obj.object === 'block' && obj.type === 'heading-two') {
        return React.createElement('h2', {}, children);
      }

      if (obj.object === 'inline' && obj.type === 'link') {
        return React.createElement('a', {}, children);
      }

      if (obj.object === 'block' && obj.type === 'quote') {
        return null;
      }

      if (obj.object === 'mark' && obj.type === 'bold') {
        return React.createElement('strong', {}, children);
      }

      if (obj.object === 'mark' && obj.type === 'italic') {
        return React.createElement('em', {}, children);
      }

      if (obj.object === 'mark' && obj.type === 'underlined') {
        return React.createElement('u', {}, children);
      }
      return null;
    },
  },
];

export const serialize = (value: any) => {
  const children = new Html({ rules: rules }).serialize(typeof value === 'string' ? JSON.parse(value) : value);
  return children;
};
