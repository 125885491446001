import React from 'react';

import ContentWrapper from '../components/ContentWrapper';
import MainLayout from '../components/MainLayout';
import PageBanner from '../components/PageBanner';
import bannerImg from '../static/img/Braner-Statistic.png';

export const StatisticPage = () => {
  return (
    <MainLayout>
      <ContentWrapper>
        <PageBanner image={bannerImg} />
        <iframe
          width="100%"
          height="70%"
          src={process.env.REACT_APP_DATA_STUDIO}
          style={{ border: 0 }}
          frameBorder="0"
          allowFullScreen
        />
      </ContentWrapper>
    </MainLayout>
  );
};
