import { MenuItem, Select, Typography } from '@material-ui/core';
import React, { memo } from 'react';

import InputBase from './InputBase';

export type PaginationLimitProps = {
  limit?: number[];
  onChange?: (event: React.ChangeEvent<{ name?: string; value: unknown }>, child: React.ReactNode) => void;
  value: string;
};

export const PaginationLimitOption = memo(({ limit = [5, 10, 15, 20], value, onChange }: PaginationLimitProps) => {
  return (
    <div>
      <Typography style={{ display: 'inline-block' }}>Show limit </Typography>
      <Select
        onChange={onChange}
        input={<InputBase name="limit" id="limit" value={value} />}
        style={{ width: '20%', marginLeft: 4, marginRight: 4 }}>
        {limit.map(item => (
          <MenuItem value={item} key={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
      <Typography style={{ display: 'inline-block' }}> items</Typography>
    </div>
  );
});

export default PaginationLimitOption;
