import React, { memo } from 'react';

import { FormInput } from '../../form';
import InputText from '../../InputText';
import { InformationProps } from './InformationProps';

export const InformationStatus: React.FC<InformationProps> = memo(
  ({ value }) => {
    return (
      <FormInput fullWidth id="status" label="สถานะ / Status" disabled>
        <InputText id="status" name="status" value={value} />
      </FormInput>
    );
  },
  (prev, next) => prev.value === next.value,
);

export default InformationStatus;
