import jwt from 'jsonwebtoken';
import { useSnackbar } from 'notistack';
import React, { createContext, useContext, useEffect, useState } from 'react';
import useRouter from 'use-react-router';

import { client } from '../../apis';
import * as AuthService from '../../apis/auth';
import * as _LocalStorage from '../../apis/LocalStorageService';
import { Employee } from '../../models';
import { JwtPayload } from '../../models/auth';
import { successOptions } from '../../utils/snackbar';

export interface UserStateProps {
  user: JwtPayload;
  setUser: (user: JwtPayload) => any;
  setAccessToken: (accessToken: string) => any;
  setRefreshToken: (refreshToken: string) => any;
  logout: () => any;
  logoutWithoutRedirect: () => any;
  isAuthenticated: boolean;
  hasAnyRoles: (...roles: string[]) => boolean;
  refreshToken: () => any;
  subordinates: Employee[];
}

const defaultProps: UserStateProps = {
  user: null,
  isAuthenticated: false,
  setUser: () => {},
  setAccessToken: () => {},
  setRefreshToken: () => {},
  hasAnyRoles: () => false,
  logout: () => {},
  logoutWithoutRedirect: () => {},
  refreshToken: () => null,
  subordinates: [],
};

export const UserStateContext = createContext<UserStateProps>(defaultProps);

export const UserStateConsumer = UserStateContext.Consumer;

export function UserStateProvider(props: any) {
  const { enqueueSnackbar } = useSnackbar();
  const { history } = useRouter();
  const [user, setUser] = useState<JwtPayload | boolean>(_LocalStorage.getPayload());
  const [subordinates, setSubordinates] = useState<Employee[]>([]);

  const setAccessToken = (accessToken: string) => {
    _LocalStorage.setAccessToken(accessToken);
    initialUser();
  };

  const setRefreshToken = (refreshToken: string) => {
    _LocalStorage.setRefreshToken(refreshToken);
  };

  const initialUser = () => {
    const token = _LocalStorage.getAccessToken();
    let payload = jwt.decode(token) as JwtPayload;
    setUser(payload);
  };

  const _refreshToken = () => {
    return AuthService.refreshToken().then(data => setUser(jwt.decode(data.accessToken) as JwtPayload));
  };

  const sendRefreshToken = async () => {
    const data = await AuthService.refreshToken();
    if (!data) return;

    const { accessToken, refreshToken } = data;
    setAccessToken(accessToken);
    setRefreshToken(refreshToken);
  };

  useEffect(() => {
    const fetchSubordinates = async () => {
      if (subordinates && subordinates.length > 0) return Promise.resolve(subordinates);

      const { data } = await client.get(`me/subordinates`);
      setSubordinates(data.data);
      return data.data;
    };

    const fetch = async () => {
      await sendRefreshToken();
    };

    if (!_LocalStorage.hasAccessToken() || (_LocalStorage.hasAccessToken() && _LocalStorage.isTokenExpiredIn())) {
      _LocalStorage.clearToken();
      setUser(null);
      return;
    }

    fetch();
    fetchSubordinates();
  }, [user && (user as JwtPayload).id]);

  const logoutWithoutRedirect = () => {
    const _user = user as JwtPayload;
    return AuthService.signOut(_LocalStorage.getRefreshToken(), _user.id)
      .then(() => enqueueSnackbar('ออกจากระบบอัตโนมัติ.', successOptions))
      .catch(ex => console.error)
      .finally(() => {
        _LocalStorage.clearToken();
      });
  };

  const logout = async () => {
    const _user = user as JwtPayload;
    return await AuthService.signOut(_LocalStorage.getRefreshToken(), _user.id)
      .then(() => {
        enqueueSnackbar('Logout successfully.', successOptions);
        history.push('/login');
      })
      .catch(ex => {
        console.error(ex);
        _LocalStorage.clearToken();
        setUser(null);
      })
      .finally(() => {
        _LocalStorage.clearToken();
        setUser(null);
      });
  };

  const hasAnyRoles = (...roles: string[]) => {
    const employee = user as JwtPayload;
    if (!employee || !employee.roles) return false;
    return roles.some(role => employee.roles.includes(role));
  };

  const context: UserStateProps = {
    user: user as JwtPayload,
    setUser,
    logout,
    logoutWithoutRedirect,
    isAuthenticated: !!user,
    setAccessToken,
    setRefreshToken,
    hasAnyRoles,
    refreshToken: _refreshToken,
    subordinates,
  };

  return <UserStateContext.Provider value={context}>{props.children}</UserStateContext.Provider>;
}

export function useUserState() {
  return useContext(UserStateContext);
}
