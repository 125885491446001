import useAxios from 'axios-hooks';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import MaterialTable, { Column } from 'material-table';

import { defaultOptions, tableIcons } from '../../components/masterdata/constants';
import { TableProps } from '../../components/masterdata/types';

import { NOTIFICATION_DATE } from '../../apis/constants';
import * as ConfigService from '../../apis/notification-date-config';
import ContentWrapper from '../../components/ContentWrapper';
import MainLayout from '../../components/MainLayout';
import { ERROR_TEXT, errorOptions } from '../../utils/snackbar';

interface NotificationConfig {
  processName: string;
  description: string;
  fieldName: string;
  remind1: number;
  remind2: number;
  emailTemplate: string;
}

const MasterConfigNotification = () => {
  const [{ data: payload = {} as any, loading, error }, refetch] = useAxios(NOTIFICATION_DATE, { manual: true });
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    refetch();
  }, []);

  if (error) enqueueSnackbar(ERROR_TEXT, errorOptions);

  return (
    <MainLayout>
      <ContentWrapper>
        <DataTable
          data={payload.data || []}
          isLoading={loading}
          onAdd={handleAdd}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
        />
      </ContentWrapper>
    </MainLayout>
  );
  function handleAdd(data: NotificationConfig) {
    return ConfigService.create(data).then(() => refetch());
  }

  function handleUpdate(newData: any, oldData: any) {
    if (newData && newData.remind1 !== undefined && newData.remind1 !== null) {
      newData.remind1 = typeof newData.remind1 === 'number' ? newData.remind1.toString() : newData.remind1;
    }

    if (newData && newData.remind2 !== undefined && newData.remind2 !== null) {
      newData.remind2 = typeof newData.remind2 === 'number' ? newData.remind2.toString() : newData.remind2;
    }

    return ConfigService.update(newData.id, newData).then(() => refetch());
  }
  function handleDelete(data: any) {
    return ConfigService.deleteOne(data.id).then(() => refetch());
  }
};

const DataTable = ({ data, onAdd, onUpdate, onDelete, isLoading = false }: TableProps<any>) => {
  const tableColumns: Column<NotificationConfig>[] = [
    { title: 'Process Name', field: 'processName', editable: 'always' },
    { title: 'Description', field: 'description', editable: 'always' },
    { title: 'fieldName', field: 'fieldName', editable: 'always' },
    { title: 'remind1', field: 'remind1', editable: 'always', type: 'numeric' },
    { title: 'remind2', field: 'remind2', editable: 'always', type: 'numeric' },
    { title: 'emailTemplate', field: 'emailTemplate', editable: 'always' },
  ];

  return (
    <MaterialTable
      columns={tableColumns}
      data={data}
      title={'Master Notification Date'}
      icons={tableIcons}
      isLoading={isLoading}
      options={defaultOptions}
      editable={{
        onRowAdd: newData => onAdd(newData),
        onRowUpdate: (newData, oldData) => onUpdate(newData, oldData),
        onRowDelete: oldData => onDelete(oldData),
      }}
    />
  );
};

export default MasterConfigNotification;
