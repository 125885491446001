import { Assessment, Assessor } from './assessment';

export class WorklistTask {
  information: Assessment;
  assessors: Assessor[];
  rootId: string;
  activityName: ActivityName;
}

export type ActivityName =
  | 'Create Assessment'
  | 'Assessment'
  | 'Admin Review'
  | 'Admin Summarize'
  | 'Completed'
  | 'Cancelled';
