import { Box, Link, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';

import Loading from '../Loading';
import { useReport6 } from './Report6Context';

const useStyles = makeStyles((theme: Theme) => ({
  cellNo: {
    [theme.breakpoints.up('sm')]: {
      width: '10%',
    },
  },
  cellCode: {
    [theme.breakpoints.up('sm')]: {
      width: '25%',
    },
  },
  cellRound: {
    [theme.breakpoints.up('sm')]: {
      width: '15%',
    },
  },
  cellTitle: {
    [theme.breakpoints.up('sm')]: {
      width: '35%',
    },
  },
  cellYear: {
    [theme.breakpoints.up('sm')]: {
      width: '15%',
    },
  },
}));

export const Report6ResultTableView = () => {
  const classes = useStyles({});
  const { isProcessing, result = [] } = useReport6();

  if (isProcessing('searching')) {
    return <Loading />;
  }

  return (
    <Box marginTop={3}>
      <Box border={1} borderColor="grey.300">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="right" className={classes.cellNo}>
                No
              </TableCell>
              <TableCell align="left" className={classes.cellCode}>
                รหัสเอกสาร / Document Code
              </TableCell>
              <TableCell align="right" className={classes.cellRound}>
                ครั้งที่ / Round
              </TableCell>
              <TableCell align="left" className={classes.cellTitle}>
                ชื่อเอกสาร
              </TableCell>
              <TableCell align="right" className={classes.cellYear}>
                ปี
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {result.length === 0 && (
              <TableRow>
                <TableCell colSpan={5}>ไม่มีข้อมูล</TableCell>
              </TableRow>
            )}
            {result.map((attachment, no) => (
              <TableRow key={`${attachment.id}`}>
                <TableCell align="right">{no + 1}</TableCell>
                <TableCell align="left">{attachment.code}</TableCell>
                <TableCell align="right">{attachment.round}</TableCell>
                <TableCell align="left">
                  <Link href={process.env.REACT_APP_BASE_URL + `/static/${attachment.uri}`} target="_blank">
                    {attachment.title}
                  </Link>
                </TableCell>
                <TableCell align="right">{attachment.year}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};
