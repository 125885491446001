import { Typography } from '@material-ui/core';
import React from 'react';
import { apiMessages } from '../../siteSettings';

export const Report1Title = () => {
  return (
    <Typography variant="h5" gutterBottom>
      {apiMessages.report.rpt01}
    </Typography>
  );
};
