import React, { createContext, useState, useContext } from 'react';
import { LoadingDialog } from './LoadingDialog';

const LoadingScreenContext = createContext(null);

export const LoadingScreenProvider = ({ children }) => {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <LoadingScreenContext.Provider value={{ open, setOpen }}>
      {children}
      <LoadingDialog open={open} />
    </LoadingScreenContext.Provider>
  );
};

export const useLoadingScreen = () => {
  const context = useContext(LoadingScreenContext);

  const openLoadingScreen = () => {
    context.setOpen(true);
  };

  const closeLoadingScreen = () => {
    context.setOpen(false);
  };

  return {
    isOpen: context.open,
    openLoadingScreen,
    closeLoadingScreen,
  };
};
