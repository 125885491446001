import React from 'react';

import { ExportToolbar } from './ExportToolbar';
import { useReport6 } from './Report6Context';
import { Report6ResultTableView } from './Report6ResultTableView';
import { Report6Title } from './Report6Title';

export const Report6Result = () => {
  const { exportExcel, isProcessing, result } = useReport6();
  return (
    <div>
      <Report6Title />
      <ExportToolbar
        disabled={!result}
        excelFn={exportExcel}
        enabled={['excel']}
        excelLoading={isProcessing('excel-exporting')}
      />
      <Report6ResultTableView />
    </div>
  );
};
