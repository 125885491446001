import { Box } from '@material-ui/core';
import MuiRating from 'material-ui-rating';
import React from 'react';

import { FormInput } from '../form';
import { QuestionComponentViewProps } from './props';

const QuestionRatingView = ({ disabled, question, onChange, questionAnswer }: QuestionComponentViewProps) => {
  if (!onChange || !question || !question.permission) {
    return (
      <Box paddingTop={1} paddingLeft={3} paddingRight={1} paddingBottom={2}>
        <FormInput label="Level" disabled>
          <Box marginTop={2}>
            <MuiRating value={1} max={(question && question.maxRate) || 5} disabled />
          </Box>
        </FormInput>
      </Box>
    );
  }
  return (
    <Box paddingTop={1} paddingLeft={3} paddingRight={1} paddingBottom={2}>
      <FormInput label="Level" disabled={!question.permission.writeable}>
        <Box marginTop={2}>
          <MuiRating
            value={question.permission.readable && questionAnswer.answer.rating}
            max={question.maxRate}
            onChange={onChange}
            disabled={!question.permission.writeable}
          />
        </Box>
      </FormInput>
    </Box>
  );
};

export { QuestionRatingView };

export default QuestionRatingView;
