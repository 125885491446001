import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import React, { useState, useEffect } from 'react';

import { useSessionTimeout } from '../context/AlertSessionTimeout';
import { apiMessages } from '../siteSettings';
import * as AuthService from '../apis/auth';

export const AlertRefreshToken = () => {
  const [isTimeout, setIsTimeout] = useState(false);
  const { open, setOpen, state, resumeTimer, resetTimer } = useSessionTimeout();

  useEffect(() => {
    if (state === 'Timeout') {
      setIsTimeout(true);
    }
  }, [state]);

  const handleOkay = () => {
    setOpen(false);
    AuthService.refreshToken();
    resumeTimer();
    resetTimer();
  };

  const handleTimeout = () => {
    window.location.reload();
  };

  return (
    <Dialog
      open={open}
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby="session-dialog-title"
      aria-describedby="session-dialog-description">
      <DialogTitle id="session-dialog-title">
        {isTimeout ? apiMessages.sessionAlert.timeout.title : apiMessages.sessionAlert.caution.title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="session-dialog-description">
          {isTimeout ? apiMessages.sessionAlert.timeout.text : apiMessages.sessionAlert.caution.text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={isTimeout ? handleTimeout : handleOkay} color="primary" autoFocus>
          ตกลง
        </Button>
      </DialogActions>
    </Dialog>
  );
};
