import { client } from './client';
import { NOTIFICATION_DATE } from './constants';

export async function getOne(id: number) {
  return await client.get(`${NOTIFICATION_DATE}/${id}`, {});
}

export async function getAll() {
  return await client.get(`${NOTIFICATION_DATE}`, {});
}

export async function create(request: any) {
  return await client.post(`${NOTIFICATION_DATE}`, request, {});
}

export async function update(id: number, request: any) {
  return await client.put(`${NOTIFICATION_DATE}/${id}`, request, {});
}

export async function deleteOne(id: number) {
  return await client.delete(`${NOTIFICATION_DATE}/${id}`, {});
}
