import { Grid } from '@material-ui/core';
import AssessmentIcon from '@material-ui/icons/Assessment';
import React from 'react';

import { useAssessmentTemplate, useComponentManager } from '../../context/AssessmentTemplateProvider';
import { Component, Question } from '../../models';
import BaseComponentWrapper from './ComponentWrapper';
import SectionView from '../SectionView';
import { QuestionPreview } from '../question/QuestionPreview';

export type ComponentWrapperProps = {
  title: string;
};

const ComponentWrapper: React.FC<ComponentWrapperProps & React.HTMLAttributes<HTMLDivElement>> = ({
  title,
  children,
}) => {
  return (
    <BaseComponentWrapper
      title={title}
      headerBarProps={{ bgcolor: '#f99139' }}
      titleProps={{ fontSize: '1.3rem', color: '#ffffff' }}>
      {children as any}
    </BaseComponentWrapper>
  );
};

export const AssessmentComponentPreview = () => {
  const { components } = useAssessmentTemplate();
  const componentManager = useComponentManager();

  return (
    <div>
      <Grid item xs={12}>
        {components
          .filter(comp => comp.status === 'A')
          .map((comp: Component, index: number) => (
            <ComponentWrapper title={comp.fullname} key={comp.id}>
              <Grid container spacing={0}>
                {componentManager
                  .getItems()
                  .filter(item => item.componentId === comp.id)
                  .map(item => {
                    if (item instanceof Question) return <QuestionPreview key={item.id} question={item} />;

                    return (
                      <SectionView key={`section-${item.id}`} section={item}>
                        {componentManager.getQuestionBySectionId(item.id).map(question => (
                          <QuestionPreview key={`${question.id},${item.id}`} question={question} />
                        ))}
                      </SectionView>
                    );
                  })}
              </Grid>
            </ComponentWrapper>
          ))}
      </Grid>
    </div>
  );
};
