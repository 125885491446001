import { IconButton } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'fixed',
    right: '5%',
    bottom: '10%',
    borderRadius: '25px',
    fontWeight: 'bold',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    cursor: 'pointer',
    transition: 'all 0.5s',
    border: '1px solid #e7e7e7',
  },
}));

export interface TopDownButtonProps {
  handleBottomClick?: () => void;
  handleTopClick?: () => void;
}

export function TopDownButton({ handleBottomClick, handleTopClick }: TopDownButtonProps) {
  const classes = useStyles({});

  return (
    <div className={classes.root}>
      <div>
        <IconButton onClick={handleTopClick}>
          <ArrowUpwardIcon fontSize="inherit" />
        </IconButton>
      </div>
      <div>
        <IconButton onClick={handleBottomClick}>
          <ArrowDownwardIcon fontSize="inherit" />
        </IconButton>
      </div>
    </div>
  );
}

export default TopDownButton;
