import React, { ReactNode, CSSProperties } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  menu: {
    '& > *': {
      display: 'inline-block',
    },
    '& > * + *': {
      marginLeft: 15,
    },
  },
}));

export type ButtonRef = HTMLButtonElement;
export type Props = {
  children?: any;
  active?: boolean;
  reversed?: boolean;
  className?: string;
  style?: CSSProperties;
  value?: any;
  [key: string]: any;
};

export const Button = React.forwardRef<ButtonRef, Props>(({ className, active, reversed, ...props }, ref) => (
  <span
    {...props}
    ref={ref}
    className={className}
    style={{
      cursor: 'pointer',
      color: `${reversed ? (active ? 'white' : '#aaa') : active ? 'black' : '#ccc'}`,
    }}
  />
));

export const EditorValue = React.forwardRef<HTMLDivElement, Props>(({ className, value, ...props }, ref) => {
  const textLines = value.document.nodes
    .map(node => node.text)
    .toArray()
    .join('\n');

  return (
    <div
      ref={ref}
      {...props}
      className={className}
      style={{
        margin: '30px -20px 0',
      }}>
      <div
        style={{
          fontSize: 14,
          padding: '5px 20px',
          color: '#404040',
          borderTop: '2px solid #eeeeee',
          background: '#f8f8f8',
        }}>
        Slate's value as text
      </div>
      <div
        style={{
          color: '#404040',
          font: '12px monospace',
          whiteSpace: 'pre-wrap',
          padding: '10px 20px',
        }}>
        {textLines}
      </div>
    </div>
  );
});

export const Icon = React.forwardRef<HTMLDivElement, Props>(({ className, ...props }, ref) => (
  <span
    {...props}
    ref={ref}
    className={clsx('material-icons', className)}
    style={{
      fontSize: 18,
      verticalAlign: 'text-bottom',
    }}
  />
));

export const Instruction = React.forwardRef<HTMLDivElement, Props>(({ className, ...props }, ref) => (
  <div
    {...props}
    ref={ref}
    className={className}
    style={{
      whiteSpace: 'pre-wrap',
      margin: '0 -20px 10px',
      padding: '10px 20px',
      fontSize: '14px',
      background: '#f8f8e8',
    }}
  />
));

export const Menu = React.forwardRef<HTMLDivElement, Props>(({ className, ...props }, ref) => {
  const classes = useStyles({});
  return <div {...props} ref={ref} className={clsx(className, classes.menu)} />;
});

export const Toolbar = React.forwardRef<HTMLDivElement, Props>(({ className, ...props }, ref) => (
  <Menu
    {...props}
    ref={ref}
    className={className}
    style={{
      position: 'relative',
      padding: '1px 18px 17px',
      margin: '0 -20px',
      borderBottom: '2px solid #eee',
      marginBottom: '20px',
    }}
  />
));
