import { useState } from 'react';
import { AttachmentState } from './AddAttachmentDialog';

export const useAttachment = () => {
  const [uploadProgress, setUploadProgress] = useState(0);

  const uploadAttachment = (
    attachment: AttachmentState,
    bucketName: string,
    _uploadAttachment: (
      formData: FormData,
      bucketName: string,
      options: { onUploadProgress: (progressEvent: any) => void },
    ) => Promise<any>,
  ) => {
    const formData = new FormData();
    // console.log(attachment.file);
    const fileChk =  (attachment.file == null || attachment.file === undefined) 
    const urlChk = (attachment.url == null || attachment.url === undefined || attachment.url === '')

    if ( fileChk && urlChk ) {
      throw new Error('Error the attachment files or url is required!');
    }
   
    formData.append('file', attachment.file);
    formData.append('url', attachment.url);
    formData.append('type', attachment.file ? 'file' : 'url');
    formData.append('title', attachment.title);
    formData.append('description', attachment.description);
    formData.append('confidential', attachment.confidential + '');
    formData.append('itemId', attachment.itemId);
    formData.append('rootId', attachment.rootId);
    formData.append('folder', attachment.folder);

    const onUploadProgress = event => {
      if (!attachment.file) return;
      const progress = (event.loaded / attachment.file.size) * 100.0;
      setUploadProgress(progress);
    };

    return _uploadAttachment(formData, bucketName, { onUploadProgress: onUploadProgress });
  };

  return {
    uploadProgress,
    uploadAttachment,
  };
};
