import React, { createContext, useContext } from 'react';
import { Component, Question, Section } from '../../models';
import { ComponentManager } from '../../models/aggregator';

export const ComponentContext = createContext<any>({});

export interface ComponentProviderProps {
  children: React.ReactNode;
  component: Component;
  questions: Question[];
  sections: Section[];
}

export function ComponentProvider({ children, component, questions, sections }: ComponentProviderProps) {
  const value = {
    component,
    componentManager: new ComponentManager(questions, sections),
  };

  return <ComponentContext.Provider value={value}>{children}</ComponentContext.Provider>;
}

export interface ComponentProviderValues {
  component: Component;
  componentManager: ComponentManager;
}

export function useComponent(): ComponentProviderValues {
  const context = useContext<ComponentProviderValues>(ComponentContext);
  return context;
}
