import * as jwt from 'jsonwebtoken';
import moment from 'moment';
import { JwtPayload } from '../models/auth';

export const ACCESS_TOKEN = 'accessToken';
export const REFRESH_TOKEN = 'refreshToken';

export const getAccessToken = () => localStorage.getItem(ACCESS_TOKEN);

export const hasAccessToken = () => !!localStorage.getItem(ACCESS_TOKEN);

export const getRefreshToken = () => localStorage.getItem(REFRESH_TOKEN);

export const setAccessToken = (accessToken: string) => localStorage.setItem(ACCESS_TOKEN, accessToken);

export const setRefreshToken = (refreshToken: string) => localStorage.setItem(REFRESH_TOKEN, refreshToken);

/**
 *
 * @param gapTime Second unit.
 */
export const isTokenExpired = (gapTime: number = 60): boolean => {
  const token = getAccessToken();
  let payload = jwt.decode(token) as JwtPayload;
  if (!payload) return true;

  return payload.exp - moment(new Date()).unix() <= gapTime;
};

/**
 *
 * @param gapTime ก่อนหมดเวลา กี่นาที
 */
export const isTokenExpiredIn = (gapTime: number = 0): boolean => {
  const token = getAccessToken();
  let payload = jwt.decode(token) as JwtPayload;
  if (!payload) return true;

  const expired = moment.unix(payload.exp).subtract(gapTime, 'minutes');
  return moment(new Date()).isSameOrAfter(expired);
};

export const getPayload = (): JwtPayload | null => {
  const token = getAccessToken();
  return token ? (jwt.decode(token) as JwtPayload) : null;
};

export const clearToken = () => {
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(REFRESH_TOKEN);
};
