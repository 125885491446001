import { useState } from 'react';
import { ValidationError, validate } from 'class-validator';

export interface FormValidationOptions<T> {
  initialValues: Partial<T>;
  type: new () => T;
  onSuccess?: (instance: T) => void;
  groups?: string[];
}

/**
 * Form validation by using class-validator
 * https://github.com/typestack/class-validator
 * @param {object} initialStates
 * @param {T} type
 */
function useFormValidation<T>({ initialValues, type, onSuccess, groups }: FormValidationOptions<T>) {
  const [isSubmitting, submitting] = useState<boolean>(false);
  const [values, setValues] = useState<Partial<T>>(initialValues);
  const [errors, setErrors] = useState<ValidationError[]>([]);
  const [hasChanged, setHasChanged] = useState(false);

  const handleKeyUp = (event: any) => {
    if (!hasChanged) setHasChanged(true);
  };

  const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const newValue = { [event.target.name as string]: event.target.value } as Pick<Partial<T>, keyof Partial<T>>;
    const newValues = { ...values, ...newValue };
    setValues(newValues);
  };

  const setValue = (key: keyof T, value: any) => {
    setValues({
      ...values,
      [key]: value,
    });
  };

  const _setValues = (partial: Partial<T>) => {
    setValues({
      ...values,
      ...partial,
    });
  };

  const clearErrors = () => setErrors([]);

  return {
    handleChange,
    handleSubmit,
    hasError,
    setValue,
    values,
    errors,
    isSubmitting,
    setErrors,
    setValues: _setValues,
    hasChanged,
    setHasChanged,
    handleKeyUp,
    clearErrors,
  };

  function hasError(name?: keyof T): boolean {
    if (!name) {
      return !!errors.length;
    }
    return errors.some(error => error.property === name);
  }

  async function handleSubmit(event: any) {
    event.preventDefault();
    if (isSubmitting) return;
    try {
      submitting(true);
      const instance = new type();
      Object.assign<T, Partial<T>>(instance, values);

      const errors = await validate(instance, { groups: groups });
      setErrors(errors);

      if (errors.length === 0 && onSuccess) {
        await onSuccess(instance);
      }
    } catch (ex) {
      console.error(ex);
    } finally {
      submitting(false);
    }
  }
}

export { useFormValidation };
