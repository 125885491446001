import React from 'react';
import { useAssessmentCopy } from '../../hooks/useAssessmentCopy';
import LoadingButton, { ButtonProps } from '../LoadingButton';

export type CopyButtonProps = ButtonProps & {
  assessmentNo: string;
};

export const CopyButton: React.FC<CopyButtonProps> = ({ assessmentNo, ...props }) => {
  const { copy, loading } = useAssessmentCopy();

  return (
    <React.Fragment>
      <LoadingButton
        isLoading={loading}
        onClick={() => {
          if (window.confirm('คุณต้องการ Copy ใบประเมินนี้หรือไม่?')) {
            copy(assessmentNo);
          }
        }}
        variant="outlined"
        color="secondary"
        {...props}>
        Copy
      </LoadingButton>
    </React.Fragment>
  );
};
