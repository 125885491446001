import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import uniq from 'lodash/uniq';
import { useTheme } from '@material-ui/core/styles';
import React from 'react';

import { Assessor } from '../models';
import { useAssessmentRequest } from './assessment/AssessmentRequestContext';
import CheckOrUncheckIcon from './CheckOrUncheckIcon';

export const AllPreparerTable = ({
  assessors,
  noBorder,
}: {
  noBorder: boolean;
  assessors: Assessor[];
  handleUpdate?: (componentId: string, questionId: string, employeeId: string) => any | Promise<any>;
}) => {
  const { assessmentRequest, getAllAssessors } = useAssessmentRequest();

  const theme = useTheme();

  function isAssignedAll(assessor: Assessor) {
    return (
      getAllAssessors().filter(a => a.employeeId === assessor.employeeId).length ===
      assessmentRequest.questionNumberOfAssessor
    );
  }

  function isComment(assessor: Assessor) {
    const componentByCommentator = getAllAssessors()
      .filter(a => a.employeeId === assessor.employeeId)
      .reduce((prev, a) => {
        if (prev[a.componentId]) {
          return prev;
        }
        return {
          ...prev,
          [a.componentId]: a.commentator,
        };
      }, {});

    return Object.values(componentByCommentator).every(_ => _);
  }

  return (
    <Box border={noBorder ? 0 : 1} borderBottom={0} borderColor={theme.palette.divider}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>No</TableCell>
            <TableCell>ระดับหน่วยงาน / Unit Level</TableCell>
            <TableCell>ชื่อหน่วยงาน / Unit Name</TableCell>
            <TableCell>ชื่อ-นามสกุล / Initial Name</TableCell>
            <TableCell>ประเภทผู้ประเมิน / Assessor Type</TableCell>
            <TableCell>ผู้สรุปการประเมินทั้งหมด</TableCell>
            <TableCell>ตอบทุกคำถาม / Assign All</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {assessors.length === 0 ? (
            <TableRow>
              <TableCell colSpan={5}>ไม่มีข้อมูล</TableCell>
            </TableRow>
          ) : (
            assessors.map((emp, no) => (
              <TableRow key={emp.employeeId}>
                <TableCell>{no + 1}</TableCell>
                <TableCell>{emp.departmentLevelName}</TableCell>
                <TableCell>{emp.departmentFullNameTh}</TableCell>
                <TableCell>{emp.fullnameWithInitialName}</TableCell>
                <TableCell>{emp.assessorRole}</TableCell>
                <TableCell align="center">
                  <CheckOrUncheckIcon value={isComment(emp)} />
                </TableCell>
                <TableCell align="center">
                  <CheckOrUncheckIcon value={isAssignedAll(emp)} />
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </Box>
  );
};

export default AllPreparerTable;
