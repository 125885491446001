import React, { useRef } from 'react';

import TopDownButton from './TopDownButton';

export function ScrollTopDown({ children }) {
  const topRef = useRef<any>();
  const bottomRef = useRef<any>();

  const handleTopClick = () => {
    if (topRef && topRef.current) {
      topRef.current.scrollIntoView();
    }
  };

  const handleBottomClick = () => {
    if (bottomRef && bottomRef.current) {
      bottomRef.current.scrollIntoView();
    }
  };

  return (
    <div>
      <div id="top" ref={topRef} />
      {children}
      <TopDownButton handleTopClick={handleTopClick} handleBottomClick={handleBottomClick} />
      <div id="bottom" ref={bottomRef} />
    </div>
  );
}

export default ScrollTopDown;
