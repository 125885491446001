import { ResponseValues } from 'axios-hooks';
import { useSnackbar } from 'notistack';
import React, { memo, useState } from 'react';
import useRouter from 'use-react-router';

import { errorOptions, successOptions } from '../../utils/snackbar';
import LoadingButton from '../LoadingButton';
import { useError } from '../../context/AssessmentErrorContext';
import { client } from '../../apis';
import { useLoadingScreen } from '../LoadingScreenProvider';

const useAssessmentCancel = (assessmentNo: string, remark: string): [ResponseValues<any>, () => void] => {
  const { setError, clearError } = useError();
  const [loading, setLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const { openLoadingScreen, closeLoadingScreen } = useLoadingScreen();
  const { history } = useRouter();

  const execute = async () => {
    if (loading) return;

    try {
      setLoading(true);
      openLoadingScreen();
      clearError();
      await client.post(`assessments/${assessmentNo}/cancel`, { remark });
      enqueueSnackbar(`Cancel successfully`, successOptions);
      history.push('/worklist');
    } catch (ex) {
      enqueueSnackbar(ex.message, errorOptions);
      console.error();
      setError(ex.response.data.message);
    } finally {
      setLoading(false);
      closeLoadingScreen();
    }
  };

  return [{ loading, data: undefined }, execute];
};

export type AssessmentCancelButtonProps = {
  assessmentNo: string;
  remark?: string;
};

export const AssessmentCancelButton: React.FC<AssessmentCancelButtonProps> = memo(
  ({ assessmentNo, remark }) => {
    const [{ loading }, executePost] = useAssessmentCancel(assessmentNo, remark);

    const cancel = () => {
      if (window.confirm('คุณต้องการยกเลิกใบประเมินและผลการประเมินทั้งหมด?')) {
        executePost();
      }
    };

    return (
      <React.Fragment>
        <LoadingButton onClick={() => cancel()} isLoading={loading} variant="contained" color="secondary">
          Cancel
        </LoadingButton>
      </React.Fragment>
    );
  },
  (prev, next) => prev.assessmentNo === next.assessmentNo && prev.remark === next.remark,
);

export default AssessmentCancelButton;

export const AssessmentDraftCancelButton: React.FC<AssessmentCancelButtonProps> = memo(
  ({ assessmentNo, remark }) => {
    const [{ loading }, executePost] = useAssessmentCancel(assessmentNo, remark);

    const cancel = () => {
      if (window.confirm('คุณต้องการยกเลิกใบประเมินนี้?')) {
        executePost();
      }
    };

    return (
      <React.Fragment>
        <LoadingButton onClick={() => cancel()} isLoading={loading} variant="contained" color="secondary">
          Cancel
        </LoadingButton>
      </React.Fragment>
    );
  },
  (prev, next) => prev.assessmentNo === next.assessmentNo && prev.remark === next.remark,
);
