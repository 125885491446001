import { Assessment, Component, Question, Section, AssessmentEvent, Assessor } from './assessment';
import { AttachmentFile, Lookup } from './master';
import { Department, Level } from './user';
import { ASSESSMENT_REQUEST_ACTIONS } from './assessment-action';

/**
 * Used for representing the response data from assessment API
 */
export class AssessmentForm {
  information: Assessment;
  attachments: AttachmentFile[];
  components: Component[];
  questions?: Question[];
  sections?: Section[];
  events?: AssessmentEvent[];
  permissions?: Permissions;
  requestStatus?: string;
  assessors?: Assessor[];
  allowedActions?: ASSESSMENT_REQUEST_ACTIONS[];
  rootId?: string;
  assignAll?: string[];
  questionNumberOfAssessor?: number;

  constructor(partial: Partial<AssessmentForm> = {}) {
    Object.assign(this, { ...partial });
  }
}

export class Permissions {
  editable?: boolean;
  answer?: boolean;
  submitToAssign?: boolean;
}

/**
 * Used for updating item ordering in assessment component
 */
export class Sequence {
  id: string;
  type: 'section' | 'question';
  sequence: number;
  componentId: string;
  sectionId: string;
}

export class EmployeeSetting {
  departmentId: string;
  departmentNameEn: string;
  departmentNameTh: string;
  departmentShortName: string;
  firstName: string;
  fulltext: string;
  id: string;
  initialName: string;
  isManager: true;
  lastName: string;
  levelName: string;
  levelNo: number;
  managerId: string;
  positionId: string;
  positionNameEn: string;
  positionNameTh: string;
}

export interface ProcessName {
  id: string;
  nameEn: string;
  nameTh: string;
}

export class AssessmentSettings {
  levels: Lookup[];
  years: number[];
  components: Component[];
  departments: Department[];
  departmentLevels: Level[];
  processNames: ProcessName[];
  managers: EmployeeSetting[];
  assessmentTitle: {
    [key: string]: string;
  };
}
