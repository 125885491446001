import MaterialTable, { Column } from 'material-table';
import React from 'react';

import { ProcessName } from '../../models';
import { defaultOptions, tableIcons } from './constants';
import { TableProps } from './types';

const ProcessNameTable = ({ data, onAdd, onUpdate, onDelete, isLoading = false }: TableProps<ProcessName>) => {
  const tableColumns: Column<any>[] = [
    { title: 'TH Name', field: 'nameTh', editable: 'always' },
    { title: 'EN Name', field: 'nameEn', editable: 'always' },
    { title: 'Status', field: 'status', editable: 'onUpdate' , lookup: { 'A': 'Active', 'I': 'Inactive', 'D': 'Delete' }},
  ];

  return (
    <MaterialTable
      columns={tableColumns}
      data={data}
      title={'Master Process Name'}
      icons={tableIcons}
      isLoading={isLoading}
      options={defaultOptions}
      editable={{
        onRowAdd: newData => onAdd(newData),
        onRowUpdate: (newData, oldData) => onUpdate(newData, oldData),
        onRowDelete: oldData => onDelete(oldData),
      }}
    />
  );
};

export default ProcessNameTable;
