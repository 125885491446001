import {
  AppBar,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import { IsBoolean } from 'class-validator';
import React, { useState } from 'react';

import { Assessor } from '../models';
import { FormInput } from './form';
import LoadingButton from './LoadingButton';
import { useFormValidation } from './useFormValidation';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: 'calc(50%)',
    [theme.breakpoints.up('md')]: {
      minHeight: 'calc(50)',
    },
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

export class AssessorState {
  @IsBoolean()
  assessorType: boolean;
}

export interface UpdateAssessorDialogProps {
  admin?: boolean;
  assessor: Assessor;
  isLoading?: boolean;
  handleSubmit?: (assessor: Assessor) => Promise<any>;
  disabled?: boolean;
}

const UpdateAssessorDialog = ({ admin, handleSubmit, isLoading, assessor, disabled }: UpdateAssessorDialogProps) => {
  const classes = useStyles({});
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = useState(false);
  const { values, setValue, setValues, handleSubmit: formSubmit, hasError } = useFormValidation({
    initialValues: {
      assessorType: assessor.assessorType,
    },
    type: AssessorState,
    groups: admin ? ['admin'] : [],
    onSuccess: () => {
      assessor.assessorType = values.assessorType;
      handleSubmit(assessor as Assessor).then(() => {
        setOpen(false);
      });
    },
  });

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    if (isLoading) return;
    setOpen(false);
    setValues({ assessorType: assessor.assessorType });
  };

  const handleRadioChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(name as any, event.target.value === 'true');
  };

  return (
    <React.Fragment>
      <IconButton color="primary" size="medium" onClick={handleOpen} disabled={isLoading || disabled}>
        <EditIcon fontSize="inherit" />
      </IconButton>
      <Dialog
        fullWidth
        maxWidth="lg"
        PaperProps={{
          classes: { root: classes.root },
        }}
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="assessor-dialog-title">
        {fullScreen && (
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="Close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                แก้ไขผู้ประเมิน
              </Typography>
            </Toolbar>
          </AppBar>
        )}

        <DialogTitle id="assessor-dialog-title">{!fullScreen && 'แก้ไขผู้ประเมิน / Edit Assessor'}</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormInput fullWidth label="ประเภทผู้ประเมิน" error={hasError('assessorType')}>
                <Box marginTop={2}>
                  <RadioGroup name="assessorType" onChange={handleRadioChange('assessorType')}>
                    <FormControlLabel
                      control={<Radio value="true" checked={values.assessorType} />}
                      label="ผู้ประเมินหลัก"
                    />
                    <FormControlLabel
                      control={<Radio value="false" checked={!values.assessorType} />}
                      label="ผู้ประเมินรอง"
                    />
                  </RadioGroup>
                </Box>
              </FormInput>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <LoadingButton isLoading={isLoading} onClick={formSubmit} variant="contained" color="primary" autoFocus>
            Save
          </LoadingButton>
          <LoadingButton disabled={isLoading} onClick={handleClose} variant="outlined" color="default">
            Cancel
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default UpdateAssessorDialog;
