import { Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import DotIcon from '@material-ui/icons/FiberManualRecord';
import React from 'react';
import Slider from 'react-slick';

import img01 from '../static/img/banner01.png';
import img02 from '../static/img/banner02.jpg';
import img03 from '../static/img/Braner-Slide3.png';

const useStyles = makeStyles((theme: Theme) => ({
  dotPaging: {
    fontSize: '1rem',
  },
}));

type BannerProps = {
  banners: any[];
}

const Banner: React.FC<BannerProps> = ({ banners }) => {
  const classes = useStyles({});
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    autoplaySpeed: 7000,
    autoplay: true,
    pauseOnHover: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: dots => (
      <div>
        <ul style={{ padding: '19px' }}> {dots} </ul>
      </div>
    ),
    customPaging: i => {
      return (
        <a>
          <DotIcon className={classes.dotPaging} />
        </a>
      );
    },
    dotsClass: 'slick-dots slick-thumb',
  };
  return (
    <>
      <Box>
        <Slider {...settings}>
          {banners.map(banner => <div key={banner.fileName}>
            <img  src={`${process.env.REACT_APP_BASE_URL}/static/${banner.uri}`} alt={banner.fileName} width={846} height={191} />
          </div>)}
          
        </Slider>
      </Box>
    </>
  );
};

export default Banner;
