import React, { memo } from 'react';
import { Grid, Box } from '@material-ui/core';
import ComponentText from '../ComponentText';

type QuestionViewTitleProps = {
  readable?: boolean;
  title?: string;
};

export const QuestionViewTitle: React.FC<QuestionViewTitleProps> = memo(({ readable, title }) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <ComponentText>
          <Box fontWeight={500} fontSize="1.0rem" color={readable ? '#02b1a8' : '#bfbdbd'}>
            {title}
          </Box>
        </ComponentText>
      </Grid>
    </Grid>
  );
});
