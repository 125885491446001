import moment from 'moment';

/**
 * ดึง list ของปี จาก Start Year ถึงปีปัจจุบน
 * @default startYear=2018
 * @param {number} startYear
 */
export function getYearListFrom(startYear: number = 2018): number[] {
  const endYear = moment().year();
  return Array(endYear - startYear + 1)
    .fill('0')
    .map((_, no) => startYear + no);
}
