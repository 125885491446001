/**
 * ถ้าไม่มี title
 */

import { IsNotEmpty, IsOptional, MaxLength } from 'class-validator';
import React, { useState, useEffect } from 'react';

import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Toolbar,
  Typography,
  FormControlLabel,
  Checkbox,
  LinearProgress,
  Tooltip,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import withMobileDialog, { WithMobileDialog } from '@material-ui/core/withMobileDialog';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { baseURL } from '../apis/constants';

import InputBase from './InputBase';
import { useFormValidation } from './useFormValidation';
import { FormInput } from './form';
import LoadingButton from './LoadingButton';
import { AttachmentFile, Question, Section } from '../models';
// import { Report1Title } from './report/Report1Title';

const useStyles = makeStyles((theme: Theme) => ({
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

export class AttachmentState {
  file?: File;

  @MaxLength(200, { message: 'title ห้ามเกิน 200 ตัวอักษร', always: true })
  @IsNotEmpty({ message: 'กรุณากรอกข้อมูล title', always: true })
  title: string;

  @IsOptional({ always: true })
  confidential?: boolean;

  @IsOptional({ always: true })
  description?: string;

  itemId?: string;

  type?: 'file' | 'url';

  url?: string;

  rootId?: string;

  folder?: string;
}

export interface AttachmentDialogProps extends WithMobileDialog {
  itemId?: string;
  onSubmit?: (attachmentData: AttachmentState) => Promise<any>;
  isLoading?: boolean;
  disabled?: boolean;
  uploadProgress?: number;
  mode?: 'add' | 'edit';
  attachment?: AttachmentFile;
  question?: Question;
  section?: Section;
}

function disableConfidential(attachment: Partial<AttachmentState>): boolean {
  return !!attachment.file || !!attachment.url;
}

const AddAttachmentDialog = ({
  itemId,
  fullScreen,
  onSubmit,
  isLoading,
  disabled,
  uploadProgress,
  mode = 'add',
  attachment,
  section,
  question,
}: AttachmentDialogProps) => {
  const classes = useStyles({});
  const defaultValue: AttachmentState = {
    confidential: false,
    description: '',
    title: getTitle(),
    itemId: itemId,
    type: null,
    url: '',
  };
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  function getTitle() {
    if (question) return `${question.no} (${(question.attachments && question.attachments.length + 1) || 1})`;
    return '';
  }

  useEffect(() => {
    resetData();
  }, [open]);

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    // if (isLoading) return;
    setOpen(false);
    clearErrors();
  };

  const { values, handleSubmit, handleChange, hasError, setValue, setValues, clearErrors } = useFormValidation({
    initialValues: attachment || defaultValue,
    type: AttachmentState,
    groups: [mode],
    onSuccess: async () => {
      const newValue = {
        ...values,
        title: `${getTitle().trim()} ${values.title}`,
      };
      try {
        setError(null);

        await onSubmit(newValue as AttachmentState);
        handleClose();
      } catch (ex) {
        console.log('error', ex);
        setError(ex);
      } finally {
        handleClose();
      }
      handleClose();
    },
  });

  function resetData() {
    setValues({
      file: null,
      confidential: (attachment && attachment.confidential) || false,
      description: (attachment && attachment.description) || '',
      title: (attachment && attachment.title) || '',
      itemId: itemId,
      url: (attachment && attachment.url) || '',
      type: (attachment && attachment.type) || 'file',
    });
  }

  const handleFileChange = (event: any) => {
    const _file = event.target.files[0] as any;
    setError(null);
    if (_file && _file.name) {
      setValues({
        file: _file,
        title: _file.name,
        confidential: false,
      });
    }
  };

  const handleCheckbox = (event: any) => {
    setError(null);
    setValue([event.target.name] as any, event.target.checked);
  };

  const handleUrlChange = (event: any) => {
    setError(null);
    setValues({ url: event.target.value, confidential: false });
  };


  return (
    <React.Fragment>
      {mode === 'add' ? (
        <Tooltip title="Add File" aria-label="Add file">
          <Button variant="contained" color="primary" size="small" onClick={handleOpen} disabled={disabled}>
            <AddIcon className={classes.leftIcon} fontSize="inherit" />
            Add File
          </Button>
        </Tooltip>
      ) : (
        <Tooltip title="Edit File" aria-label="Edit file">
          <IconButton color="primary" size="medium" onClick={handleOpen} disabled={disabled}>
            <EditIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
      )}

  

      <Dialog open={open} fullScreen={fullScreen} onClose={handleClose} aria-labelledby="add-attachment-dialog">
        {fullScreen && (
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="Close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                เอกสารแนบเพิ่มเติม
              </Typography>
            </Toolbar>
          </AppBar>
        )}

        <DialogTitle id="add-attachment-dialog">
          {!fullScreen && (
            <>{mode === 'add' ? 'เอกสารแนบเพิ่มเติม / Add Attachment' : 'แก้ไขเอกสารแนบ / Edit Attachment'}</>
          )}
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={1}>
            {isLoading && uploadProgress > 0 && (
              <Grid item xs={12} hidden={!isLoading}>
                <LinearProgress variant="determinate" value={uploadProgress} />
              </Grid>
            )}
            <Grid item xs={12}>
              {(attachment && attachment.type === 'file') ||
                (mode === 'add' && (
                  <FormInput fullWidth error={hasError('file')} label="ไฟล์แนบ / Attach File" id="file">
                    {mode === 'add' ? (
                      <>
                        <InputBase
                          id="file"
                          name="file"
                          type="file"
                          onChange={handleFileChange}
                          aria-describedby="file-text"
                          // disabled={isLoading}
                          disabled={mode !== 'add' ? isLoading : false}
                        />
                        <FormHelperText id="file-text">
                          รองรับไฟล์ txt, png, jpg, pdf, doc, pptx, docx, xls, xlsx, mp4
                        </FormHelperText>
                      </>
                    ) : (
                      <InputBase value={attachment.fileName} disabled fullWidth />
                    )}
                  </FormInput>
                ))}
            </Grid>
            {mode === 'add' && question && (
              <Grid item xs={12}>
                <FormInput label="ลำดับ / No." id="no" disabled>
                  <InputBase id="no" name="no" value={getTitle().trim()} />
                </FormInput>
              </Grid>
            )}
            <Grid item xs={12}>
              <FormInput
                fullWidth
                error={hasError('title')}
                required
                label="ชื่อไฟล์ / Title"
                id="title"
                // disabled={isLoading}
                disabled={mode !== 'add' ? isLoading : false}>
                <InputBase id="title" name="title" value={values.title} onChange={handleChange} fullWidth />
              </FormInput>
            </Grid>
            <Grid item xs={12}>
              <FormInput
                fullWidth
                error={hasError('url')}
                label="ลิงค์ไฟล์ / URL"
                id="url"
                // disabled={isLoading}
                disabled={mode !== 'add' ? isLoading : false}
                >
                <InputBase id="url" name="url" value={values.url} onChange={handleUrlChange} fullWidth />
              </FormInput>
            </Grid>
            <Grid item xs={12}>
              <FormInput
                fullWidth
                label="รายละเอียดเอกสารแนบ / Description"
                id="description"
                //  disabled={isLoading}
                disabled={mode !== 'add' ? isLoading : false}>
                <InputBase
                  id="description"
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                  multiline
                  rows={2}
                  rowsMax={10}
                  fullWidth
                />
              </FormInput>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                // disabled={isLoading}
                disabled={mode !== 'add' ? isLoading : false}
                fullWidth>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.confidential}
                      onChange={handleCheckbox}
                      name="confidential"
                      value="checked"
                    />
                  }
                  label="Confidential"
                />
              </FormControl>
            </Grid>
          </Grid>
          {error && error.response && (
            <Box marginTop={2} marginBottom={2}>
              <Typography style={{ color: 'red', fontWeight: 500 }}>
                {error.response.status === 400 && !error.response.message
                  ? 'กรุณาแนบไฟล์ที่ระบบรองรับดังนี้  txt, png, jpg, pdf, doc, pptx, docx, xls, xlsx, mp4'
                  : 'Bad Request'}
              </Typography>
            </Box>
          )}
        </DialogContent>

        <DialogActions>
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={
              values &&
              mode === 'add' &&
              (values.title === '' || (values.url === '' && (values.file == null || values.file === undefined)))
            }
            isLoading={mode !== 'add' ? isLoading : false}>
            {mode === 'add' ? (
              <React.Fragment>
                <CloudUploadIcon /> Upload
              </React.Fragment>
            ) : (
              <React.Fragment>
                <SaveIcon /> Save
              </React.Fragment>
            )}
          </LoadingButton>

          <LoadingButton
            variant="outlined"
            color="default"
            onClick={handleClose}
            disabled={mode !== 'add' ? isLoading : false}
            //  disabled={isLoading}
          >
            Cancel
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default withMobileDialog()(AddAttachmentDialog);
