export const baseURL = process.env.REACT_APP_BASE_URL;

export const ATTACHMENT_URI = 'attachments';
export const ASSESSMENT_URI = 'assessments';
export const ASSESSMENT_FORM_URI = 'assessment-form';
export const COMPONENT_URI = 'components';
export const COMPONENT_ITEM_URI = 'component-items';
export const LOOKUP_URI = 'lookups';
export const HOME_IMAGE_URI = 'home-images';
export const LINK_URI = 'links';
export const FAQ_URI = 'faqs';
export const AUTH_URI = 'auth';
export const HOMEPAGE_URI = 'homepage';
export const ANNOUNCEMENT_URI = 'announcements';
export const REPORT_CONFIGURATION = 'report-configs';
export const EMPLOYEE = 'employees';
export const MASTER_PROCESS_NAME_URI = 'process-name';
export const MASTER_EMPLOYEE = 'employee-master';
export const MASTER_EMPLOYEE_ROLE = 'employee-role-master';
export const NOTIFICATION_DATE = 'notification-date';

export const fieldNames = {
  component: 'FIELD_Component',
};

export const PROCESS_LEVEL = 'PC';
export const CORPORATE_LEVEL = 'CO';
