import { Assessment, Question, Section } from '../models';
import { client } from './client';
import { ASSESSMENT_URI } from './constants';
import { Sequence } from '../models/assessment-dto';

export async function save(assessment: Assessment, remark?: string) {
  return await client.post(`${ASSESSMENT_URI}`, { ...assessment, remark });
}

export async function update(no: string, assessment: Assessment) {
  return await client.put(`${ASSESSMENT_URI}/${no}`, assessment);
}

export async function getComponents(no: string) {
  return await client.get(`${ASSESSMENT_URI}/${no}/components`);
}

export async function getOneComponent(no: string, componentId: string) {
  return await client.get(`${ASSESSMENT_URI}/${no}/components/${componentId}`);
}

export async function createQuestion(no: string, componentId: string, question: Partial<Question>) {
  return await client.post<{ data: Question }>(`${ASSESSMENT_URI}/${no}/components/${componentId}/questions`, question);
}

export async function createSection(no: string, componentId: string, section: Partial<Section>) {
  return await client.post<{ data: Section }>(`${ASSESSMENT_URI}/${no}/components/${componentId}/sections`, section);
}

export async function updateQuestion(no: string, componentId: string, question: Partial<Question>) {
  const { assessors, choices, ..._question } = question;
  return await client.put<{ data: Question }>(
    `${ASSESSMENT_URI}/${no}/components/${componentId}/questions/${question.id}`,
    _question,
  );
}

export async function updateSection(no: string, componentId: string, section: Partial<Section>) {
  return await client.put<{ data: Section }>(
    `${ASSESSMENT_URI}/${no}/components/${componentId}/sections/${section.id}`,
    section,
  );
}

export async function deleteQuestion(no: string, componentId: string, questionId: string) {
  return await client.delete(`${ASSESSMENT_URI}/${no}/components/${componentId}/questions/${questionId}`);
}

export async function deleteSection(no: string, componentId: string, sectionId: string) {
  return await client.delete(`${ASSESSMENT_URI}/${no}/components/${componentId}/sections/${sectionId}`);
}

export async function sequenceUp(no: string, componentId: string, sequences: Sequence[]) {
  return await client.put(`${ASSESSMENT_URI}/${no}/components/${componentId}/sequences`, sequences);
}
export async function sequenceDown(no: string, componentId: string, sequences: Sequence[]) {
  return await client.put(`${ASSESSMENT_URI}/${no}/components/${componentId}/sequences`, sequences);
}
