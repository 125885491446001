import { client } from './client';

export async function getOne(id: number) {
  return await client.get(`employee-master/${id}`, {});
}

export async function getAll() {
  return await client.get(`employee-master`, {});
}

export async function create(request: any) {
  return await client.post(`employee-master`, request, {});
}

export async function update(id: number, request: any) {
  return await client.put(`employee-master/${id}`, request, {});
}

export async function deleteOne(id: number) {
  return await client.delete(`employee-master/${id}`, {});
}
