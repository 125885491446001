import React from 'react';
import { Typography, Grid, Button } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import useRouter from 'use-react-router';

import BackIcon from '@material-ui/icons/ArrowBackIos';
import HomeIcon from '@material-ui/icons/Home';
import { AdapterLink } from './RouterLink';

const useStyles = makeStyles((theme: Theme) => ({
  grid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '50px',
  },
  text: {
    color: '#A9A9A9',
  },
  backIcon: {
    position: 'relative',
  },
  homeIcon: {
    position: 'relative',
    right: '13px',
  },
  errorIcon: {
    fontSize: '5.8rem',
    position: 'relative',
    top: '10px',
  },
  bottomText: {
    color: '#A9A9A9',
    marginTop: '20px',
    marginLeft: '20px',
    marginRight: '20px',
    marginBottom: '50px',
  },
  button: {
    width: '166px',
  },
}));

export interface ErrorComponentProps {
  status?: string | React.ComponentType;
  title?: string;
  description?: string;
}

const ErrorComponent = ({ status: Status, title, description }: ErrorComponentProps) => {
  const { history } = useRouter();
  const classes = useStyles({});
  return (
    <div>
      <Grid container spacing={5} className={classes.grid}>
        <Grid item lg="auto">
          <Typography variant="h1" component="h2" align="center" className={classes.text}>
            {typeof Status === 'string' ? Status : <Status />}
          </Typography>

          <Typography variant="h4" component="h2" align="center" className={classes.text}>
            {title}
          </Typography>
          <Typography variant="h5" component="h2" align="center" className={classes.bottomText}>
            {description}
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="row" justify="center" alignItems="center" spacing={3}>
        <Grid item>
          <Button variant="contained" color="default" className={classes.button} onClick={history.goBack}>
            <BackIcon className={classes.backIcon} />
            previous page
          </Button>
        </Grid>
        <Grid item>
          <Button variant="outlined" className={classes.button} component={AdapterLink} to={'/'}>
            <HomeIcon className={classes.homeIcon} /> home page
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default ErrorComponent;
