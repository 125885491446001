import { ValidationError } from 'class-validator';
import React, { ReactNode } from 'react';

import { Component, Question, Section } from '../models';
import { useError } from '../context/AssessmentErrorContext';

export interface ErrorMessagesProps {
  components: Component[];
  questions: Question[];
  sections?: Section[];
}

export default function AssessmentEditErrorMessages({  }: ErrorMessagesProps) {
  const { error: errors } = useError();

  console.log(errors);
  if (!errors || errors.length === 0) return <div />;
  if (typeof errors === 'string') return <React.Fragment>{errors}</React.Fragment>;

  return <div>{errors.map(error => ChildrenError(error))}</div>;

  function ChildrenError(error: ValidationError, parent?: ValidationError): ReactNode {
    const Children = () => (
      <React.Fragment>
        {error.children && error.children.length > 0 ? (
          <React.Fragment>
            {error.children.map(childError => (
              <React.Fragment>
                {error.property === 'sections' && (
                  <React.Fragment>
                    <li>หลักการลำดับที่ {childError.value.no || '-'}</li>
                    <ul>{ChildrenError(childError, error)}</ul>
                  </React.Fragment>
                )}
                {error.property === 'questions' && (
                  <React.Fragment>
                    <li>คำถาม ลำดับที่ {childError.value.no || '-'}</li>
                    <ul>{ChildrenError(childError, error)}</ul>
                  </React.Fragment>
                )}

                {error.property === 'components' &&
                  (!parent && (
                    <ul>
                      <li>{childError.value.fullname}</li>
                      <ul>{ChildrenError(childError, error)}</ul>
                    </ul>
                  ))}

                {!['questions', 'sections', 'components'].includes(error.property) &&
                  (childError.children && childError.children.length > 0 ? (
                    <li>{ChildrenError(childError, error)}</li>
                  ) : (
                    <ul>{ChildrenError(childError, error)}</ul>
                  ))}
              </React.Fragment>
            ))}
          </React.Fragment>
        ) : (
          Object.keys(error.constraints).map(key => <li key={key}>{error.constraints[key]}</li>)
        )}
      </React.Fragment>
    );

    return (
      <React.Fragment>
        {!parent ? (
          <ul key={error.property}>
            {error.property === 'information' && <li>ข้อมูล / Information โปรดระบุข้อมูลให้ครบถ้วนดังนี้</li>}
            {error.property === 'question' && <li>รายการคำถาม / Question List โปรดระบุข้อมูลให้ครบถ้วนดังนี้</li>}
            <Children />
          </ul>
        ) : (
          <Children />
        )}
      </React.Fragment>
    );
  }
}
