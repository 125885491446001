import AssessmentIcon from '@material-ui/icons/Assessment';
import useAxios from 'axios-hooks';
import React from 'react';
import useRouter from 'use-react-router';

import { ASSESSMENT_URI } from '../../apis/constants';
import { AssessmentPreview } from '../../components/AssessmentPreview';
import ContentWrapper from '../../components/ContentWrapper';
import { useUserState } from '../../components/context/UserStateContext';
import LoadingContent from '../../components/LoadingContent';
import MainLayout from '../../components/MainLayout';
import PageTitle from '../../components/PageTitle';
import { ScrollTopDown } from '../../components/ScrollTopDown';
import { AssessmentTemplateProvider } from '../../context/AssessmentTemplateProvider';
import AccessDenied from '../AccessDenied';
import NotFoundPage from '../NotFoundPage';

export const PreviewPage = () => {
  const { match } = useRouter();
  const id = match.params['id'];
  const { hasAnyRoles } = useUserState();

  const [{ data: payload = {}, loading, error }] = useAxios(`${ASSESSMENT_URI}/${id}`);

  if (!hasAnyRoles('ADMIN')) return <AccessDenied />;
  if (error) return <NotFoundPage />;
  if (loading || !!!payload) return <LoadingContent />;

  return (
    <AssessmentTemplateProvider data={payload.data}>
      <ScrollTopDown>
        <MainLayout>
          <ContentWrapper>
            <PageTitle title="Assessment Form" icon={AssessmentIcon} />
            <AssessmentPreview />
          </ContentWrapper>
        </MainLayout>
      </ScrollTopDown>
    </AssessmentTemplateProvider>
  );
};
