import React from 'react';
import MainLayout from '../components/MainLayout';
import { Grid, Paper, Box, Divider, CircularProgress } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ContentWrapper from '../components/ContentWrapper';
import bannerImg from '../static/img/Banner-FAQ.png';
import PageBanner from '../components/PageBanner';
import useAxios from 'axios-hooks';
import RichTextView from '../components/Richtext/RichTextView';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  header: {
    color: 'white',
    padding: '2rem',
    backgroundImage: `linear-gradient(to bottom right, #E04700, yellow )`,
    borderRadius: '5px',
    marginBottom: '25px',
  },
  greenBox: {
    height: '3px',
    width: '100%',
    backgroundColor: '#00B1AB',
  },
  divider: {
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  question: {
    padding: '10px',
  },
  answer: {
    padding: '10px',
  },
}));

const FaqPage = () => {
  const classes = useStyles({});

  const [{ data: payload, loading, error }] = useAxios('faqs');
  const { data = [] } = payload || {};

  return (
    <MainLayout>
      <Box className={classes.root}>
        <ContentWrapper>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <PageBanner image={bannerImg} />
            </Grid>
            {loading && <CircularProgress />}
            {!loading &&
              data
                .sort((d1, d2) => d1.sequence - d2.sequence)
                .map(faq => (
                  <Grid item xs={12} key={faq.id}>
                    <Paper>
                      <Grid container spacing={0}>
                        <Grid item xs={12}>
                          <Box className={classes.greenBox} />
                          <Box className={classes.question}>
                            <div style={{ display: 'inline-block', verticalAlign: 'top' }}>คำถาม : </div>
                            {" "}<RichTextView value={faq.question} inline />
                          </Box>
                          <Box className={classes.divider}>
                            <Divider />
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box className={classes.answer}>
                            <div style={{ display: 'inline-block', verticalAlign: 'top' }}>คำตอบ : </div>
                            {" "}<RichTextView value={faq.answer} inline />
                          </Box>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                ))}
          </Grid>
        </ContentWrapper>
      </Box>
    </MainLayout>
  );
};

export default FaqPage;
