import React from 'react';

import { FormControl, FormControlLabel, Box, Checkbox } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { QuestionComponentViewWithChoicesProps } from './props';

const useStyles = makeStyles((theme: Theme) => ({
  checkBox: {
    height: '30px',
    paddingLeft: '10px',
  },
}));

const QuestionCheckboxView = ({
  choices,
  disabled,
  question,
  questionAnswer,
  onChange,
}: QuestionComponentViewWithChoicesProps) => {
  const classes = useStyles({});

  if (!onChange || !question || !question.permission) {
    return (
      <FormControl>
        {choices.map(choice => (
          <Box className={classes.checkBox} key={choice.id}>
            <FormControlLabel
              disabled
              control={<Checkbox name={choice.id.toString()} value={choice.id} color="primary" checked={false} />}
              label={choice.title}
            />
          </Box>
        ))}
      </FormControl>
    );
  }

  return (
    <FormControl>
      {choices.map(choice => (
        <Box className={classes.checkBox} key={choice.id}>
          <FormControlLabel
            disabled={!question.permission.writeable}
            control={
              <Checkbox
                name={choice.id.toString()}
                value={choice.id}
                color="primary"
                onChange={onChange}
                checked={question.permission.readable && questionAnswer.answer.choiceIds.includes(choice.id)}
              />
            }
            label={choice.title}
          />
        </Box>
      ))}
    </FormControl>
  );
};

export { QuestionCheckboxView };

export default QuestionCheckboxView;
