import { Box, Grid } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import React, { memo } from 'react';

import { Question, Section } from '../../models';
import ComponentText from '../ComponentText';
import { FormInput } from '../form';
import TextInput from '../InputBase';
import { AttachmentTablePreview } from '../AttachmentTable';
import QuestionCheckboxView from './QuestionCheckboxView';
import QuestionChoiceView from './QuestionChoiceView';
import QuestionRankingView from './QuestionRankingView';
import QuestionRatingView from './QuestionRatingView';
import QuestionTextView from './QuestionTextView';
import QuestionYesNoView from './QuestionYesNoView';

export type QuestionPreviewProps = {
  question: Question;
  section?: Section;
};

export const QuestionPreview: React.FC<QuestionPreviewProps> = memo(
  ({ question, section }) => {
    return (
      <Box border={0} borderColor="grey.300" paddingLeft={7} paddingRight={1} paddingBottom={1} paddingTop={1}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <ComponentText>
              <Box fontWeight={500} fontSize="1.0rem" color="#02b1a8">
                {(question.no && question.no) || ``} {question.title}
              </Box>
            </ComponentText>
          </Grid>
        </Grid>
        <Box paddingLeft={5}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              {getQuestion(question)}
            </Grid>
            <Grid item xs={12}>
              <FormInput grey id="comment" label="รายละเอียด/คำอธิบาย" required={question.required} disabled>
                <TextInput multiline rows={2} rowsMax={10} name="comment" placeholder={question.comment} />
              </FormInput>
            </Grid>
            {question.requiredCompletedDate && (
              <Grid item xs={12}>
                <KeyboardDatePicker
                  margin="normal"
                  label="วันที่กำหนดแล้วเสร็จ / Completed Date"
                  value={null}
                  format="DD/MM/YYYY"
                  InputLabelProps={{
                    style: {
                      minWidth: 'max-content',
                    },
                  }}
                  InputProps={{
                    style: {
                      marginTop: 40,
                    },
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  onChange={() => {}}
                  disabled
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <AttachmentTablePreview />
            </Grid>
          </Grid>
        </Box>
      </Box>
    );

    function getQuestion(question) {
      switch (question.control) {
        case 'checkbox':
          return (
            <QuestionCheckboxView
              disabled
              question={question}
              choices={question.choices}
              questionAnswer={{ controlType: 'checkbox', answer: { controlType: 'checkbox', choiceIds: [] } }}
            />
          );
        case 'choice':
          return (
            <QuestionChoiceView
              disabled
              question={question}
              choices={question.choices}
              questionAnswer={{ controlType: 'choice', answer: { controlType: 'choice', choiceId: undefined } }}
            />
          );
        case 'yesno':
          return (
            <QuestionYesNoView
              disabled
              question={question}
              questionAnswer={{ controlType: 'yesno', answer: { controlType: 'yesno', yesNo: undefined } }}
            />
          );
        case 'rating':
          return (
            <QuestionRatingView
              disabled
              question={question}
              questionAnswer={{ controlType: 'rating', answer: { controlType: 'rating', rating: undefined } }}
            />
          );
        case 'date':
        case 'ranking':
          return (
            <QuestionRankingView
              disabled
              question={question}
              choices={question.choices}
              questionAnswer={{ controlType: 'ranking', answer: { controlType: 'ranking', choiceIds: [] } }}
            />
          );
        case 'text':
          return (
            <QuestionTextView
              disabled
              question={question}
              questionAnswer={{ controlType: 'text', answer: { controlType: 'text', detail: '' } }}
            />
          );
        default:
      }
    }
  },
  (prev, next) => prev.question.id === next.question.id && prev.question.sectionId === next.question.sectionId,
);
