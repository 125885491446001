import axios, { CancelTokenSource } from 'axios';
import { plainToClass } from 'class-transformer';
import * as qs from 'query-string';
import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import useRouter from 'use-react-router';

import { client } from '../apis';
import { WorklistTask, IPaginationResult } from '../models';
import { extractObjectByKeys } from '../utils/object';

export const WorklistContext = createContext<any>({});

export const WorklistConsumer = ({ children }) => {
  const props = useContext(WorklistContext);

  return <div>{React.cloneElement(children, props)}</div>;
};

const keys = ['status', 'level', 'code', 'processOwnerDepartmentId', 'nullstartDate', 'nullendDate', 'departmentId', 'page', 'limit'];
const defaultFilter = {
  status: 'pending',
  processOwnerDepartmentId: 'all',
  level: 'all',
  code: '',
  startDate: null,
  endDate: null,
  departmentId: 'all',
  page: 1,
  limit: 20,
};

function getParams(search) {
  return search ? extractObjectByKeys(qs.parse(search), keys) : defaultFilter;
}

export const WorklistProvider = ({ children }) => {
  const cancelToken = useRef<CancelTokenSource>(null);
  const { location } = useRouter();
  const [filter, setFilter] = useState(getParams(location.search));
  const [loading, setLoading] = useState(false);
  const [worklist, setWorklist] = useState<IPaginationResult<WorklistTask>>();
  const [meta, setMeta] = useState<any>(null);

  useEffect(() => {
    submit(filter);
  }, []);

  const updateQS = (inputParams = filter) => {
    window.history.replaceState('', '', '?' + qs.stringify(inputParams));
  };

  const submit = async (inputParams = filter) => {
    if (loading) {
      if (cancelToken.current) cancelToken.current.cancel('Operation Cancel');
    }
    setLoading(true);

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    cancelToken.current = source;

    const params = Object.keys(inputParams).reduce((prev, key) => {
      const value = inputParams[key];
      if (inputParams[key] === 'all') {
        return { ...prev, [key]: '' };
      }
      return { ...prev, [key]: value };
    }, {});

    try {
      if (inputParams && (inputParams.page !== undefined || inputParams.page !== null)) {
        inputParams.page = 1
      }

      updateQS(inputParams);
      const { data: payload } = await client.get(`worklists`, { params: params, cancelToken: source.token });
      // const result = payload.data.map(data => plainToClass(WorklistTask, data));

      const paging = {
        ...payload.data,
        items: payload.data.items.map(data => plainToClass(WorklistTask, data)),
      };
      setWorklist(paging);
      setMeta(payload.meta);
      return Promise.resolve();
    } catch (ex) {
      console.error(ex);
    } finally {
      cancelToken.current = null;
      setLoading(false);
    }
  };

  const resetFilter = () => {
    submit(defaultFilter);
    setFilter(defaultFilter);
  };

  const value = {
    filter,
    setFilter,
    worklist,
    setWorklist,
    loading,
    resetFilter,
    submit,
    meta,
  };

  return <WorklistContext.Provider value={value}>{children}</WorklistContext.Provider>;
};

export const useWorklist = () => {
  const context = useContext(WorklistContext);

  const setFilterByKey = (key: string, value: any) => {
    const { filter, setFilter } = context;
    setFilter({ ...filter, [key]: value });
  };

  return {
    ...context,
    setFilterByKey,
  };
};
