import React, { memo } from 'react';

import { FormInput } from '../../form';
import InputText from '../../InputText';

export interface InformationRequestNoProps {
  value: string;
  disabled?: boolean;
}

export const InformationRequestNo: React.FC<InformationRequestNoProps> = memo(
  ({ value, disabled }) => {
    return (
      <FormInput fullWidth label="รหัสการสร้างเอกสาร / Request No." id="request-no" required disabled={disabled}>
        <InputText id="request-no" name="no" value={value} disabled required />
      </FormInput>
    );
  },
  (prev, next) => prev.value === next.value && prev.disabled === next.disabled,
);

export default InformationRequestNo;
