import { Box, Grid, Paper } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';

import ContentWrapper from '../../components/ContentWrapper';
import MainLayout from '../../components/MainLayout';
import { Report4IndividualProvider } from '../../components/report/Report4IndividualContext';
import Report4IndividualFilter from '../../components/report/Report4IndividualFilter';
import { Report4IndividualResult } from '../../components/report/Report4IndividualResult';
import { Report4IndividualTitle } from '../../components/report/Report4IndividualTitle';

const useStyles = makeStyles((theme: Theme) => ({
  //style here
  root: {
    padding: '5px 20px 5px 20px',
  },
  input: {
    padding: 10,
  },
  paper: {
    padding: 0,
  },
  resetButton: { paddingLeft: 10 },
  table: {
    marginTop: 10,
    padding: 10,
  },
}));

export const Report4IndividualPage = () => {
  const classes = useStyles({});
  return (
    <MainLayout>
      <ContentWrapper>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Report4IndividualTitle />
          </Grid>
        </Grid>
        <Report4IndividualProvider>
          <Paper className={classes.paper}>
            <Report4IndividualFilter />
            <Box marginTop={4} padding={3}>
              <Report4IndividualResult />
            </Box>
          </Paper>
        </Report4IndividualProvider>
      </ContentWrapper>
    </MainLayout>
  );
};

export default Report4IndividualPage;
