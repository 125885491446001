import React, { memo } from 'react';

import { Box, Paper, Grid, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { BoxProps } from '@material-ui/core/Box';

interface ComponentWrapperProps {
  children: JSX.Element | JSX.Element[];
  title: string;
  headerBarProps?: BoxProps;
  titleProps?: BoxProps;
}

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontWeight: 600,
    fontSize: '1.3rem',
  },
}));

const ComponentWrapperTitle = memo(
  ({ headerBarProps, titleProps, title }: { headerBarProps: any; titleProps: any; title: string }) => {
    const classes = useStyles({});

    return (
      <Grid item xs={12}>
        <Box
          borderBottom={1}
          borderColor="grey.300"
          bgcolor="#F4FFFF"
          paddingLeft={1}
          paddingRight={1}
          paddingBottom={1}
          paddingTop={1}
          {...headerBarProps}>
          <Typography component="div" className={classes.title}>
            <Box {...titleProps}>{title}</Box>
          </Typography>
        </Box>
      </Grid>
    );
  },
);

const ComponentWrapper = ({ children, title, headerBarProps, titleProps }: ComponentWrapperProps) => {
  return (
    <Box marginTop={3}>
      <Paper square>
        <Grid container spacing={0}>
          <ComponentWrapperTitle headerBarProps={headerBarProps} titleProps={titleProps} title={title} />
          <Grid item xs={12}>
            <Box paddingLeft={2} paddingRight={2} paddingTop={1} paddingBottom={3}>
              {children}
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default ComponentWrapper;
