import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';

import ErrorIcon from '@material-ui/icons/ErrorOutline';
import ErrorComponent from './ErrorComponent';

const useStyles = makeStyles((theme: Theme) => ({
  errorIcon: {
    fontSize: '5.8rem',
    position: 'relative',
    top: '10px',
  },
}));

const NotFound = () => {
  const classes = useStyles({});
  const status = () => (
    <>
      <span>4</span>
      <ErrorIcon className={classes.errorIcon} />
      <span>4</span>
    </>
  );

  return (
    <ErrorComponent title="NOT FOUND" description="Sorry, We can't find the page that you requested." status={status} />
  );
};

export default NotFound;
