import React from 'react';
import { Grid, Checkbox } from '@material-ui/core';
import InputText from '../InputText';
import { QuestionComponentProps } from './props';
import { FormGroup, FormControlLabel, FormInput } from '../form';
import { apiMessages } from '../../siteSettings';

const QuestionText = ({ question, disabled, handleCheck }: QuestionComponentProps) => {
  return (
    <>
      <Grid container direction="row" spacing={1}>
        <Grid item container xs={12}>
          <Grid item xs={12} md={1} />
          <Grid item xs={12} md={8}>
            <FormInput disabled label="คำตอบ / Answer">
              <InputText type="text" id="answer" name="answer" placeholder="คำตอบ" fullWidth />
            </FormInput>
          </Grid>
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={12} md={1} />
          <Grid item xs={12} md={11}>
            <FormInput fullWidth label="เอกสารแนบ" disabled={disabled}>
              <FormGroup>
                <FormControlLabel
                  onChange={handleCheck}
                  control={
                    <Checkbox
                      checked={question.requiredAttachment}
                      id="requiredAttachment"
                      name="requiredAttachment"
                      value="requiredAttachment"
                    />
                  }
                  label={apiMessages.requireAttachment}
                />
              </FormGroup>
            </FormInput>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export { QuestionText };

export default QuestionText;
