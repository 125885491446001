import React from 'react';
import useRouter from 'use-react-router';
import useAxios from 'axios-hooks';

import ContentWrapper from '../../components/ContentWrapper';
import MainLayout from '../../components/MainLayout';
import AssessmentEditForm from '../../components/assessment/AssessmentEditForm';
import { AssessmentProvider } from '../../components/assessment/AssessmentContext';
import NotFoundPage from '../NotFoundPage';
import AccessDenied from '../AccessDenied';
import LoadingContent from '../../components/LoadingContent';
import { useUserState } from '../../components/context/UserStateContext';
import { ASSESSMENT_URI } from '../../apis/constants';
import { ScrollTopDown } from '../../components/ScrollTopDown';
import { AssessmentStepper } from '../../components/AssessmentStepper';
import { AssessmentErrorProvider } from '../../context/AssessmentErrorContext';

const Page = () => {
  const {
    match: { params },
  } = useRouter();
  const { hasAnyRoles } = useUserState();

  const [{ data: payload = {}, loading, error }] = useAxios(`${ASSESSMENT_URI}/${params['id']}`, { useCache: false });

  if (!hasAnyRoles('ADMIN')) return <AccessDenied />;
  if (error) return <NotFoundPage />;
  if (loading || !!!payload) return <LoadingContent />;

  const assessmentData = payload.data;

  return (
    <AssessmentErrorProvider>
      <AssessmentProvider initialValues={assessmentData} mode="edit">
        <ScrollTopDown>
          <MainLayout>
            <ContentWrapper>
              <AssessmentStepper assessment={assessmentData.information} activeStep={0} />
              <AssessmentEditForm />
            </ContentWrapper>
          </MainLayout>
        </ScrollTopDown>
      </AssessmentProvider>
    </AssessmentErrorProvider>
  );
};

export default Page;
