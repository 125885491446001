import { client } from './client';
import { REPORT_CONFIGURATION } from './constants';

export async function getOne(id: number) {
  return await client.get(`${REPORT_CONFIGURATION}/${id}`, {});
}

export async function getAll() {
  return await client.get(`${REPORT_CONFIGURATION}`, {});
}

export async function create(request: any) {
  return await client.post(`${REPORT_CONFIGURATION}`, request, {});
}

export async function update(id: number, request: any) {
  return await client.put(`${REPORT_CONFIGURATION}/${id}`, request, {});
}

export async function deleteOne(id: number) {
  return await client.delete(`${REPORT_CONFIGURATION}/${id}`, {});
}
