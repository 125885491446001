import { createStyles, fade, Theme, withStyles } from '@material-ui/core/styles';
import MuiInputBase from '@material-ui/core/InputBase';

const InputBase = withStyles((theme: Theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(2.5),
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.common.white,
      border: '1px solid #ced4da',
      fontSize: theme.typography.pxToRem(15),
      padding: '6px 8px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&:focus': {
        boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
      '&:disabled': {
        background: theme.palette.grey[100],
        color: theme.palette.common.black,
      },
    },
    error: {
      '&>input': {
        borderColor: theme.palette.error.main,
      },
    },
    multiline: {
      'label + &': {
        marginTop: 14,
      },
    },
  }),
)(MuiInputBase);

export default InputBase;
