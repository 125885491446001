import {
  Box,
  Button,
  Divider,
  Link,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import moment from 'moment';
import React, { useState } from 'react';

import Loading from '../Loading';
import { AdapterLink } from '../RouterLink';
import { useReport1 } from './Report1Context';
import { apiMessages } from '../../siteSettings';

export const Report1ResultTableView = () => {
  const { isProcessing, data = [] } = useReport1();

  if (isProcessing('searching')) {
    return <Loading />;
  }

  if (!data) return null;

  return (
    <Box marginTop={3}>
      <Box border={1} borderColor="grey.300">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center" rowSpan={2}>
                รหัสแบบประเมิน
              </TableCell>
              <TableCell align="center" rowSpan={2}>
                ปี
              </TableCell>
              <TableCell align="center" rowSpan={2}>
                ครั้งที่
              </TableCell>
              <TableCell align="center" colSpan={2}>
                วันที่รับประเมิน
              </TableCell>
              <TableCell align="center" colSpan={2}>
                วันที่ประเมิน
              </TableCell>
              <TableCell align="center" rowSpan={2}>
                สถานะ
              </TableCell>
              <TableCell align="center" rowSpan={2}>
                %
              </TableCell>
              <TableCell align="center" rowSpan={2}>
                ดูรายละเอียด
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">เริ่ม</TableCell>
              <TableCell align="center">สิ้นสุด</TableCell>
              <TableCell align="center">เริ่ม</TableCell>
              <TableCell align="center">สิ้นสุด</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length === 0 && (
              <TableRow>
                <TableCell colSpan={10}>ไม่มีข้อมูล</TableCell>
              </TableRow>
            )}
            {data.map(record => (
              <TableRow key={`${record.code},${record.round},${record.year}`}>
                <TableCell>
                  <Link component={AdapterLink} to={`/worklist/${record.no}/edit`}>
                    {record.code}
                  </Link>
                </TableCell>
                <TableCell align="right">{record.year}</TableCell>
                <TableCell align="right">{record.round}</TableCell>
                <TableCell align="center">{moment(record.startDate).format('DD/MM/YYYY')}</TableCell>
                <TableCell align="center">{moment(record.endDate).format('DD/MM/YYYY')}</TableCell>
                <TableCell align="center">
                  {(record.firstCreatedAtAnswer && moment(record.firstCreatedAtAnswer).format('DD/MM/YYYY')) || '-'}
                </TableCell>
                <TableCell align="center">
                  {(record.lastCreatedAtAnswer && moment(record.lastCreatedAtAnswer).format('DD/MM/YYYY')) || '-'}
                </TableCell>
                <TableCell align="center">{record.currentStatus}</TableCell>
                <TableCell>{`${record.percent}%`}</TableCell>
                <TableCell align="center">
                  <PortalMenu record={record} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};

type PortalMenuProps = {
  record: any;
};

const PortalMenu: React.FC<PortalMenuProps> = ({ record }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const id = `menu-report01-${record.no}`;
  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <React.Fragment>
      <Button
        aria-controls={id}
        aria-haspopup="true"
        onClick={handleOpen}
        variant="outlined"
        color="primary"
        size="small">
        ดูรายละเอียด
      </Button>
      <Menu id={id} anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem dense component={AdapterLink} to={`/worklist/${record.no}/edit`}>
          ใบประเมิน
        </MenuItem>
        <Divider variant="fullWidth" />
        <MenuItem
          dense
          component={AdapterLink}
          to={`/reports/rpt-02?year=${record.year}&level=${record.level}&no=${record.no}`}>
          {apiMessages.report.rpt02}
        </MenuItem>
        <MenuItem
          dense
          component={AdapterLink}
          to={`/reports/rpt-03?year=${record.year}&level=${record.level}&no=${record.no}`}>
          {apiMessages.report.rpt03}
        </MenuItem>
        <MenuItem
          dense
          component={AdapterLink}
          to={`/reports/rpt-04?year=${record.year}&level=${record.level}&no=${record.no}`}>
          {apiMessages.report.rpt04}
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};
