import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import MuiFormControlLabel from '@material-ui/core/FormControlLabel';

const FormControlLabel = withStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'inline-flex',
      alignItems: 'center',
      cursor: 'pointer',
      // For correct alignment with the text.
      verticalAlign: 'middle',
      // Remove grey highlight
      WebkitTapHighlightColor: 'transparent',
      marginLeft: -11,
      marginRight: 16, // used for row presentation of radio/checkbox
      '&$disabled': {
        cursor: 'default',
      },
    },
  }),
)(MuiFormControlLabel);

export { FormControlLabel };

export default FormControlLabel;
