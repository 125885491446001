import { Radio, RadioGroup, FormControlLabel, Box, Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';

import { QuestionYesNoSummarize } from '../../models/admin-summarize';
import ComponentText from '../ComponentText';

export interface QuestionYesNoSummarizeProps {
  questionSummarize: QuestionYesNoSummarize;
  section?: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  radio: {
    height: '30px',
    paddingLeft: '10px',
  },
}));

export const QuestionYesNoSummarizeView = ({ questionSummarize, section }: QuestionYesNoSummarizeProps) => {
  const classes = useStyles({});
  const { question, yesAnswer, noAnswer } = questionSummarize;
  return (
    <Box border={0} borderColor="grey.300" paddingLeft={7} paddingRight={1} paddingBottom={1} paddingTop={1}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <ComponentText>
            <Box fontWeight={500} fontSize="1.0rem" color="#02b1a8">
              {(question.no && question.no) || ``} {question.title}
            </Box>
          </ComponentText>
        </Grid>
      </Grid>
      <Box paddingLeft={5}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <RadioGroup>
              <Box className={classes.radio}>
                <FormControlLabel
                  disabled
                  value="true"
                  control={<Radio color="primary" />}
                  label={`ใช่ / Yes (${yesAnswer.percent * 100.0}%)`}
                />
              </Box>
              <Box className={classes.radio}>
                <FormControlLabel
                  disabled
                  value="false"
                  control={<Radio color="primary" />}
                  label={`ไม่ใช่ / No (${noAnswer.percent * 100.0}%)`}
                />
              </Box>
            </RadioGroup>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
