import { AdminReviewValue } from '../context/AdminReviewContext';
import { client } from './client';

export type ActionBody = {
  remark: string;
};

export type AdminReviewReturn = ActionBody & {
  changedClosingDate: string;
};

export async function getAdminReview(assessmentNo: string, assessorId: string) {
  return await client.get(`assessment-requests/${assessmentNo}/${assessorId}/admin-review`);
}

export async function saveAdminReview(assessmentNo: string, assessorId: string, body: AdminReviewValue) {
  return await client.post(`assessment-requests/${assessmentNo}/${assessorId}/admin-review/save`, body);
}

export async function submitAdminReview(assessmentNo: string, assessorId: string, body: ActionBody) {
  return await client.post(`assessment-requests/${assessmentNo}/${assessorId}/admin-review/submit`, body);
}

export async function returnAdminReview(assessmentNo: string, assessorId: string, body: AdminReviewReturn) {
  return await client.post(`assessment-requests/${assessmentNo}/${assessorId}/admin-review/return`, body);
}
