import { Box, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';

import { QuestionComponentViewWithChoicesProps } from './props';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '10px 0px 20px 0px',
  },
  radio: {
    height: '30px',
    paddingLeft: '10px',
  },
}));

const QuestionChoiceView = ({
  question,
  choices,
  disabled,
  onChange,
  questionAnswer,
}: QuestionComponentViewWithChoicesProps) => {
  const classes = useStyles({});
  if (!onChange || !question || !question.permission) {
    return (
      <RadioGroup>
        {choices.map(choice => (
          <Box className={classes.radio} key={choice.id}>
            {choice.isOther ? (
              <>
                <FormControlLabel disabled value="other" control={<Radio color="primary" />} label={`อื่นๆ `} />
              </>
            ) : (
              <>
                <FormControlLabel
                  disabled
                  value={choice.id || ''}
                  control={<Radio color="primary" />}
                  checked={false}
                  label={choice.title}
                />
              </>
            )}
          </Box>
        ))}
      </RadioGroup>
    );
  }

  return (
    <RadioGroup onChange={onChange}>
      {choices.map(choice => (
        <Box className={classes.radio} key={choice.id}>
          {choice.isOther ? (
            <>
              <FormControlLabel
                disabled={!question.permission.writeable}
                value="other"
                control={<Radio color="primary" />}
                label={`อื่นๆ `}
              />
            </>
          ) : (
            <>
              <FormControlLabel
                disabled={!question.permission.writeable}
                value={choice.id || ''}
                control={<Radio color="primary" />}
                checked={question.permission.readable && choice.id === questionAnswer.answer.choiceId}
                label={choice.title}
              />
            </>
          )}
        </Box>
      ))}
    </RadioGroup>
  );
};

export { QuestionChoiceView };

export default QuestionChoiceView;
