import { Grid, Box, Select, MenuItem } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';

import { FormInput } from '../form';
import InputBase from '../InputBase';
import LoadingButton from '../LoadingButton';
import { useReport5 } from './Report5Context';
import { ReportSearchButton } from './ReportSearchButton';
import { getYearListFrom } from '../../utils/date';

const useStyles = makeStyles((theme: Theme) => ({
  //style here
  root: {
    padding: '5px 20px 5px 20px',
  },
  input: {
    padding: '10px',
  },
  paper: {
    padding: '0px',
  },
  resetButton: { paddingLeft: '10px' },
  table: {
    padding: '10px',
  },
}));

const Report5Filter = () => {
  const classes = useStyles({});
  const {
    assessmentFilter,
    handleAssessmentFilterChange,
    submitFilterForm,
    resetDataFilter,
    isProcessing,
  } = useReport5();

  return (
    <React.Fragment>
      <Grid container direction="row" alignItems="center">
        <Grid item xs={12} sm={12} md={2}>
          <Box className={classes.input}>
            <FormInput fullWidth id="startYear" label="ปีที่เริ่มต้น" required>
              <Select
                onChange={handleAssessmentFilterChange('startYear')}
                input={<InputBase name="startYear" id="startYear" value={assessmentFilter.startYear} />}>
                {getYearListFrom().map(year => (
                  <MenuItem key={year} value={year}>
                    {year + 543}
                  </MenuItem>
                ))}
              </Select>
            </FormInput>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={2}>
          <Box className={classes.input}>
            <FormInput fullWidth id="endYear" label="ปีที่สิ้นสุด" required>
              <Select
                onChange={handleAssessmentFilterChange('endYear')}
                input={<InputBase name="endYear" id="endYear" value={assessmentFilter.endYear} />}>
                {getYearListFrom().map(year => (
                  <MenuItem key={year} value={year}>
                    {year + 543}
                  </MenuItem>
                ))}
              </Select>
            </FormInput>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={2}>
          <Box className={classes.input}>
            <FormInput fullWidth id="level" label="ระดับ / Level" required>
              <Select
                onChange={handleAssessmentFilterChange('level')}
                input={<InputBase name="level" id="level" value={assessmentFilter.level} />}>
                <MenuItem value="CO">องค์กร</MenuItem>
                <MenuItem value="PC">กระบวนการ</MenuItem>
              </Select>
            </FormInput>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={2}>
          <ReportSearchButton
            onClick={submitFilterForm}
            disabled={isProcessing()}
            loading={isProcessing('submitting')}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={12} md={2}>
          <Box className={classes.resetButton}>
            <LoadingButton fullWidth color="secondary" variant="contained" size="small" onClick={resetDataFilter}>
              Reset Filter
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Report5Filter;
