import { Box, Divider, Grid, Link, Typography, Paper } from '@material-ui/core';
import WorkListIcon from '@material-ui/icons/ViewList';
import React, { memo } from 'react';

import { AdapterLink } from '../RouterLink';
import TaskItem from './TaskItem';

export type WorklistHomePageProps = {
  worklist: any[];
};

export const WorklistHomePage = memo(({ worklist = [] }: WorklistHomePageProps) => {
  return (
    <React.Fragment>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Box>
            <Link component={AdapterLink} to="/worklist">
              <Typography variant="h6">
                <WorkListIcon /> WorkList
              </Typography>
            </Link>
            <Box>
              <Divider />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Paper square>
            {worklist.slice(0, 3).map(task => (
              <div key={`${task.information.no}-${task.rootId}`}>
                <TaskItem task={task} showDetails={false} />
                <Divider />
              </div>
            ))}
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
});

export default WorklistHomePage;
