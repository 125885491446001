import React from 'react';
import { Grid, Box, Select, MenuItem } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import InputBase from '../InputBase';
import { FormInput } from '../form';
import { useReport7 } from './Report7Context';
import InputText from '../InputText';
import LoadingButton from '../LoadingButton';
import { getYearListFrom } from '../../utils/date';
import { ReportSearchButton } from './ReportSearchButton';
import ReportProcessOption from './ReportProcessOption';

const useStyles = makeStyles((theme: Theme) => ({
  //style here
  root: {
    padding: '5px 20px 5px 20px',
  },
  input: {
    padding: '10px',
  },
  paper: {
    padding: '0px',
  },
  resetButton: { paddingLeft: '10px' },
  table: {
    padding: '10px',
  },
}));

export const Report7Filter = () => {
  const classes = useStyles({});
  const {
    filter,
    meta,
    assessmentDataFilter,
    setFilter,
    handleAssessmentFilterChange,
    handleAssessmentDataFilterChange,
    submitFilterForm,
    isProcessing,
    searching,
    result = [],
    resetDataFilter,
    hasNoResult,
  } = useReport7();

  const handleCodeChange = e => {
    const no = e.target.value;
    setFilter({
      ...filter,
      no,
    });
  };

  return (
    <>
      <Grid container direction="row" alignItems="center">
        <Grid item xs={12} sm={12} md={2}>
          <Box className={classes.input}>
            <FormInput fullWidth id="year" label="ปี / Year" required>
              <Select
                onChange={handleAssessmentFilterChange('year')}
                input={<InputBase name="year" id="year" value={filter.year} />}>
                {getYearListFrom().map(year => (
                  <MenuItem key={year} value={year}>
                    {year + 543}
                  </MenuItem>
                ))}
              </Select>
            </FormInput>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={2}>
          <Box className={classes.input}>
            <FormInput fullWidth id="level" label="ระดับ / Level" required>
              <Select
                onChange={handleAssessmentFilterChange('level')}
                input={<InputBase name="level" id="level" value={filter.level} />}>
                <MenuItem value="CO">องค์กร</MenuItem>
                <MenuItem value="PC">กระบวนการ</MenuItem>
              </Select>
            </FormInput>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <Box className={classes.input}>
            <ReportProcessOption
              onChange={handleAssessmentFilterChange('processId')}
              value={filter.processId}
              disabled={filter.level !== 'PC' || isProcessing()}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <Box className={classes.input}>
            <FormInput fullWidth id="code" label="รหัสแบบประเมิน / Document Code" required>
              {!result || result.length === 0 ? (
                <InputText disabled value={searching ? 'กำลังโหลด' : 'ไม่มีข้อมูล'} />
              ) : (
                <Select onChange={handleCodeChange} input={<InputBase name="code" id="code" value={filter.no} />}>
                  {result.map(result => (
                    <MenuItem key={result.no} value={result.no}>
                      {result.code} ครั้งที่ {result.round}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </FormInput>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={2}>
          <ReportSearchButton
            onClick={submitFilterForm}
            disabled={(!filter.code && !filter.round) || isProcessing()}
            loading={isProcessing('submitting') || searching}
            boxProps={{ padding: '10px' }}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={12} md={5}>
          <Box className={classes.input}>
            <FormInput fullWidth id="form-componentId" label="องค์ประกอบ / Component" disabled={hasNoResult}>
              <Select
                onChange={handleAssessmentDataFilterChange('componentId')}
                input={<InputBase name="componentId" id="componentId" value={assessmentDataFilter.componentId} />}>
                {meta &&
                  meta.components.map(item => (
                    <MenuItem value={item.id} key={item.id}>
                      {item.text}
                    </MenuItem>
                  ))}
              </Select>
            </FormInput>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <Box className={classes.input}>
            <FormInput
              fullWidth
              id="form-departmentInitialName"
              label="Unit / หน่วนงานผู้ประเมิน"
              disabled={hasNoResult}>
              <Select
                onChange={handleAssessmentDataFilterChange('departmentInitialName')}
                input={
                  <InputBase
                    name="departmentInitialName"
                    id="departmentInitialName"
                    value={assessmentDataFilter.departmentInitialName}
                  />
                }>
                {meta &&
                  meta.departmentInitialNames.map(item => (
                    <MenuItem value={item.id} key={item.id}>
                      {item.text}
                    </MenuItem>
                  ))}
              </Select>
            </FormInput>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={2}>
          <Box className={classes.input}>
            <FormInput fullWidth id="form-sectionId" label="ลำดับหลักการ / Principles Order" disabled={hasNoResult}>
              <Select
                onChange={handleAssessmentDataFilterChange('sectionId')}
                input={
                  <InputBase
                    name="sectionId"
                    id="sectionId"
                    onChange={handleAssessmentDataFilterChange}
                    value={assessmentDataFilter.sectionId}
                  />
                }>
                {meta &&
                  meta.sections.map(item => (
                    <MenuItem value={item.id} key={item.id}>
                      {item.text}
                    </MenuItem>
                  ))}
              </Select>
            </FormInput>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={2}>
          <Box className={classes.input}>
            <FormInput fullWidth id="form-questionId" label="ลำดับคำถาม / Question Order" disabled={hasNoResult}>
              <Select
                onChange={handleAssessmentDataFilterChange('questionId')}
                input={<InputBase name="questionId" id="questionId" value={assessmentDataFilter.questionId} />}>
                {meta &&
                  meta.questions.map(item => (
                    <MenuItem value={item.id} key={item.id}>
                      {item.text}
                    </MenuItem>
                  ))}
              </Select>
            </FormInput>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={2}>
          <Box className={classes.resetButton}>
            <LoadingButton
              fullWidth
              variant="contained"
              color="secondary"
              size="small"
              onClick={resetDataFilter}
              disabled={hasNoResult}>
              Reset Filter
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Report7Filter;
