import React, { useState, memo } from 'react';
import { FormInput } from '../form';
import LoadingButton from '../LoadingButton';
import InputText from '../InputText';
import { client } from '../../apis';
import { useSnackbar } from 'notistack';
import { errorOptions } from '../../utils/snackbar';
import { AssessmentLevel } from '../../models';

export type FetchCountProps = {
  documentCode: string;
  level: AssessmentLevel;
  year: number;
  processId?: number;
};

export const useRoundCount = () => {
  const [roundCount, setRoundCount] = useState<number | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const fetchCountCO = ({ documentCode, level, year }: FetchCountProps) => {
    return client.get(`assessment-stats/code-count/${documentCode}/${level}/${year}`);
  };

  const fetchCountPC = ({ documentCode, level, year, processId }: FetchCountProps) => {
    return client.get(`assessment-stats/code-count/${documentCode}/${level}/${year}/${processId}`);
  };

  const fetchCount = async (fetchCountProps: FetchCountProps) => {
    if (loading) return;
    setLoading(true);
    try {
      const { data: payload } = await (fetchCountProps.processId
        ? fetchCountPC(fetchCountProps)
        : fetchCountCO(fetchCountProps));

      setRoundCount(payload.data);
    } catch (ex) {
      console.error(ex);
      enqueueSnackbar('ไม่สามารถดึงค่ามาได้', errorOptions);
    } finally {
      setLoading(false);
    }
  };

  return {
    roundCount,
    loading,
    fetchCount,
  };
};

export type RoundCountProps = FetchCountProps & {
  disabled?: boolean;
};

const RoundCountComp: React.FC<RoundCountProps> = ({ disabled, documentCode, level, year, processId }) => {
  const { loading, roundCount, fetchCount } = useRoundCount();

  return (
    <FormInput fullWidth label="ตรวจสอบครั้งที่สร้าง" id="round-count" disabled={disabled}>
      <div style={{ paddingTop: 20, display: 'flex' }}>
        <LoadingButton
          disabled={!documentCode || disabled}
          isLoading={loading}
          variant="outlined"
          color="primary"
          size="small"
          onClick={() => fetchCount({ documentCode, level, year, processId })}
          style={{ marginRight: 8 }}>
          ตรวจสอบ
        </LoadingButton>
        <InputText id="round-count-field" name="round-count" value={loading ? '....' : roundCount} disabled />
      </div>
    </FormInput>
  );
};

const MemoRoundCount = memo(RoundCountComp, (prev, next) => {
  return [
    prev.disabled === next.disabled,
    prev.documentCode === next.documentCode,
    prev.level === next.level,
    prev.year === next.year,
    prev.processId === next.processId,
  ].every(_ => _);
});

export const RoundCount = MemoRoundCount;
