import { client } from './client';
import { Choice } from '../models';
import { ASSESSMENT_URI } from './constants';

export async function addChoice(no: string, componentId: string, questionId: string, choice: Choice) {
  return await client.post<{ data: Choice }>(
    `${ASSESSMENT_URI}/${no}/components/${componentId}/questions/${questionId}/choices`,
    choice,
  );
}

export async function updateChoice(no: string, componentId: string, questionId: string, choice: Choice) {
  return await client.put<{ data: Choice }>(
    `${ASSESSMENT_URI}/${no}/components/${componentId}/questions/${questionId}/choices/${choice.id}`,
    choice,
  );
}

export async function deleteChoice(no: string, componentId: string, questionId: string, choiceId: number) {
  return await client.delete(
    `${ASSESSMENT_URI}/${no}/components/${componentId}/questions/${questionId}/choices/${choiceId}`,
  );
}
