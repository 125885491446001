import React from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import { DebugSessionTimeout } from './DebugSessionTimeout';
import ErrorBoundary from './ErrorBoundary';

const MainLayout = (props: { readonly children: JSX.Element | JSX.Element[] }) => {
  return (
    <React.Fragment>
      <div id="top" />
      <Header />
      <Sidebar />
      <ErrorBoundary>{props.children}</ErrorBoundary>
      <div id="bottom" />
      <DebugSessionTimeout />
    </React.Fragment>
  );
};

export default MainLayout;
