import { ResponseValues } from 'axios-hooks';
import { useSnackbar } from 'notistack';
import React, { memo, useState } from 'react';
import useRouter from 'use-react-router';

import { errorOptions, successOptions } from '../../utils/snackbar';
import LoadingButton from '../LoadingButton';
import { client } from '../../apis';
import { useError } from '../../context/AssessmentErrorContext';
import { useLoadingScreen } from '../LoadingScreenProvider';

const useRecall = (assessmentNo: string, remark: string): [ResponseValues<any>, () => void] => {
  const { setError, clearError } = useError();
  const [loading, setLoading] = useState<boolean>(false);
  const { openLoadingScreen, closeLoadingScreen } = useLoadingScreen();
  const { enqueueSnackbar } = useSnackbar();
  const { history } = useRouter();

  const execute = async () => {
    if (loading) return;

    try {
      setLoading(true);
      openLoadingScreen();
      clearError();
      await client.post(`assessments/${assessmentNo}/recall`, { remark });
      enqueueSnackbar(`Recall successfully`, successOptions);
      history.push('/worklist');
    } catch (ex) {
      enqueueSnackbar(ex.message, errorOptions);
      console.error(ex);
      setError(ex.response.data.message);
    } finally {
      setLoading(false);
      closeLoadingScreen();
    }
  };

  return [{ loading, data: undefined }, execute];
};

export type RecallButtonProps = {
  assessmentNo: string;
  remark: string;
};

export const RecallButton: React.FC<RecallButtonProps> = memo(
  ({ assessmentNo, remark }) => {
    const [{ loading }, executePost] = useRecall(assessmentNo, remark);

    const recall = async () => {
      if (window.confirm('คุณต้องการ recall ใบประเมินนี้?')) {
        executePost();
      }
    };

    return (
      <React.Fragment>
        <LoadingButton onClick={() => recall()} isLoading={loading} color="primary" variant="contained">
          Recall
        </LoadingButton>
      </React.Fragment>
    );
  },
  (prev, next) => prev.assessmentNo === next.assessmentNo && prev.remark === next.remark,
);
