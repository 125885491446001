import React from 'react';
import MainLayout from '../components/MainLayout';
import { Typography, Grid, Paper, Box, Divider, CircularProgress } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import ContentWrapper from '../components/ContentWrapper';

import bannerImg from '../static/img/Banner-About-Internal-Control.png';
import PageBanner from '../components/PageBanner';
import useAxios from 'axios-hooks';
import { ANNOUNCEMENT_URI } from '../apis/constants';
import RichTextView from '../components/Richtext/RichTextView';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  header: {
    color: 'white',
    padding: '2rem',
    //backgroundColor: '#F9A000',
    backgroundImage: `linear-gradient(to bottom right, #E04700, yellow )`,
    borderRadius: '5px',
    marginBottom: '25px',
  },
  greenBox: {
    height: '3px',
    width: '100%',
    backgroundColor: '#00B1AB',
  },
  divider: {
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  title: {
    padding: '10px',
  },
  content: {
    padding: '10px 20px 10px 20px',
  },
  image: {
    width: 110,
    height: 110,
    padding: '15px',
    margin: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bannerImg: {
    width: '100%',
    height: '100%',
    maxWidth: '100%',
  },
}));

const AboutPage = () => {
  const classes = useStyles({});

  const [{ data: payload, loading, error }] = useAxios(ANNOUNCEMENT_URI);
  const { data = [] } = payload || {};

  return (
    <MainLayout>
      <Box className={classes.root}>
        <ContentWrapper>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <PageBanner image={bannerImg} />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            {loading && <CircularProgress />}
            {!loading && data.map(content => (
              <Grid item xs={12} key={content.id}>
                <Paper>
                  <Grid container spacing={0}>
                    <Grid item xs={12}>
                      <Box className={classes.greenBox} />
                      <Box className={classes.title}>
                        <Typography variant="h6">{content.title}</Typography>
                      </Box>
                      <Box className={classes.divider}>
                        <Divider />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <img src={`${process.env.REACT_APP_BASE_URL}/static/${content.uri}`} className={classes.image} alt={content.title} />
                    </Grid>
                    <Grid item xs={12} md={10}>
                      <Box className={classes.content}>
                        <RichTextView value={content.detail} />
                      </Box>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </ContentWrapper>
      </Box>
    </MainLayout>
  );
};

export default AboutPage;
