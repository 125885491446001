import { Box, Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import ContentWrapper from '../../components/ContentWrapper';
import MainLayout from '../../components/MainLayout';
import { Report7Provider } from '../../components/report/Report7Context';
import Report7Filter from '../../components/report/Report7Filter';
import { Report7Result } from '../../components/report/Report7Result';
import { Report7Title } from '../../components/report/Report7Title';

const useStyles = makeStyles(() => ({
  paper: {
    padding: 0,
  },
}));

export const Report7Page = () => {
  const classes = useStyles({});

  return (
    <MainLayout>
      <ContentWrapper>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Report7Title />
          </Grid>
        </Grid>
        <Report7Provider>
          <Paper className={classes.paper}>
            <Box padding="10px">
              <Report7Filter />
              <Box marginTop={4} padding={3}>
                <Report7Result />
              </Box>
            </Box>
          </Paper>
        </Report7Provider>
      </ContentWrapper>
    </MainLayout>
  );
};

export default Report7Page;
