import { useSnackbar } from 'notistack';
import React, { useState } from 'react';

import { Box, Grid } from '@material-ui/core';

import * as ChoiceService from '../../apis/choice';
import { Choice, Question } from '../../models';
import { defaultOptions, ERROR_TEXT, errorOptions, successOptions } from '../../utils/snackbar';
import AddAssessorDialog from '../AddAssessorDialog';
import { useAssessmentManager, useAssessorManager, useQuestion, useMoveable } from '../assessment/AssessmentContext';
import AssessorTable from '../AssessorTable';
import { FormInput } from '../form';
import InputText from '../InputText';
import ToolbarComponent from '../ToolbarComponent';
import { useFormValidation } from '../useFormValidation';
import { ComponentProps } from './ComponentProps';
import QuestionCheckbox from './QuestionCheckbox';
import QuestionChoice from './QuestionChoice';
import QuestionDate from './QuestionDate';
import QuestionRanking from './QuestionRanking';
import QuestionRating from './QuestionRating';
import QuestionText from './QuestionText';
import QuestionYesNo from './QuestionYesNo';

export interface QuestionComponentProps extends ComponentProps<Question> {}

const QuestionComponent = ({ data, handleUpdate, handleDelete, ...props }: QuestionComponentProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { copy } = useQuestion(data.id);
  const { moveUp, moveDown } = useMoveable('question', data.id);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { assessment, permissions } = useAssessmentManager();
  const { addAssessor, deleteAssessor, isLoading: assessorLoading } = useAssessorManager();
  const { values, handleChange, handleKeyUp, hasChanged, setHasChanged, setValue } = useFormValidation({
    initialValues: data,
    type: Question,
  });

  const [choiceChange, setChoiceChange] = useState(false);
  const [choices, _setChoices] = useState(data.choices || []);

  const setChoices = (choices: Choice[] = []) => {
    const _choices = choices.sort((a, b) => a.sequence - b.sequence);
    _setChoices(_choices);
  };

  const _handleUpdate = () => {
    if (hasChanged) {
      handleUpdate(values as Question);
      setHasChanged(false);
    }
  };

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.name as any, event.target.checked);
    handleUpdate({ ...(values as Question), [event.target.name]: event.target.checked });
    setHasChanged(false);
  };

  const errorCb = (ex: Error) => {
    console.error(ex);
    enqueueSnackbar(ERROR_TEXT + ': ' + ex.message, { ...errorOptions });
  };

  const finallyCb = (snackbar: React.ReactText) => () => {
    closeSnackbar(snackbar);
    setIsLoading(false);
  };

  const question = values as Question;

  const handleAddChoice = (choice: Choice) => {
    setIsLoading(true);
    const snackbar = enqueueSnackbar('Adding...', { ...defaultOptions });
    return ChoiceService.addChoice(assessment.no, data.componentId, data.id, choice)
      .then(({ data: payload }) => payload.data)
      .then(choice => setChoices([...choices, choice]))
      .then(() => enqueueSnackbar('Added successfully.', successOptions))
      .catch(errorCb)
      .finally(finallyCb(snackbar));
  };

  const handleUpdateChoice = (choice: Choice) => {
    setChoiceChange(true);
    const _choices = choices.filter(c => c.id !== choice.id);
    setChoices([..._choices, choice]);
    return Promise.resolve(choice);
  };

  const handleDeleteChoice = (choiceId: number) => {
    setIsLoading(true);
    const snackbar = enqueueSnackbar('Deleting...', { ...defaultOptions });
    const _choices = choices.filter(c => c.id !== choiceId);
    setChoices(_choices);
    return ChoiceService.deleteChoice(assessment.no, data.componentId, data.id, choiceId)
      .then(() => enqueueSnackbar('Deleted successfully.', successOptions))
      .catch(errorCb)
      .finally(finallyCb(snackbar));
  };

  const _handleBlurChoice = (choice: Choice, byPass?: boolean) => {
    if (choiceChange || byPass) {
      setIsLoading(true);
      const snackbar = enqueueSnackbar('Updating...', { ...defaultOptions });
      return ChoiceService.updateChoice(assessment.no, data.componentId, data.id, choice)
        .then(({ data: payload }) => payload.data)
        .then(() => setChoiceChange(false))
        .then(() => enqueueSnackbar('Save Draft Successfully.', successOptions))
        .catch(errorCb)
        .finally(() => {
          closeSnackbar(snackbar);
          setIsLoading(false);
        });
    }
  };

  const handleRatingChange = (value: number) => {
    setValue('maxRate', value);
    handleUpdate({ ...(values as Question), maxRate: value });
    setHasChanged(false);
  };

  const disabled = !permissions.editable;
  const questionProps = {
    disabled: disabled,
    isLoading: isLoading,
    question: values as Question,
    choices: choices,
    handleChange: handleChange,
    handleKeyUp: handleKeyUp,
    handleCheck: handleCheck,
  };

  const choiceProps = {
    choices: choices,
    handleAddChoice: handleAddChoice,
    handleUpdateChoice: handleUpdateChoice,
    handleDeleteChoice: handleDeleteChoice,
    handleBlurChoice: _handleBlurChoice,
  };

  const getQuestion = () => {
    switch (data.control) {
      case 'checkbox':
        return <QuestionCheckbox {...questionProps} {...choiceProps} />;
      case 'choice':
        return <QuestionChoice {...questionProps} {...choiceProps} />;
      case 'yesno':
        return <QuestionYesNo {...questionProps} handleBlurChoice={_handleBlurChoice} />;
      case 'rating':
        return <QuestionRating {...questionProps} handleRatingChange={handleRatingChange} />;
      case 'date':
        return <QuestionDate {...questionProps} />;
      case 'ranking':
        return <QuestionRanking {...questionProps} {...choiceProps} />;
      case 'text':
      default:
        return <QuestionText {...questionProps} />;
    }
  };

  return (
    <Box {...props}>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12} md={1}>
          <FormInput id="no" label="ลำดับ / No" disabled={disabled}>
            <InputText
              type="text"
              defaultValue={values.no || ''}
              onChange={handleChange}
              id="no"
              name="no"
              placeholder="ลำดับ"
              onKeyUp={handleKeyUp}
              onBlur={() => _handleUpdate()}
              fullWidth
            />
          </FormInput>
        </Grid>
        <Grid item xs={12} md={8}>
          <FormInput id="title" label="คำถาม / Title" disabled={disabled}>
            <InputText
              type="text"
              id="title"
              defaultValue={values.title || ''}
              onChange={handleChange}
              name="title"
              placeholder="คำถาม"
              onKeyUp={handleKeyUp}
              onBlur={() => _handleUpdate()}
              fullWidth
              multiline
              rows={5}
              rowsMax={10}
            />
          </FormInput>
        </Grid>
        <Grid item xs={12} md={3}>
          <ToolbarComponent
            itemId={data.id}
            handleDelete={handleDelete}
            handleCopy={copy}
            handleUp={moveUp}
            handleDown={moveDown}
            disabled={disabled}
            item
          />
        </Grid>
      </Grid>

      {getQuestion()}

      <Grid container direction="row" spacing={2}>
        <Grid item xs={12} md={1} />
        <Grid item xs={12} md={11}>
          <FormInput id="comment" label="กำหนดข้อความ Default ของคำตอบ" disabled={disabled}>
            <InputText
              type="text"
              id="comment"
              name="comment"
              placeholder="ข้อความ Default ของคำตอบ"
              fullWidth
              defaultValue={question.comment || ''}
              onChange={handleChange}
              onBlur={() => _handleUpdate()}
              onKeyUp={handleKeyUp}
              multiline
              rows={2}
              rowsMax={10}
            />
          </FormInput>
        </Grid>
      </Grid>

      <Grid container direction="row" spacing={2}>
        <Grid item xs={12} md={1}>
          {!disabled && (
            <AddAssessorDialog
              assessmentNo={assessment.no}
              isLoading={assessorLoading}
              handleSubmit={addAssessor}
              question={data}
            />
          )}
        </Grid>
        <Grid item xs={12} md={11}>
          <AssessorTable
            disabled={disabled}
            assessorList={(data.assessors && data.assessors.filter(a => a.assessorRole === 'ASSESSOR')) || []}
            componentId={question.componentId}
            questionId={question.id}
            handleDelete={deleteAssessor}
            isLoading={assessorLoading}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export { QuestionComponent };

export default QuestionComponent;
