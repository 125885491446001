import { Box, Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import moment from 'moment';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import KeyboardEventHandler from 'react-keyboard-event-handler';

import AssessmentEditErrorMessages from '../AssessmentEditErrorMessages';
import { FormInput } from '../form';
import InputText from '../InputText';
import LoadingButton from '../LoadingButton';
import { useAssessmentManager } from './AssessmentContext';
import AttachmentFilesSection from './AttachmentSection';
import ComponentWrapper from './ComponentWrapper';
import InformationSection from './InformationSection';
import QuestionListSection from './QuestionListSection';
import { CopyButton } from './CopyButton';
import { RecallButton } from './RecallButton';
import { AssessmentCancelButton, AssessmentDraftCancelButton } from './AssessmentCancelButton';
import { AssessmentPreviewButton } from './AssessmentPreviewButton';
import { useError } from '../../context/AssessmentErrorContext';

import useAxios from 'axios-hooks';
import { ASSESSMENT_URI } from '../../apis/constants';
import useRouter from 'use-react-router';

const EventTable = lazy(() => import('../EventTable'));
const ContentContainer = lazy(() => import('../ContentContainer'));

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    border: `1px solid ${theme.palette.divider}`,
    paddingBottom: theme.spacing(2),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightMedium,
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  formAction: {
    padding: '16px 18px',
  },
  content: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    display: 'block',
  },
}));

export interface AssessmentFormProps {}

const currentYear = moment().year();
const yearOptions = [currentYear, currentYear + 1];

const AssessmentEditForm = () => {
  const classes = useStyles({});
  const { error: errors } = useError();
  const {
    isProcessing,
    saveDraft,
    submitAssessment,
    assessment,
    attachments,
    components,
    questions,
    handleRemarkChange,
    events,
    remark,
  } = useAssessmentManager();

  // const [attr, setAttr] = useState(attachments)

  // const {
  //   match: { params },
  // } = useRouter();

  // const [{ data: payload = {}, loading, error }] = useAxios(`${ASSESSMENT_URI}/${params['id']}`, { useCache: false });

  // useEffect(()=>{
  //   setAttr(payload.Data)
  // }, [payload, attachments])

  const disabled = assessment.currentStatus !== 'Draft';

  const FormActionSection = () => {
    return (
      <Grid container spacing={2} direction="row" justify="center" className={classes.formAction}>
        <Grid item>
          <LoadingButton
            hidden={'Draft' !== assessment.currentStatus}
            isLoading={isProcessing('save-assessment')}
            disabled={
              disabled ||
              ['Submitted', 'Assessment', 'Completed', 'Cancelled'].includes(assessment.currentStatus) ||
              isProcessing('submit-assessment', 'cancel-assessment')
            }
            variant="contained"
            color="primary"
            onClick={() => {
              saveDraft();
            }}>
            Save Draft
          </LoadingButton>
        </Grid>
        <Grid item>
          <LoadingButton
            isLoading={isProcessing('submit-assessment')}
            hidden={'Draft' !== assessment.currentStatus}
            disabled={
              !assessment.no ||
              disabled ||
              ['Submitted', 'Assessment', 'Completed', 'Cancelled', undefined].includes(assessment.currentStatus) ||
              isProcessing('save-assessment', 'cancel-assessment')
            }
            variant="contained"
            color="primary"
            onClick={submitAssessment}>
            Submit
          </LoadingButton>
        </Grid>
        <Grid item hidden={!assessment.no}>
          <AssessmentPreviewButton
            assessmentNo={assessment.no}
            disabled={assessment.currentStatus !== 'Draft' || isProcessing()}
          />
        </Grid>
        <Grid item hidden={'Draft' !== assessment.currentStatus}>
          <AssessmentDraftCancelButton assessmentNo={assessment.no} remark={remark} />
        </Grid>
        <Grid item hidden={'Assessment' !== assessment.currentStatus}>
          <AssessmentCancelButton assessmentNo={assessment.no} remark={remark} />
        </Grid>
        <Grid item hidden={!assessment.no}>
          <CopyButton assessmentNo={assessment.no}>Copy</CopyButton>
        </Grid>
        <Grid item hidden={'Submitted' !== assessment.currentStatus}>
          <RecallButton assessmentNo={assessment.no} remark={remark} />
        </Grid>
      </Grid>
    );
  };

  return (
    <div>
      <div className={classes.root}>
        <KeyboardEventHandler
          handleKeys={['ctrl+s', 'cmd+s']}
          onKeyEvent={(key, e) => {
            e.preventDefault();
            saveDraft();
          }}
        />
        <KeyboardEventHandler
          handleKeys={['ctrl+s', 'cmd+s']}
          onKeyEvent={(key, e) => {
            e.preventDefault();
            saveDraft();
          }}>
          <InformationSection years={yearOptions} />
          <AttachmentFilesSection
            assessment={assessment}
            attachments={attachments}
            expansionProps={{ defaultExpanded: !!assessment.no }}
          />
          <QuestionListSection />

          {['Draft', 'Submitted', 'Assessment'].includes(assessment.currentStatus) && (
            <Box p={2} paddingTop={0}>
              <FormInput fullWidth id="remark" label="หมายเหตุ / Remark">
                <InputText
                  id="remark"
                  name="remark"
                  placeholder="กรอกหมายเหตุที่นี่"
                  onChange={e => handleRemarkChange(e.target.value)}
                  multiline
                  rows={3}
                  rowsMax={10}
                />
              </FormInput>
            </Box>
          )}
        </KeyboardEventHandler>

        {errors && errors.length !== 0 && (
          <Box p={2}>
            <Suspense fallback={null}>
              <ContentContainer error title="โปรดระบุข้อมูลให้ครบถ้วนดังนี้">
                <AssessmentEditErrorMessages components={components} questions={questions} />
              </ContentContainer>
            </Suspense>
          </Box>
        )}
        <FormActionSection />
      </div>
      {events && (
        <Box marginTop={3}>
          <Suspense fallback={null}>
            <ComponentWrapper title="Activity Log">
              <EventTable events={events} />
            </ComponentWrapper>
          </Suspense>
        </Box>
      )}
    </div>
  );
};

export default AssessmentEditForm;
