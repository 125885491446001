import { Box, FormControl, Grid, Tooltip } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';

import { useAdminSummarize } from '../context/AdminSummarizeContext';
import { Component } from '../models';
import { AdminSummarizeComponent } from './AdminSummarizeComponent';
import { useAssessmentManager } from './assessment/AssessmentContext';
import ComponentWrapper from './assessment/ComponentWrapper';
import InformationSectionView from './assessment/InformationSectionView';
import TextInput from './InputBase';
import LoadingButton from './LoadingButton';
import { useAttachment } from './useAttachment';
import AddAttachmentSummarizeDialog from './AddAttachmentSummarizeDialog';
import AttachmentSummarizeTable from './AttachmentSummarizeTable';
import { Button } from './Richtext/components';
import AddIcon  from '@material-ui/icons/Add';
import { onBulkDownload } from '../utils/onBulkDownload';
const useStyles = makeStyles((theme: Theme) => ({
  remarkBox: {
    paddingTop: '0px',
  },
  button: {
    padding: '0px 20px 0px 20px',
    maxWidth: '220px',
    margin: '10px auto 10px auto',
  },
}));

export const AdminSummarizeContainer = () => {
  const classes = useStyles({});
  const {
    components,
    questions,
    sections,
    attachments,
    submit,
    uploadAttachment: uploadFn,
    deleteAttachment,
    updateAttachment,
  } = useAdminSummarize();
  const { assessment, isProcessing, handleRemarkChange } = useAssessmentManager();
  const { uploadAttachment: _uploadAttachment, uploadProgress } = useAttachment();

  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <ComponentWrapper
          headerBarProps={{ bgcolor: '#00b2a9c7' }}
          titleProps={{ color: '#ffffff' }}
          title={'ข้อมูล / Information'}>
          <InformationSectionView information={assessment} />
        </ComponentWrapper>
      </Grid>

      <Grid item xs={12}>
        {components
          .filter(comp => comp.status === 'A')
          .map((comp: Component, index: number) => (
            <AdminSummarizeComponent
              key={comp.id}
              component={comp}
              questions={questions && questions.filter(({ question: q }) => comp.id === q.componentId)}
              sections={sections && sections.filter(section => comp.id === section.componentId)}
            />
          ))}
      </Grid>

      <Grid item xs={12}>
        <Box className={classes.remarkBox}>
          <ComponentWrapper title="แนบไฟล์ / Attachment">
            <AddAttachmentSummarizeDialog
              itemId={assessment.no}
              isLoading={isProcessing('add-attachment')}
              uploadProgress={uploadProgress}
              onSubmit={data => _uploadAttachment(data, 'admin-summarize', uploadFn)}
            />
  {/* <Tooltip title="Download All" aria-label="Download All">
        <Button variant="contained" color="primary" size="small" 
          className={classes.button}
          onClick={()=>{onBulkDownload({files: attachments, assessments: {assessmentNo: assessment.no}})}} 
          >
            <AddIcon fontSize="inherit" />
            Download All
          </Button>
      </Tooltip> */}


            <AttachmentSummarizeTable
              disabled={assessment.currentStatus !== 'Summarize'}
              loading={
                isProcessing('add-attachment') || isProcessing('edit-attachment') || isProcessing('delete-attachment')
              }
              files={attachments}
              handleEdit={updateAttachment}
              handleDelete={deleteAttachment}
            />
          </ComponentWrapper>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box className={classes.remarkBox}>
          <ComponentWrapper title="หมายเหตุ / Remark">
            <FormControl fullWidth>
              <TextInput
                id="remark"
                name="remark"
                placeholder="กรอกหมายเหตุที่นี่"
                onChange={e => handleRemarkChange(e.target.value)}
                multiline
                rows={3}
                rowsMax={10}
              />
            </FormControl>
          </ComponentWrapper>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={0} direction="row" justify="center" alignItems="center">
          <Grid item xs={12} sm={12} md={3}>
            <Box className={classes.button}>
              <LoadingButton variant="contained" color="primary" onClick={submit} fullWidth>
                Submit
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
