import React from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  image: {
    width: '100%',
    maxWidth: '100%',
  },
}));

const PageBanner = ({ image }) => {
  const classes = useStyles({});
  return <img src={image} className={classes.image} alt="Page banner" />;
};

export default PageBanner;
