import React from 'react';
import { Typography } from '@material-ui/core';
import { apiMessages } from '../../siteSettings';

export const Report7Title = () => {
  return (
    <Typography variant="h5" gutterBottom>
      {apiMessages.report.rpt07}
    </Typography>
  );
};

export default Report7Title;
