import { debounce } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { createContext, useContext, useEffect, useState } from 'react';
import useRouter from 'use-react-router';

import * as AdminReviewApi from '../apis/admin-review';
import { useAssessmentManager } from '../components/assessment/AssessmentContext';
import { useAssessmentRequest } from '../components/assessment/AssessmentRequestContext';
import { errorCbTemplate, infoOptions } from '../utils/snackbar';
import * as AuthService from '../apis/auth';

export interface AdminReviewFollowUp {
  followUpStatus?: string;
  planStartDate?: string;
  planCompletedDate?: string;
  actualStartDate?: string;
  actualCompletedDate?: string;
  remark?: string;
}

export interface AdminReviewAssessmentComment extends AdminReviewFollowUp {
  comment: string;
  followUp: boolean;
  readOnly: boolean;
}

export interface AdminReviewQuestionComment extends AdminReviewFollowUp {
  questionId: string;
  comment: string;
  followUp: boolean;
  readOnly: boolean;
}

export const AdminReviewContext = createContext<any>({});

export interface AdminReviewProviderProps {
  children: React.ReactNode;
}

export interface AdminReviewValue {
  questions: AdminReviewQuestionComment[];
  assessment: AdminReviewAssessmentComment;
}

export const AdminReviewProvider = ({ children }: AdminReviewProviderProps) => {
  const { assessmentRequest } = useAssessmentRequest();
  const { setIsProcessing } = useAssessmentManager();
  const { no: assessmentNo } = assessmentRequest.information;
  const { rootId } = assessmentRequest;
  const [questionReviews, setQuestionReviews] = useState<AdminReviewQuestionComment[]>([]);
  const [assessmentReview, setAssessmentReview] = useState<AdminReviewAssessmentComment>(null);

  useEffect(() => {
    setIsProcessing('admin-review-loading', true);
    AdminReviewApi.getAdminReview(assessmentNo, rootId)
      .then(({ data: payload }) => {
        setQuestionReviews(payload.data.questions || []);
        setAssessmentReview(payload.data.assessment || {});
      })
      .finally(() => setIsProcessing('admin-review-loading', false));
  }, [assessmentNo, rootId, assessmentRequest.requestStatus]);

  const value = {
    questionReviews,
    setQuestionReviews,
    assessmentReview,
    setAssessmentReview,
  };

  return <AdminReviewContext.Provider value={value}>{children}</AdminReviewContext.Provider>;
};

export interface AdminReviewContextState {
  questionReviews: AdminReviewQuestionComment[];
  setQuestionReviews: (data: AdminReviewQuestionComment[]) => void;
  assessmentReview: AdminReviewAssessmentComment;
  setAssessmentReview: (data: AdminReviewAssessmentComment) => void;
}

export const useAdminReview = () => {
  return useContext<AdminReviewContextState>(AdminReviewContext);
};

export const useAdminReviewAssessment = () => {
  const { questionReviews, assessmentReview, setAssessmentReview } = useAdminReview();
  const { setIsProcessing, setErrors, remark } = useAssessmentManager();
  const { assessmentRequest } = useAssessmentRequest();
  const { enqueueSnackbar } = useSnackbar();
  const { history } = useRouter();
  const { no: assessmentNo } = assessmentRequest.information;
  const { rootId } = assessmentRequest;
  const errorCb = (err: any) => {
    errorCbTemplate(enqueueSnackbar)(err);
    if (err.response && err.response.status === 400) {
      setErrors(err.response.data.message);
    } else {
      console.error(err);
    }
  };

  const saveData = () => {
    return AdminReviewApi.saveAdminReview(assessmentNo, rootId, {
      questions: questionReviews.map(review => ({
        questionId: review.questionId,
        comment: review.comment,
        readOnly: !!review.readOnly,
        followUp: !!review.followUp,
        followUpStatus: review.followUpStatus,
        planStartDate: review.planStartDate,
        planCompletedDate: review.planCompletedDate,
        actualStartDate: review.actualStartDate,
        actualCompletedDate: review.actualCompletedDate,
        remark: review.remark,
      })),
      assessment: {
        comment: assessmentReview.comment,
        readOnly: !!assessmentReview.readOnly,
        followUp: !!assessmentReview.followUp,
        followUpStatus: assessmentReview.followUpStatus,
        planStartDate: assessmentReview.planStartDate,
        planCompletedDate: assessmentReview.planCompletedDate,
        actualStartDate: assessmentReview.actualStartDate,
        actualCompletedDate: assessmentReview.actualCompletedDate,
        remark: assessmentReview.remark,
      },
    });
  };

  const saveAdminReview = () => {
    setIsProcessing('save-draft-review', true);
    saveData()
      .then(() => enqueueSnackbar('Save Draft Successfully', infoOptions))
      .catch(errorCb)
      .finally(() => {
        setIsProcessing('save-draft-review', false);
        AuthService.refreshToken();
      });
  };

  const submitAdminReview = () => {
    setIsProcessing('submit-review', true);
    saveData()
      .then(() => AdminReviewApi.submitAdminReview(assessmentNo, rootId, { remark }))
      .then(() => enqueueSnackbar('Submit Successfully', infoOptions))
      .then(() => history.push('/worklist'))
      .catch(errorCb)
      .finally(() => {
        setIsProcessing('submit-review', false);
        AuthService.refreshToken();
      });
  };

  const returnAdminReview = (changedClosingDate: string) => {
    setIsProcessing('submit-return', true);
    saveData()
      .then(() => AdminReviewApi.returnAdminReview(assessmentNo, rootId, { remark, changedClosingDate }))
      .then(() => enqueueSnackbar('Return Successfully', infoOptions))
      .then(() => history.push('/worklist'))
      .catch(errorCb)
      .finally(() => {
        setIsProcessing('submit-return', false);
        AuthService.refreshToken();
      });
  };

  const onCommentChange = debounce(value => {
    setAssessmentReview({
      ...assessmentReview,
      comment: value,
    });
  }, 200);

  const onReadOnlyChange = value => {
    setAssessmentReview({
      ...assessmentReview,
      readOnly: value,
    });
  };

  const onFollowUpChange = value => {
    setAssessmentReview({
      ...assessmentReview,
      followUp: value,
    });
  };

  const onFollowUpPopupChange = data => {
    setAssessmentReview({
      ...assessmentReview,
      ...data,
    });
  };

  return {
    saveAdminReview,
    submitAdminReview,
    returnAdminReview,
    onCommentChange,
    onReadOnlyChange,
    onFollowUpChange,
    onFollowUpPopupChange,
  };
};

export const useAdminReviewQuestion = (questionId: string) => {
  const { questionReviews = [], setQuestionReviews } = useAdminReview();

  const setAdminReview = (inputReview: AdminReviewQuestionComment) => {
    const alreadyHas = questionReviews.some(review => review.questionId === questionId);
    const list = questionReviews.map(review => {
      if (questionId === review.questionId) return inputReview;
      return review;
    });
    if (alreadyHas) {
      setQuestionReviews(list);
    } else {
      setQuestionReviews(list.concat(inputReview));
    }

    return inputReview;
  };

  const adminReview = questionReviews.find(review => review.questionId === questionId) || undefined;

  const onCommentChange = debounce(value => {
    setAdminReview({
      ...adminReview,
      questionId: questionId,
      comment: value,
    });
  }, 200);

  const onReadOnlyChange = value => {
    setAdminReview({
      ...adminReview,
      questionId: questionId,
      readOnly: value,
    });
  };

  const onFollowUpChange = value => {
    setAdminReview({
      ...adminReview,
      questionId: questionId,
      followUp: value,
    });
  };

  const onFollowUpPopupChange = data => {
    setAdminReview({
      ...adminReview,
      ...data,
    });
  };

  return {
    adminReview,
    setAdminReview,
    onCommentChange,
    onReadOnlyChange,
    onFollowUpChange,
    onFollowUpPopupChange,
  };
};
