import useAxios from 'axios-hooks';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import MaterialTable, { Column } from 'material-table';

import { defaultOptions, tableIcons } from '../../components/masterdata/constants';
import { TableProps } from '../../components/masterdata/types';

import { MASTER_EMPLOYEE } from '../../apis/constants';
import * as EmployeeService from '../../apis/employee';
import ContentWrapper from '../../components/ContentWrapper';
import MainLayout from '../../components/MainLayout';
import { ERROR_TEXT, errorOptions } from '../../utils/snackbar';
import { Switch } from '@material-ui/core';

const MasterEmployee = () => {
  const [{ data: payload = {} as any, loading, error }, refetch] = useAxios(MASTER_EMPLOYEE, { manual: true });
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    refetch();
  }, []);

  if (error) enqueueSnackbar(ERROR_TEXT, errorOptions);

  return (
    <MainLayout>
      <ContentWrapper>
        <DataTable
          data={payload.data || []}
          isLoading={loading}
          onAdd={handleAdd}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
        />
      </ContentWrapper>
    </MainLayout>
  );

  function handleAdd(data: any) {
    data.step = Number(data.step);
    return EmployeeService.create(data).then(() => refetch());
  }

  function handleUpdate(newData: any, oldData: any) {
    newData.step = Number(newData.step);
    return EmployeeService.update(newData.id, newData).then(() => refetch());
  }
  function handleDelete(data: any) {
    return EmployeeService.deleteOne(data.id).then(() => refetch());
  }
};

const DataTable = ({ data, onAdd, onUpdate, onDelete, isLoading = false }: TableProps<any>) => {
  const tableColumns: Column<any>[] = [
    { title: 'ID', field: 'id', editable: 'always' },
    { title: 'Initial Name', field: 'initialName', editable: 'always' },
    { title: 'First Name', field: 'firstName', editable: 'always' },
    { title: 'Last Name', field: 'lastName', editable: 'always' },
    { title: 'Email', field: 'email', editable: 'always' },
    {
      title: 'hrStatus',
      field: 'hrStatus',
      editable: 'always',
      type: 'boolean',
      editComponent: props => (
        <Switch size="small" checked={props.rowData.hrStatus} onChange={e => props.onChange(e.target.checked)} />
      ),
    },
    { title: 'Step', field: 'step', editable: 'always', type: 'numeric' },
    { title: 'Department', field: 'departmentId', editable: 'always' },
    { title: 'Position', field: 'positionId', editable: 'always' },
    { title: 'Set ID', field: 'setId', editable: 'always' },
  ];

  return (
    <MaterialTable
      columns={tableColumns}
      data={data}
      title={'Master Employee'}
      icons={tableIcons}
      isLoading={isLoading}
      options={defaultOptions}
      editable={{
        onRowAdd: newData => onAdd(newData),
        onRowUpdate: (newData, oldData) => onUpdate(newData, oldData),
        onRowDelete: oldData => onDelete(oldData),
      }}
    />
  );
};

export default MasterEmployee;
