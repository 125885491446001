import { Box, Divider, Grid, MenuItem, Paper, Select, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';

import { useWorklist } from '../../context';
import { FormInput } from '../form';
import InputBase from '../InputBase';

const useStyles = makeStyles((theme: Theme) => ({
  fiterPaper: {
    padding: '12px',
  },
  datePicker: {
    margin: '8px 6px 8px 6px',
  },
  checkBoxGroup: {
    paddingTop: '11px',
    marginTop: '10px',
    marginBottom: '15px',
  },
  checkBox: {
    padding: '2px',
    marginLeft: '20px',
  },
  group: {
    margin: theme.spacing(1, 0),
  },
}));

const WorklistFilter = () => {
  const classes = useStyles({});
  const { setFilterByKey, filter, meta } = useWorklist();

  return (
    <>
      <Paper className={classes.fiterPaper}>
        <Typography variant="h6">Filter</Typography>
        <Box marginBottom={1}>
          <Divider />
        </Box>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormInput fullWidth id="form-level" label="ระดับ / Level" required>
              <Select
                onChange={(e: any) => {
                  setFilterByKey('level', e.target.value);
                }}
                input={<InputBase name="level" id="level" value={filter.level} />}>
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="CO">ระดับองค์กร</MenuItem>
                <MenuItem value="PC">ระดับกระบวนการ</MenuItem>
              </Select>
            </FormInput>
          </Grid>
          <Grid item xs={12}>
            <FormInput fullWidth id="form-processUnits" label="หน่วยงานกระบวนการ/Unit of Process">
              <Select
                onChange={(e: any) => {
                  setFilterByKey('processOwnerDepartmentId', e.target.value);
                }}
                input={
                  <InputBase
                    name="processOwnerDepartmentId"
                    id="processOwnerDepartmentId"
                    value={filter.processOwnerDepartmentId}
                  />
                }>
                <MenuItem value="all">All</MenuItem>
                {meta &&
                  meta.processDepartments.map(item => (
                    <MenuItem value={item.processOwnerDepartmentId} key={item.processOwnerDepartmentId}>
                      {item.processOwnerDepartmentInitialName}
                    </MenuItem>
                  ))}
              </Select>
            </FormInput>
          </Grid>
          <Grid item xs={12}>
            <FormInput fullWidth id="form-units" label="หน่วยงาน / Unit">
              <Select
                onChange={(e: any) => {
                  setFilterByKey('departmentId', e.target.value);
                }}
                input={<InputBase name="departmentId" id="departmentId" value={filter.departmentId} />}>
                <MenuItem value="all">All</MenuItem>
                {meta &&
                  meta.departments.map(item => (
                    <MenuItem value={item.assessorDepartmentId} key={item.assessorDepartmentId}>
                      {item.assessorDepartmentInitialName}
                    </MenuItem>
                  ))}
              </Select>
            </FormInput>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default WorklistFilter;
