import { Chip, Grid, Link, ListItem, Typography, Avatar } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import uniqBy from 'lodash/uniqBy';
import moment from 'moment';
import React, { memo } from 'react';

import { WorklistProgressBar } from '.';
import { WorklistTask } from '../../models';
import { AdapterLink } from '../RouterLink';
import { TaskActivity } from './TaskActivityCard';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  activity: {
    padding: 0,
  },
  progressSection: {
    paddingTop: '0.415rem',
  },
  chip: {},
  startDate: {
    display: 'inline-block',
  },
  endDate: {
    display: 'inline-block',
  },
  dueDate: {
    paddingTop: '2.5rem',
  },
  responsible: {
    marginRight: theme.spacing(1),
  },
  workListTitle: {
    fontSize: '1.07rem',
    paddingTop: 5,
  },
  avatar: {
    color: 'inherit',
    backgroundColor: '#c43c00',
    paddingLeft: 4,
    paddingRight: 4,
    height: 36,
    width: 36,
  },
}));

export type TaskItemProps = {
  task?: WorklistTask;
  showDetails?: boolean;
};

const TaskItem = memo(({ showDetails = true, task }: TaskItemProps) => {
  const classes = useStyles({});
  const { information, assessors, activityName } = task;
  const root = task.rootId && assessors.find(a => a.assessorRole === 'ASSESSOR');

  const ProgressCard = () => {
    const now = moment();
    const sMoment = moment(information.startDate);
    const eMoment = moment(information.endDate);
    const sDate = sMoment.format('DD/MM/YYYY');
    const eDate = eMoment.format('DD/MM/YYYY');

    const totalDays = eMoment.diff(sMoment, 'days');
    const _pastDays = now.diff(sMoment, 'days');
    const pastDays = _pastDays < 0 ? 0 : _pastDays;

    return (
      <div>
        <Grid container direction="row" alignItems="center" justify="space-between" className={classes.dueDate}>
          <Grid item>
            <Typography className={classes.startDate}>{sDate}</Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.endDate}>{eDate}</Typography>
          </Grid>
        </Grid>
        <div className={classes.progressSection}>
          <WorklistProgressBar totalDays={totalDays} pastDays={pastDays} activityName={activityName} />
        </div>
      </div>
    );
  };

  const Details = () => {
    return <Typography gutterBottom>Level: ระดับ{information.level === 'CO' ? 'องค์กร' : `กระบวนการ (${information.processOwnerDepartmentInitialName})`}</Typography>;
  };

  const linkTo = () => {
    if (!task.rootId && information.currentStatus === 'Summarize') {
      return `/worklist/${information.no}/summarize`;
    }
    return `/worklist/${information.no}/${task.rootId || 'edit'}`;
  };

  return (
    <ListItem className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={8} md={8} lg={8}>
          <div>
            <Chip label={information.code} className={classes.chip} color="primary" />{' '}
            {task.rootId && (
              <Chip
                label={`${root.departmentInitialName} - ${task.rootId}`}
                className={classes.chip}
                color="secondary"
                avatar={<Avatar className={classes.avatar}>{root.employeeInitialName}</Avatar>}
              />
            )}
            {!task.rootId && <Chip label="Template" className={classes.chip} color="secondary" />}
          </div>
          <div>
            <Link component={AdapterLink} to={linkTo()}>
              <Typography className={classes.workListTitle}>
                {information.title} ประจำปี {information.year}/{information.round}
              </Typography>
            </Link>
          </div>
          {showDetails && <Details />}
          {task.rootId && task.activityName === 'Assessment' && (
            <Typography component="div" gutterBottom>
              Waiting For :{' '}
              {assessors &&
                uniqBy(
                  assessors.filter(assessor => assessor.isAssigned && assessor.currentStatus === 'Assessment'),
                  'employeeId',
                )
                  .sort((a, b) => a.sortPriority - b.sortPriority)
                  .map(assessor => (
                    <Chip
                      key={`${assessor.questionId}-${assessor.employeeId}`}
                      label={assessor.employeeInitialName}
                      className={classes.responsible}
                      size="small"
                    />
                  ))}
            </Typography>
          )}
          {task.rootId && task.activityName === 'Admin Review' && (
            <Typography component="div" gutterBottom>
              Waiting For : Admin
            </Typography>
          )}
          {!task.rootId && information.currentStatus === 'Draft' && (
            <Typography component="div" gutterBottom>
              Waiting For : Admin
            </Typography>
          )}
          {!task.rootId && information.currentStatus === 'Submitted' && (
            <Typography component="div" gutterBottom>
              Waiting For : System Start
            </Typography>
          )}
          {!task.rootId && information.currentStatus === 'Assessment' && (
            <Typography component="div" gutterBottom>
              Waiting For :
              {assessors &&
                uniqBy(
                  assessors.filter(assessor => assessor.isAssigned && assessor.currentStatus === 'Assessment'),
                  'employeeId',
                )
                  .filter(assessor => assessor.assessorRole === 'ASSESSOR')
                  .sort((a, b) => a.sortPriority - b.sortPriority)
                  .map(assessor => (
                    <Chip
                      key={`${assessor.questionId}-${assessor.employeeId}`}
                      label={assessor.employeeInitialName}
                      className={classes.responsible}
                      size="small"
                    />
                  ))}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4} style={{ padding: 0 }}>
          <Grid item container style={{ height: '100%' }}>
            <Grid item sm={6} md={8} style={{ paddingRight: 24 }}>
              <ProgressCard />
            </Grid>
            <Grid item sm={6} md={4}>
              <TaskActivity status={task.activityName} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ListItem>
  );
});

export default TaskItem;
