import { Grid, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import uniqBy from 'lodash/uniqBy';
import React, { useCallback, useContext } from 'react';

import { Question } from '../models';
import { ComponentManager } from '../models/aggregator';
import { AssessmentRequestContext, useAssessmentRequest } from './assessment/AssessmentRequestContext';
import { ComponentSummary } from './ComponentSummary';
import { useUserState } from './context/UserStateContext';
import { FormInput } from './form';
import TextInput from './InputBase';
import { QuestionView } from './question';
import QuestionViewNoWriteable from './question/QuestionViewNoWriteable';
import SectionView from './SectionView';

import { flatMap } from '../utils/array';

const useStyles = makeStyles((theme: Theme) => ({
  commentatorSection: {
    padding: '8px !important',
    paddingRight: '0 !important',
    paddingLeft: '0 !important',
    margin: '0',
  },
  headerBar: {
    backgroundColor: 'rgb(249, 145, 57)',
    height: 48,
    padding: '0 24px',
  },
  heading: {
    color: 'rgb(255, 255, 255)',
    fontSize: '1.3rem',
    flexBasis: '60%',
    flexShrink: 0,
  },
  headingSecondary: {
    fontSize: theme.typography.pxToRem(15),
    color: 'rgb(255, 255, 255)',
    flexBasis: '20%',
    paddingTop: 4,
    flexShrink: 0,
  },
}));

const ComponentBox = ({
  component,
  sections = [],
  questions = [],
}: {
  component: any;
  sections: any[];
  questions: Question[];
}) => {
  const classes = useStyles({});
  const componentManager = new ComponentManager(questions, sections);
  const { user, hasAnyRoles } = useUserState();
  const { addCommentator, removeCommentator, hasAnyActions, getRootId } = useAssessmentRequest();
  const { comments = [], setComments } = useContext(AssessmentRequestContext);
  const isAssessor = questions.some(q =>
    q.assessors.some(a => a.assessorRole === 'ASSESSOR' && a.employeeId === user.id),
  );
  const isPreparer2nd = questions.some(q =>
    q.assessors.some(a => a.assessorRole === 'PREPARER_2ND' && a.employeeId === user.id),
  );
  const isPreparer1st = questions.some(q =>
    q.assessors.some(a => a.assessorRole === 'PREPARER_1ST' && a.employeeId === user.id),
  );
  const isCommentator = questions.some(q => q.assessors.some(a => a.commentator && a.employeeId === user.id));
  const componentComment = comments.find(c => c.componentId === component.id) || {
    componentId: component.id,
    comment: '',
  };

  const onCommentatorChange = useCallback(
    (assessor, checked) => {
      if (checked) {
        addCommentator(component.id, assessor.questionId, assessor.employeeId);
      } else {
        removeCommentator(component.id, assessor.questionId, assessor.employeeId);
      }
    },
    [component.id],
  );

  const onCommentChange = e => {
    const _comments = comments.filter(c => c.componentId !== component.id);
    const comment = e.target.value;
    setComments([..._comments, { ...componentComment, comment }]);
  };

  const commentatorList = uniqBy(
    flatMap(questions.filter(q => q.permission.readable), q => q.assessors).filter(a => a.employeeId !== user.id),
    assessor => assessor && assessor.employeeId,
  );

  return (
    <ExpansionPanel>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-label="Expand"
        id={`component-${component.id}`}
        classes={{ root: classes.headerBar }}>
        <Typography className={classes.heading}>{component.fullname}</Typography>
        <Typography className={classes.headingSecondary}>
          จำนวนข้อที่ต้องประเมิน:{' '}
          {
            componentManager.getTodoQuestionsByComponentId(
              component.id,
              hasAnyRoles('ADMIN', 'AUDIT') ? getRootId() : user.id,
            ).length
          }
        </Typography>
        <Typography className={classes.headingSecondary}>
          จำนวนข้อทั้งหมด: {componentManager.getQuestionByComponentId(component.id).length}
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Grid container spacing={0}>
          {componentManager.getItems().map(item => {
            if (item instanceof Question)
              return (
                <Grid item xs={12} key={item.id}>
                  {item.permission.readable ? (
                    <QuestionView question={item} />
                  ) : (
                    <QuestionViewNoWriteable question={item} />
                  )}
                </Grid>
              );

            return (
              <Grid item xs={12} key={item.id}>
                <SectionView
                  collapse
                  section={item}
                  totalQuestion={componentManager.getQuestionBySectionId(item.id).length}
                  totalTodoQuestion={
                    componentManager.getTodoQuestionsBySectionId(
                      item.id,
                      hasAnyRoles('ADMIN', 'AUDIT') ? getRootId() : user.id,
                    ).length
                  }>
                  <Grid container direction="row">
                    {componentManager.getQuestionBySectionId(item.id).map(question => (
                      <Grid item xs={12} key={question.id}>
                        {question.permission.readable ? (
                          <QuestionView question={question} section={item} />
                        ) : (
                          <QuestionViewNoWriteable question={question} section={item} />
                        )}
                      </Grid>
                    ))}
                  </Grid>
                </SectionView>
              </Grid>
            );
          })}

          {[hasAnyRoles('ADMIN', 'AUDIT'), isAssessor, isCommentator].includes(true) && (
            <Grid item xs={12}>
              <FormInput
                grey
                id="commentCommentator"
                disabled={!hasAnyActions('ASSESSMENT_SUBMIT')}
                label="สรุปความคิดเห็น (เพื่อเป็นข้อมูลสำหรับจัดทำรายงานการควบคุมภายใน เปิดเผยในรายงานประจำปี)">
                <TextInput
                  multiline
                  rows={3}
                  rowsMax={10}
                  name="commentCommentator"
                  defaultValue={componentComment.comment}
                  onChange={onCommentChange}
                />
              </FormInput>
            </Grid>
          )}

          {(isAssessor || isCommentator) && !isPreparer2nd && !isPreparer1st && (
            <Grid item xs={12}>
              <ComponentSummary list={commentatorList} onChange={onCommentatorChange} disabled={!hasAnyActions} />
            </Grid>
          )}
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default ComponentBox;
