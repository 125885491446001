import React, { useEffect, CSSProperties, HTMLAttributes } from 'react';
import Select from 'react-select';
import { ValueContainerProps } from 'react-select/src/components/containers';
import { ControlProps } from 'react-select/src/components/Control';
import { MenuProps, NoticeProps } from 'react-select/src/components/Menu';
import { OptionProps } from 'react-select/src/components/Option';
import { PlaceholderProps } from 'react-select/src/components/Placeholder';
import { SingleValueProps } from 'react-select/src/components/SingleValue';
import { ValueType, ActionMeta } from 'react-select/src/types';

import { MenuItem, Paper, Typography } from '@material-ui/core';
import { createStyles, useTheme, makeStyles, Theme } from '@material-ui/core/styles';
import { BaseTextFieldProps } from '@material-ui/core/TextField';

import InputText from './InputText';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      display: 'flex',
    },
    valueContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      flex: 1,
      alignItems: 'center',
      overflow: 'hidden',
    },
    noOptionsMessage: {
      padding: theme.spacing(1, 2),
    },
    singleValue: {
      fontSize: 12,
    },
    placeholder: {
      position: 'absolute',
      left: 2,
      bottom: 6,
      fontSize: 12,
    },
    paper: {
      position: 'absolute',
      zIndex: 1,
      // marginTop: theme.spacing(1),
      left: 0,
      right: 0,
    },
    divider: {
      height: theme.spacing(2),
    },
  }),
);

export interface OptionType {
  label: string;
  value: string;
  description?: string;
}

export interface AutocompleteSelectProps {
  options: {
    label: string;
    value: string;
  }[];
  onChange?: (value: ValueType<OptionType>, actionMeta: ActionMeta) => void;
  name?: string;
  value?: string;
  disabled?: boolean;
  isLoading?: boolean;
}

function NoOptionsMessage(props: NoticeProps<OptionType>) {
  return (
    <Typography color="textSecondary" className={props.selectProps.classes.noOptionsMessage} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

type InputComponentProps = Pick<BaseTextFieldProps, 'inputRef'> & HTMLAttributes<HTMLDivElement>;

function inputComponent({ inputRef, ...props }: InputComponentProps) {
  return <div ref={inputRef} {...props} />;
}

function Control(props: ControlProps<OptionType>) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps },
  } = props;

  return (
    <InputText
      fullWidth
      style={{ marginTop: 20 }}
      inputComponent={inputComponent}
      inputProps={{
        className: classes.input,
        ref: innerRef,
        children,
        ...innerProps,
      }}
      {...TextFieldProps}
    />
  );
}

function Placeholder(props: PlaceholderProps<OptionType>) {
  return (
    <Typography color="textSecondary" className={props.selectProps.classes.placeholder} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function SingleValue(props: SingleValueProps<OptionType>) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}{' '}
      <span style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: 10, marginLeft: 20 }} hidden={!props.data.description}>
        ({props.data.description})
      </span>
    </Typography>
  );
}

function ValueContainer(props: ValueContainerProps<OptionType>) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function Menu(props: MenuProps<OptionType>) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

function Option(props: OptionProps<OptionType>) {
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontSize: 13,
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}>
      {props.children}
      <span style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: 10, marginLeft: 20 }} hidden={!props.data.description}>
        ({props.data.description})
      </span>
    </MenuItem>
  );
}

const components = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

const AutocompleteSelect = ({ options, name, value, onChange, disabled, isLoading }: AutocompleteSelectProps) => {
  const classes = useStyles({});
  const theme = useTheme();
  const [single, setSingle] = React.useState<ValueType<OptionType>>(null);

  useEffect(() => {
    const _value = options.find(option => option.value === value);
    setSingle(_value);
  }, [options, value]);

  const selectStyles = {
    input: (base: CSSProperties) => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
  };

  function handleChangeSingle(value: ValueType<OptionType>, actionMeta) {
    setSingle(value);

    if (onChange) onChange(value, actionMeta);
  }

  return (
    <Select
      isLoading={isLoading}
      isDisabled={disabled}
      classes={classes}
      styles={selectStyles}
      inputId="react-select-single"
      options={options}
      components={components}
      value={single}
      name={name}
      onChange={handleChangeSingle}
    />
  );
};

export default AutocompleteSelect;
