import React, { useContext, useState, useEffect } from 'react';
import FileSaver from 'file-saver';

import { client } from '../../apis';
import { useIsProcessing } from '../useIsProcessing';
import { mimetype } from '../../utils/mimetype';

const Report6Context = React.createContext(null);

export const Report6Provider = ({ children }) => {
  const { isProcessing, setIsProcessing } = useIsProcessing();
  const [result, setResult] = useState<any>();
  const [assessmentFilter, setAssessmentFilter] = useState({
    year: '',
    level: undefined,
    name: '',
    no: '',
  });

  const hofHandleChange = (getter: any, setter: any) => (name: string) => (e: any) => {
    setter({
      ...getter,
      [name]: e.target.value,
    });
  };

  const handleAssessmentFilterChange = hofHandleChange(assessmentFilter, setAssessmentFilter);

  const resetDataFilter = () => {
    setAssessmentFilter({
      year: '',
      level: undefined,
      name: '',
      no: '',
    });

    searchReport6();
  };

  const searchReport6 = () => {
    setIsProcessing('searching', true);
    setResult(null);

    client
      .get(`reports/rpt-06`, { params: assessmentFilter })
      .then(({ data: payload }) => {
        setResult(payload.data);
      })
      .catch(ex => {
        console.log(ex.response);
        console.error(ex);
        setResult(null);
      })
      .finally(() => setIsProcessing('searching', false));
  };

  useEffect(() => {
    searchReport6();
  }, []);

  const submitFilterForm = () => {
    searchReport6();
  };

  const exportExcel = () => {
    setIsProcessing('exporting', true);
    let url = `reports/rpt-06/excel`
    // if(q.year) {
    //    url = `reports/rpt-06/excel?year=${q.year}`
    // }
    client
      .get(url, { params: assessmentFilter,responseType: 'blob' })
      .then(response => {
        const { filename } = response.headers;

        (window as any).$ = response;

        const blob = new Blob([response.data], {
          type: mimetype.excel,
        });
        FileSaver.saveAs(blob, decodeURIComponent(filename));
      })
      .finally(() => setIsProcessing('exporting', false));
  };

  const value = {
    assessmentFilter,
    setAssessmentFilter,
    handleAssessmentFilterChange,
    submitFilterForm,
    exportExcel,
    isProcessing,
    setIsProcessing,
    result,
    resetDataFilter,
  };

  return <Report6Context.Provider value={value}>{children}</Report6Context.Provider>;
};

export interface Report6Values {
  assessmentFilter: any;
  setAssessmentFilter: any;
  handleAssessmentFilterChange: any;
  submitFilterForm: () => void;
  exportExcel: () => void;
  isProcessing: (...keys: string[]) => boolean;
  setIsProcessing: (key: string, flag: boolean) => void;
  result: any;
  resetDataFilter: () => void;
}

export const useReport6 = () => {
  return useContext<Report6Values>(Report6Context);
};
