import React from 'react';
import { ExpansionPanel, ExpansionPanelSummary } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ExpansionPanelProps } from '@material-ui/core/ExpansionPanel';
import { ExpansionPanelSummaryProps } from '@material-ui/core/ExpansionPanelSummary';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightMedium,
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expansionPanel: {
    boxShadow: 'none',
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  panelSummary: {
    '&:hover:not($disabled)': {
      cursor: 'pointer',
      color: theme.palette.primary.main,
    },
  },
}));

export const AssessmentPanel = (props: ExpansionPanelProps) => {
  const classes = useStyles({});
  const { children, ...restProps } = props;

  return (
    <ExpansionPanel
      defaultExpanded={true}
      square
      classes={{
        root: classes.expansionPanel,
      }}
      {...restProps}>
      {children}
    </ExpansionPanel>
  );
};

export const AssessmentPanelSummary = (props: ExpansionPanelSummaryProps) => {
  const classes = useStyles({});
  const { children, ...restProps } = props;

  return (
    <ExpansionPanelSummary
      expandIcon={<ExpandMoreIcon />}
      classes={{ content: classes.content, root: classes.panelSummary }}
      {...restProps}>
      {children}
    </ExpansionPanelSummary>
  );
};
