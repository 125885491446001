import { Grid } from '@material-ui/core';
import React from 'react';

import { useAssessmentTemplate } from '../context/AssessmentTemplateProvider';
import { AssessmentInformation } from './assessment/AssessmentInformationPreview';
import ComponentWrapper from './assessment/ComponentWrapper';
import { AssessmentComponentPreview } from './assessment/AssessmentComponentPreview';

export const AssessmentPreview = () => {
  const { information } = useAssessmentTemplate();
  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <ComponentWrapper
          headerBarProps={{ bgcolor: '#00b2a9c7' }}
          titleProps={{ color: '#ffffff' }}
          title={'ข้อมูล / Information'}>
          <AssessmentInformation information={information} />
        </ComponentWrapper>
      </Grid>
      <Grid item xs={12}>
        <AssessmentComponentPreview />
      </Grid>
    </Grid>
  );
};
