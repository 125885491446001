import React from 'react';
import ReactGA from 'react-ga';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Action, Location } from 'history';

class Analytics extends React.Component<RouteComponentProps, {}> {
  constructor(props: any) {
    super(props);

    // TODO: Pass the userId into gaOptions
    const defaultOptions = {
      gaOptions: {},
    };

    if (['ecsa.bangchak.co.th'].includes(window.location.hostname)) {
      ReactGA.initialize('UA-151180205-1', defaultOptions);
    } else {
      ReactGA.initialize('UA-151180205-2', { debug: true, ...defaultOptions });
    }

    this.sendPageView = this.sendPageView.bind(this);
  }

  componentDidMount() {
    this.sendPageView(this.props.location);
    this.props.history.listen(this.sendPageView);
  }

  componentWillReceiveProps(nextProps: RouteComponentProps) {
    const currentPage = this.props.location.pathname;
    const nextPage = nextProps.location.pathname;

    if (currentPage !== nextPage) {
      this.sendPageView(nextProps.location);
    }
  }

  sendPageView(location: Location, action?: Action) {
    let path = location.pathname;

    // ReactGA.set({ page: path, userId: this.getUserId() });
    ReactGA.set({ page: path });
    ReactGA.pageview(path);
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(Analytics);
