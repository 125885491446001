/**
 * เช็คสิทธิ์การแก้ไข
 * ถ้า assessment.currentStatus === 'DRAFT' และ เป็น Admin -> return true
 * ถ้า ['Completed', 'Reviewed'].includes(assessment.currentStatus) return false
 * ถ้า assessmentRequest.requestStatus !== 'Assessment' (รวมกรณี Submitted, Reviewed) return false
 *
 * ถ้า currentAssessor.assessorRole === 'ASSESSOR' || file.createdBy === user.idreturn true
 */
import { Box, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DeleteIcon from '@material-ui/icons/Delete';
import moment from 'moment';
import React, { memo, useState, useEffect } from 'react';
import { baseURL } from '../apis/constants';
import { AttachmentFile } from '../models';
import { apiMessages } from '../siteSettings';
import AddAttachmentDialog from './AddAttachmentDialog';
import { useAssessmentManager, useAssessorManager } from './assessment/AssessmentContext';
import { useAssessmentRequest } from './assessment/AssessmentRequestContext';
import CheckOrUncheckIcon from './CheckOrUncheckIcon';
import { useUserState } from './context/UserStateContext';

interface AttachmentTableProps {
  files?: AttachmentFile[];
  loading?: boolean;
  handleDelete?: (attachmentId: number) => Promise<any>;
  handleEdit?: (attachmentId: number, attachment: AttachmentFile) => Promise<any>;
  disabled?: boolean;
}

const AttachmentTable = ({
  files = [],
  loading,
  handleEdit,
  handleDelete: _handleDelete,
  disabled,
}: AttachmentTableProps) => {
  const { assessment, setAttachments } = useAssessmentManager();
  const { assessmentRequest } = useAssessmentRequest();
  const { getCurrentUserAsAssessor } = useAssessorManager();
  const { user, hasAnyRoles } = useUserState();
  const theme = useTheme();


  const completed =
    ['Completed'].includes(assessment.currentStatus) ||
    ['Submitted', 'Reviewed'].includes(assessmentRequest && assessmentRequest.requestStatus);

  const hasPermissionToDelete = file => {
    if (['Submitted', 'Reviewed'].includes(assessmentRequest && assessmentRequest.requestStatus) && hasAnyRoles('ADMIN') && file.folder === 'admin') {
      return true
    }

    if (completed) return false

    if (['Draft', 'Summarize'].includes(assessment.currentStatus)) {
      return true;
    }
    
    const currentAssessor = getCurrentUserAsAssessor();
    if (!currentAssessor) return false;
    if (currentAssessor.assessorRole === 'ASSESSOR' || file.createdBy === user.id) {
      return true;
    }
    return false;
  };
  
  const [fileState, setFileState] = useState([])

  useEffect(()=>{
    const indexedAttachments = files.map((attachment, index) => ({
      ...attachment,
      key: index + 1// Creating keys like item_1, item_2, ...
    }));
    setFileState(indexedAttachments)
  }, [files])

 
  function handleDelete(attachmentId: number) {
    if (window.confirm(apiMessages.confirmPopup)) {
      _handleDelete(attachmentId);
    }
  }
  

  return (
    <Box border={1} borderBottom={0} borderColor={theme.palette.divider}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell align="right">No</TableCell>
            <TableCell align="left">
              ชื่อเอกสาร/
              <br />
              Document Name
            </TableCell>
            <TableCell align="left">รายละเอียดเอกสาร/Description</TableCell>
            <TableCell align="center">
              เอกสารลับ/
              <br />
              Confidential
            </TableCell>
            <TableCell align="left">
              ผู้แนบเอกสาร/
              <br />
              Attached By
            </TableCell>
            <TableCell align="left">
              วันที่แนบเอกสาร/
              <br />
              Attached Date
            </TableCell>
            <TableCell align="right" style={{ width: '17%' }} />
          </TableRow>
        </TableHead>
        <TableBody>
          {fileState.map((file, index) => (
            <TableRowData
              key={file.id}
              file={file}
              no={file.key}
              disabled={!hasPermissionToDelete(file) || disabled}
              handleEdit={handleEdit}
              // handleDelete={handleDelete}
              handleDelete={() => handleDelete(file.id)}
            />
          ))}
          {fileState.length === 0 && (
            <TableRow>
              <TableCell colSpan={7}>ไม่มีข้อมูล</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Box>
  );
};

const AttachmentTitle = memo(({ attachment }: { attachment }) => {
  if (attachment.type === 'file') return attachment.title;
  if (!attachment.url || attachment.url.length === 0) return attachment.title;

  const url = attachment.url.includes('http') ? attachment.url : `//${attachment.url}`;

  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      {attachment.title}
    </a>
  );
});

const onDownload = (file: any) => {
  if (!file || !file.title) {
    console.error("Invalid file or file title");
    return;
  }

  const hrefURLFile = `${baseURL}/static/${file.uri ? file.uri : `${file.bucket}/${file.fileName}`}`;
  const xhr = new XMLHttpRequest();
  const a = document.createElement('a');

  xhr.open('GET', hrefURLFile, true);
  xhr.responseType = 'blob';
  xhr.onload = function () {
    if (xhr.status === 200) {
      const blob = new Blob([xhr.response], { type: 'application/octet-stream' });
      a.href = window.URL.createObjectURL(blob);
      a.download = file.title;
      document.body.appendChild(a); // Append to body to ensure it works in all browsers
      a.click();
      document.body.removeChild(a); // Clean up after click
    } else {
      console.error("Failed to download file");
    }
  };
  xhr.send();
};



const TableRowData = memo(
  ({
    file,
    no,
    disabled,
    handleEdit,
    handleDelete,
  }: {
    file: any;
    no: any;
    disabled: any;
    handleEdit: any;
    handleDelete: any;
  }) => {
    return (
      <TableRow>
        <TableCell align="right">{no}</TableCell>
        <TableCell align="left" scope="row">
          <AttachmentTitle attachment={file} />
        </TableCell>
        <TableCell align="left">{file.description || '-'}</TableCell>
        <TableCell align="center">
          <CheckOrUncheckIcon value={file.confidential} />
        </TableCell>
        <TableCell align="left">{file.fullnameWithInitialName}</TableCell>
        <TableCell align="left">
          {(file.createdAt && moment(new Date(file.createdAt)).format('DD/MM/YYYY')) || '-'}
        </TableCell>
        <TableCell align="right">
          <Tooltip title="Download File" aria-label="download file">
            <IconButton
              color="primary"
              size="medium"
              aria-label="Download"
              component="a"
              onClick={()=>{onDownload(file)}}
              // href={`${baseURL}/static/${file.uri ? file.uri : `${file.bucket}/${file.fileName}`}`}
              // download={file.title}
              disabled={file.type === 'url'}
              target="_blank">
              <CloudDownloadIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
          <AddAttachmentDialog
            mode="edit"
            attachment={file}
            onSubmit={data => handleEdit(file.id, data as any)}
            disabled={disabled}
          />
          <Tooltip title="Delete File" aria-label="Delete file">
            <IconButton
              color="primary"
              aria-label="Delete"
              size="medium"
              disabled={disabled}
              onClick={() => handleDelete(file.id)}>
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
    );
  },
  (prev, next) =>
    prev.file.id === next.file.id &&
    prev.file.title === next.file.title &&
    prev.file.description === next.file.description &&
    prev.file.confidential === next.file.confidential &&
    prev.file.url === next.file.url && 
    prev.file.key === next.file.key,
);

export default AttachmentTable;

export const AttachmentTablePreview = () => {
  const theme = useTheme();
  return (
 
    <Box border={1} borderBottom={0} borderColor={theme.palette.divider}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell align="right">No</TableCell>
            <TableCell align="left">
              ชื่อเอกสาร/
              <br />
              Document Name
            </TableCell>
            <TableCell align="left">รายละเอียดเอกสาร/Description</TableCell>
            <TableCell align="center">
              เอกสารลับ/
              <br />
              Confidential
            </TableCell>
            <TableCell align="left">
              ผู้แนบเอกสาร/
              <br />
              Attached By
            </TableCell>
            <TableCell align="left">
              วันที่แนบเอกสาร/
              <br />
              Attached Date
            </TableCell>
            <TableCell align="right" style={{ width: '17%' }} />
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell colSpan={7}>ไม่มีข้อมูล</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>

  );
};
