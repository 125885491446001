import React from 'react';
import ComponentWrapper from './assessment/ComponentWrapper';
import { Grid } from '@material-ui/core';
import { QuestionYesNoSummarizeView } from './question/QuestionYesNoSummarize';
import { AdminSummarizeComponentManager } from '../models/aggregator';
import { Section } from '../models';
import SectionView from './SectionView';

export const AdminSummarizeComponent = ({ component, questions, sections }) => {
  const componentManager = new AdminSummarizeComponentManager(questions, sections);

  return (
    <ComponentWrapper
      title={component.fullname}
      key={component.id}
      headerBarProps={{ bgcolor: '#f99139' }}
      titleProps={{ fontSize: '1.3rem', color: '#ffffff' }}>
      <Grid container spacing={0}>
        {componentManager.getItems().map(item => {
          if (item instanceof Section) {
            const section = item;
            return (
              <Grid item xs={12} key={section.id}>
                <SectionView section={section}>
                  <Grid container direction="row">
                    {componentManager.getQuestionBySectionId(section.id).map(summarize => (
                      <Grid item xs={12} key={summarize.question.id}>
                        <QuestionYesNoSummarizeView questionSummarize={summarize} section={section} />
                      </Grid>
                    ))}
                  </Grid>
                </SectionView>
              </Grid>
            );
          }
          const summarize = item;
          return (
            <Grid item xs={12} key={summarize.question.id}>
              <QuestionYesNoSummarizeView questionSummarize={summarize} />
            </Grid>
          );
        })}
        {/* {componentManager.getSections().map(section => {
          return (
            <Grid item xs={12} key={section.id}>
              <SectionView section={section}>
                <Grid container direction="row">
                  {componentManager.getQuestionBySectionId(section.id).map(summarize => (
                    <Grid item xs={12} key={summarize.question.id}>
                      <QuestionYesNoSummarizeView questionSummarize={summarize} section={section} />
                    </Grid>
                  ))}
                </Grid>
              </SectionView>
            </Grid>
          );
        })} */}
      </Grid>
    </ComponentWrapper>
  );
};
