import { Checkbox, Grid } from '@material-ui/core';
import React from 'react';

import { apiMessages } from '../../siteSettings';
import { FormControlLabel, FormGroup, FormInput } from '../form';
import { QuestionComponentWithChoicesProps } from './props';

const QuestionYesNo = ({
  question,
  choices,
  handleChange,
  handleKeyUp,
  handleCheck,
  disabled,
}: QuestionComponentWithChoicesProps) => {
  return (
    <>
      <Grid container direction="row" spacing={1}>
        <Grid item container xs={12}>
          <Grid item xs={12} md={1} />
          <Grid item xs={12} md={5}>
            <FormInput fullWidth label="ใช่ / Yes" disabled={disabled}>
              <FormGroup>
                <FormControlLabel
                  onChange={handleCheck}
                  control={
                    <Checkbox
                      checked={!!question.requiredComment}
                      id="requiredComment"
                      name="requiredComment"
                      value="requiredComment"
                    />
                  }
                  label={apiMessages.requireComment}
                />
                <FormControlLabel
                  onChange={handleCheck}
                  control={
                    <Checkbox
                      checked={!!question.requiredAttachment}
                      id="requiredAttachment"
                      name="requiredAttachment"
                      value="requiredAttachment"
                    />
                  }
                  label={apiMessages.requireAttachment}
                />
              </FormGroup>
            </FormInput>
          </Grid>
          <Grid item xs={12} md={5}>
            <FormInput fullWidth label="ไม่ใช่ / No" disabled={disabled}>
              <FormGroup>
                <FormControlLabel
                  onChange={handleCheck}
                  control={
                    <Checkbox
                      checked={!!question.noRequiredComment}
                      id="noRequiredComment"
                      name="noRequiredComment"
                      value="noRequiredComment"
                    />
                  }
                  label={apiMessages.requireComment}
                />
                <FormControlLabel
                  onChange={handleCheck}
                  control={
                    <Checkbox
                      checked={!!question.noRequiredAttachment}
                      id="noRequiredAttachment"
                      name="noRequiredAttachment"
                      value="noRequiredAttachment"
                    />
                  }
                  label={apiMessages.requireAttachment}
                />
              </FormGroup>
            </FormInput>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={12} md={1} />
            <Grid item xs={12} md={11}>
              <FormGroup>
                <FormControlLabel
                  disabled={disabled}
                  onChange={handleCheck}
                  control={
                    <Checkbox
                      checked={!!question.requiredCompletedDate}
                      id="requiredCompletedDate"
                      name="requiredCompletedDate"
                      value="requiredCompletedDate"
                    />
                  }
                  label="วันที่กำหนดแล้วเสร็จ"
                />
              </FormGroup>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export { QuestionYesNo };

export default QuestionYesNo;
