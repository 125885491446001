import React, { createContext, useContext, useState, useRef } from 'react';
import IdleTimer from 'react-idle-timer';
import moment from 'moment';

import * as _LocalStorage from '../apis/LocalStorageService';
import { useInterval } from '../components/useInterval';
import siteSettings from '../siteSettings';
import { useUserState } from '../components/context/UserStateContext';

export const AlertSessionTimeoutContext = createContext(null);

export type SessionState = 'Active' | 'Idle' | 'Timeout';

export const AlertSessionTimeoutProvider = ({ children }) => {
  const [state, setState] = useState<SessionState>('Active');
  const [open, setOpen] = useState(false);
  const { logoutWithoutRedirect } = useUserState();
  const [expiredTime, setExpiredTime] = useState('');
  const idleTimer = useRef<IdleTimer>();

  const resetTimer = () => {
    idleTimer.current.reset();
    setState('Active');
  };

  const resumeTimer = () => {
    idleTimer.current.resume();
  };

  const pauseTimer = () => {
    idleTimer.current.pause();
  };

  const validateSession = () => {
    const almostExpiredIn = siteSettings.cautionSessionTimeout;
    if (_LocalStorage.isTokenExpiredIn() && open) {
      setState('Timeout');
      if (_LocalStorage.hasAccessToken()) {
        logoutWithoutRedirect();
      }
    } else if (_LocalStorage.isTokenExpiredIn(almostExpiredIn) && !open) {
      setOpen(true);
    } else if (!_LocalStorage.isTokenExpiredIn(almostExpiredIn)) {
      const exp = moment.unix(_LocalStorage.getPayload().exp);
      setExpiredTime(exp.format('YYYY-MM-DD HH:mm:ss'));
      resetTimer();
      setOpen(false);
    }
  };

  useInterval(() => {
    validateSession();
  }, 5000);

  const onIdle = () => {};

  const onActive = () => {};

  const onAction = () => {};

  const value = {
    open,
    setOpen,
    state,
    expiredTime,
    idleTimer,
    resetTimer,
    resumeTimer,
    pauseTimer,
  };
  return (
    <AlertSessionTimeoutContext.Provider value={value}>
      <IdleTimer
        ref={ref => {
          idleTimer.current = ref;
        }}
        element={document}
        onActive={onActive}
        onIdle={onIdle}
        onAction={onAction}
        debounce={1000}
        timeout={siteSettings.cautionSessionTimeout}>
        {children}
      </IdleTimer>
    </AlertSessionTimeoutContext.Provider>
  );
};

export interface SessionTimeoutProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  reset: () => void;
  expiredTime: string;
  state: SessionState;
  resetTimer: () => void;
  resumeTimer: () => void;
  pauseTimer: () => void;
}

export const useSessionTimeout = () => {
  return useContext<SessionTimeoutProps>(AlertSessionTimeoutContext);
};
