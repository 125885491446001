import React from 'react';
import { Typography, Grid, Paper, Box, Link } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import MainLayout from '../../components/MainLayout';
import ContentWrapper from '../../components/ContentWrapper';
import { useUserState } from '../../components/context/UserStateContext';
import PageBanner from '../../components/PageBanner';
import { AdapterLink } from '../../components/RouterLink';
import { apiMessages } from '../../siteSettings';
import bannerImg from '../../static/img/Banner-Report.png';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  header: {
    color: 'white',
    padding: '2rem',
    backgroundImage: `linear-gradient(to bottom right, #00B1AB, yellow )`,
    borderRadius: '5px',
    marginBottom: '25px',
  },
  greenBox: {
    height: '3px',
    width: '100%',
    backgroundColor: '#00B1AB',
  },
  report: {},
  reportName: {
    padding: '10px',
  },
}));

const ReportPage = () => {
  const classes = useStyles({});
  const { hasAnyRoles } = useUserState();
  const reports = [
    { id: 1, name: apiMessages.report.rpt01, link: 'reports/rpt-01', roles: ['ADMIN', 'AUDIT'] },
    { id: 2, name: apiMessages.report.rpt02, link: 'reports/rpt-02', roles: ['ADMIN', 'AUDIT'] },
    { id: 3, name: apiMessages.report.rpt03, link: 'reports/rpt-03', roles: ['ADMIN'] },
    { id: 4, name: apiMessages.report.rpt04, link: 'reports/rpt-04', roles: ['ADMIN'] },
    { id: 5, name: apiMessages.report.rpt05, link: 'reports/rpt-05', roles: ['ADMIN'] },
    { id: 6, name: apiMessages.report.rpt06, link: 'reports/rpt-06', roles: ['ADMIN'] },
    { id: 7, name: apiMessages.report.rpt07, link: 'reports/rpt-07', roles: ['ADMIN'] },
    { id: 8, name: apiMessages.report.rpt08, link: 'reports/rpt-08', roles: ['ADMIN'] },
  ];

  return (
    <MainLayout>
      <Box>
        <ContentWrapper>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <PageBanner image={bannerImg} />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            {reports.map(
              report =>
                hasAnyRoles(...report.roles) && (
                  <Grid item xs={12} key={report.id}>
                    <Paper>
                      <Grid container spacing={0}>
                        <Grid item xs={12}>
                          <Link component={AdapterLink} to={report.link}>
                            <Box className={classes.greenBox} />
                            <Box className={classes.reportName}>
                              <Typography variant="body1">
                                {report.id}. {report.name}
                              </Typography>
                            </Box>
                          </Link>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                ),
            )}
          </Grid>
        </ContentWrapper>
      </Box>
    </MainLayout>
  );
};

export default ReportPage;
