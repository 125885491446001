import React, { memo, useCallback } from 'react';
import InputText from './InputText';
import { Box, Grid, FormControlLabel, Checkbox } from '@material-ui/core';
import AdminReviewFollowUpPopup from './AdminReviewFollowUpPopup';
import {
  AdminReviewAssessmentComment,
  AdminReviewQuestionComment,
  useAdminReview,
  useAdminReviewAssessment,
  useAdminReviewQuestion,
} from '../context/AdminReviewContext';
import RoleVisible from './assessment/RoleVisible';
import { FormInput } from './form';
import { useUserState } from './context/UserStateContext';
import ComponentWrapper from './assessment/ComponentWrapper';

export interface AdminReviewBaseProps {
  disabled?: boolean;
  loading?: boolean;
  entity?: AdminReviewAssessmentComment | AdminReviewQuestionComment;
  ableToComment?: boolean;
  ableToFollowUp?: boolean;
}

export interface AdminReviewInputBoxProps extends AdminReviewBaseProps {
  handleComment?: (e: any) => void;
  handleReadOnly?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  handleFollowUp?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  handleFollowUpPopup?: (data: any) => void;
}

export const AdminReviewInputBox: React.FC<AdminReviewInputBoxProps> = memo((props: AdminReviewInputBoxProps) => {
  const {
    loading,
    disabled,
    handleComment,
    handleReadOnly,
    handleFollowUp,
    handleFollowUpPopup,
    ableToComment,
    ableToFollowUp,
    entity,
  } = props;
  const { hasAnyRoles } = useUserState();
  if (loading || disabled) return <AdminReviewReadonly entity={entity} ableToFollowUp={ableToFollowUp} />;
  const { comment = '', readOnly = false, followUp = false } = entity || {};

  return (
    <Grid container>
      <Grid item md={12}>
        <FormInput grey id="admin-review-input" label="ความเห็นจาก Admin">
          <Box marginTop={2}>
            <InputText
              disabled={!hasAnyRoles('ADMIN') || disabled || !ableToComment}
              fullWidth
              multiline
              rows={2}
              rowsMax={10}
              defaultValue={comment}
              name="comment"
              onChange={e => handleComment(e)}
            />
          </Box>
        </FormInput>
      </Grid>
      <RoleVisible adminOnly>
        <Grid item xs={12} sm={12} md={12}>
          <FormControlLabel
            disabled={!ableToFollowUp}
            control={<Checkbox value="followUp" checked={followUp} onChange={handleFollowUp} />}
            label="Follow Up"
          />
          <FormControlLabel
            control={
              <Checkbox value="readOnly" disabled={!ableToComment} checked={readOnly} onChange={handleReadOnly} />
            }
            label="Admin Read Only"
          />
        </Grid>
        {followUp && (
          <Grid item md={12}>
            <AdminReviewFollowUpPopup entity={entity} handleSubmit={handleFollowUpPopup} />
          </Grid>
        )}
      </RoleVisible>
    </Grid>
  );
});

const AdminReviewReadonly = memo(
  ({
    entity,
    ableToFollowUp,
  }: {
    entity?: AdminReviewAssessmentComment | AdminReviewQuestionComment;
    ableToFollowUp?: boolean;
  }) => {
    const { comment = '', readOnly = false, followUp = false } = entity || {};
    return (
      <Grid container>
        <Grid item md={12}>
          <FormInput grey id="admin-review-input" label="ความเห็นจาก Admin">
            <Box marginTop={2}>
              <InputText disabled fullWidth multiline rows={2} rowsMax={10} value={comment || ''} name="comment" />
            </Box>
          </FormInput>
        </Grid>
        <RoleVisible adminOnly>
          <Grid item xs={12} sm={12} md={12}>
            <FormControlLabel
              disabled={!ableToFollowUp}
              control={<Checkbox value="followUp" checked={followUp} />}
              label="Follow Up"
            />
            <FormControlLabel
              disabled
              control={<Checkbox value="readOnly" checked={readOnly} />}
              label="Admin Read Only"
            />
          </Grid>
          {followUp && (
            <Grid item md={12}>
              <AdminReviewFollowUpPopup entity={entity} disabled={!ableToFollowUp} />
            </Grid>
          )}
        </RoleVisible>
      </Grid>
    );
  },
);

export const AdminReviewAssessmentInputBox = memo(
  ({
    loading,
    disabled,
    ableToFollowUp,
    ableToComment,
  }: {
    loading?: boolean;
    disabled?: boolean;
    ableToFollowUp?: boolean;
    ableToComment?: boolean;
  }) => {
    const { assessmentReview } = useAdminReview();
    const { onCommentChange, onReadOnlyChange, onFollowUpChange, onFollowUpPopupChange } = useAdminReviewAssessment();

    const comment = useCallback(e => onCommentChange(e.target.value), [onCommentChange]);
    const readonly = useCallback(e => onReadOnlyChange(e.target.checked), [onReadOnlyChange]);
    const followup = useCallback(e => onFollowUpChange(e.target.checked), [onFollowUpChange]);
    const popup = useCallback(e => onFollowUpPopupChange(e), [onFollowUpPopupChange]);

    return (
      <ComponentWrapper title="สรุปการประเมินจาก Admin">
        <AdminReviewInputBox
          ableToFollowUp={ableToFollowUp}
          ableToComment={ableToComment}
          loading={loading}
          disabled={disabled}
          handleComment={comment}
          handleReadOnly={readonly}
          handleFollowUp={followup}
          handleFollowUpPopup={popup}
          entity={assessmentReview}
        />
      </ComponentWrapper>
    );
  },
  (prev, next) => prev.disabled === next.disabled && prev.loading === next.loading,
);

export const AdminReviewQuestionInputBox = memo(
  ({
    loading,
    disabled,
    questionId,
    ableToFollowUp,
    ableToComment,
  }: {
    loading?: boolean;
    disabled?: boolean;
    questionId: any;
    ableToFollowUp?: boolean;
    ableToComment?: boolean;
  }) => {
    const {
      adminReview,
      onCommentChange,
      onReadOnlyChange,
      onFollowUpChange,
      onFollowUpPopupChange,
    } = useAdminReviewQuestion(questionId);

    const comment = useCallback(e => onCommentChange(e.target.value), [onCommentChange]);
    const readonly = useCallback(e => onReadOnlyChange(e.target.checked), [onReadOnlyChange]);
    const followup = useCallback(e => onFollowUpChange(e.target.checked), [onFollowUpChange]);
    const popup = useCallback(e => onFollowUpPopupChange(e), [onFollowUpPopupChange]);

    return (
      <AdminReviewInputBox
        loading={loading}
        disabled={disabled}
        handleComment={comment}
        handleReadOnly={readonly}
        handleFollowUp={followup}
        handleFollowUpPopup={popup}
        ableToFollowUp={ableToFollowUp}
        ableToComment={ableToComment}
        entity={adminReview}
      />
    );
  },
  (prev, next) =>
    prev.disabled === next.disabled && prev.loading === next.loading && prev.questionId === next.questionId,
);
