import MaterialTable, { Column } from 'material-table';
import React from 'react';
import { head } from 'lodash'

import { HomeImage } from '../../models';
import { defaultOptions, tableIcons } from './constants';
import { TableProps } from './types';
import { ColumnImage } from './ColumnImage';

const HomeTable = ({ data, onAdd, onUpdate, onDelete, isLoading = false }: TableProps<HomeImage>) => {
  const tableColumns: Column<any>[] = [
    { title: 'Sequence', field: 'sequence', type: 'numeric',  },
    { title: 'TH Title', field: 'titleTh' },
    { title: 'EN Title', field: 'titleEn' },
    { title: 'Image', field: 'file', editComponent: (props) => <input
      id="file"
      name="file"
      type="file"
      onChange={(e) => props.onChange(head(e.target.files))}
    />,
    render: rowData => <ColumnImage title={rowData.titleTh} imageUrl={`${process.env.REACT_APP_BASE_URL}/static/${rowData.uri}`} />
   },
    { title: 'Status', field: 'status', editable: 'onUpdate' , lookup: { 'A': 'Active', 'I': 'Inactive', 'D': 'Delete' } },
  ];

  return (
    <MaterialTable
      columns={tableColumns}
      data={data}
      title="Master Home"
      icons={tableIcons}
      options={defaultOptions}
      editable={{
        onRowAdd: newData => onAdd(newData),
        onRowUpdate: (newData, oldData) => onUpdate(newData, oldData),
        onRowDelete: oldData => onDelete(oldData),
      }}
    />
  );
};

export default HomeTable;
