import { Divider, Grid, List, Paper, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { memo } from 'react';

export type TaskListProps = {
  // readonly children: JSX.Element | JSX.Element[];
};

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    margin: 0,
    width: '100%',
    padding: '8px 10px',
  },
  title: {},
  search: {
    // paddingRight: theme.spacing(2),
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  input: {
    padding: '9px 10px',
  },
  headerColumn: {
    fontSize: '1.1rem',
  },
  center: {
    textAlign: 'center',
  },
  startEnd: {
    fontWeight: 200,
  },
}));

const TaskList = memo((props: TaskListProps) => {
  const classes = useStyles({});

  return (
    <React.Fragment>
      <Grid container direction="row" alignItems="center" className={classes.header}>
        <Grid item xs={12} sm={8} md={8} lg={8}>
          <Typography component="div" variant="h6" className={classes.headerColumn}>
            Task List
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <Grid item container direction="row" alignItems="center">
            <Grid item sm={6} md={8} style={{ paddingRight: 24 }}>
              <Typography component="div" variant="h6" className={clsx(classes.headerColumn, classes.center)}>
                Assessment Date
              </Typography>
              <Typography component="div" variant="body2" className={clsx(classes.center, classes.startEnd)}>
                (Start - End)
              </Typography>
            </Grid>
            <Grid item sm={6} md={4}>
              <Typography component="div" variant="h6" className={clsx(classes.headerColumn, classes.center)}>
                Activity
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Divider />
    </React.Fragment>
  );
});

export default TaskList;
