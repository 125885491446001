import React from 'react';
import moment from 'moment';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { FormInput } from '../form';
import ComponentText from '../ComponentText';
import { Assessment } from '../../models';

const useStyles = makeStyles((theme: Theme) => ({
  information: {
    margin: '15px 0px 0px 0px',
  },
}));

type TextViewProps = {
  label: string;
  value: any;
};

const TextView: React.FC<TextViewProps & React.HTMLAttributes<HTMLDivElement>> = ({ label, value }) => {
  const classes = useStyles({});

  return (
    <FormInput fullWidth label={label}>
      <Typography component="div" className={classes.information}>
        {value}
      </Typography>
    </FormInput>
  );
};

export const AssessmentInformation = ({ information }: { information: Assessment }) => {
  const classes = useStyles({});

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6} md={4}>
        <TextView label="รหัสแบบประเมิน / Document Code" value={information.code} />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextView id="request-no" label="รหัสการสร้างเอกสาร / Request No." value={information.no} />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextView
          id="created-by"
          label="ผู้สร้าง / Created By"
          value={`${information.createdByInitialName} ${information.createdByName}`}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextView id="year" label="ปี / Year" value={information.year} />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextView id="round" label="ครั้งที่ / Round" value={information.round} />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextView id="year" label="ระดับ / Level" value={information.level} />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextView
          id="startDate"
          label="วันที่เปิดรับประเมิน / Opening Date"
          value={moment(information.startDate).format('DD/MM/YYYY')}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextView
          id="endDate"
          label="วันที่ปิดรับประเมิน / Closing Date"
          value={moment(information.endDate).format('DD/MM/YYYY')}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} />
      <Grid item xs={12} sm={6} md={4}>
        <TextView id="status" label="สถานะการใช้งาน / Status" value={information.currentStatus} />
      </Grid>
      {information.level === 'PC' && (
        <>
          <Grid item xs={12} sm={6} md={4}>
            <TextView id="process-name" label="ชื่อกระบวนการ / Process Name" value={information.processName} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextView id="level-unit" label="ระดับหน่วยงาน / Level Unit" value={information.processOwnerLevelName} />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <TextView
              id="unit-name"
              label="ชื่อหน่วยงาน / Unit Name"
              value={information.processOwnerDepartmentFullNameTh}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextView
              id="process-owner"
              label="เจ้าของกระบวนการ / Process Owner"
              value={information.processOwnerName}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <FormInput fullWidth id="description" label="คำอธิบาย / Description">
          <ComponentText className={classes.information}>{information.description || '-'}</ComponentText>
        </FormInput>
      </Grid>
    </Grid>
  );
};
