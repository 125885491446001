import React from 'react';

import { ExportToolbar } from './ExportToolbar';
import { useReport4_1 } from './Report4IndividualContext';
import { Report4IndividualResultTableView } from './Report4IndividualResultTableView';
import { Report4IndividualTitle } from './Report4IndividualTitle';

export const Report4IndividualResult = () => {
  const { exportExcel, exportWord, exportPdf, isProcessing, data } = useReport4_1();
  return (
    <div>
      <Report4IndividualTitle />
      <ExportToolbar
        disabled={!data}
        excelFn={exportExcel}
        wordFn={exportWord}
        pdfFn={exportPdf}
        enabled={['excel', 'pdf', 'word']}
        excelLoading={isProcessing('excel-exporting')}
        wordLoading={isProcessing('word-exporting')}
        pdfLoading={isProcessing('pdf-exporting')}
      />
      <Report4IndividualResultTableView />
    </div>
  );
};
