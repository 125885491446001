import MuiRating from 'material-ui-rating';
import React from 'react';

import { Checkbox, FormControlLabel, Grid, MenuItem, Select, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { FormInput } from '../form';
import InputBase from '../InputBase';
import { QuestionRatingProps } from './props';
import { apiMessages } from '../../siteSettings';

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    paddingTop: 10,
  },
}));

const QuestionRating = ({ question, handleRatingChange, handleCheck, disabled }: QuestionRatingProps) => {
  const classes = useStyles({});
  const _handleRatingChange = (value: number) => {
    if (value === question.maxRate) return;
    handleRatingChange(value);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={1} />
        <Grid item xs={12} md={11}>
          <MuiRating value={1} max={question.maxRate} disabled />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} md={1} />
        <Grid item xs={12} md={11}>
          <Grid item container>
            <Grid container item md={2}>
              <FormInput label="Level" disabled={disabled}>
                <Select
                  onChange={e => _handleRatingChange(e.target.value as number)}
                  input={<InputBase name="maxRate" id="maxRate" value={question.maxRate} />}>
                  <MenuItem value={7}>7</MenuItem>
                  <MenuItem value={6}>6</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                </Select>
              </FormInput>
            </Grid>
            <Grid container item md={4}>
              <Grid item xs={6}>
                <Typography variant="body1" align="center" className={classes.label}>
                  โปรดระบุ
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  disabled={disabled}
                  onChange={handleCheck}
                  control={
                    <Checkbox
                      checked={!!question.requiredComment}
                      id="requiredComment"
                      name="requiredComment"
                      value="requiredComment"
                    />
                  }
                  label={apiMessages.requireComment}
                />
              </Grid>
            </Grid>
            <Grid container item md={4}>
              <Grid item xs={6}>
                <Typography variant="body1" align="center" className={classes.label}>
                  เอกสารแนบ
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <FormControlLabel
                  disabled={disabled}
                  onChange={handleCheck}
                  control={
                    <Checkbox
                      checked={!!question.requiredAttachment}
                      id="requiredAttachment"
                      name="requiredAttachment"
                      value="requiredAttachment"
                    />
                  }
                  label={apiMessages.requireAttachment}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export { QuestionRating };

export default QuestionRating;
