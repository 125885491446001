import { client } from './client';
import { COMPONENT_URI } from './constants';

export async function getOne(id: number) {
  return await client.get(`${COMPONENT_URI}/${id}`, {});
}

export async function getAll() {
  return await client.get(`${COMPONENT_URI}`, {});
}

export async function create(request: any) {
  return await client.post(`${COMPONENT_URI}`, request, {});
}

export async function update(id: number, request: any) {
  return await client.put(`${COMPONENT_URI}/${id}`, request, {});
}

export async function deleteOne(id: number) {
  return await client.delete(`${COMPONENT_URI}/${id}`, {});
}
