import { faFileExcel, faFilePdf, faFileWord } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress, Grid, IconButton } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';

export type ExportToolbarProps = {
  excelFn?: () => any;
  pdfFn?: () => any;
  wordFn?: () => any;
  excelLoading?: boolean;
  pdfLoading?: boolean;
  wordLoading?: boolean;
  enabled?: ('excel' | 'pdf' | 'word')[];
  disabled?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    width: '2em !important',
  },
  circularProgress: {
    marginTop: theme.spacing(0.65),
    marginLeft: theme.spacing(2.1),
    marginRight: theme.spacing(1.7),
  },
}));

export const ExportToolbar = ({
  excelFn,
  pdfFn,
  wordFn,
  excelLoading,
  pdfLoading,
  wordLoading,
  enabled = [],
  disabled: _disabled,
}: ExportToolbarProps) => {
  const classes = useStyles({});
  const disabled = _disabled || excelLoading || pdfLoading || wordLoading;

  return (
    <Grid container spacing={1}>
      <Grid hidden={!enabled.includes('excel')} item>
        <IconButton aria-label="Excel" onClick={excelFn} color={disabled ? 'default' : 'primary'} disabled={disabled}>
          {excelLoading ? (
            <CircularProgress color="inherit" className={classes.circularProgress} size={18} />
          ) : (
            <FontAwesomeIcon className={classes.icon} icon={faFileExcel} />
          )}
        </IconButton>
      </Grid>
      <Grid hidden={!enabled.includes('pdf')} item>
        <IconButton aria-label="Pdf" onClick={pdfFn} color={disabled ? 'default' : 'primary'} disabled={disabled}>
          {pdfLoading ? (
            <CircularProgress color="inherit" className={classes.circularProgress} size={18} />
          ) : (
            <FontAwesomeIcon className={classes.icon} icon={faFilePdf} />
          )}
        </IconButton>
      </Grid>
      <Grid hidden={!enabled.includes('word')} item>
        <IconButton aria-label="Word" onClick={wordFn} color={disabled ? 'default' : 'primary'} disabled={disabled}>
          {wordLoading ? (
            <CircularProgress color="inherit" className={classes.circularProgress} size={18} />
          ) : (
            <FontAwesomeIcon className={classes.icon} icon={faFileWord} />
          )}
        </IconButton>
      </Grid>
    </Grid>
  );
};
