import FileSaver from 'file-saver';
import React, { useContext, useState } from 'react';

import { client } from '../../apis';
import { useIsProcessing } from '../useIsProcessing';
import { mimetype } from '../../utils/mimetype';
import { useReportSearch } from './ReportSearchContext';

const Report5Context = React.createContext(null);

export const Report5Provider = ({ children }) => {
  const { isProcessing, setIsProcessing } = useIsProcessing();
  const [data, setData] = useState<any>();
  const [meta, setMeta] = useState<any>();
  const { filter, setFilter } = useReportSearch();
  const [assessmentFilter, setAssessmentFilter] = useState({
    startYear: '',
    endYear: '',
    level: '',
  });

  const hofHandleChange = (getter: any, setter: any) => (name: string) => (e: any) => {
    setter({
      ...getter,
      [name]: e.target.value,
    });
  };

  const handleFilterChange = hofHandleChange(filter, setFilter);
  const handleAssessmentFilterChange = hofHandleChange(assessmentFilter, setAssessmentFilter);

  const resetDataFilter = () => {
    setAssessmentFilter({ level: '', startYear: '', endYear: '' });
    searchReport5();
  };

  const searchReport5 = () => {
    setIsProcessing('searching', true);
    setData(null);
    client
      .get(`reports/rpt-05`, { params: assessmentFilter })
      .then(({ data: payload }) => {
        setData(payload.data);
        setMeta(payload.meta);
      })
      .catch(ex => {
        console.log(ex.response);
        console.error(ex);
        setData(null);
      })
      .finally(() => setIsProcessing('searching', false));
  };

  const submitFilterForm = () => {
    searchReport5();
  };

  const exportExcel = () => {
    setIsProcessing('exporting', true);
    client
      .get(`reports/rpt-05/excel`, { responseType: 'blob', params: assessmentFilter })
      .then(response => {
        const { filename } = response.headers;

        (window as any).$ = response;

        const blob = new Blob([response.data], {
          type: mimetype.excel,
        });
        FileSaver.saveAs(blob, decodeURIComponent(filename));
      })
      .finally(() => setIsProcessing('exporting', false));
  };

  const value = {
    assessmentFilter,
    setAssessmentFilter,
    handleAssessmentFilterChange,
    submitFilterForm,
    exportExcel,
    isProcessing,
    setIsProcessing,
    resetDataFilter,
    meta,
    data,
  };

  return <Report5Context.Provider value={value}>{children}</Report5Context.Provider>;
};

export interface Report5Values {
  assessmentFilter: any;
  setAssessmentFilter: any;
  assessmentDataFilter: any;
  setAssessmentDataFilter: any;
  handleAssessmentFilterChange: any;
  handleAssessmentDataFilterChange: any;
  submitFilterForm: () => void;
  exportExcel: () => void;
  isProcessing: (...keys: string[]) => boolean;
  setIsProcessing: (key: string, flag: boolean) => void;
  data: any;
  meta: any;
  resetDataFilter: () => void;
}

export const useReport5 = () => {
  return useContext<Report5Values>(Report5Context);
};
