import { MenuItem, Select } from '@material-ui/core';
import useAxios from 'axios-hooks';
import React, { useState } from 'react';

import { FormInput } from '../form';
import InputBase from '../InputBase';

export type ReportProcessOptionProps = {
  onChange: (
    event: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>,
    child: React.ReactNode,
  ) => void;

  value: string;
  disabled?: boolean;
};

export const ReportProcessOption = ({ onChange, value, disabled }: ReportProcessOptionProps) => {
  const [{ data: payload = {}, loading, error }] = useAxios('process-name');

  return (
    <FormInput fullWidth id="process" label="ชื่อกระบวนการ/Process Name" disabled={disabled || loading}>
      <Select onChange={onChange} input={<InputBase name="level" id="level" value={value} />}>
        <MenuItem value="all">All</MenuItem>
        {payload.data &&
          payload.data.map(item => (
            <MenuItem value={item.id} key={item.id}>
              {item.nameTh}
            </MenuItem>
          ))}
      </Select>
    </FormInput>
  );
};

export default ReportProcessOption;
