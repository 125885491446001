import React, { ErrorInfo } from 'react';
import ReactGA from 'react-ga';

class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
    errorMessage: '',
  };

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, errorMessage: String(error) };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    ReactGA.exception({
      description: String(error),
      fatal: true,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <strong style={{ color: 'red' }}>Failed to render:</strong>
          <br />
          {this.state.errorMessage}
          <br />
          <button onClick={() => this.setState({ hasError: false })}>Retry</button>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
