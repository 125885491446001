import { Box, Grid, MenuItem, Select } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';

import { FormInput } from '../form';
import InputBase from '../InputBase';
import { useReport1 } from './Report1Context';
import { ReportSearchButton } from './ReportSearchButton';
import ReportProcessOption from './ReportProcessOption';

const useStyles = makeStyles((theme: Theme) => ({
  //style here
  root: {
    padding: '5px 20px 5px 20px',
  },
  input: {
    padding: '10px',
  },
  paper: {
    padding: '0px',
  },
  resetButton: { paddingLeft: '10px' },
  table: {
    padding: '10px',
  },
}));

const Report1Filter = () => {
  const classes = useStyles({});
  const { assessmentFilter, handleAssessmentFilterChange, submitFilterForm, isProcessing } = useReport1();

  return (
    <Grid container direction="row" alignItems="center">
      <Grid item xs={12} sm={12} md={2}>
        <Box className={classes.input}>
          <FormInput fullWidth id="level" label="ระดับ/Level" required>
            <Select
              onChange={handleAssessmentFilterChange('level')}
              input={<InputBase name="level" id="level" value={assessmentFilter.level} />}>
              <MenuItem value="CO">องค์กร</MenuItem>
              <MenuItem value="PC">กระบวนการ</MenuItem>
            </Select>
          </FormInput>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={2}>
        <Box className={classes.input}>
          <ReportProcessOption
            onChange={handleAssessmentFilterChange('processId')}
            value={assessmentFilter.processId}
            disabled={assessmentFilter.level !== 'PC' || isProcessing()}
          />
        </Box>
      </Grid>

      <Grid item xs={12} sm={12} md={2}>
        <ReportSearchButton onClick={submitFilterForm} disabled={isProcessing()} loading={isProcessing('searching')} />
      </Grid>
    </Grid>
  );
};

export default Report1Filter;
