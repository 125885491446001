import React, { memo } from 'react';
import Button, { ButtonProps } from '@material-ui/core/Button';

export type AssessmentPreviewButtonProps = {
  assessmentNo: string;
  disabled?: boolean;
};

export const AssessmentPreviewButton: React.FC<AssessmentPreviewButtonProps> = memo(
  ({ assessmentNo, disabled }) => {
    return (
      <Button
        variant="outlined"
        color="primary"
        component="a"
        href={`/worklist/${assessmentNo}/preview`}
        target="_blank"
        disabled={disabled}>
        Preview
      </Button>
    );
  },
  (prev, next) => prev.assessmentNo === next.assessmentNo,
);
