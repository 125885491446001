import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CloseIcon from '@material-ui/icons/Close';
import { IsBoolean, IsNotEmpty } from 'class-validator';
import React, { useState } from 'react';
import { ActionMeta, ValueType } from 'react-select/src/types';
import minBy from 'lodash/minBy';

import { Assessor, Question } from '../models';
import { toDepartmentOption, toLevelOption } from '../utils/options';
import { useAssessmentManager } from './assessment/AssessmentContext';
import AutocompleteSelect, { OptionType } from './AutocompleteSelect';
import { FormInput } from './form';
import LoadingButton from './LoadingButton';
import { useFormValidation } from './useFormValidation';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: 'calc(100% - 48px)',
    [theme.breakpoints.up('md')]: {
      minHeight: 'calc(100% - 96px)',
    },
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

export class AssessorState {
  @IsNotEmpty({ message: 'กรุณาเลือก' })
  employeeId: string;

  @IsNotEmpty({ message: 'กรุณาเลือก', groups: ['admin'] })
  levelId: number;

  @IsNotEmpty({ message: 'กรุณาเลือก', groups: ['admin'] })
  unitId: string;

  @IsBoolean()
  assessorType: boolean;

  selected?: string | undefined;
}

export interface AddAssessorDialogProps {
  assessmentNo: string;
  question: Question;
  departmentLevelNo?: number;
  departmentId?: string;
  employeeId?: string;
  admin?: boolean;
  isLoading?: boolean;
  handleSubmit?: (componentId: string, questionId: string, assessor: Assessor) => Promise<any>;
}

const AddAssessorDialog = ({
  question,
  departmentLevelNo,
  departmentId,
  employeeId,
  admin,
  handleSubmit,
  isLoading,
}: AddAssessorDialogProps) => {
  const { settings } = useAssessmentManager();

  const classes = useStyles({});
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = useState(false);
  const {
    values,
    setValue,
    setValues,
    handleSubmit: formSubmit,
    hasError,
  } = useFormValidation({
    initialValues: {
      levelId: departmentLevelNo,
      unitId: departmentId,
      employeeId: employeeId,
      assessorType: true,
    },
    type: AssessorState,
    groups: admin ? ['admin'] : [],
    onSuccess: () => {
      handleSubmit(question.componentId, question.id, values as Assessor).then(() => {
        setOpen(false);
        setValues({ levelId: undefined, unitId: '', employeeId: '', assessorType: false });
      });
    },
  });

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    if (isLoading) return;
    setOpen(false);
  };

  const handleSelectChange = (name: string) => (option: ValueType<OptionType>, actionMeta: ActionMeta) => {
    const value = (option as any).value;

    if (name === 'employeeId') {
      const assessor = settings.managers.find(emp => emp.id === value);
      setValues({
        levelId: assessor.levelNo,
        unitId: assessor.departmentId,
        employeeId: value,
        selected: 'employeeId',
      });
    } else if (name === 'levelId') {
      const levelNo = value;
      setValues({
        levelId: levelNo,
        unitId: '-',
        employeeId: '-',
        selected: 'levelId',
      });
    } else if (name === 'unitId') {
      const departmentId = value;
      const department = settings.departments.find(d => d.id === departmentId);

      if (!department) return;

      const highestEmployee = minBy(
        settings.managers.filter(emp => emp.departmentId === department.id),
        'levelNo',
      );
      setValues({
        levelId: department.levelNo,
        unitId: department.id,
        employeeId: highestEmployee && highestEmployee.id,
        selected: 'unitId',
      });
    } else {
      setValue(name as any, value);
    }
  };

  const handleRadioChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(name as any, event.target.value === 'true');
  };

  const levelOptions = settings.departmentLevels.map(toLevelOption);
  const departmentOptions = settings.departments
    .filter(d => (values.levelId ? d.levelNo === Number(values.levelId) : true))
    .map(toDepartmentOption);
  const employeeOptions = settings.managers.map(d => ({
    label: `${d.initialName} ${d.firstName} ${d.lastName}`,
    value: d.id,
    description: `${d.departmentShortName} ${d.departmentNameEn}`,
  }));

  return (
    <div>
      <Button variant="outlined" color="primary" size="small" onClick={handleOpen}>
        Assessor
      </Button>
      <Dialog
        fullWidth
        maxWidth="lg"
        PaperProps={{
          classes: { root: classes.root },
        }}
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="assessor-dialog-title">
        {fullScreen && (
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="Close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                เพิ่มรายชื่อผู้ประเมิน
              </Typography>
            </Toolbar>
          </AppBar>
        )}

        <DialogTitle id="assessor-dialog-title">{!fullScreen && 'เพิ่มรายชื่อผู้ประเมิน / Add Assessor'}</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item container spacing={1}>
              <Grid item xs={12}>
                <FormInput
                  fullWidth
                  label="ระดับหน่วยงาน / Unit Level"
                  id="levelId"
                  required
                  error={hasError('levelId')}>
                  <AutocompleteSelect
                    name="levelId"
                    options={levelOptions}
                    value={(values.levelId && values.levelId.toString()) || ''}
                    onChange={handleSelectChange('levelId')}
                  />
                </FormInput>
              </Grid>
              <Grid item xs={12}>
                <FormInput fullWidth label="ชื่อหน่วยงาน / Unit Name" id="unitId" required error={hasError('unitId')}>
                  <AutocompleteSelect
                    name="unitId"
                    options={departmentOptions}
                    value={values.unitId || ''}
                    onChange={handleSelectChange('unitId')}
                  />
                </FormInput>
              </Grid>
              <Grid item xs={12}>
                <FormInput
                  fullWidth
                  label="ผู้ประเมิน / Initial Name"
                  id="employeeId"
                  required
                  error={hasError('employeeId')}>
                  <AutocompleteSelect
                    name="employeeId"
                    options={employeeOptions}
                    value={values.employeeId || ''}
                    onChange={handleSelectChange('employeeId')}
                  />
                </FormInput>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <FormInput fullWidth label="ประเภทผู้ประเมิน" error={hasError('assessorType')}>
                <Box marginTop={2}>
                  <RadioGroup name="assessorType" onChange={handleRadioChange('assessorType')}>
                    <FormControlLabel
                      control={<Radio value="true" checked={values.assessorType} />}
                      label="ผู้ประเมินหลัก"
                    />
                    <FormControlLabel
                      control={<Radio value="false" checked={!values.assessorType} />}
                      label="ผู้ประเมินรอง"
                    />
                  </RadioGroup>
                </Box>
              </FormInput>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <LoadingButton isLoading={isLoading} onClick={formSubmit} variant="contained" color="primary" autoFocus>
            Save
          </LoadingButton>
          <LoadingButton disabled={isLoading} onClick={handleClose} variant="outlined" color="default">
            Cancel
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddAssessorDialog;
