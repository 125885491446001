import useAxios from 'axios-hooks';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import MaterialTable, { Column } from 'material-table';

import { defaultOptions, tableIcons } from '../../components/masterdata/constants';
import { TableProps } from '../../components/masterdata/types';

import { COMPONENT_URI } from '../../apis/constants';
import * as ComponentService from '../../apis/component';
import ContentWrapper from '../../components/ContentWrapper';
import MainLayout from '../../components/MainLayout';
import { ERROR_TEXT, errorOptions } from '../../utils/snackbar';

const MasterComponent = () => {
  const [{ data: payload = {} as any, loading, error }, refetch] = useAxios(COMPONENT_URI, { manual: true });
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    refetch()
  }, [])

  if (error) enqueueSnackbar(ERROR_TEXT, errorOptions);

  return (
    <MainLayout>
      <ContentWrapper>
        <DataTable
          data={payload.data || []}
          isLoading={loading}
          onAdd={handleAdd}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
        />
      </ContentWrapper>
    </MainLayout>
  );
  function handleAdd(data: any) {
    return ComponentService.create(data).then(() => refetch());
  }

  function handleUpdate(newData: any, oldData: any) {
    return ComponentService.update(newData.id, newData).then(() => refetch());
  }
  function handleDelete(data: any) {
    return ComponentService.deleteOne(data.id).then(() => refetch());
  }
};


const DataTable = ({ data, onAdd, onUpdate, onDelete, isLoading = false }: TableProps<any>) => {
  const tableColumns: Column<any>[] = [
    { title: 'NameTh', field: 'nameTh', editable: 'always' },
    { title: 'NameEn', field: 'nameEn', editable: 'always' },
    { title: 'Sequence', field: 'sequence', editable: 'always',  type: 'numeric' },
  ];

  return (
    <MaterialTable
      columns={tableColumns}
      data={data}
      title={'Master Component'}
      icons={tableIcons}
      isLoading={isLoading}
      options={defaultOptions}
      editable={{
        onRowAdd: newData => onAdd(newData),
        onRowUpdate: (newData, oldData) => onUpdate(newData, oldData),
        onRowDelete: oldData => onDelete(oldData),
      }}
    />
  );
};

export default MasterComponent;
