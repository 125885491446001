import { Card, CardContent, Grid, Typography, Link } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React, { useState } from 'react';
import useAxios from 'axios-hooks';

import ContentWrapper from '../components/ContentWrapper';
import MainLayout from '../components/MainLayout';
import PageBanner from '../components/PageBanner';
import bannerImg from '../static/img/Banner-Portal.png';

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    color: theme.palette.text.primary,
  },
  paper: {
    padding: '30px',
  },
  card: {
  },
  cardText: {
    textAlign: 'center',
    marginTop: '8px',
    color: 'white',
    textShadow: '2px 2px #424242',
  },
  cardContent: {},
  header: {
    color: 'white',
    padding: '2rem',
    backgroundImage: `linear-gradient(to bottom right, #00B1AB, yellow )`,
    borderRadius: '5px',
    marginBottom: '25px',
  },
}));

const Page = () => {
  const classes = useStyles({});
  const [{ data: payload, loading }] = useAxios('links')

  return (
    <MainLayout>
      <ContentWrapper>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <PageBanner image={bannerImg} />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          {!loading && payload.data.map(link => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={link.id}>
              <a href={`//${link.url}`} target="_blank">
                <Card style={{
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundImage: `url("${process.env.REACT_APP_BASE_URL}/static/${link.uri}")` }}>
                  <CardContent className={classes.cardContent}>
                    <Typography variant="h5" className={classes.cardText}>
                      {link.title}
                    </Typography>
                  </CardContent>
                </Card>
              </a>
            </Grid>
          ))}
        </Grid>
      </ContentWrapper>
    </MainLayout>
  );
};

export default Page;
