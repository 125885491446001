import useAxios from 'axios-hooks';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';

import * as LinkService from '../../apis/link';
import { LINK_URI } from '../../apis/constants';
import ContentWrapper from '../../components/ContentWrapper';
import MainLayout from '../../components/MainLayout';
import LinkTable from '../../components/masterdata/LinkTable';
import { Link } from '../../models';
import { LinkCreateRequest } from '../../models/master-dto';
import { ERROR_TEXT, errorOptions } from '../../utils/snackbar';
import { upload } from '../../apis/upload';

const MasterPortal = () => {
  const [{ data: payload = {} as any, loading, error }, refetch] = useAxios(LINK_URI, { manual: true });
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    refetch();
  }, []);

  if (error) enqueueSnackbar(ERROR_TEXT, errorOptions);

  const data = payload.data || [];
  console.log(data, 'data');

  return (
    <MainLayout>
      <ContentWrapper>
        <LinkTable isLoading={loading} data={data} onAdd={handleAdd} onUpdate={handleUpdate} onDelete={handleDelete} />
      </ContentWrapper>
    </MainLayout>
  );

  async function handleAdd({ file, ...data }: LinkCreateRequest) {
    try {
      const { data: fileResponse } = await upload('master', file, {
        type: 'file',
      });

      data.sequence = Number(data.sequence);
      await LinkService.create({
        ...data,
        sequence: Number(data.sequence),
        fileName: fileResponse.fileName,
      });
      refetch();
    } catch (ex) {
      console.error(ex);
      console.log(ex);
    }
  }

  async function handleUpdate({ file, ...newData }: LinkCreateRequest, oldData: LinkCreateRequest) {
    try {
      if (file) {
        const { data: fileResponse } = await upload('master', file, {
          type: 'file',
        });

        await LinkService.update(newData.id, {
          ...newData,
          sequence: Number(newData.sequence),
          fileName: fileResponse.fileName,
        });
      } else {
        await LinkService.update(newData.id, {
          ...newData,
          sequence: Number(newData.sequence),
        });
      }

      refetch();
    } catch (ex) {
      console.error(ex);
    }
  }

  function handleDelete(data: Link) {
    return LinkService.deleteOne(data.id).then(() => refetch());
  }
};

export default MasterPortal;
