import { Box } from '@material-ui/core';
import { BoxProps } from '@material-ui/core/Box';
import React from 'react';

import LoadingButton from '../LoadingButton';

export type ReportSearchButtonProps = {
  onClick: () => void;
  disabled?: boolean;
  loading?: boolean;
  boxProps?: BoxProps;
};

export const ReportSearchButton = ({ onClick, disabled, loading, boxProps }: ReportSearchButtonProps) => {
  return (
    <Box marginTop={2} {...boxProps}>
      <LoadingButton
        fullWidth
        variant="contained"
        color="primary"
        onClick={onClick}
        disabled={disabled}
        isLoading={loading}>
        Search
      </LoadingButton>
    </Box>
  );
};
