import MaterialTable, { Column } from 'material-table';
import React from 'react';
import { head } from 'lodash';

import { Link } from '../../models';
import { defaultOptions, tableIcons } from './constants';
import { TableProps } from './types';
import { ColumnImage } from './ColumnImage';

const LinkTable = ({ data, onAdd, onUpdate, onDelete, isLoading = false }: TableProps<Link>) => {
  const tableColumns: Column<any>[] = [
    { title: 'Sequence', field: 'sequence' },
    { title: 'Title', field: 'title' },
    {
      title: 'Image',
      field: 'file',
      editComponent: props => (
        <input id="file" name="file" type="file" onChange={e => props.onChange(head(e.target.files))} />
      ),
      render: rowData => (
        <ColumnImage title={rowData.titleTh} imageUrl={`${process.env.REACT_APP_BASE_URL}/static/${rowData.uri}`} />
      ),
    },
    { title: 'URL', field: 'url' },
    { title: 'Status', field: 'status', editable: 'onUpdate', lookup: { A: 'Active', I: 'Inactive', D: 'Delete' } },
  ];

  return (
    <MaterialTable
      isLoading={isLoading}
      columns={tableColumns}
      data={data}
      title={'Master Portal'}
      icons={tableIcons}
      options={defaultOptions}
      editable={{
        onRowAdd: newData => onAdd(newData),
        onRowUpdate: (newData, oldData) => onUpdate(newData, oldData),
        onRowDelete: oldData => onDelete(oldData),
      }}
    />
  );
};

export default LinkTable;
