import classnames from 'classnames';
import useAxios from 'axios-hooks';
import React from 'react';

import { Box, Divider, Grid, Paper, Link, Card } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ManualIcon from '@material-ui/icons/Book';
import ReportIcon from '@material-ui/icons/InsertChart';
import SiteMapIcon from '@material-ui/icons/Apps';
import LinkIcon from '@material-ui/icons/Link';

import Banner from '../components/Banner';
import ContentWrapper from '../components/ContentWrapper';
import MainLayout from '../components/MainLayout';
import { AdapterLink } from '../components/RouterLink';
import LoadingContent from '../components/LoadingContent';
import AnnouncementCard from '../components/AnnouncementCard';
import { WorklistHomePage } from '../components/worklist/WorklistHomePage';
import faqImg from '../static/img/faq-2.jpg';
import { HOMEPAGE_URI } from '../apis/constants';
import { Announcement } from '../models';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    background: theme.palette.primary.main,
  },
  menu: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  worklist: {
    height: 300,
  },
  about: {
    height: 300,
  },
  icon: {
    fontSize: '5rem',
    color: 'white',
  },
  text: {
    fontSize: '1.9rem',
    color: 'white',
  },
  faqImg: {
    maxWidth: '100%',
    height: '100%',
  },
  aboutCard: {
    padding: '10px 0px 10px 0px',
  },
  aboutCardImg: {
    marginRight: 'auto',
    marginLeft: 'auto',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  aboutCardContent: {
    padding: '5px',
  },
  contactText: {
    color: '#767676',
  },
}));

const HomePage = () => {
  const [{ data: payload = {}, loading }] = useAxios(`${HOMEPAGE_URI}`, { useCache: false });

  if (loading) return <LoadingContent />;

  const data = payload.data || {};

  return (
    <MainLayout>
      <HomePageContrainer announcements={data.announcements} banners={data.banners} worklist={data.worklist} />
    </MainLayout>
  );
};

interface HomePageContainerProps {
  announcements?: Announcement[];
  banners?: any[];
  worklist?: any[];
}

function HomePageContrainer({ announcements, banners, worklist }: HomePageContainerProps) {
  const classes = useStyles({});

  const FAQSection = () => (
    <AdapterLink to="/faq">
      <Box className={classes.faqImg}>
        <img src={faqImg} alt="Faq Image" />
      </Box>
    </AdapterLink>
  );
  const AboutSection = () => (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <Link href="/COSO 2013_Internal Control - Integrated.pdf" target="_blank" rel="noreferrer">
          <Typography variant="h6">
            <LinkIcon />
            About Internal Control
          </Typography>
        </Link>
        <Divider />
      </Grid>
      {announcements.slice(0, 2).map(record => 
        <Grid item xs={12} key={record.id}>
          <Box className={classes.aboutCard}>
          <AnnouncementCard data={record} />
          </Box>
        </Grid>
      )}
    </Grid>
  );

  return (
    <ContentWrapper>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={8}>
          <Banner banners={banners} />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <FAQSection />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={8}>
          <WorklistHomePage worklist={worklist} />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <AboutSection />
        </Grid>
      </Grid>
      <Box margin={3} />
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <Link href="/User Manual.pdf" target="_blank" rel="noreferrer">
            <Paper className={classnames(classes.paper, classes.menu)}>
              <ManualIcon className={classes.icon} />
              <Typography className={classes.text}>Manual</Typography>
            </Paper>
          </Link>
        </Grid>
        <Grid item xs={12} md={3}>
          <AdapterLink to="/reports">
            <Paper className={classnames(classes.paper, classes.menu)}>
              <ReportIcon className={classes.icon} />
              <Typography className={classes.text}>Report</Typography>
            </Paper>
          </AdapterLink>
        </Grid>
        <Grid item xs={12} md={3}>
          <AdapterLink to="/sitemap">
            <Paper className={classnames(classes.paper, classes.menu)}>
              <SiteMapIcon className={classes.icon} />
              <Typography className={classes.text}>Site Map</Typography>
            </Paper>
          </AdapterLink>
        </Grid>
        <Grid item xs={12} md={3}>
          <AdapterLink to="/portal">
            <Paper className={classnames(classes.paper, classes.menu)}>
              <LinkIcon className={classes.icon} />
              <Typography className={classes.text}>Portal</Typography>
            </Paper>
          </AdapterLink>
        </Grid>
      </Grid>
      <Box marginTop={2} />
      <Grid container direction="row" justify="flex-end" alignItems="center">
        <Grid item xs={12}>
          <Typography align="right" component="div">
            <Link component={AdapterLink} to="/statistic">
              Statistics
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography align="right" className={classes.contactText}>
            Contact Administrator : ICD Tel. 8203, 8235, 8278
          </Typography>
        </Grid>
      </Grid>
    </ContentWrapper>
  );
}

export default HomePage;
