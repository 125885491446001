import { Box, Grid, MenuItem, Select } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';

import { FormInput } from '../form';
import LoadingButton from '../LoadingButton';
import { useReport6 } from './Report6Context';
import { ReportSearchButton } from './ReportSearchButton';
import InputBase from '../InputBase';
import { getYearListFrom } from '../../utils/date';

const useStyles = makeStyles((theme: Theme) => ({
  //style here
  root: {
    padding: '5px 20px 5px 20px',
  },
  input: {
    padding: '10px',
  },
  paper: {
    padding: '0px',
  },
  resetButton: { paddingLeft: '10px' },
  table: {
    padding: '10px',
  },
}));

const Report6Filter = ({}) => {
  const classes = useStyles({});
  const {
    assessmentFilter,
    handleAssessmentFilterChange,
    result,
    submitFilterForm,
    isProcessing,
    resetDataFilter,
  } = useReport6();

  return (
    <>
      <Grid container direction="row" alignItems="center">
        <Grid item xs={12} sm={12} md={2}>
          <Box className={classes.input}>
            <FormInput fullWidth id="year" label="ปี / Year" required>
              <Select
                onChange={handleAssessmentFilterChange('year')}
                input={<InputBase name="year" id="year" value={assessmentFilter.year} />}>
                {getYearListFrom().map(year => (
                  <MenuItem key={year} value={year}>
                    {year + 543}
                  </MenuItem>
                ))}
              </Select>
            </FormInput>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={2}>
          <Box className={classes.input}>
            <FormInput fullWidth id="level" label="ระดับ / Level" required>
              <Select
                onChange={handleAssessmentFilterChange('level')}
                input={<InputBase name="level" id="level" value={assessmentFilter.level || ''} />}>
                <MenuItem value="CO">องค์กร</MenuItem>
                <MenuItem value="PC">กระบวนการ</MenuItem>
              </Select>
            </FormInput>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={2}>
          <ReportSearchButton onClick={submitFilterForm} disabled={isProcessing()} loading={isProcessing('search')} />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={12} md={2}>
          <Box className={classes.resetButton}>
            <LoadingButton fullWidth variant="contained" color="secondary" size="small" onClick={resetDataFilter}>
              Reset Filter
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Report6Filter;
