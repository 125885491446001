import AssessmentIcon from '@material-ui/icons/Assessment';
import useAxios from 'axios-hooks';
import React, { useEffect } from 'react';
import useRouter from 'use-react-router';

import { AssessmentProvider } from '../../components/assessment/AssessmentContext';
import { AssessmentRequestProvider } from '../../components/assessment/AssessmentRequestContext';
import ContentWrapper from '../../components/ContentWrapper';
import { useUserState } from '../../components/context/UserStateContext';
import LoadingContent from '../../components/LoadingContent';
import MainLayout from '../../components/MainLayout';
import PageTitle from '../../components/PageTitle';
import AccessDenied from '../AccessDenied';
import NotFoundPage from '../NotFoundPage';
import AssessmentRequestContainer from '../../components/assessment/AssessmentRequestContainer';
import { ScrollTopDown } from '../../components/ScrollTopDown';
import { AdminReviewProvider } from '../../context/AdminReviewContext';
import { AssessmentErrorProvider } from '../../context/AssessmentErrorContext';

export const AssessmentRequestPage = () => {
  const {
    match: { params },
  } = useRouter();
  const { hasAnyRoles } = useUserState();
  const [{ data: payload, loading, error }, refetch] = useAxios(
    {
      url: `/assessment-requests/${params['assessmentNo']}/${params['assessorId']}`,
    },
    { manual: true },
  );

  useEffect(() => {
    refetch();
  }, []);

  if (error && error.response.status === 403) return <AccessDenied />;
  if (error) return <NotFoundPage />;
  if (loading || !!!payload) return <LoadingContent />;

  const assessmentData = payload.data;
  if (
    !hasAnyRoles('ADMIN') &&
    !['Assessment', 'Completed', 'Summarize'].includes(assessmentData.information.currentStatus)
  ) {
    return <AccessDenied />;
  }

  return (
    <AssessmentRequestStack>
      <AdminReviewProvider>
        <AssessmentRequestContainer />
      </AdminReviewProvider>
    </AssessmentRequestStack>
  );

  function AssessmentRequestStack({ children }) {
    return (
      <AssessmentErrorProvider>
        <AssessmentProvider initialValues={assessmentData} mode="edit">
          <AssessmentRequestProvider
            comments={assessmentData.comments}
            requestStatus={assessmentData.requestStatus}
            assessmentRequest={assessmentData}>
            <ScrollTopDown>
              <MainLayout>
                <ContentWrapper>
                  <PageTitle title="Assessment Form" icon={AssessmentIcon} />
                  {children}
                </ContentWrapper>
              </MainLayout>
            </ScrollTopDown>
          </AssessmentRequestProvider>
        </AssessmentProvider>
      </AssessmentErrorProvider>
    );
  }
};

export default AssessmentRequestPage;
