import React, { createContext, useContext, useState } from 'react';

export const AssessmentErrorContext = createContext(null);

export const AssessmentErrorProvider = ({ children }) => {
  const [error, setError] = useState<any>(null);

  const clearError = () => setError(null);

  const value = {
    error,
    setError,
    clearError,
  };
  return <AssessmentErrorContext.Provider value={value}>{children}</AssessmentErrorContext.Provider>;
};

export type AssessmentErrorContextValues = {
  error: any;
  setError: (error: any) => void;
  clearError: () => void;
};

export const useError = () => {
  return useContext<AssessmentErrorContextValues>(AssessmentErrorContext);
};
