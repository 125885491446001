import { client } from './client';
import { LINK_URI } from './constants';

export async function getOne(id: number) {
  return await client.get(`${LINK_URI}/${id}`, {});
}

export async function getAll() {
  return await client.get(`${LINK_URI}`, {});
}

export async function create(request: any) {
  return await client.post(`${LINK_URI}`, request, {});
}

export async function update(id: number, request: any) {
  return await client.put(`${LINK_URI}/${id}`, request, {});
}

export async function deleteOne(id: number) {
  return await client.delete(`${LINK_URI}/${id}`, {});
}
