import maxBy from 'lodash/maxBy';
import uniqBy from 'lodash/uniqBy';

import { Question, Section, Assessor } from './assessment';
import { QuestionYesNoSummarize } from './admin-summarize';

/**
 * Component composes of both question and section
 * We defines the word "item" that represents both of them
 * This class is to manage the item
 */
export class ComponentManager {
  questions: Question[] = [];
  sections: Section[] = [];

  constructor(questions: Question[], sections: Section[]) {
    // this.questions = questions
    //   .map(q => new Question(q))
    //   .sort((q1, q2) => q1.sequence - q2.sequence)
    //   .sort((a, b) => {
    //     const aNo = Number(a.no);
    //     const bNo = Number(b.no);
    //     return aNo - bNo;
    //   });

    this.questions = questions
  .map(q => new Question(q))
  .sort((a, b) => {
    // Sort primarily by sequence
    const sequenceComparison = a.sequence - b.sequence;
    if (sequenceComparison !== 0) {
      return sequenceComparison;
    }

    // If sequences are equal, sort by no
    const aNo = Number(a.no);
    const bNo = Number(b.no);
    return aNo - bNo;
  });


    this.sections = sections.map(s => new Section(s)).sort((s1, s2) => s1.sequence - s2.sequence);
  }

  /**
   * If add assessor successfully, will return true else false
   * @param questionId
   * @param assessor
   */
  addAssessor(questionId: string, assessor: Assessor) {
    const question = this.questions.find(question => question.id === questionId);
    if (!question) return false;

    const exist = question.assessors.find(_ => _.employeeId === assessor.employeeId);
    if (exist) return false;

    question.assessors.push(assessor);
    return true;
  }

  removeAssessor(questionId: string, assessor: Assessor) {
    throw new Error('Not implemented yet');
  }

  addItem(questionId: string, assessor: Assessor) {
    throw new Error('Not implemented yet');
  }

  removeItem(questionId: string, assessor: Assessor) {
    throw new Error('Not implemented yet');
  }

  getTodoQuestionsByComponentId(componentId: string, targetId: string): Question[] {
    return this.questions
      .filter(q =>
        q.assessors.some(({ employeeId, componentId: cid }) => componentId === cid && employeeId === targetId),
      )
      .sort((a, b) => {
        const aNo = Number(a.no);
        const bNo = Number(b.no);
        return aNo - bNo;
      });
  }
  getTodoQuestionsBySectionId(sectionId: string, targetId: string): Question[] {
    return this.questions.filter(q =>
      q.assessors.some(({ employeeId }) => sectionId === q.sectionId && employeeId === targetId),
    );
  }

  /**
   * Level 1
   */
  getItems(): (Question | Section)[] {
    const questionsNoSection = this.questions.filter(q => !q.sectionId);
    return [...questionsNoSection, ...this.sections].sort((a, b) => a.sequence - b.sequence);
  }

  // TODO: Rename this method add plural
  getQuestionBySectionId(sectionId: string): Question[] {
    return this.questions.filter(q => q.sectionId === sectionId);
  }

  getQuestionByComponentId(componentId: string): Question[] {
    return this.questions.filter(q => q.componentId === componentId);
  }

  getSectionByComponentId(componentId: string): Section[] {
    return this.sections.filter(section => section.componentId === componentId);
  }

  getNextSequenceBySectionId(sectionId: string): number {
    const questions = this.getQuestionBySectionId(sectionId);
    const item = maxBy(questions, s => s.sequence);
    return item ? item.sequence + 1 : 1;
  }

  getNextSequenceByComponentId(componentId: string): number {
    const items = this.getItems().filter(item => item.componentId === componentId);
    const item = maxBy(items, s => s.sequence);
    return item ? item.sequence + 1 : 1;
  }
}

export class AdminSummarizeComponentManager {
  questionSummarizes: QuestionYesNoSummarize[];

  constructor(questionSummarizes: QuestionYesNoSummarize[], public readonly sections: Section[]) {
    this.questionSummarizes = questionSummarizes.map(
      ({ noAnswer, question, total, yesAnswer }) => new QuestionYesNoSummarize(question, total, noAnswer, yesAnswer),
    );
    this.sections = sections.map(s => new Section(s));
  }

  addItem(questionId: string, assessor: Assessor) {
    throw new Error('Not implemented yet');
  }

  removeItem(questionId: string, assessor: Assessor) {
    throw new Error('Not implemented yet');
  }

  getSections(): Section[] {
    if (!this.sections) return [];
    return this.sections;
  }

  /**
   * Level 1
   */
  getItems(): (QuestionYesNoSummarize | Section)[] {
    const questionsNoSection = this.questionSummarizes.filter(({ question: q }) => !q.sectionId);
    return [...questionsNoSection, ...this.sections].sort((a, b) => {
      if (a instanceof QuestionYesNoSummarize && b instanceof QuestionYesNoSummarize) {
        return a.question.sequence - b.question.sequence;
      }
      if (a instanceof QuestionYesNoSummarize && b instanceof Section) {
        return a.question.sequence - b.sequence;
      }
      if (a instanceof Section && b instanceof QuestionYesNoSummarize) {
        return a.sequence - b.question.sequence;
      }
      if (a instanceof Section && b instanceof Section) {
        return a.sequence - b.sequence;
      }
      return -1;
    });
  }

  // TODO: Rename this method add plural
  getQuestionBySectionId(sectionId: string): QuestionYesNoSummarize[] {
    return this.questionSummarizes.filter(({ question: q }) => q.sectionId === sectionId);
  }
}
