import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: 10,
  },
  titleIcon: {
    verticalAlign: 'middle',
    paddingBottom: 5,
    fontSize: '2rem',
    marginRight: '0.575rem',
  },
}));

type PageTitleProps = {
  icon?: React.ComponentType<SvgIconProps>;
  title: string;
};

export default ({ title, icon: Icon }: PageTitleProps) => {
  const classes = useStyles({});

  return (
    <Typography variant="h5" component="h6" className={classes.root}>
      {Icon && <Icon className={classes.titleIcon} />}
      {title}
    </Typography>
  );
};
