import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import React from 'react';

import Loading from '../Loading';
import { useReport5 } from './Report5Context';

export const Report5ResultTableView = () => {
  const { isProcessing, data = [] } = useReport5();

  if (isProcessing('searching')) {
    return <Loading />;
  }

  return (
    <Box marginTop={3}>
      <Box border={1} borderColor="grey.300">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center" rowSpan={2}>
                No
              </TableCell>
              <TableCell align="center" rowSpan={2}>
                คำถาม / Title
              </TableCell>
              <TableCell align="center" rowSpan={2}>
                รหัสแบบสอบถาม
              </TableCell>
              <TableCell align="center" rowSpan={2}>
                หน่วยงานผู้ประเมิน
              </TableCell>
              <TableCell align="center" rowSpan={2}>
                Initial
              </TableCell>
              <TableCell align="center" rowSpan={2}>
                Remark
              </TableCell>
              <TableCell align="center" rowSpan={2}>
                Status of Follow
              </TableCell>
              <TableCell align="center" colSpan={2}>
                วันที่ตามแผน
              </TableCell>
              <TableCell align="center" colSpan={2}>
                วันที่ดำเนินการ
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">เริ่ม</TableCell>
              <TableCell align="center">สิ้นสุด</TableCell>
              <TableCell align="center">เริ่ม</TableCell>
              <TableCell align="center">สิ้นสุด</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length === 0 && (
              <TableRow>
                <TableCell colSpan={11}>ไม่มีข้อมูล</TableCell>
              </TableRow>
            )}
            {data.map((record, no) => (
              <TableRow key={`${record.id}`}>
                <TableCell>{no + 1}</TableCell>
                <TableCell>{record.title}</TableCell>
                <TableCell>{record.code}</TableCell>
                <TableCell>{record.departmentInitialName}</TableCell>
                <TableCell>{record.employeeInitialName}</TableCell>
                <TableCell>{record.remark}</TableCell>
                <TableCell>{record.followUpStatus}</TableCell>
                <TableCell>{record.planStartDate}</TableCell>
                <TableCell>{record.planCompletedDate}</TableCell>
                <TableCell>{record.actualStartDate}</TableCell>
                <TableCell>{record.actualCompletedDate}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};
