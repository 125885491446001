import { LoginResponse } from '../models/auth';
import { client } from './client';
import { AUTH_URI } from './constants';
import * as _LocalStorage from './LocalStorageService';

export async function signIn(email: string, password: string) {
  return await client.post(`${AUTH_URI}/signin/local`, {
    email,
    password,
  });
}

export async function signInLdap(email: string, password: string) {
  return await client.post(`${AUTH_URI}/signin/ldap`, {
    email,
    password,
  });
}

export async function signOut(refreshToken: string, userId: string) {
  return await client.post(`${AUTH_URI}/signout`, {
    refreshToken,
    userId,
  });
}

async function _refreshToken() {
  return await client.post(`${AUTH_URI}/refresh-token`, {});
}

export async function refreshToken(): Promise<null | LoginResponse> {
  if (_LocalStorage.isTokenExpiredIn()) return Promise.resolve(null);

  return _refreshToken().then(({ data }: { data: LoginResponse }) => {
    _LocalStorage.setAccessToken(data.accessToken);
    _LocalStorage.setRefreshToken(data.refreshToken);
    return data;
  });
}
