import React from 'react';
import { useReport7 } from './Report7Context';
import { ExportToolbar } from './ExportToolbar';
import { Report7ResultTableView } from './Report7ResultTableView';

export const Report7Result = () => {
  const { exportExcel, isProcessing, data } = useReport7();
  return (
   <div>
     <ExportToolbar
      disabled={!data}
      excelFn={exportExcel}
      enabled={['excel']}
      excelLoading={isProcessing('excel-exporting')}
    />
    <Report7ResultTableView />
   </div>
  );
};

export default Report7Result;
