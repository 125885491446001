import React from 'react';

import MainLayout from '../components/MainLayout';
import NotFound from '../components/NotFound';

const NotFoundPage = () => {
  return (
    <MainLayout>
      <NotFound />
    </MainLayout>
  );
};

export default NotFoundPage;
