import React from 'react';
import maxBy from 'lodash/maxBy';

import CancelIcon from '@material-ui/icons/Cancel';
import { Checkbox as MuiCheckbox, FormControlLabel, Grid, IconButton } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { FormGroup, FormInput } from '../form';
import InputText from '../InputText';
import { QuestionComponentWithChoicesProps } from './props';
import LoadingButton from '../LoadingButton';
import { apiMessages } from '../../siteSettings';

const useStyles = makeStyles((theme: Theme) => ({
  cancelIcon: {
    marginTop: theme.spacing(0.9),
  },
  label: {
    paddingTop: 10,
  },
}));

const QuestionCheckbox = ({
  choices,
  handleAddChoice,
  handleUpdateChoice,
  handleDeleteChoice,
  handleBlurChoice,
  isLoading,
  disabled,
}: QuestionComponentWithChoicesProps) => {
  const classes = useStyles({});

  return (
    <>
      <Grid container>
        <Grid item xs={1} md={1} />
        <Grid item xs={4} md={11}>
          <FormInput fullWidth label="คำตอบ">
            <FormGroup>
              {choices.map((checkbox, i: number) => (
                <Grid container key={checkbox.id}>
                  <Grid container item md={6}>
                    <Grid item xs={1} md={1}>
                      <MuiCheckbox disabled />
                    </Grid>
                    <Grid item xs={10} md={10}>
                      <InputText
                        placeholder="ระบุคำตอบ"
                        fullWidth
                        value={checkbox.title}
                        onChange={(e: React.ChangeEvent<{ name?: string; value: unknown }>) =>
                          handleUpdateChoice({
                            ...checkbox,
                            title: e.target.value as string,
                          })
                        }
                        onBlur={() => handleBlurChoice(checkbox)}
                        disabled={isLoading || disabled}
                      />
                    </Grid>
                    <Grid item xs={1} md={1}>
                      <IconButton
                        aria-label="Cancel"
                        size="small"
                        className={classes.cancelIcon}
                        disabled={isLoading || disabled}
                        onClick={() => handleDeleteChoice(checkbox.id)}>
                        <CancelIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Grid container item md={3}>
                    <Grid item xs={12}>
                      <FormControlLabel
                        disabled={disabled}
                        control={
                          <MuiCheckbox
                            disabled={isLoading}
                            checked={!!checkbox.required}
                            onChange={() =>
                              handleUpdateChoice({
                                ...checkbox,
                                required: !checkbox.required,
                              }).then(c => handleBlurChoice(c, true))
                            }
                          />
                        }
                        label={apiMessages.requireComment}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item md={3}>
                    <Grid item xs={12}>
                      <FormControlLabel
                        disabled={disabled}
                        control={
                          <MuiCheckbox
                            disabled={isLoading}
                            checked={!!checkbox.requiredAttachment}
                            onChange={() =>
                              handleUpdateChoice({
                                ...checkbox,
                                requiredAttachment: !checkbox.requiredAttachment,
                              }).then(c => handleBlurChoice(c, true))
                            }
                          />
                        }
                        label={apiMessages.requireAttachment}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </FormGroup>
          </FormInput>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={1} />
        <Grid item xs={4}>
          {!disabled && (
            <LoadingButton isLoading={isLoading} variant="outlined" size="small" onClick={() => addCheckbox()}>
              + Add Options
            </LoadingButton>
          )}
        </Grid>
      </Grid>
    </>
  );

  function addCheckbox() {
    if (handleAddChoice) {
      const _choice = maxBy(choices, s => s.sequence);
      const sequence = _choice ? _choice.sequence + 1 : 1;
      const choice = {
        title: '',
        sequence,
        isOther: false,
        required: false,
        requiredAttachment: false,
        requiredCompletedDate: false,
      };
      handleAddChoice(choice);
    }
  }
};

export { QuestionCheckbox };

export default QuestionCheckbox;
