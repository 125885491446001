import React from 'react';

import { FormControl, Box, Typography, InputLabel, Select, MenuItem } from '@material-ui/core';
import { QuestionComponentViewWithChoicesProps } from './props';
import InputBase from '../InputBase';

const QuestionRankingView = ({
  choices = [],
  disabled,
  question,
  questionAnswer,
  onChange,
}: QuestionComponentViewWithChoicesProps) => {
  if (!onChange || !question || !question.permission) {
    return (
      <div>
        {choices.map(choice => (
          <div style={{ marginBottom: 6 }} key={choice.id}>
            <FormControl disabled style={{ marginRight: 6 }}>
              <Select disabled input={<InputBase name={`choice-${choice.id}`} value="0" />}>
                {choices.map((menu, index) => (
                  <MenuItem value={index} key={`menu-${menu.id}`} disabled>
                    {index + 1}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl disabled>
              <InputBase defaultValue={choice.title} disabled />
            </FormControl>
          </div>
        ))}
      </div>
    );
  }
  const choiceIds: string[] = (questionAnswer.answer.choiceIds || []) as string[];
  const answers = choiceIds.reduce((prev, curr) => {
    const [choiceId, ranking] = curr.split(':');
    return { ...prev, [choiceId]: ranking };
  }, {});

  const handleChange = (choiceId: any, ranking: string) => {
    const answer = choiceIds.find(choice => choice.startsWith(choiceId + ':'));
    if (answer) {
      const newAnswer = choiceIds.map(choice => {
        if (choice.startsWith(choiceId + ':')) return `${choiceId}:${ranking}`;
        return choice;
      });
      onChange(newAnswer);
      return;
    }
    const newAnswer = choiceIds.concat(`${choiceId}:${ranking}`);
    onChange(newAnswer);
  };

  const unwritable = !question.permission.writeable;

  return (
    <div>
      {choices.map(choice => (
        <div style={{ marginBottom: 6 }}>
          <FormControl key={choice.id} disabled={unwritable} style={{ marginRight: 6 }}>
            <Select
              disabled={unwritable}
              value={answers[choice.id]}
              input={
                <InputBase name={`choice-${choice.id}`} onChange={e => handleChange(choice.id, e.target.value)} />
              }>
              {choices.map((menu, index) => (
                <MenuItem value={index} key={`menu-${menu.id}`} disabled={answers[menu.id] === index}>
                  {index + 1}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl disabled={unwritable}>
            <InputBase defaultValue={choice.title} disabled />
          </FormControl>
        </div>
      ))}
    </div>
  );
};

export { QuestionRankingView };

export default QuestionRankingView;
