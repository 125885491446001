import { Box, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import React from 'react';

import Loading from '../Loading';
import { useReport4_1 } from './Report4IndividualContext';

export const Report4IndividualResultTableView = () => {
  const { isProcessing, data } = useReport4_1();

  if (isProcessing('submitting')) {
    return <Loading />;
  }

  if (!data) return null;

  return (
    <Box marginTop={3}>
      {data.components.map(({ component, commentByAssessors = [] }) => (
        <Box marginTop={3} key={component.id}>
          <Typography variant="h6">
            องค์ประกอบ: {component.fullname}
          </Typography>
          <Box border={1} borderColor="grey.300">
            <Table>
              <TableBody>
                {commentByAssessors.map(
                  record =>
                    record.assessor && (
                      <TableRow key={`${component.id},${record.assessor.employeeInitialName}`}>
                        <TableCell>{record.comment}</TableCell>
                        <TableCell>{record.assessor.departmentInitialName}</TableCell>
                        <TableCell>{record.assessor.employeeInitialName}</TableCell>
                      </TableRow>
                    ),
                )}
              </TableBody>
            </Table>
          </Box>
        </Box>
      ))}
    </Box>
  );
};
