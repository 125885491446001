import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  useMediaQuery,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import React, { useState } from 'react';

import { AdminReviewAssessmentComment, AdminReviewQuestionComment } from '../context/AdminReviewContext';
import { DEFAULT_DATE_PICKER_FORMAT } from '../siteSettings';
import { FormInput } from './form';
import TextInput from './InputBase';
import LoadingButton from './LoadingButton';
import { useFormValidation } from './useFormValidation';

class FollowUpPopup {
  followUpStatus: string;
  planStartDate: string;
  planCompletedDate: string;
  actualStartDate: string;
  actualCompletedDate: string;
  remark: string;
}

export interface AdminReviewFollowUpPopupProps {
  handleSubmit?: (instance: FollowUpPopup) => void;
  entity?: AdminReviewAssessmentComment | AdminReviewQuestionComment;
  disabled?: boolean;
}

export function AdminReviewFollowUpPopup({ disabled, entity, handleSubmit }: AdminReviewFollowUpPopupProps) {
  const [open, setOpen] = useState<boolean>(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { planStartDate, planCompletedDate, actualStartDate, actualCompletedDate } = entity;

  const { values, setValues, setValue, handleSubmit: formSubmit, handleChange } = useFormValidation({
    initialValues: {
      followUpStatus: entity.followUpStatus || 'Inprocess',
      planStartDate: (planStartDate && moment(planStartDate).format('YYYY-MM-DD')) || null,
      planCompletedDate: (planCompletedDate && moment(planCompletedDate).format('YYYY-MM-DD')) || null,
      actualStartDate: (actualStartDate && moment(actualStartDate).format('YYYY-MM-DD')) || null,
      actualCompletedDate: (actualCompletedDate && moment(actualCompletedDate).format('YYYY-MM-DD')) || null,
      remark: entity.remark || '',
    },
    type: FollowUpPopup,
    onSuccess: data => {
      const postData = {
        ...data,
        planStartDate: data.planStartDate ? data.planStartDate : null,
        planCompletedDate: data.planCompletedDate ? data.planCompletedDate : null,
        actualStartDate: data.actualStartDate ? data.actualStartDate : null,
        actualCompletedDate: data.actualCompletedDate ? data.actualCompletedDate : null,
      };
      handleSubmit(postData);
      setOpen(false);
    },
  });

  const clearData = () => {
    setValues({
      followUpStatus: entity.followUpStatus || 'Inprocess',
      planStartDate: (planStartDate && moment(planStartDate).format('YYYY-MM-DD')) || null,
      planCompletedDate: (planCompletedDate && moment(planCompletedDate).format('YYYY-MM-DD')) || null,
      actualStartDate: (actualStartDate && moment(actualStartDate).format('YYYY-MM-DD')) || null,
      actualCompletedDate: (actualCompletedDate && moment(actualCompletedDate).format('YYYY-MM-DD')) || null,
      remark: entity.remark || '',
    });
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    clearData();
  };

  const handleDateChange = (name: keyof FollowUpPopup) => (date: moment.Moment | null) => {
    setValue(name as any, date && date.format('YYYY-MM-DD'));
  };

  return (
    <div>
      <Button variant="contained" color="primary" size="small" onClick={handleOpen}>
        Update Follow up
      </Button>
      <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose} aria-labelledby="followup-dialog-title">
        <DialogTitle id="followup-dialog-title">{!fullScreen && 'Update Follow up / ผลการติดตาม'}</DialogTitle>
        <DialogContent>
          <Box marginBottom={4}>
            <Grid container spacing={2}>
              <Grid item container>
                <Grid item xs={12} sm={12} md={6}>
                  <FormInput fullWidth label="Status of Follow / สถานะการติดตาม" id="followUpStatus">
                    <Select
                      value={values.followUpStatus}
                      onChange={handleChange}
                      inputProps={{
                        name: 'followUpStatus',
                        id: 'followUpStatus-input',
                      }}>
                      <MenuItem value="Inprocess">Inprocess</MenuItem>
                      <MenuItem value="Completed">Completed</MenuItem>
                    </Select>
                  </FormInput>
                </Grid>
              </Grid>
              <Grid item container spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                  <FormInput fullWidth label="Plan: Start Date / วันที่เริ่มตามแผน" id="planStartDate">
                    <DatePicker
                      name="planStartDate"
                      disabled={disabled}
                      autoOk
                      clearable
                      showTodayButton
                      margin="normal"
                      format={DEFAULT_DATE_PICKER_FORMAT}
                      value={values.planStartDate}
                      onChange={handleDateChange('planStartDate')}
                    />
                  </FormInput>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <FormInput fullWidth label="Plan: Completed Date / วันที่กำหนดแล้วเสร็จ" id="planCompletedDate">
                    <DatePicker
                      name="planCompletedDate"
                      disabled={disabled}
                      autoOk
                      clearable
                      showTodayButton
                      margin="normal"
                      format={DEFAULT_DATE_PICKER_FORMAT}
                      value={values.planCompletedDate}
                      onChange={handleDateChange('planCompletedDate')}
                    />
                  </FormInput>
                </Grid>
              </Grid>
              <Grid item container spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                  <FormInput fullWidth label="Actual: Start Date / วันที่เริ่มดำเนินการ" id="actualStartDate">
                    <DatePicker
                      name="actualStartDate"
                      disabled={disabled}
                      autoOk
                      clearable
                      showTodayButton
                      margin="normal"
                      format={DEFAULT_DATE_PICKER_FORMAT}
                      value={values.actualStartDate}
                      onChange={handleDateChange('actualStartDate')}
                    />
                  </FormInput>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <FormInput
                    fullWidth
                    label="Actual: Completed Date / วันที่ดำเนินการแล้วเสร็จ"
                    id="actualCompletedDate">
                    <DatePicker
                      name="actualCompletedDate"
                      disabled={disabled}
                      autoOk
                      clearable
                      showTodayButton
                      margin="normal"
                      format={DEFAULT_DATE_PICKER_FORMAT}
                      value={values.actualCompletedDate}
                      onChange={handleDateChange('actualCompletedDate')}
                    />
                  </FormInput>
                </Grid>
              </Grid>
              <Grid item container>
                <Grid item xs={12} sm={12} md={12}>
                  <FormInput fullWidth label="Remark / หมายเหตุการติดตาม" id="remark">
                    <TextInput
                      multiline
                      rows={3}
                      rowsMax={10}
                      name="remark"
                      defaultValue={values.remark || ''}
                      onChange={handleChange}
                    />
                  </FormInput>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <LoadingButton onClick={formSubmit} variant="contained" color="primary" hidden={disabled} autoFocus>
            Save
          </LoadingButton>
          <LoadingButton onClick={handleClose} variant="outlined" color="default">
            Cancel
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AdminReviewFollowUpPopup;
