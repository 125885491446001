import React, { useState } from 'react';
import { Box, Grid } from '@material-ui/core';

import { Section, Question, ControlTypes } from '../models';
import { useMoveable } from './assessment/AssessmentContext';
import InputText from './InputText';
import ToolbarComponent from './ToolbarComponent';
import { ComponentProps } from './question/ComponentProps';
import { useFormValidation } from './useFormValidation';
import Loading from './Loading';
import { FormInput } from './form';
import AddQuestionButton from './assessment/AddQuestionButton';

export interface SectionComponentProps extends ComponentProps<Section> {
  questions?: Question[];
  handleAddItem?: (type: ControlTypes) => Promise<any>;
  handleUpdate?: (section: Section) => any;
  isLoading?: boolean;
  readonly children?: JSX.Element | JSX.Element[];
  disabled?: boolean;
}

const SectionComponent = ({
  data,
  isLoading = false,
  handleAddItem,
  handleDelete,
  children,
  handleUpdate,
  disabled,
}: SectionComponentProps) => {
  const { moveUp, moveDown } = useMoveable('section', data.id);
  const { values, handleChange, handleKeyUp, hasChanged, setHasChanged } = useFormValidation({
    initialValues: data,
    type: Section,
  });
  const [isProcessing, setIsProcessing] = useState(false);

  function _handleUpdate() {
    if (hasChanged) {
      if (handleUpdate) {
        handleUpdate(values as Section);
      }
      setHasChanged(false);
    }
  }

  function _handleAddItem(type: ControlTypes) {
    setIsProcessing(true);
    handleAddItem(type).finally(() => setIsProcessing(false));
  }

  return (
    <Box borderRadius={2} marginTop={1}>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12} md={1}>
          <FormInput fullWidth id="no" label="ลำดับ / No" disabled={disabled}>
            <InputText
              type="text"
              value={values.no || ''}
              onChange={handleChange}
              id="no"
              name="no"
              placeholder="ลำดับ"
              onKeyUp={handleKeyUp}
              onBlur={() => _handleUpdate()}
            />
          </FormInput>
        </Grid>
        <Grid item xs={12} md={8}>
          <FormInput fullWidth disabled={disabled} id="title" label="ชื่อหัวข้อ / Section's Title">
            <InputText
              type="text"
              id="title"
              value={values.title || ''}
              onChange={handleChange}
              name="title"
              placeholder="Section's Title"
              onKeyUp={handleKeyUp}
              onBlur={() => _handleUpdate()}
              multiline
              rows={3}
              rowsMax={10}
            />
          </FormInput>
        </Grid>
        <Grid item xs={12} md={3}>
          <ToolbarComponent
            itemId={data.id}
            handleDelete={handleDelete}
            handleUp={moveUp}
            handleDown={moveDown}
            disabled={disabled}
            disableCopy
          />
        </Grid>
      </Grid>

      <Box marginTop={1} marginBottom={1}>
        {children}
      </Box>

      {isProcessing && (
        <Box marginTop={1} marginBottom={1}>
          <Loading />
        </Box>
      )}

      <Grid container direction="row" spacing={2}>
        <Grid item xs={12}>
          <Box paddingLeft={3}>{!disabled && <AddQuestionButton handleAddItem={_handleAddItem} nested />}</Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SectionComponent;
