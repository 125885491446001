import React, { createContext, useState, useContext } from 'react';
import { AssessmentForm } from '../models/assessment-dto';
import { ComponentManager } from '../models/aggregator';

export const AssessmentTemplateDispatch = createContext(null);
export const AssessmentTemplateState = createContext(null);

export interface AssessmentTemplateProviderProps {
  data?: AssessmentForm;
}

export const AssessmentTemplateProvider: React.FC<AssessmentTemplateProviderProps> = ({
  children,
  data: inputData,
}) => {
  const [data, setData] = useState<AssessmentForm>(inputData);

  const value = { data, setData };

  const dispatch = {
    information: data.information,
    attachments: data.attachments,
    questions: data.questions,
    sections: data.sections,
    components: data.components,
  };

  return (
    <AssessmentTemplateState.Provider value={value}>
      <AssessmentTemplateDispatch.Provider value={dispatch}>{children}</AssessmentTemplateDispatch.Provider>
    </AssessmentTemplateState.Provider>
  );
};

export const useAssessmentTemplate = () => {
  return useContext(AssessmentTemplateDispatch);
};

export const useComponentManager = () => {
  const { questions, sections } = useContext(AssessmentTemplateDispatch);
  return new ComponentManager(questions, sections);
};
