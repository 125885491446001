import { Divider, ExpansionPanelDetails, Grid, Typography, Box, Tooltip, Button } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ExpansionPanelProps } from '@material-ui/core/ExpansionPanel';
import AttachmentIcon from '@material-ui/icons/AttachFile';
import React from 'react';

import { Assessment, AttachmentFile } from '../../models';
import AddAttachmentDialog from '../AddAttachmentDialog';
import AttachmentTable from '../AttachmentTable';
import { useAssessmentManager, useAttachmentManager } from './AssessmentContext';
import { AssessmentPanel, AssessmentPanelSummary } from './AssessmentPanel';
import { useAttachment } from '../useAttachment';
import {onBulkDownload} from "../../utils/onBulkDownload"


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
  alignRight: {
    justifyContent: 'space-between',
    display: 'flex',
  },
  button: {
    background: theme.palette.secondary.main,
    color: theme.palette.common.white,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightMedium,
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  formAction: {
    padding: '16px 18px',
  },
  content: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    display: 'block',
  },
}));

export interface AttachmentFilesSectionProps {
  attachments: AttachmentFile[];
  assessment?: Assessment;
  expansionProps?: ExpansionPanelProps;
}

const AttachmentFilesSection = ({ assessment, attachments = [], expansionProps }: AttachmentFilesSectionProps) => {
  const classes = useStyles({});
  const { uploadAttachment: _uploadAttachment, uploadProgress } = useAttachment();
  const { isProcessing, permissions } = useAssessmentManager();
  const { uploadAttachment: uploadFn, deleteAttachment, updateAttachment } = useAttachmentManager();

  const disabled = !permissions.editable;
  return (
    <AssessmentPanel {...expansionProps}>
      <AssessmentPanelSummary aria-controls="attachment-content" id="attachment-header">
        <Typography className={classes.heading}>
          <AttachmentIcon color="primary" />
          เอกสารแนบ / Attachment
        </Typography>
      </AssessmentPanelSummary>
      <Divider variant="middle" />
      <ExpansionPanelDetails>
        {assessment.no ? (
           <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                  {!disabled && (
                    <Box className={classes.alignRight}>
                      <AddAttachmentDialog
                        itemId={assessment.no}
                        isLoading={isProcessing('add-attachment')}
                        uploadProgress={uploadProgress}
                        onSubmit={data => _uploadAttachment(data, 'assessment', uploadFn)}
                      />
                          <Tooltip title="Download All" aria-label="Download All">
                              <Button variant="contained" color="secondary" size="small" 
                                className={classes.button}
                                onClick={()=>{onBulkDownload({attachments, assessmentNo: assessment.no})}} 
                                >
                                  Download All
                                </Button>
                          </Tooltip>
                        </Box>
                  )}
                </Grid>

              <Grid item xs={12} md={12}>
                <AttachmentTable
                  files={attachments}
                  loading={isProcessing('edit-attachment') || isProcessing('delete-attachment')}
                  handleEdit={updateAttachment}
                  handleDelete={deleteAttachment}
                  disabled={disabled}
                />
              </Grid>


            </Grid>
          </>
        ) : (
          <Typography variant="h6" gutterBottom>
            กรุณาทำการบันทึกใบประเมินก่อน เพื่อทำการแนบไฟล์
          </Typography>
        )}
      </ExpansionPanelDetails>
    </AssessmentPanel>
  );
};

export default AttachmentFilesSection;