import { Level, Department, Employee } from '../models';

export const toLevelOption = (level: Level) => ({ label: level.name, value: level.no.toString() });
export const toDepartmentOption = (department: Department) => ({
  label: `${department.shortName} ${department.nameEn}`,
  value: department.id,
});
export const toEmployeeOption = (emp: Employee) => ({
  label: `${emp.initialName} ${emp.firstName} ${emp.lastName}`,
  value: emp.id,
});
