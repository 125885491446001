import React from 'react';

import { ExportToolbar } from './ExportToolbar';
import { useReport2 } from './Report2Context';
import { Report2ResultTableView } from './Report2ResultTableView';
import { Report2Title } from './Report2Title';

export const Report2Result = () => {
  const { exportExcel, exportWord, exportPdf, isProcessing, data } = useReport2();
  return (
    <div>
      <Report2Title />
      <ExportToolbar
        disabled={!data}
        excelFn={exportExcel}
        wordFn={exportWord}
        pdfFn={exportPdf}
        enabled={['excel', 'pdf', 'word']}
        excelLoading={isProcessing('excel-exporting')}
        wordLoading={isProcessing('word-exporting')}
        pdfLoading={isProcessing('pdf-exporting')}
      />
      <Report2ResultTableView />
    </div>
  );
};

function parseAnswer(question, answer) {
  switch (question.control) {
    case 'yesno': return answer.yesNo ? 'ใช่' : 'ไม่ใช่';
    case 'checkbox': {
      return answer.choiceIds
        .map(choiceId => {
          const choice = question.choices.find(({ id }) => id === choiceId);
          return choice && choice.title;
        })
        .join('\r\n');
    }
    case 'choice': {
      const answerChoice = question.choices.find(({ id }) => id === answer.choiceId)
      return answerChoice && answerChoice.title
    }
    case 'text':
      return answer.detail === null ? '-' : answer.detail;
    case 'rating':
      return answer.rating === null ? '-' : answer.rating;
    case 'ranking': {
      if (!answer.choiceIds) return ''
      return answer.choiceIds.map(i => i.split(':'))
        .sort(([, rank1], [, rank2]) => Number(rank1) - Number(rank2))
        .map(([choiceId], index) => {
          const choice = question.choices.find(({ id }) => id === choiceId);
          return `${index + 1}. ${choice.title}`;
        })
        .join(', ');
    }
    case 'date':
      return answer.date === null ? '-' : answer.date;
  }
}