import useAxios from 'axios-hooks';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';

import { MASTER_PROCESS_NAME_URI } from '../../apis/constants';
import * as ProcessNameService from '../../apis/process-name';
import ContentWrapper from '../../components/ContentWrapper';
import MainLayout from '../../components/MainLayout';
import ProcessNameTable from '../../components/masterdata/ProcessNameTable';
import { ProcessName } from '../../models';
import { ProcessNameCreateRequest, ProcessNameUpdateRequest } from '../../models/master-dto';
import { ERROR_TEXT, errorOptions } from '../../utils/snackbar';

const MasterProcessName = () => {
  const [{ data: payload = {} as any, loading, error }, refetch] = useAxios(MASTER_PROCESS_NAME_URI, { manual: true });
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    refetch()
  }, [])

  if (error) enqueueSnackbar(ERROR_TEXT, errorOptions);

  return (
    <MainLayout>
      <ContentWrapper>
        <ProcessNameTable
          data={payload.data || []}
          isLoading={loading}
          onAdd={handleAdd}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
        />
      </ContentWrapper>
    </MainLayout>
  );
  function handleAdd(processName: ProcessNameCreateRequest) {
    return ProcessNameService.create(processName).then(() => refetch());
  }

  function handleUpdate(newData: ProcessName, oldData: ProcessNameUpdateRequest) {
    return ProcessNameService.update(newData.id, newData).then(() => refetch());
  }
  function handleDelete(processName: ProcessName) {
    return ProcessNameService.deleteOne(processName.id).then(() => refetch());
  }
};

export default MasterProcessName;
