import { Box, Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import ContentWrapper from '../../components/ContentWrapper';
import MainLayout from '../../components/MainLayout';
import { Report8Provider } from '../../components/report/Report8Context';
import Report8Filter from '../../components/report/Report8Filter';
import { Report8Result } from '../../components/report/Report8Result';
import { Report8Title } from '../../components/report/Report8Title';

const useStyles = makeStyles(() => ({
  paper: {
    padding: 0,
  },
}));

export const Report8Page = () => {
  const classes = useStyles({});

  return (
    <MainLayout>
      <ContentWrapper>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Report8Title />
          </Grid>
        </Grid>
        <Report8Provider>
          <Paper className={classes.paper}>
            <Box padding="10px">
              <Report8Filter />
              <Box marginTop={4} padding={3}>
                <Report8Result />
              </Box>
            </Box>
          </Paper>
        </Report8Provider>
      </ContentWrapper>
    </MainLayout>
  );
};

export default Report8Page;
