import React, { createContext, useContext, useState } from 'react';
import siteSettings from '../../siteSettings';

export interface AppStateProps {
  sidebarOpen: boolean;
  sidebarToggle: (v: boolean) => void | any;
  title: string;
  setTitle: (v: string) => any;
  resetTitle: () => any;
}

const defaultProps = {
  sidebarOpen: false,
  sidebarToggle: (v: boolean) => {},
  title: siteSettings.title,
  setTitle: () => {},
  resetTitle: () => {},
};

export const AppStateContext = createContext<AppStateProps>(defaultProps);

export function AppStateProvider(props: any) {
  const [state, setState] = useState<{ sidebarOpen: boolean }>({
    sidebarOpen: false,
  });
  const [title, setTitle] = useState<string>();

  const sidebarToggle = (sidebarOpen: boolean) => setState({ ...state, sidebarOpen });
  const resetTitle = () => setTitle(siteSettings.title);

  const context: AppStateProps = {
    sidebarOpen: state.sidebarOpen,
    sidebarToggle,
    title,
    setTitle,
    resetTitle,
  };

  return <AppStateContext.Provider value={context}>{props.children}</AppStateContext.Provider>;
}

export function useAppState() {
  return useContext(AppStateContext);
}
