import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

type ColumnImageProps = {
  title: string;
  imageUrl: string;
}

const useStyles = makeStyles(() => ({
  image: {
    maxWidth: 120,
    height: 'auto',
  }
}))

export const ColumnImage: React.FC<ColumnImageProps> = ({ title, imageUrl }) => {
  const classes = useStyles({})
  return <img alt={title} src={imageUrl} className={classes.image} />
}