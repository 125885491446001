import React from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody, Checkbox } from '@material-ui/core';
import { Assessor } from '../models';

export interface CommentatorTableProps {
  list?: Assessor[];
  onCheck?: (assessor: Assessor, checked: boolean) => void;
  disabled?: boolean;
}

const CommentatorTable = ({ list = [], onCheck, disabled }: CommentatorTableProps) => {
  const onCheckChange = (assessor: Assessor, checked: boolean) => {
    if (onCheck) onCheck(assessor, checked);
  };

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>No.</TableCell>
          <TableCell>ระดับหน่วยงาน / Unit Level</TableCell>
          <TableCell>ชื่อหน่วยงาน / Unit Name</TableCell>
          <TableCell>ผู้ประเมิน / Initial Name</TableCell>
          <TableCell>ผู้สรุปความคิดเห็น</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {list.length === 0 ? (
          <TableRow>
            <TableCell colSpan={6}>ไม่มีข้อมูล</TableCell>
          </TableRow>
        ) : (
          list.map((assessor, index) => (
            <TableRow key={index}>
              <TableCell>{index + 1}</TableCell>
              <TableCell>{assessor.departmentLevelName}</TableCell>
              <TableCell>{assessor.departmentFullNameEn}</TableCell>
              <TableCell>
                {assessor.employeeInitialName} {assessor.employeeName}
              </TableCell>
              <TableCell>
                <Checkbox
                  disabled={assessor.isAssigned || disabled}
                  onChange={e => onCheckChange(assessor, e.target.checked)}
                  defaultChecked={!!assessor.commentator}
                  value="commentator"
                  name="commentator"
                  style={{ padding: 0 }}
                  inputProps={{
                    'aria-label': 'commentator checkbox',
                  }}
                />
              </TableCell>
            </TableRow>
          ))
        )}
      </TableBody>
    </Table>
  );
};

export { CommentatorTable as Commentator };

export default CommentatorTable;
