import { MenuItem, Select } from '@material-ui/core';
import React from 'react';

import { useWorklist } from '../../context';
import { FormInput } from '../form';
import InputBase from '../InputBase';

export const WorklistStatusOption = () => {
  const { setFilterByKey, filter } = useWorklist();

  return (
    <FormInput fullWidth id="form-status" label="สถานะ / Status" required>
      <Select
        onChange={(e: any) => {
          setFilterByKey('status', e.target.value);
        }}
        input={<InputBase name="status" id="status" value={filter.status} />}>
        <MenuItem value="all">All</MenuItem>
        <MenuItem value="pending">Pending</MenuItem>
        <MenuItem value="inprocess">In Process</MenuItem>
        <MenuItem value="completed">Completed</MenuItem>
        <MenuItem value="cancelled">Cancelled</MenuItem>
      </Select>
    </FormInput>
  );
};

export default WorklistStatusOption;
