import React from 'react';

import { Grid, IconButton, Tooltip } from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import DeleteIcon from '@material-ui/icons/Delete';
import CopyIcon from '@material-ui/icons/FileCopy';

import { ToolbarComponentProps as Props } from './question/ComponentProps';
import { GridProps } from '@material-ui/core/Grid';

interface ToolbarComponentProps extends Props, GridProps {
  itemId: string;
  disabled?: boolean;
  disableCopy?: boolean;
}

const ToolbarComponent = ({
  itemId,
  handleDelete,
  handleCopy,
  handleUp,
  handleDown,
  disabled,
  disableCopy,
  ...props
}: ToolbarComponentProps) => (
  <Grid container direction="row" justify="flex-end" {...props}>
    <Grid item>
      <Tooltip title="Delete" aria-label="Delete">
        <IconButton disabled={disabled} aria-label="Delete" size="medium" onClick={() => handleDelete(itemId)}>
          <DeleteIcon fontSize="inherit" />
        </IconButton>
      </Tooltip>
    </Grid>
    <Grid item hidden={disableCopy}>
      <Tooltip title="Copy" aria-label="Copy">
        <IconButton disabled={disabled} aria-label="Copy" size="medium" onClick={() => handleCopy(itemId)}>
          <CopyIcon fontSize="inherit" />
        </IconButton>
      </Tooltip>
    </Grid>
    <Grid item>
      <Tooltip title="Move Up" aria-label="Move Up">
        <IconButton disabled={disabled} aria-label="Up" size="medium" onClick={() => handleUp(itemId)}>
          <ArrowUpwardIcon fontSize="inherit" />
        </IconButton>
      </Tooltip>
    </Grid>
    <Grid item>
      <Tooltip title="Move Down" aria-label="Move Down">
        <IconButton disabled={disabled} aria-label="Down" size="medium" onClick={() => handleDown(itemId)}>
          <ArrowDownwardIcon fontSize="inherit" />
        </IconButton>
      </Tooltip>
    </Grid>
  </Grid>
);

export default ToolbarComponent;
