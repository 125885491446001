import { Box, Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderBottom: '1px solid #f7f7f7',
  },
  statusCard: {},
  left: {
    padding: '4px 16px',
  },
}));

export const TaskItemSkeleton = () => {
  const classes = useStyles({});

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12} sm={8} md={8} lg={8}>
          <div className={classes.left}>
            <Skeleton height={30} width="50%" />
            <Skeleton height={15} width="70%" />
            <Skeleton height={12} width="50%" />
            <Skeleton height={12} width="70%" />
            <Skeleton height={12} width="50%" />
          </div>
        </Grid>
        <Grid item container xs={12} sm={2} md={2} lg={4} classes={{ item: classes.statusCard }}>
          <Grid item md={8} style={{ paddingRight: 24 }}>
            <Box paddingTop={3}>
              <Skeleton height={20} width="100%" />
              <Skeleton height={15} width="100%" />
            </Box>
          </Grid>
          <Grid item md={4}>
            <Skeleton variant="rect" height="100%" width="100%" />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default TaskItemSkeleton;
