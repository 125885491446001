import React from 'react';

import { Report1ResultTableView } from './Report1ResultTableView';
import { Report1Title } from './Report1Title';
import { ExportToolbar } from './ExportToolbar';
import { useReport1 } from './Report1Context';

export const Report1Result = () => {
  const { exportExcel, isProcessing, data } = useReport1();

  return (
    <div>
      <Report1Title />
      <ExportToolbar
        disabled={!data}
        excelFn={exportExcel}
        enabled={['excel']}
        excelLoading={isProcessing('excel-exporting')}
      />
      <Report1ResultTableView />
    </div>
  );
};
