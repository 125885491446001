import React from 'react';
import LoadingButton, { ButtonProps } from './LoadingButton';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  submitColor: {
    color: theme.palette.common.white,
    backgroundColor: '#00b278',
    '&:hover': {
      backgroundColor: '#008a5d',
    },
  },
}));

export const SubmitButton = (props: ButtonProps) => {
  const classes = useStyles({});
  return (
    <LoadingButton className={classes.submitColor} {...props}>
      Submit
    </LoadingButton>
  );
};

export default SubmitButton;
