import { OptionsObject } from 'notistack';

const globalOptions: Partial<OptionsObject> = {
  autoHideDuration: 3000,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
};

export const defaultOptions: OptionsObject = {
  variant: 'default',
  ...globalOptions,
};

export const errorOptions: OptionsObject = {
  variant: 'error',
  ...globalOptions,
};

export const successOptions: OptionsObject = {
  variant: 'success',
  ...globalOptions,
};

export const infoOptions: OptionsObject = {
  variant: 'info',
  ...globalOptions,
};

export const warningOptions: OptionsObject = {
  variant: 'warning',
  ...globalOptions,
};

export const ERROR_TEXT = 'Something went wrong';

export const errorCbTemplate = (enqueueSnackbar: any) => (ex: Error) => {
  console.error(ex);
  enqueueSnackbar(ERROR_TEXT + ': ' + ex.message, { ...errorOptions });
  return Promise.reject(ex);
};
