import { Box, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import withMobileDialog, { WithMobileDialog } from '@material-ui/core/withMobileDialog';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';

import { Assessor } from '../models';
import { apiMessages } from '../siteSettings';
import UpdateAssessorDialog from './UpdateAssessorDialog';
import { useAssessorManager } from './assessment/AssessmentContext';

export interface AssessorTableProps extends WithMobileDialog {
  componentId?: string;
  questionId?: string;
  assessorList?: Assessor[];
  handleDelete?: (componentId: string, questionId: string, assessor: Assessor) => Promise<any>;
  isLoading?: boolean;
  disabled?: boolean;
}

const AssessorTable = ({
  assessorList = [],
  componentId,
  questionId,
  handleDelete: _handleDelete,
  isLoading,
  disabled,
}: AssessorTableProps) => {
  const { updateAssessor, isLoading: updating } = useAssessorManager();
  function handleDelete(componentId: string, questionId: string, assessor: Assessor) {
    if (window.confirm(apiMessages.confirmPopup)) {
      _handleDelete(componentId, questionId, assessor);
    }
  }

  function handleUpdate(assessor: Assessor) {
    return updateAssessor(componentId, questionId, assessor);
  }

  return (
    <Box border={1} bgcolor="common.white" borderColor="grey.300">
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>No.</TableCell>
            <TableCell>ระดับหน่วยงาน / Unit Level</TableCell>
            <TableCell>ชื่อหน่วยงาน / Unit Name</TableCell>
            <TableCell>ชื่อ-นามสกุล / Initial Name</TableCell>
            <TableCell>ประเภทผู้ประเมิน / Assessor Type</TableCell>
            <TableCell style={{ width: '10%' }}>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!assessorList.length ? (
            <TableRow>
              <TableCell colSpan={6}>ไม่มีข้อมูล</TableCell>
            </TableRow>
          ) : (
            assessorList.map((assessor, no) => (
              <TableRow key={assessor.employeeId}>
                <TableCell>{no + 1}</TableCell>
                <TableCell>{assessor.departmentLevelName}</TableCell>
                <TableCell>{assessor.departmentFullNameEn}</TableCell>
                <TableCell>
                  {assessor.employeeInitialName} {assessor.employeeName}
                </TableCell>
                <TableCell>{assessor.assessorType ? 'ผู้ประเมินหลัก' : 'ผู้ประเมินรอง'}</TableCell>
                <TableCell>
                  <UpdateAssessorDialog
                    assessor={assessor}
                    isLoading={updating || isLoading}
                    handleSubmit={handleUpdate}
                    disabled={isLoading || disabled}
                  />
                  <IconButton
                    color="primary"
                    size="small"
                    disabled={isLoading || disabled}
                    onClick={() => handleDelete(componentId, questionId, assessor)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </Box>
  );
};

export default withMobileDialog()(AssessorTable);
