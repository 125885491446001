import React from 'react';
import { Typography } from '@material-ui/core';
import { apiMessages } from '../../siteSettings';

export const Report4IndividualTitle = () => {
  return (
    <Typography variant="h5" gutterBottom>
      {apiMessages.report.rpt04}
    </Typography>
  );
};
