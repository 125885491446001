import { client } from './client';
import { ANNOUNCEMENT_URI } from './constants';
import { AnnouncementCreateRequest, AnnouncementUpdateRequest } from '../models/master-dto';

export async function getOne(id: number) {
  return await client.get(`${ANNOUNCEMENT_URI}/${id}`, {});
}

export async function getAll() {
  return await client.get(`${ANNOUNCEMENT_URI}`, {});
}

export async function create(request: AnnouncementCreateRequest) {
  return await client.post(`${ANNOUNCEMENT_URI}`, request, {});
}

export async function update(id: number, request: AnnouncementUpdateRequest) {
  return await client.put(`${ANNOUNCEMENT_URI}/${id}`, request, {});
}

export async function deleteOne(id: number) {
  return await client.delete(`${ANNOUNCEMENT_URI}/${id}`, {});
}
