import { Box, Divider, Grid, Typography, Paper } from '@material-ui/core';
import React from 'react';
import Pagination from 'material-ui-flat-pagination';

import TaskItem from '../components/worklist/TaskItem';
import TaskItemSkeleton from '../components/worklist/TaskItemSkeleton';
import TaskList from '../components/worklist/TaskList';
import WorklistFilter from '../components/worklist/WorklistFilter';
import WorklistSearch from '../components/worklist/WorklistSearch';
import { useWorklist } from '../context';
import { PaginationLimitOption } from '../components/PaginationLimitOption';

export const WorklistContainer = () => {
  const { worklist, submit, filter, setFilterByKey, loading } = useWorklist();

  const onPageChange = (e, offset, page) => {
    setFilterByKey('page', page);
    submit({
      ...filter,
      page: page,
    });
  };

  const onLimitChange = e => {
    const limit = e.target.value;
    setFilterByKey('limit', limit);
    submit({
      ...filter,
      limit: limit,
      page: 1,
    });
  };
//  console.log("-------------");
//  console.log(worklist);
//  console.log("-------------");
 
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item md={12}>
          <WorklistSearch />
        </Grid>
        <Grid item md={9}>
          <Paper>
            <TaskList />

            {loading ? (
              Array(5)
                .fill('-')
                .map((_, i) => <TaskItemSkeleton key={i} />)
            ) : worklist && worklist.count === 0 ? (
              <EmptyData />
            ) : (
              worklist &&
              worklist.items.map(task => (
                <div key={`${task.information.no}-${task.rootId}`}>
                  <TaskItem task={task} />
                  <Divider />
                </div>
              ))
            )}
          </Paper>
        </Grid>
        <Grid item md={3}>
          <WorklistFilter />
        </Grid>
      </Grid>
      <Box marginTop={2} />
      <Grid container>
        <Grid item md={9}>
          <Grid item container>
            <Grid item md={9}>
              {worklist && (
                <Pagination
                  currentPageColor="primary"
                  
                  otherPageColor="default"
                  limit={worklist.limit}
                  offset={(worklist.page - 1) * worklist.limit}
                  total={worklist.totalItems}
                  onClick={onPageChange}
                />
              )}
            </Grid>
            <Grid item md={3}>
              {worklist && (
                <PaginationLimitOption onChange={onLimitChange} value={worklist.limit} limit={[5, 10, 15, 20]} />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

function EmptyData() {
  return (
    <div>
      <Grid container>
        <Grid item xs={12} sm={8} md={8} lg={8}>
          <Box padding={2}>
            <Typography variant="body1">ไม่มีข้อมูล</Typography>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default WorklistContainer;
