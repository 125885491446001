import { Grid, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import moment from 'moment';
import React, { memo } from 'react';

import { Assessment } from '../../models';
import ComponentText from '../ComponentText';
import { FormInput } from '../form';

const useStyles = makeStyles((theme: Theme) => ({
  information: {
    margin: '15px 0px 0px 0px',
  },
}));

const areEquals: (
  prevProps: Readonly<
    React.PropsWithChildren<{
      information: Assessment;
    }>
  >,
  nextProps: Readonly<
    React.PropsWithChildren<{
      information: Assessment;
    }>
  >,
) => boolean = (prev, next) => {
  return [prev.information.no === next.information.no, prev.information.endDate === next.information.endDate].every(
    i => i,
  );
};

const InformationSectionView = memo(({ information }: { information: Assessment }) => {
  const classes = useStyles({});

  const disabled = information.currentStatus !== 'Assessment';

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6} md={4}>
        <FormInput fullWidth id="document-code" label="รหัสแบบประเมิน / Document Code">
          <ComponentText className={classes.information}>{`${information.code}`}</ComponentText>
        </FormInput>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <FormInput fullWidth id="request-no" label="รหัสการสร้างเอกสาร / Request No.">
          <Typography component="div" className={classes.information}>
            {`${information.no}`}
          </Typography>
        </FormInput>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <FormInput fullWidth id="created-by" label="ผู้สร้าง / Created By">
          <Typography component="div" className={classes.information}>
            {`${information.createdByInitialName} ${information.createdByName}`}
          </Typography>
        </FormInput>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <FormInput fullWidth id="year" label="ปี / Year">
          <Typography component="div" className={classes.information}>
            {`${information.year}`}
          </Typography>
        </FormInput>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <FormInput fullWidth id="round" label="ครั้งที่ / Round">
          <Typography component="div" className={classes.information}>
            {`${information.round}`}
          </Typography>
        </FormInput>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <FormInput fullWidth id="year" label="ระดับ / Level">
          <Typography component="div" className={classes.information}>
            {`${information.level}`}
          </Typography>
        </FormInput>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <FormInput fullWidth id="startDate" label="วันที่เปิดรับประเมิน / Opening Date">
          <Typography component="div" className={classes.information}>
            {`${moment(information.startDate).format('DD/MM/YYYY')}`}
          </Typography>
        </FormInput>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <FormInput fullWidth id="endDate" label="วันที่ปิดรับประเมิน / Closing Date">
          <Typography component="div" className={classes.information}>
            {`${moment(information.endDate).format('DD/MM/YYYY')}`}
          </Typography>
        </FormInput>
      </Grid>
      <Grid item xs={12} sm={6} md={4} />
      <Grid item xs={12} sm={6} md={4}>
        <FormInput fullWidth id="status" label="สถานะการใช้งาน / Status">
          <Typography component="div" className={classes.information}>
            {`${information.currentStatus}`}
          </Typography>
        </FormInput>
      </Grid>
      {information.level === 'PC' && (
        <>
          <Grid item xs={12} sm={6} md={4}>
            <FormInput fullWidth id="process-name" label="ชื่อกระบวนการ / Process Name">
              <Typography component="div" className={classes.information}>
                {`${information.processName}`}
              </Typography>
            </FormInput>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormInput fullWidth id="level-unit" label="ระดับหน่วยงาน / Level Unit">
              <Typography component="div" className={classes.information}>
                {`${information.processOwnerLevelName}`}
              </Typography>
            </FormInput>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <FormInput fullWidth id="unit-name" label="ชื่อหน่วยงาน / Unit Name">
              <Typography component="div" className={classes.information}>
                {`${information.processOwnerDepartmentFullNameTh}`}
              </Typography>
            </FormInput>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormInput fullWidth id="process-owner" label="เจ้าของกระบวนการ / Process Owner">
              <Typography component="div" className={classes.information}>
                {`${information.processOwnerName}`}
              </Typography>
            </FormInput>
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <FormInput fullWidth id="description" label="คำอธิบาย / Description">
          <ComponentText className={classes.information}>{information.description || '-'}</ComponentText>
        </FormInput>
      </Grid>
    </Grid>
  );
}, areEquals);

export default InformationSectionView;
