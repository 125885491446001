import React, { useState } from 'react';
import { ControlTypes } from '../../models';

import { Box, Button, CircularProgress, Grid, IconButton } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles((theme: Theme) => ({
  leftIcon: {
    marginRight: theme.spacing(0.765),
  },
  button: {
    padding: '2px 8px',
    fontSize: '0.6165rem',
  },
  buttonType: {
    padding: '2px 8px',
    fontSize: '0.6765rem',
  },
  iconButton: {},
}));

const types: { name: string; controlType: ControlTypes; nested: boolean }[] = [
  {
    controlType: 'section',
    name: 'Section',
    nested: false,
  },
  {
    controlType: 'yesno',
    name: 'Yes/No',
    nested: true,
  },
  {
    controlType: 'choice',
    name: 'Choice',
    nested: true,
  },
  {
    controlType: 'checkbox',
    name: 'Checkbox',
    nested: true,
  },
  {
    controlType: 'text',
    name: 'Text',
    nested: true,
  },
  {
    controlType: 'rating',
    name: 'Rating',
    nested: true,
  },
  {
    controlType: 'ranking',
    name: 'Ranking',
    nested: true,
  },
  {
    controlType: 'date',
    name: 'Date',
    nested: true,
  },
];

export interface AddQuestionButtonProps {
  nested?: boolean;
  handleAddItem?: (type: ControlTypes) => any;
  isLoading?: boolean;
}

const AddQuestionButton = ({ handleAddItem, nested = false, isLoading = false }: AddQuestionButtonProps) => {
  const classes = useStyles({});
  const [showItems, setShowItems] = useState(false);

  const handleOpen = () => setShowItems(true);
  const handleClose = () => setShowItems(false);

  const onSelectComponent = (type: ControlTypes) => {
    if (handleAddItem) {
      handleAddItem(type);
    }

    handleClose();
  };

  return (
    <div>
      {showItems ? (
        <Box marginTop={1} marginBottom={2} marginLeft={2}>
          <Grid container spacing={2}>
            <Grid item>
              <IconButton className={classes.iconButton} aria-label="Close" size="small" onClick={handleClose}>
                <CancelIcon fontSize="inherit" />
              </IconButton>
            </Grid>
            {types
              .filter(type => (nested ? type.nested : true))
              .map(type => (
                <Grid item key={type.controlType}>
                  <Button
                    variant="text"
                    className={classes.buttonType}
                    onClick={() => onSelectComponent(type.controlType)}>
                    {type.name}
                  </Button>
                </Grid>
              ))}
          </Grid>
        </Box>
      ) : (
        <Button disabled={isLoading} variant="outlined" color="primary" onClick={handleOpen} className={classes.button}>
          {isLoading ? <CircularProgress size={24} /> : <AddIcon className={classes.leftIcon} />}
          Add Question
        </Button>
      )}
    </div>
  );
};

export default AddQuestionButton;
