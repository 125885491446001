import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';

import MainLayout from './MainLayout';
import Loading from './Loading';
import ContentWrapper from './ContentWrapper';

const useStyles = makeStyles((theme: Theme) => ({
  loading: {
    paddingTop: theme.spacing(20),
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(7),
    },
    textAlign: 'center',
  },
}));

const LoadingContent = () => {
  const classes = useStyles({});

  return (
    <MainLayout>
      <ContentWrapper>
        <div className={classes.loading}>
          <Loading />
        </div>
      </ContentWrapper>
    </MainLayout>
  );
};

export default LoadingContent;
