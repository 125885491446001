import { Box, Grid, Tooltip, IconButton, Button } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import debounce from 'lodash/debounce';
import moment from 'moment';
import React, { lazy, Suspense, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { AssessorRole, Question, Section } from '../../models';
import { AdminReviewQuestionInputBox } from '../AdminReviewInputBox';
import { useAssessmentManager, useQuestionManager } from '../assessment/AssessmentContext';
import { QuestionAnswerDto, useAssessmentRequest } from '../assessment/AssessmentRequestContext';
import StatusVisible from '../assessment/StatusVisible';
import { useUserState } from '../context/UserStateContext';
import { useAttachment } from '../useAttachment';
import { QuestionViewTitle } from './QuestionViewTitle';
import  AddIcon  from '@material-ui/icons/Add';

import {onBulkDownload} from "../../utils/onBulkDownload"

const AttachmentTable = lazy(() => import('../AttachmentTable'));
const AddAttachmentDialog = lazy(() => import('../AddAttachmentDialog'));
const AddPreparerDialog = lazy(() => import('../AddPreparerDialog'));
const AnswerHistoryDialog = lazy(() => import('../AnswerHistoryDialog'));
const PreparerTable = lazy(() => import('../PreparerTable'));
const QuestionCheckboxView = lazy(() => import('./QuestionCheckboxView'));
const QuestionChoiceView = lazy(() => import('./QuestionChoiceView'));
const QuestionRankingView = lazy(() => import('./QuestionRankingView'));
const QuestionRatingView = lazy(() => import('./QuestionRatingView'));
const QuestionTextView = lazy(() => import('./QuestionTextView'));
const QuestionYesNoView = lazy(() => import('./QuestionYesNoView'));
const QuestionDateView = lazy(() => import('./QuestionDateView'));
const QuestionComment = lazy(() => import('./QuestionComment'));

export interface QuestionViewProps {
  question: Question;
  section?: Section;
}

const useStyles = makeStyles((theme: Theme) => ({
  alignRight: {
    justifyContent: 'space-between',
    display: 'flex',
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  button: {
    background: theme.palette.secondary.main,
    color: theme.palette.common.white,
  },
  tooltip: {
    backgroundColor: '#000000', // Custom tooltip background color
    color: '#ffffff', // Custom tooltip text color
    fontSize: theme.typography.pxToRem(12), // Custom font size
  },
}));

const QuestionView = ({ question, section }: QuestionViewProps) => {
  const { user, hasAnyRoles } = useUserState();
  const { assessment, isProcessing, requestStatus } = useAssessmentManager();
  const {
    getQuestionAnswer,
    updateQuestionAnswer,
    assign,
    deletePreparer,
    hasAnyActions,
    assessmentRequest,
  } = useAssessmentRequest();
  const [selectedDate, setSelectedDate] = useState<moment.Moment>(moment(new Date()));
  const hasNoPermission = !hasAnyActions('ASSESSMENT_SUBMIT');
  const questionAnswer = getQuestionAnswer(question.id);
  const { uploadAttachment: uploadFn, updateAttachment, deleteAttachment } = useQuestionManager(question.id);
  const { uploadAttachment: _uploadAttachment, uploadProgress } = useAttachment();

  const classes = useStyles({});

  const onCommentChange = (question: Question, oldAnswer: QuestionAnswerDto) =>
    debounce(value => {
      const newAnswer = new QuestionAnswerDto({ ...oldAnswer, comment: value });
      updateQuestionAnswer(question, newAnswer);
    }, 100);

  const getQuestion = (question, questionAnswer) => {
    // Text Question
    const onDetailChange = (question: Question, oldAnswer: QuestionAnswerDto) =>
      debounce(value => {
        const newAnswer = QuestionAnswerDto.build(oldAnswer).updateAnswer({ detail: value });
        updateQuestionAnswer(question, newAnswer);
      }, 100);

    // YesNo Question
    const onYesNoChange = (question: Question, oldAnswer: QuestionAnswerDto) => (e: any) => {
      const yesNo = e.target.value === 'true';
      const newAnswer = QuestionAnswerDto.build(oldAnswer).updateAnswer({ yesNo: yesNo });
      updateQuestionAnswer(question, newAnswer);
    };

    // Checkbox Question
    const onCheckboxChange = (question: Question, oldAnswer: QuestionAnswerDto) => (e: any) => {
      const { choiceIds } = oldAnswer.answer;
      const choiceId = e.target.value;
      const newChoiceIds = e.target.checked ? choiceIds.concat(choiceId) : choiceIds.filter(id => id !== choiceId);

      let newAnswer = QuestionAnswerDto.build(oldAnswer).updateAnswer({ choiceIds: newChoiceIds });
      updateQuestionAnswer(question, newAnswer);
    };

    // Choice Question
    const onChoiceChange = (question: Question, oldAnswer: QuestionAnswerDto) => (e: any) => {
      const newAnswer = QuestionAnswerDto.build(oldAnswer).updateAnswer({ choiceId: e.target.value });
      updateQuestionAnswer(question, newAnswer);
    };

    // Ranking Question
    const onRankingChange = (question: Question, oldAnswer: QuestionAnswerDto) => (value: any) => {
      const newAnswer = QuestionAnswerDto.build(oldAnswer).updateAnswer({ choiceIds: value });
      updateQuestionAnswer(question, newAnswer);
    };

    // Rating Question
    const onRatingChange = (question: Question, oldAnswer: QuestionAnswerDto) => (value: any) => {
      const newAnswer = QuestionAnswerDto.build(oldAnswer).updateAnswer({ rating: value });
      updateQuestionAnswer(question, newAnswer);
    };

    // Date Question
    const onDateChange = (question: Question, oldAnswer: QuestionAnswerDto) => (value: moment.Moment) => {
      const newAnswer = QuestionAnswerDto.build(oldAnswer).updateAnswer({ date: value.toDate() });
      updateQuestionAnswer(question, newAnswer);
    };
    const props = {
      disabled: hasNoPermission,
      question: question,
      questionAnswer: questionAnswer,
    };
    switch (question.control) {
      case 'checkbox':
        return (
          <QuestionCheckboxView
            {...props}
            choices={question.choices}
            onChange={onCheckboxChange(question, questionAnswer)}
          />
        );
      case 'choice':
        return (
          <QuestionChoiceView
            {...props}
            choices={question.choices}
            onChange={onChoiceChange(question, questionAnswer)}
          />
        );
      case 'yesno':
        return <QuestionYesNoView {...props} onChange={onYesNoChange(question, questionAnswer)} />;
      case 'rating':
        return <QuestionRatingView {...props} onChange={onRatingChange(question, questionAnswer)} />;
      case 'date':
        return <QuestionDateView {...props} onChange={onDateChange(question, questionAnswer)} />;
      case 'ranking':
        return (
          <QuestionRankingView
            {...props}
            choices={question.choices}
            onChange={onRankingChange(question, questionAnswer)}
          />
        );
      case 'text':
        return <QuestionTextView {...props} onChange={e => onDetailChange(question, questionAnswer)(e.target.value)} />;
      default:
    }
  };


  


  return (
    <Box border={0} borderColor="grey.300" paddingLeft={7} paddingRight={1} paddingBottom={1} paddingTop={1}>
      <QuestionViewTitle
        title={`${(question.no && question.no) || ''} ${question.title}`}
        readable={question.permission.readable}
      />
      <Box paddingLeft={5}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Suspense fallback={null}>{getQuestion(question, questionAnswer)}</Suspense>
          </Grid>
          {question.permission.readable && (
            <>
              <Suspense fallback={null}>
                <Grid item xs={12}>
                  <Grid container direction="row" justify="flex-end" alignItems="center">
                    <Grid item>
                      <div style={{ position: 'relative', bottom: -15 }}>
                        <AnswerHistoryDialog
                          assessmentNo={assessment.no}
                          question={question}
                          rootId={assessmentRequest.rootId}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Suspense>
              <Suspense fallback={null}>
                <QuestionComment
                  question={question}
                  questionAnswer={questionAnswer}
                  onChange={e => {
                    onCommentChange(question, questionAnswer)(e.target.value);
                  }}
                />
              </Suspense>

              {question.requiredCompletedDate && (
                <Grid item xs={12}>
                  <KeyboardDatePicker
                    margin="normal"
                    label="วันที่กำหนดแล้วเสร็จ / Completed Date"
                    placeholder="2018/10/10"
                    value={(question.permission.readable && selectedDate) || null}
                    onChange={handleDateChange}
                    format="DD/MM/YYYY"
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    disabled={!question.requiredCompletedDate || !question.permission.writeable}
                  />
                </Grid>
              )}

              {assessment.currentStatus === 'Assessment' && question.permission.writeable && (
                <Grid item xs={12}>
                  <Suspense fallback={null}>
                  <AddAttachmentDialog
                              question={question}
                              section={section}
                              onSubmit={data =>
                                _uploadAttachment({ ...data, rootId: assessmentRequest.rootId }, 'question', uploadFn)
                              }
                              uploadProgress={uploadProgress}
                              itemId={question.id}
                              isLoading={isProcessing('add-attachment')}
                            />
                    {/* <Box className={classes.alignRight}>
                          <AddAttachmentDialog
                              question={question}
                              section={section}
                              onSubmit={data =>
                                _uploadAttachment({ ...data, rootId: assessmentRequest.rootId }, 'question', uploadFn)
                              }
                              uploadProgress={uploadProgress}
                              itemId={question.id}
                              isLoading={isProcessing('add-attachment')}
                            />
                          <Tooltip title="Download All" aria-label="Download All">
                            <Button variant="contained" color="primary" size="small" 
                              className={classes.button}
                              onClick={()=>{onBulkDownload(question)}} 
                              >
                                Download All
                              </Button>
                          </Tooltip>
                    </Box> */}
                  
                  </Suspense>
                </Grid>
              )}

              {['Submitted', 'Reviewed'].includes(requestStatus) && hasAnyRoles('ADMIN') && (
                <Grid item xs={12}>
                  <Suspense fallback={null}>
                  <Box className={classes.alignRight}>
                                
                          <AddAttachmentDialog
                            question={question}
                            section={section}
                            onSubmit={data =>
                              _uploadAttachment({ ...data, rootId: assessmentRequest.rootId, folder: 'admin' }, 'question', uploadFn)
                            }
                            uploadProgress={uploadProgress}
                            itemId={question.id}
                            isLoading={isProcessing('add-attachment')}
                          />

                          <Tooltip title="Download All" aria-label="Download All">
                                <Button variant="contained" color="secondary" size="small" 
                                  className={classes.button}
                                  onClick={()=>{onBulkDownload(question)}} 
                                  >
                                    Download All
                                  </Button>
                              </Tooltip>

                         </Box>
 
                  </Suspense>
                </Grid>
              )}
              <Grid item xs={12}>
                <Suspense fallback={null}>
                  {question.permission.readable ? (
                    <>
                        <AttachmentTable
                          files={question.attachments || []}
                          handleEdit={updateAttachment}
                          handleDelete={deleteAttachment}
                        />
                    </>
                  ) : (
                    <AttachmentTable files={[]} handleEdit={undefined} handleDelete={undefined} />
                  )}
                </Suspense>
              </Grid>
              {assessment.currentStatus === 'Assessment' && hasAnyPermission('ASSESSOR', 'PREPARER_1ST') && (
                <React.Fragment>
                  {hasAnyActions('ASSESSMENT_SUBMIT_FOR_ASSIGNMENT') && (
                    <Grid item xs={12}>
                      <Suspense fallback={null}>
                    
                        <AddPreparerDialog
                          disabled={!hasAnyActions('ASSESSMENT_SUBMIT_FOR_ASSIGNMENT')}
                          question={question}
                          handleDelete={deletePreparer}
                          handleSubmit={assign}
                          loading={isProcessing('assign-preparer')}
                        />



                      </Suspense>
                    </Grid>
                  )}
                  <Suspense fallback={null}>
                    <Grid item xs={12}>
                      <Box marginBottom={2}>
                        
                        <PreparerTable question={question} handleDelete={deletePreparer} />
                      </Box>
                    </Grid>
                  </Suspense>
                </React.Fragment>
              )}
              <StatusVisible requiredRequestStatus={['Assessment', 'Submitted', 'Reviewed']}>
                <Grid item xs={12}>
                  <AdminReviewQuestionInputBox
                    ableToComment={hasAnyActions('ADMIN_REVIEW_COMMENT')}
                    ableToFollowUp={hasAnyActions('ADMIN_REVIEW_FOLLOW_UP')}
                    loading={isProcessing('admin-review-loading')}
                    disabled={!hasAnyRoles('ADMIN') || !['Submitted', 'Reviewed'].includes(requestStatus)}
                    questionId={question.id}
                  />
                </Grid>
              </StatusVisible>
            </>
          )}
        </Grid>
      </Box>
    </Box>
  );

  function handleDateChange(date: moment.Moment | null) {
    setSelectedDate(date);
  }

  function hasAnyPermission(...assessorRoles: AssessorRole[]) {
    return question.assessors.some(a => a.employeeId === user.id && assessorRoles.includes(a.assessorRole));
  }
};

export { QuestionView };

export default QuestionView;
