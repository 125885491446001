import React from 'react';
import { Link as ReactRouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';

// required for react-router-dom < 6.0.0
// see https://github.com/ReactTraining/react-router/issues/6056#issuecomment-435524678
export const AdapterLink = React.forwardRef<HTMLAnchorElement, RouterLinkProps>((props, ref) => (
  <ReactRouterLink innerRef={ref as any} {...props} />
));

const RouterLink = (to: string) => (props: any) => <ReactRouterLink {...props} to={to} />;

export default RouterLink;
