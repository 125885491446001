import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import MuiFormGroup from '@material-ui/core/FormGroup';

const FormGroup = withStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      marginTop: theme.spacing(1.5),
    },
  }),
)(MuiFormGroup);

export { FormGroup };

export default FormGroup;
