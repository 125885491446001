import React, { createContext, useState } from 'react';

export const ProcessingContext = createContext<any>(null);

export const useIsProcessing = () => {
  const [_isProcessing, _setIsProcessing] = useState<{ [key: string]: boolean }>({});

  const setIsProcessing = (key: string, flag: boolean): void => {
    _setIsProcessing({
      ..._isProcessing,
      [key]: flag,
    });
  };

  /**
   * Check if the name of processing is true
   */
  const isProcessing = (...keys: string[]): boolean => {
    return keys.length === 0
      ? Object.values(_isProcessing).includes(true)
      : Object.keys(_isProcessing)
          .filter(k => keys.includes(k))
          .map(k => _isProcessing[k])
          .includes(true);
  };

  return { isProcessing, setIsProcessing };
};

export const ProcessingProvider = ({ children }) => {
  const value = useIsProcessing();
  return <ProcessingContext.Provider value={value}>{children}</ProcessingContext.Provider>;
};
