import { Question, Answer } from './assessment';

export interface YesNoSummarize {
  answers: Answer[];
  percent: number;
  total: number;
}

export class QuestionYesNoSummarize {
  constructor(
    public readonly question: Question,
    public readonly total: number,
    public readonly noAnswer: YesNoSummarize,
    public readonly yesAnswer: YesNoSummarize,
  ) {}
}
