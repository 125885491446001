import React from 'react';
import { Typography } from '@material-ui/core';
import { TypographyProps } from '@material-ui/core/Typography';

const ComponentText = ({ style, ...props }: TypographyProps) => {
  const mergeStyle: React.CSSProperties = {
    wordBreak: 'break-word',
    whiteSpace: 'pre-wrap',
    ...style,
  };
  return <Typography component="div" style={mergeStyle} {...props} />;
};

export default ComponentText;
