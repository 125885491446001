import { client } from './client';
import { LOOKUP_URI } from './constants';

import { LookupCreateRequest, LookupUpdateRequest } from '../models/master-dto';

export async function create(request: LookupCreateRequest) {
  return await client.post(`${LOOKUP_URI}`, request, {});
}

export async function update(id: number, request: LookupUpdateRequest) {
  return await client.put(`${LOOKUP_URI}/${id}`, request, {});
}

export async function deleteOne(id: number) {
  return await client.delete(`${LOOKUP_URI}/${id}`, {});
}
