import React from 'react';

import { Box, Divider, ExpansionPanelDetails, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ExpansionPanelProps } from '@material-ui/core/ExpansionPanel';
import ListIcon from '@material-ui/icons/List';
import LazyLoad from 'react-lazyload';

import { Component } from '../../models';
import { AssessmentPanel, AssessmentPanelSummary } from './AssessmentPanel';
import { useAssessmentManager, useComponentManager } from './AssessmentContext';
import AssessmentComponent from './AssessmentComponent';

const useStyles = makeStyles((theme: Theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightMedium,
  },
  content: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    display: 'block',
  },
  component: {
    background: 'rgba(84, 179, 169, 0.03)',
  },
}));

interface QuestionListSectionProps {
  expansionProps?: ExpansionPanelProps;
}

function QuestionListSection({ expansionProps }: QuestionListSectionProps) {
  const classes = useStyles({});
  const { assessment, components } = useAssessmentManager();
  const { questions, sections } = useComponentManager();

  return (
    <AssessmentPanel {...expansionProps}>
      <AssessmentPanelSummary aria-controls="question-list-content" id="question-list-header">
        <Typography className={classes.heading}>
          <ListIcon color="primary" />
          รายการคำถาม / Question List
        </Typography>
      </AssessmentPanelSummary>
      <Divider variant="middle" />
      <ExpansionPanelDetails className={classes.content}>
        {assessment.no ? (
          components.map((comp: Component, index: number) => (
            <Box border={1} borderColor="grey.300" marginBottom={4} className={classes.component} key={comp.id}>
              <AssessmentComponent
                assessment={assessment}
                component={comp}
                questions={questions && questions.filter(q => comp.id === q.componentId)}
                sections={sections && sections.filter(q => comp.id === q.componentId)}
              />
            </Box>
          ))
        ) : (
          <Typography variant="h6" gutterBottom>
            กรุณาทำการบันทึกใบประเมินก่อน เพื่อสร้างคำถาม
          </Typography>
        )}
      </ExpansionPanelDetails>
    </AssessmentPanel>
  );
}

export default QuestionListSection;
