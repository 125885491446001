import moment from 'moment';

import { BaseModel } from './base';
import { StatusTypes, AttachmentFile } from './master';
import { Employee } from './user';

export type AssessmentLevel = 'CO' | 'PC';
export type ControlTypes = 'section' | 'yesno' | 'rating' | 'checkbox' | 'choice' | 'ranking' | 'date' | 'text';

export class Assessment extends BaseModel {
  no: string;
  title: string;
  description: string;
  publishDate?: moment.Moment;
  publishDateNextRound: moment.Moment;
  startDate: moment.Moment;
  endDate: moment.Moment;
  code: string;
  level: AssessmentLevel;
  status: StatusTypes;
  year: number;
  round?: number;
  createdByInitialName?: string;
  createdByName?: string;
  processId?: string;
  processName?: string;
  processOwnerId?: string;
  processOwnerInitial?: string;
  processOwnerName?: string;
  processOwnerLevelId?: number;
  processOwnerLevelName?: string;
  processOwnerDepartmentId?: string;
  processOwnerDepartmentInitialName?: string;
  processOwnerDepartmentNameTh?: string;
  processOwnerDepartmentNameEn?: string;
  processOwnerDepartmentFullNameTh?: string;
  processOwnerDepartmentFullNameEn?: string;
  processOwnerPositionId?: string;
  processOwnerPositionNameTh?: string;
  processOwnerPositionNameEn?: string;
  processInstanceId?: string;
  assessmentModifiedHistories?: ModifiedHistory[];
  assessmentModifiedHistory?: ModifiedHistory;
  currentStatus?: 'Draft' | 'Submitted' | 'Assessment' | 'Summarize' | 'Completed' | 'Cancelled';
  statusText?: string;
}

export class Commentator {
  comment?: string;
}

export class Component extends BaseModel {
  id?: string;
  nameTh: string;
  nameEn: string;
  assessmentId: string;
  questions?: Question[] = [];
  sections?: Section[] = [];
  commentator?: Commentator;
  fullname: string;
}

export class Question extends BaseModel {
  id?: string;
  no?: string;
  title: string;
  control: ControlTypes;
  required?: boolean;
  requiredComment?: boolean;
  requiredAttachment?: boolean;
  requiredCompletedDate?: boolean;
  noRequired?: boolean;
  noRequiredAttachment?: boolean;
  noRequiredComment?: boolean;
  adminComment?: string;
  sequence: number;
  comment?: string;
  commentPlaceholder?: string;
  status?: StatusTypes;
  sectionId: string;
  componentId: string;
  assessors: Assessor[];
  answers: Answer[];
  choices?: Choice[];
  maxRate?: number;
  answer: Answer;
  attachments: AttachmentFile[];
  isAssessor: boolean;
  permission: QuestionPermision;

  constructor(partial: Partial<Question> = {}) {
    super(partial);
    Object.assign(this, { ...partial });
  }
}

export class Section extends BaseModel {
  id?: string;
  no?: string;
  title: string;
  description: string;
  sequence: number;
  active: boolean = true;
  questions: Question[];
  componentId: string;

  constructor(partial: Partial<Section> = {}) {
    super(partial);
    Object.assign(this, { ...partial });
  }
}

export class Answer extends BaseModel {
  id?: number;
  detail: string;
  questionId: string;
  comment: string;
  status: StatusTypes;
  choiceId: number;
  choiceIds: number[];
  yesNo: boolean;
  rating: number;
  date: Date;
  employee?: Employee;
  employeeInitialName?: string;
  employeeFirstName?: string;
  employeeLastName?: string;
  fullnameWithInitialName?: string;
  departmentId?: string;
  departmentNameEn?: string;
  departmentNameTh?: string;
  departmentShortName?: string;
}

export class Choice extends BaseModel {
  id?: number;
  title: string;
  sequence: number;
  required?: boolean = false;
  requiredAttachment?: boolean = false;
  requiredCompletedDate?: boolean = false;
  isOther?: boolean = false;
}

export class Assessor extends BaseModel {
  questionId: string;
  employeeId: string;
  status: any;
  assessorType: boolean;
  assessorRole: AssessorRole;
  departmentId: string;
  departmentLevelName: string;
  departmentLevelNo: number;
  departmentNameEn: string;
  departmentNameTh: string;
  departmentInitialName: string;
  departmentFullNameEn: string;
  departmentFullNameTh: string;
  employeeInitialName: string;
  employeeName: string;
  fullnameWithInitialName: string;
  statusText: string;
  isAssigned: boolean;
  commentator: boolean;
  currentStatus: 'Assessment' | 'Submitted';
  assignedBy: Employee;
  assignee: Employee;
  sortPriority?: number;
  componentId?: string;
  rootId?: string;
}

export type AssessorRole = 'ASSESSOR' | 'PREPARER_1ST' | 'PREPARER_2ND';

export class AssessmentEvent {
  id: number;
  assessmentNo: string;
  processInstanceId: string;
  parentProcessInstanceId: string;
  processCode: string;
  processName: string;
  activityCode: string;
  activityName: string;
  actionCode: string;
  actionName: string;
  actionById: string;
  actionByDescription: string;
  actionDate: Date;
  actionRole: string;
  originatorById: string;
  originatorByDescription: string;
  stageStartDate: Date;
  roleCode: string;
  roleName: string;
  remark: string;
  actionBy: Employee;
}

export class ModifiedHistory {
  changedClosingDate: string;
  closingReviseDate: string;
}

export type QuestionPermision = {
  readable: boolean;
  writeable: boolean;
  assignee: boolean;
};
